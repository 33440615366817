import styled from 'styled-components'

export const EmailSequence2Svg = styled.svg`
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to right, 
    black 75%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(to right, 
    black 75%,
    transparent 100%
  );
`
