import { round } from 'lodash'
import { AnalyticsCard } from 'src/components/charts/primitives/analytics-card'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { Grid } from 'src/components/primitives/grid'
import type { PipelineStats } from 'src/libs/api/backend/reports_pipeline_insights'

interface StatsCardsChartProps {
  data: PipelineStats
}

export const StatsCardsChart = ({ data }: StatsCardsChartProps): JSX.Element => {
  return (
    <Grid $columns={4} $columnGap={16} $rowGap={16} $width="100%">
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Outreach Effectiveness"
          value={data.outreachEffectiveness < 0 ? 'Not enough data' : `${round(data.outreachEffectiveness, 1)} Attempts`}
          variantConfig={{
            value: data.outreachEffectiveness,
            thresholds: { good: 2.5, average: 3, poor: 5 },
            higherIsBetter: false
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Time to First Reply"
          value={`${Math.round(data.timeToFirstReply / 1440).toString()} Days`}
          change={{ current: data.timeToFirstReply, past: data.pastTimeToFirstReply }}
          variantConfig={{
            value: Math.round(data.timeToFirstReply / 1440),
            thresholds: { good: 5 * 24 * 60, average: 8 * 24 * 60, poor: 10 * 24 * 60 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Open Rate"
          value={`${Math.round(data.openRate * 100).toString()}%`}
          change={{ current: data.openRate, past: data.pastOpenRate, changeType: 'percentage' }}
          variantConfig={{
            value: data.openRate,
            thresholds: { good: 0.55, average: 0.3, poor: 0.2 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Reply Rate"
          value={`${Math.round(data.replyRate * 100).toString()}%`}
          change={{ current: data.replyRate, past: data.pastReplyRate, changeType: 'percentage' }}
          variantConfig={{
            value: data.replyRate,
            thresholds: { good: 0.3, average: 0.15, poor: 0.05 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Interested Rate"
          value={`${Math.round(data.interestedRate * 100).toString()}%`}
          change={{ current: data.interestedRate, past: data.pastInterestedRate, changeType: 'percentage' }}
          variantConfig={{
            value: data.interestedRate,
            thresholds: { good: 0.08, average: 0.05, poor: 0.03 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Team Response Time"
          value={
            data.teamResponseTime === 0
              ? 'Not enough data'
              : `${Math.round(data.teamResponseTime).toString()} Min`
          }
          change={{ current: data.teamResponseTime, past: data.pastTeamResponseTime }}
          variantConfig={{
            value: data.teamResponseTime,
            thresholds: { good: 10, average: 20, poor: 30 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Time to First Interview"
          value={
            data.timeToFirstInterview
              ? `${Math.round(data.timeToFirstInterview / 1440).toString()} Days`
              : 'Not enough data'
          }
          // change={{ current: data.timeToFirstInterview, past: data.pastTimeToFirstInterview }}
          variantConfig={{
            value: data.timeToFirstInterview ?? 0,
            thresholds: { good: 12 * 24 * 60, average: 20 * 24 * 60, poor: 30 * 24 * 60 }
          }}
        />
      </ChartWrapper>
      <ChartWrapper $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
        <AnalyticsCard
          title="Scheduled Rate"
          value={`${Math.round(data.scheduledRate * 100).toString()}%`}
          change={{ current: data.scheduledRate, past: data.pastScheduledRate, changeType: 'percentage' }}
          variantConfig={{
            value: data.scheduledRate,
            thresholds: { good: 0.05, average: 0.03, poor: 0.01 }
          }}
        />
      </ChartWrapper>
    </Grid>
  )
}
