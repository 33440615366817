import * as Dialog from 'src/components/primitives/dialog'
import * as S from './send-feedback-dialog.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Button, Flex } from 'src/components/primitives'
import { useCallback, useMemo, useState } from 'react'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useTheme } from 'styled-components'
import { Editor } from 'src/components/blocks/editor'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import type { Editor as TiptapEditor } from '@tiptap/react'
import { MAX_INLINE_IMAGE_SIZE } from 'src/components/blocks/editor/types'
import { compressAndGenerateUrl, getCompressedQuality } from 'src/utils/compress-image'
import { UploadAttachments } from 'src/components/blocks/upload-attachments'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom } from 'src/stores/dialogs'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Divider } from 'src/components/primitives/divider'
import { BookADemoButton } from 'src/components/blocks/book-a-demo-button'
import { AnimatePresence, motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

const FeedbackTypeButton = ({ label, onClick, selected }: { label: string, onClick: () => void, selected: boolean }): JSX.Element => {
  return (
    <Button
      ariaLabel={label}
      $variant={selected ? 'raised-light' : 'ghost'}
      $height={24}
      $fontSize={12}
      onClick={onClick}
    >
      <S.FeedbackLabel>{label}</S.FeedbackLabel>
    </Button>
  )
}

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp']

const AnimatedContent = ({ children, motionKey }: { children: React.ReactNode, motionKey: string }): JSX.Element => {
  return (
    <motion.div
      key={motionKey}
      initial={{ opacity: 0, y: 6 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -6 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}

export interface FeedbackDialogPayload {
  type?: FeedbackType
  defaultContent?: string
}

export const SendFeedbackDialog = ({ type, defaultContent }: FeedbackDialogPayload): JSX.Element => {
  const { jobSearchRefinementId } = useParams()
  const { colors } = useTheme()
  const [view, setView] = useState<'FEEDBACK' | 'SUCCESS'>('FEEDBACK')
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.SEND_FEEDBACK), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const [uploadingFile, setUploadingFile] = useState<string | null>(null)
  const { submitFeedback } = useSubmitFeedback()
  const [content, setContent] = useState<string>('')
  const [editor, setEditor] = useState<TiptapEditor | null>(null)
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(type ?? FeedbackType.FEATURE_REQUEST)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const handleUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event?.target?.files?.[0]
    if (!file) {
      return
    }
    setUploadingFile(file.name)
    const quality = getCompressedQuality(file.size, MAX_INLINE_IMAGE_SIZE)
    const src = await compressAndGenerateUrl({ file, quality })
    if (editor && src) {
      const { schema } = editor.view.state
      const node = schema.nodes.image.create({
        src: src.viewSignedUrl,
        alt: file.name
      })
      const transaction = editor.view.state.tr.insert(editor.view.state.selection.from, node)
      editor.view.dispatch(transaction)
    }
    setUploadingFile(null)
  }, [editor])

  return (
    <Dialog.Root
      id={DialogId.SEND_FEEDBACK}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.SEND_FEEDBACK, newState: value })
      }}
      $width='640px'
      $maxWidth='100%'
      $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
      modal={false}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Send Feedback"
          onClose={() => {
            controlDialog({ id: DialogId.SEND_FEEDBACK, newState: false })
          }}
        />
        <Dialog.Content>
          <AnimatePresence mode="wait" initial={false}>
            <AnimatedContent motionKey={view}>
              {view === 'FEEDBACK' && (
                  <Flex $direction='column' $gap={0} $height='full'>
                    <S.BannerText>
                      <Caption size='XS' $fontWeight={400}>Thank you for being a part of our Beta testing. </Caption>
                      <Caption size='XS' $fontWeight={400}>If you have a request, idea or bug to report, please send us feedback</Caption>
                    </S.BannerText>
                    <S.FeedbackView>
                      <S.FeedbackTitle>
                        <ButtonGroup
                          $variant='ghost'
                          $direction='horizontal'
                          $padding={4}
                          $gap={4}
                          $backgroundColor={colors.bgTertiary}
                          $borderRadius={4}
                          $groupBorderRadius={8}
                        >
                          <FeedbackTypeButton
                            label='Feature request'
                            onClick={() => {
                              setFeedbackType(FeedbackType.FEATURE_REQUEST)
                            }}
                            selected={feedbackType === FeedbackType.FEATURE_REQUEST}
                          />
                          <FeedbackTypeButton
                            label='Idea'
                            onClick={() => {
                              setFeedbackType(FeedbackType.IDEA)
                            }}
                            selected={feedbackType === FeedbackType.IDEA}
                          />
                          <FeedbackTypeButton
                            label='Bug report'
                            onClick={() => {
                              setFeedbackType(FeedbackType.BUG_REPORT)
                            }}
                            selected={feedbackType === FeedbackType.BUG_REPORT}
                          />
                          <FeedbackTypeButton
                            label='Other'
                            onClick={() => {
                              setFeedbackType(FeedbackType.OTHER)
                            }}
                            selected={feedbackType === FeedbackType.OTHER}
                          />
                        </ButtonGroup>
                      </S.FeedbackTitle>
                      <S.FeedbackContent>
                        <Editor
                          initialContent={defaultContent}
                          onDataChanged={(data) => {
                            setContent(data)
                          }}
                          contentType="html"
                          placeholder="Write your feedback..."
                          setEditor={setEditor}
                          $minHeight='20rem'
                          $editorHeight='fit-content'
                          $maxHeight='calc(100vh - 20rem)'
                          useFileHandler={true}
                          forceEditorFocus
                          toolbar={
                            <S.Toolbar>
                              <Button
                                $variant='flat'
                                $colorTheme='tint'
                                $height={24}
                                $fontSize={12}
                                disabled={editor?.isEmpty}
                                onClick={() => {
                                  setIsSubmitting(true)
                                  submitFeedback({
                                    feedback: {
                                      body: content,
                                      type: feedbackType,
                                      jobSearchRefinementId,
                                      url: window.location.href
                                    },
                                    onSuccess: () => {
                                      setView('SUCCESS')
                                      setIsSubmitting(false)
                                      // controlDialog({ id: DialogId.SEND_FEEDBACK, newState: false })
                                    }
                                  })
                                }}
                                loading={isSubmitting}
                              >
                                Send feedback
                              </Button>
                              { uploadingFile
                                ? (
                                    <S.FeedbackUploading>
                                      <Caption size='XS' $fontWeight={400}>Uploading {uploadingFile}...</Caption>
                                    </S.FeedbackUploading>
                                  )
                                : (
                                    <UploadAttachments
                                      accept={ALLOWED_FILE_TYPES.join(',')}
                                      handleUpload={handleUpload}
                                    />
                                  )
                              }
                            </S.Toolbar>
                          }
                        />
                      </S.FeedbackContent>
                    </S.FeedbackView>
                  </Flex>
              )}
              {view === 'SUCCESS' && (
                <S.SuccessView>
                  <S.SuccessIcon>
                    <Icon name={Icons.check} size={32} color="bgPrimary" />
                  </S.SuccessIcon>
                  <Flex $direction='column' $gap={4}>
                    <Caption size='LG' $fontWeight={400}>Thank you!</Caption>
                    <Paragraph size="SM" $color="fgSecondary">Feedback received and we&apos;ll check it soon.</Paragraph>
                  </Flex>
                  <Divider $spacing={{ before: 28, after: 28 }} />
                  <Flex $direction='column' $gap={24}>
                    <Flex $direction='column' $gap={4}>
                      <Caption size="MD">Would you like to schedule a demo call with us?</Caption>
                      <Paragraph size="SM" $color="fgSecondary">Our team can help you get the most out of Pin.</Paragraph>
                    </Flex>
                    <BookADemoButton />
                  </Flex>
                </S.SuccessView>
              )}
            </AnimatedContent>
          </AnimatePresence>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
