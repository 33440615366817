import { Heading, Paragraph } from 'src/components/primitives/typography'
import * as S from './analytics-card.styled'
import { Badge } from 'src/components/primitives/badge'
import type { BadgeStyleProps } from 'src/components/primitives/badge'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import type { Color } from 'src/styles/theme/types'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { useReportsShouldCompareToPreviousPeriod } from 'src/hooks/use-reports-should-compare-to-previous-period'
import { round } from 'lodash'

interface AnalyticsCardStyleProps {
  $variant?: 'positive' | 'warning' | 'negative' | 'neutral'
}

interface ThresholdConfig {
  good?: number
  average?: number
  poor?: number
}

interface VariantConfig {
  value: number
  thresholds?: ThresholdConfig
  higherIsBetter?: boolean
}

interface AnalyticsCardProps extends AnalyticsCardStyleProps {
  title?: string
  value?: string
  change?: {
    current: number
    past: number
    changeType?: 'percentage' | 'absolute'
  }
  helpText?: string
  variantConfig?: VariantConfig
}

interface GetVariantArgs {
  value: number
  thresholds?: ThresholdConfig
  higherIsBetter?: boolean
}

const getVariant = ({
  value,
  thresholds = { good: 0.75, average: 0.5, poor: 0.25 },
  higherIsBetter = true
}: GetVariantArgs): AnalyticsCardStyleProps['$variant'] => {
  const { good, average, poor } = thresholds

  if (higherIsBetter) {
    if (good !== undefined && value >= good) return 'positive'
    if (average !== undefined && value <= average) return 'warning'
    if (poor !== undefined && value <= poor) return 'negative'
  } else {
    if (good !== undefined && value <= good) return 'positive'
    if (average !== undefined && value >= average) return 'warning'
    if (poor !== undefined && value >= poor) return 'negative'
  }

  return 'neutral'
}

export const AnalyticsCard = ({
  title,
  value,
  change,
  helpText,
  $variant = 'neutral',
  variantConfig
}: AnalyticsCardProps): JSX.Element => {
  const { shouldCompare } = useReportsShouldCompareToPreviousPeriod()

  let higherIsBetter = true
  if (
    variantConfig?.thresholds?.good !== undefined &&
    variantConfig?.thresholds?.poor !== undefined &&
    variantConfig?.thresholds?.good < variantConfig?.thresholds?.poor
  ) {
    higherIsBetter = false
  }

  const calculatedVariant =
    variantConfig?.value !== undefined
      ? getVariant({
        value: variantConfig.value,
        thresholds: variantConfig.thresholds,
        higherIsBetter
      })
      : $variant

  const getDisplay = (): {
    badgeVariant: BadgeStyleProps['$variant']
    badgeText: string
  } => {
    switch (calculatedVariant) {
      case 'positive':
        return {
          badgeVariant: 'positiveLight',
          badgeText: 'Great'
        }
      case 'warning':
        return {
          badgeVariant: 'warningLight',
          badgeText: 'Below Average'
        }
      case 'negative':
        return {
          badgeVariant: 'negativeLight',
          badgeText: 'Improvement Needed'
        }
      default:
        return {
          badgeVariant: 'positiveLight',
          badgeText: 'Average'
        }
    }
  }

  const changeDisplay = (): {
    changeColor: Color
    changeIcon: IconName
  } | undefined => {
    if (!change?.current || !change?.past) {
      return undefined
    }

    const isIncreasing = change.current > change.past
    const isPositive = higherIsBetter ? isIncreasing : !isIncreasing

    return {
      changeColor: isPositive ? 'positiveFg' : 'warningFg',
      changeIcon: isIncreasing ? 'arrow-up' : 'arrow-down'
    }
  }

  const showVariantBadge = (variantConfig?.value ?? 0) > 0

  return (
    <S.Card>
      <Flex $align="center" $justify="space-between">
        <Paragraph size="XS" $color="fgSecondary">
          {title}
        </Paragraph>
        {helpText && (
          <Button
            nested
            $variant="ghost"
            $colorTheme="muted"
            $width={12}
            $height={12}
            leadingIcon="info"
            iconSize={12}
            tooltip={{
              text: helpText
            }}
          />
        )}
      </Flex>
      <Flex $align="center" $justify="space-between">
        <Flex $align="center" $gap={12} $width="fit-content">
          <Heading size="MD" $color="fgPrimary" $whiteSpace="nowrap">
            {value}
          </Heading>
          {shouldCompare && change?.current !== undefined && change?.past !== undefined && change?.past !== 0 &&
            (() => {
              const displayInfo = changeDisplay()
              return (
                <Flex $align="center" $gap={2}>
                  <Paragraph size="XS" $color={displayInfo?.changeColor} $whiteSpace="nowrap" $lineHeight={1}>
                    {change.past === 0 && change.current > 0
                      ? '+100%'
                      : change.past === 0 && change.current === 0
                        ? '0%'
                        : change.changeType === 'percentage'
                          ? round((change.current * 100 - change.past * 100), 1)
                          : (() => {
                              const percentChange = Math.round(((change.current - change.past) / change.past) * 100)
                              return percentChange > 999 ? '+999%' : `${percentChange}%`
                            })()
                    }
                  </Paragraph>
                  {displayInfo ? <Icon name={displayInfo.changeIcon} color={displayInfo.changeColor} size={12} /> : <></>}
                </Flex>
              )
            })()}
        </Flex>
        {showVariantBadge ? <Badge $variant={getDisplay().badgeVariant}>{getDisplay().badgeText}</Badge> : <></>}
      </Flex>
    </S.Card>
  )
}
