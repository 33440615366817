import { useMemo } from 'react'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption } from 'src/components/primitives/typography'
import { Button } from 'src/components/primitives/button'
import * as S from './send-all-from-queue-dialog.styled'
import { StandaloneCheckbox } from 'src/components/forms/checkbox/standalone-checkbox'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Flex } from 'src/components/primitives/flex'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import type { ManualQueueItem } from 'src/libs/api/backend/manual_queue'

export interface SendAllFromQueueDialogProps {
  queueItems: Record<ManualQueueItem['id'], ManualQueueItem>
}

export const SendAllFromQueueDialog = ({ queueItems }: SendAllFromQueueDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.SEND_ALL_FROM_QUEUE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)

  const { emailItems, linkedinItems } = useMemo(() => {
    const items = Object.values(queueItems)

    return {
      emailItems: items.filter(item => item.candidateJob.statusDisplay?.status !== 'MISSING_EMAIL'),
      linkedinItems: items.filter(item => item.candidateJob.statusDisplay?.status === 'MISSING_EMAIL')
    }
  }, [queueItems])

  return (
    <Dialog.Root
      id={DialogId.SEND_ALL_FROM_QUEUE}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.SEND_ALL_FROM_QUEUE, newState: value })
      }}
      $maxWidth="608px"
      $innerPadding={{ top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Send All Messages"
          onClose={() => { controlDialog({ id: DialogId.SEND_ALL_FROM_QUEUE, newState: false }) }}
        />
        <Dialog.Content>
          <S.Content>
            <Caption size="SM" $color="fgSecondary">Are you sure you want to send all messages in queue?</Caption>
            <S.Form>
              <StandaloneCheckbox
                leadingIcon={
                  <Flex $align="center" $gap={8} $width="fit-content" as="span">
                    <Icon name={Icons.mail} size={16} color="fgSecondary" />
                    <div />
                  </Flex>
                }
                label={`${emailItems.length} Emails`}
                name="emailMessages"
                $marginBottom={0}
                defaultChecked={true}
              />
              <StandaloneCheckbox
                label={`${linkedinItems.length} LinkedIn Messages`}
                leadingIcon={
                  <Flex $align="center" $gap={8} $width="fit-content" as="span">
                    <BrandIcon name="linkedin" size={16} color="fgSecondary" />
                    <div />
                  </Flex>
                }
                name="linkedinMessages"
                defaultChecked={true}
                $marginBottom={0}
              />
            </S.Form>
          </S.Content>
          <S.Actions>
            <Button
              $variant="fill"
              $colorTheme="tint"
              $height={40}
            >
              Send {emailItems.length + linkedinItems.length} Messages
            </Button>
            <Button
              $variant="raised"
              $colorTheme="muted"
              $height={40}
              onClick={() => { closeDialog(DialogId.SEND_ALL_FROM_QUEUE) }}
            >
              Cancel
            </Button>
          </S.Actions>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
