import * as Dialog from 'src/components/primitives/dialog'
import { AddCandidateForm } from 'src/components/blocks/add-candidate-form'
import { controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const AddCandidateDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.ADD_CANDIDATE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { jobId } = useParams()

  return (
    <Dialog.Root
      id={DialogId.ADD_CANDIDATE}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({
          id: DialogId.ADD_CANDIDATE,
          newState: value
        })
      }}
      $maxWidth="600px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Add New Candidates"
          onClose={() => {
            controlDialog({
              id: DialogId.ADD_CANDIDATE,
              newState: false
            })
          }}
        />
        <Dialog.Content>
          {jobId && <AddCandidateForm jobId={jobId} />}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
