import { useRef } from 'react'
import { Button } from 'src/components/primitives/button'
import { Avatar } from 'src/components/primitives/avatar'
import type { Job } from 'src/libs/api/backend/jobs'
import * as S from './trigger.styled'
import { DepartmentLogo } from '../department-logo'
import { useLocation } from 'react-router-dom'

interface TriggerProps {
  isCollapsed: boolean
  isLoading?: boolean
  selectedJob?: Job
  logoUrl?: string | null
}

export const Trigger = ({
  isCollapsed,
  isLoading = false,
  selectedJob,
  logoUrl
}: TriggerProps): JSX.Element => {
  const triggerRef = useRef<HTMLElement>(null)
  const { pathname } = useLocation()

  const getDisplayText = (title: string | undefined): string => {
    if (isLoading) {
      return ''
    } else {
      if (pathname.startsWith('/all')) {
        return 'All Job Positions'
      } else if (!title || title === undefined) {
        return 'Select a job'
      } else {
        return title ?? ' '
      }
    }
  }

  return (
    <S.Trigger ref={triggerRef}>
      <Button
        nested
        $variant="raised"
        $colorTheme="normal"
        $width={isCollapsed ? 36 : 'full'}
        $innerMinWidth="0"
        $height={40}
        $align="space-between"
        trailingIcon={!isCollapsed && 'chevrons-up-down-small'}
      >
        {selectedJob?.departmentId
          ? <DepartmentLogo departmentId={selectedJob.departmentId} />
          : <Avatar
              $size={16}
              photoUrl={logoUrl}
              $border={false}
              $shape="soft"
              $type="logo"
            />
        }
        {!isCollapsed && <S.Text>{getDisplayText(selectedJob?.title)}</S.Text>}
      </Button>
    </S.Trigger>
  )
}
