import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { TileChart } from 'src/components/charts/primitives/tile-chart'
import type { EventsByTimeReport } from 'src/libs/api/backend/reports_communication_insights'

interface RepliesByTimeReceivedChartProps {
  data: EventsByTimeReport
}

export const RepliesByTimeReceivedChart = ({ data }: RepliesByTimeReceivedChartProps): JSX.Element => {
  const chartData = data.dataPoints
  const streaks = data.longestStreaks

  return (
    <ChartWrapper>
      <TileChart
        chartTitle="Time Replies Were Received"
        chartSubtitle="Pin automatically optimizes time outreach emails are sent"
        data={chartData}
        streaks={streaks}
      />
    </ChartWrapper>
  )
}
