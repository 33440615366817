import { driver } from 'driver.js'

export const productTour = driver()
export const sequenceTour = driver()

export enum ProductTourStep {
  SOURCING_RESULTS = 'tour-sourcing-results',
  CANDIDATE_ACTIONS = 'tour-candidate-actions',
  SEARCH_CRITERIA = 'tour-search-criteria',
  SOURCING_COMPANY_DETAILS = 'tour-sourcing-company-details',
  MENU_SOURCING = 'tour-menu-sourcing',
  MENU_OUTREACH = 'tour-menu-outreach',
  MENU_INBOX = 'tour-menu-inbox',
  MENU_REPORTS = 'tour-menu-reports',
  MENU_JOB_SETTINGS = 'tour-menu-job-settings',
  MENU_ACCOUNT_SETTINGS = 'tour-menu-account-settings',
}

export enum SequenceTourStep {
  STEPS_SIDEBAR = 'tour-steps-sidebar',
  MESSAGE_BODY = 'tour-message-body',
  MESSAGE_SETTINGS = 'tour-message-settings',
  MESSAGE_SUGGESTIONS = 'tour-message-suggestions',
}
