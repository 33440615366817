import { useParams } from 'react-router-dom'
import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesArchivedTable } from 'src/components/tables/candidates-archived-table'
import { isNil } from 'lodash'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { SEO } from '../../../components/primitives/seo'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer'
import { ExportCandidatesDropdown } from 'src/components/blocks/export-candidates-dropdown'
import { CandidateExportFileType } from 'src/libs/api/backend/candidate_jobs'
import { useSession } from 'src/hooks/use-session'

const JobCandidatesArchivedPage = (): JSX.Element => {
  const { jobId } = useParams()

  const { isPending, data: rejectedCandidates } = useCandidateJobsQuery({
    archived: true
  })

  const { userHasViewerRole } = useSession()

  const showHeader = !isPending && (rejectedCandidates?.length ?? 0) > 0

  if (isNil(jobId)) {
    return <></>
  }

  return (
    <>
      <SEO title="Archived Candidates" />

      <CandidatesTablePagesContentInner>
        <When condition={showHeader}>
          <CandidatesPageHeader
            heading="Archived"
            context={<ExportCandidatesDropdown
              searchParams={{
                archived: true
              }}
              exportOptions={[CandidateExportFileType.CSV]}
              disabled={userHasViewerRole}
            />}
          />
        </When>
        <When condition={!showHeader}>
          <Spacer $size={20} />
        </When>
        <CandidatesArchivedTable />
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesArchivedPage
