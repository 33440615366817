import styled from 'styled-components'

export const CandidateContactDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0 0.75rem 0.75rem 0.75rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const ManualTaskDescription = styled.div`
  padding: 0.75rem;
  * {
    font-size: ${({ theme }) => theme.fontSizes['12']};
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: ${({ theme }) => theme.fontWeights['500']};
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.25rem;
  }
  ul, ol {
    list-style: none;
    padding-left: 0.5rem;
    margin: 0.5rem 0 0.75rem 0;
    li {
      margin-bottom: 0.1rem;
    }
  }
  ul {
    padding-left: 1.25rem;
  }
  ul li {
    position: relative;
  }
  ul li::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -0.75rem;
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.fgSecondary};
    border-radius: 50%;
    margin-right: 0.25rem;
  }
  ol li {
    counter-increment: custom-counter;
  }
  ol li::before {
    content: counter(custom-counter) '.';
    display: inline-block;
    color: ${({ theme }) => theme.colors.fgSecondary};
    font-weight: ${({ theme }) => theme.fontWeights['500']};
    width: 1rem;
    margin-right: 0rem;
  }
`

export const CommentEditor = styled.div`
  width: 100%;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const CommentEditorHeader = styled.div`
  padding: 0.75rem 0.75rem 0 0.75rem;
`

export const CommentEditorInner = styled.div`
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  gap: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
`

export const ReadyToDoManualTaskTitle = styled.div`
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};

  span {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    border: none;
    color: ${({ theme }) => theme.colors.fgPrimary};
    outline: none;
  }
`
