import { z } from 'zod'
import Api from '..'
import type { FetchReportArgs } from './reports_types'
import { buildReportFetchParams } from './reports_utils'

const pipelineOverviewParser = z.object({
  reviewed: z.number(),
  accepted: z.number(),
  emailLookups: z.number(),
  emailOpened: z.number(),
  replies: z.number(),
  bounced: z.number(),
  pastReviewed: z.number(),
  pastAccepted: z.number(),
  pastEmailLookups: z.number(),
  pastEmailOpened: z.number(),
  pastReplies: z.number(),
  pastBounced: z.number()
})

export type PipelineOverview = z.infer<typeof pipelineOverviewParser>

const pipelineStatsParser = z.object({
  timeToFirstReply: z.number(),
  timeToFirstInterview: z.number().nullable(),
  outreachEffectiveness: z.number(),
  teamResponseTime: z.number(),
  openRate: z.number(),
  replyRate: z.number(),
  interestedRate: z.number(),
  scheduledRate: z.number(),
  bouncedRate: z.number(),
  pastOpenRate: z.number(),
  pastReplyRate: z.number(),
  pastInterestedRate: z.number(),
  pastScheduledRate: z.number(),
  pastBouncedRate: z.number(),
  pastTeamResponseTime: z.number(),
  pastTimeToFirstReply: z.number()
})

export type PipelineStats = z.infer<typeof pipelineStatsParser>

const pipelinePositionActivitiesParser = z.object({
  jobId: z.string().uuid(),
  jobTitle: z.string(),
  departmentId: z.string().uuid().nullish(),
  departmentName: z.string().nullish(),
  reviewed: z.number(),
  accepted: z.number(),
  outreached: z.number(),
  read: z.number(),
  replies: z.number(),
  interested: z.number(),
  scheduled: z.number(),
  pastReviewed: z.number().optional(),
  pastAccepted: z.number().optional(),
  pastOutreached: z.number().optional(),
  pastRead: z.number().optional(),
  pastReplies: z.number().optional(),
  pastInterested: z.number().optional(),
  pastScheduled: z.number().optional()
})

export type PipelinePositionActivities = z.infer<typeof pipelinePositionActivitiesParser>

const pipelineTeamMemberActivitiesParser = z.object({
  userId: z.string().uuid(),
  userName: z.string(),
  profilePhotoUrl: z.string().nullish(),
  reviewed: z.number(),
  accepted: z.number(),
  outreached: z.number(),
  read: z.number(),
  replies: z.number(),
  interested: z.number(),
  scheduled: z.number(),
  pastReviewed: z.number(),
  pastAccepted: z.number(),
  pastOutreached: z.number(),
  pastRead: z.number(),
  pastReplies: z.number(),
  pastInterested: z.number(),
  pastScheduled: z.number()
})

export type PipelineTeamMemberActivities = z.infer<typeof pipelineTeamMemberActivitiesParser>

const pipelineInsightsParser = z.object({
  pipelineOverview: pipelineOverviewParser,
  pipelineStats: pipelineStatsParser,
  positionActivities: z.array(pipelinePositionActivitiesParser),
  teamMemberActivities: z.array(pipelineTeamMemberActivitiesParser)
})

export type PipelineInsights = z.infer<typeof pipelineInsightsParser>

export async function fetchPipelineInsightsReport (args: FetchReportArgs): Promise<PipelineInsights> {
  const params = buildReportFetchParams(args)
  const { data } = await Api.get('/reports/pipeline_insights', params)
  return pipelineInsightsParser.parse(data)
}
