import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { Table } from 'src/components/primitives/table'
import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import { TableReportNumberCell } from 'src/pages/job/reports/report-components'
import { DefaultTableHeader } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import type { TopSender } from 'src/libs/api/backend/reports_communication_insights'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { useMemo, useState } from 'react'
import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { useLocation } from 'react-router-dom'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer'
import { Button } from 'src/components/primitives/button'

interface TopSendersChartProps {
  data: TopSender[]
}

interface TopSenderTableData {
  id: string
  name: string
  email: string | null | undefined
  photoUrl: string | null | undefined
  sent: number
  opened: number
  replied: number
  interested: number
  scheduled: number
}

type TopSenderActivityColumns = 'sent' | 'opened' | 'replied' | 'interested' | 'scheduled'

const sortTopSenderColumn = (field: TopSenderActivityColumns) => {
  return (rowA: TopSenderTableData, rowB: TopSenderTableData) => {
    const valueA = rowA[field] ?? 0
    const valueB = rowB[field] ?? 0
    return valueA - valueB
  }
}

export const TopSendersChart = ({ data }: TopSendersChartProps): JSX.Element => {
  const { pathname } = useLocation()
  const isAllPositionsReport = pathname.includes('all/reports')
  const [showAllActivities, setShowAllActivities] = useState(false)

  const tableData = useMemo((): TopSenderTableData[] => {
    return data.map((sender) => ({
      id: sender.user.id,
      name: sender.user.name,
      email: sender.user.email,
      photoUrl: sender.user.profilePhoto,
      sent: sender.sent,
      opened: sender.opened,
      replied: sender.replied,
      interested: sender.interested,
      scheduled: sender.scheduled
    }))
  }, [data])

  const calculatePercentage = (value: number, baseValue: number): number => {
    return baseValue ? Number((value / baseValue).toFixed(2)) : 0
  }

  const columns: Array<TableColumnDef<TopSenderTableData>> = useMemo(() => [
    {
      columnId: 'user',
      size: 'minmax(min-content, 3fr)',
      visible: true,
      header: <DefaultTableHeader title="Top Member" minHeight={40} />,
      sortingFn: (a: TopSenderTableData, b: TopSenderTableData) => {
        return a.name.localeCompare(b.name)
      },
      cell: (info: TopSenderTableData) => (
        <Flex $gap={8} $align="center">
          <Avatar
            $type="photo"
            $size={24}
            initials={info.name.charAt(0)}
            photoUrl={info.photoUrl}
          />
          <Flex $direction="column" $gap={2}>
            <Caption size="XS">{info.name}</Caption>
            <Caption size="XS" $color="fgTertiary">{info.email}</Caption>
          </Flex>
        </Flex>
      )
    },
    {
      columnId: 'sent',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Sent" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortTopSenderColumn('sent'),
      cell: (info: TopSenderTableData) => (
        <TableReportNumberCell value={info.sent} />
      )
    },
    {
      columnId: 'opened',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Opened" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortTopSenderColumn('opened'),
      cell: (info: TopSenderTableData) => (
        <TableReportNumberCell
          value={info.opened}
          percentage={calculatePercentage(info.opened, info.sent)}
        />
      )
    },
    {
      columnId: 'replied',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Replied" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortTopSenderColumn('replied'),
      cell: (info: TopSenderTableData) => (
        <TableReportNumberCell
          value={info.replied}
          percentage={calculatePercentage(info.replied, info.sent)}
        />
      )
    },
    {
      columnId: 'interested',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Interested" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortTopSenderColumn('interested'),
      cell: (info: TopSenderTableData) => (
        <TableReportNumberCell
          value={info.interested}
          percentage={calculatePercentage(info.interested, info.sent)}
        />
      )
    },
    {
      columnId: 'scheduled',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Scheduled" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortTopSenderColumn('scheduled'),
      cell: (info: TopSenderTableData) => (
        <TableReportNumberCell
          value={info.scheduled}
          percentage={calculatePercentage(info.scheduled, info.sent)}
        />
      )
    }
  ], [])

  return (
    <ChartWrapper>
      <ChartHeader title="Top Senders" />
      <Table<TopSenderTableData>
        tableData={tableData}
        columns={columns}
        emptyState={<EmptyChart $minHeight='80px' />}
        visibleRowsCount={showAllActivities ? undefined : 5}
      />
      <When condition={isAllPositionsReport && !showAllActivities && tableData.length > 5}>
        <Spacer $size={12} />
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width="100%"
          $fontSize={12}
          onClick={() => {
            setShowAllActivities(!showAllActivities)
          }}
        >
          See All
        </Button>
      </When>
    </ChartWrapper>
  )
}
