import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`

export const InfoTrigger = styled.button`
  all: unset;
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
`
