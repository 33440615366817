import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { useTheme } from 'styled-components'
import { ChartHeader } from '../chart-header'
import { EmptyChart } from '../empty-chart'
import { getChartDataMaxValue } from 'src/utils/get-chart-data-max-value'
import { adjustChartDataWithMinimumPoints } from 'src/utils/adjust-chart-data'
import { When } from 'src/components/blocks/when'
import { ChartTooltip } from '../chart-tooltip'
import { ChartCursor } from '../chart-cursor'
import { useMemo, useState } from 'react'
import { TimeFilter } from '../time-filter-dropdown/constants'
import { TimeFilterDropdown } from '../time-filter-dropdown'
import { CHART_COLORS } from '../../base/constants'

export interface DataPoint {
  label: string | number
  [key: string]: string | number
}

interface DataColor {
  name?: string
  fill: string
}

export interface BarChartProps {
  chartTitle: string
  chartSubtitle?: string
  chartContext?: string
  data: DataPoint[]
  dataFormat?: 'number' | 'percent'
  dataColors?: DataColor[]
  showLegend?: boolean
  showTimeFilter?: boolean
  barFormat?: 'stacked' | 'grouped'
}

interface MinHeightBarProps {
  width: number
  x: number
  y: number
}

const MinHeightBar = ({ width, x, y }: MinHeightBarProps): JSX.Element => {
  // const { colors } = useTheme()
  const minHeight = 4
  return (
    <rect
      x={x}
      y={y - minHeight / 2}
      width={width}
      height={minHeight}
      // fill={colors.borderTranslucent}
      fill="#ECECEC"
      style={{ transform: 'translateY(-3px)' }}
    />
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomShape = (props: any): JSX.Element => {
  const { width, x, y, height, value, isEveryDataPointZero, barFormat } = props

  const valueIsZero = Array.isArray(value)
    ? value.every(v => v === 0)
    : value === 0

  if ((valueIsZero && isEveryDataPointZero) || (valueIsZero && barFormat === 'grouped')) {
    return <MinHeightBar width={width} x={x} y={y} />
  } else {
    return <rect x={x} y={y} width={width} height={height} fill={props.fill} />
  }
}

const filterDataByTime = (data: DataPoint[], timeFilter: TimeFilter): DataPoint[] => {
  if (timeFilter === TimeFilter.ALL_TIME) {
    return data
  }

  const currentDate = new Date()
  const filterDate = new Date()

  switch (timeFilter) {
    case TimeFilter.LAST_7_DAYS:
      filterDate.setDate(currentDate.getDate() - 7)
      break
    case TimeFilter.LAST_30_DAYS:
      filterDate.setDate(currentDate.getDate() - 30)
      break
    case TimeFilter.LAST_3_MONTHS:
      filterDate.setMonth(currentDate.getMonth() - 3)
      break
    case TimeFilter.LAST_6_MONTHS:
      filterDate.setMonth(currentDate.getMonth() - 6)
      break
  }

  return data.filter(item => {
    const itemDate = new Date(item.label as string)
    return itemDate <= currentDate && itemDate >= filterDate
  })
}

export const BarChart = ({
  chartTitle,
  chartSubtitle,
  chartContext,
  data,
  dataFormat = 'number',
  dataColors,
  showLegend = true,
  showTimeFilter = true,
  barFormat = 'stacked'
}: BarChartProps): JSX.Element => {
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.ALL_TIME)

  const { colors } = useTheme()

  const barColors = dataColors ?? [
    {
      fill: CHART_COLORS.GREEN_BRIGHT
    },
    {
      fill: CHART_COLORS.BLUE_MID
    },
    {
      fill: CHART_COLORS.GREEN_MINT
    },
    {
      fill: colors.chart4
    },
    {
      fill: colors.chart5
    },
    {
      fill: colors.chart6
    }
  ]

  const filteredData = filterDataByTime(data, timeFilter)
  const preparedData = adjustChartDataWithMinimumPoints(filteredData, { friendlyDate: true })

  const isEveryDataPointZero = useMemo(() => {
    return preparedData.every(point => {
      return Object.entries(point)
        .filter(([key]) => key !== 'label')
        .every(([_, value]) => value === 0)
    })
  }, [preparedData])

  const allKeys = new Set<string>()
  preparedData.forEach(dataPoint => {
    Object.keys(dataPoint).forEach(key => {
      if (key !== 'label') {
        allKeys.add(key)
      }
    })
  })

  const areaKeys = Array.from(allKeys)

  const legendData = areaKeys.map((key, index) => ({
    name: key,
    color: barColors[index % barColors.length].fill
  }))

  return (
    <>
      <ChartHeader
        title={chartTitle}
        subtitle={chartSubtitle}
        context={chartContext}
        legend={showLegend && data?.length ? legendData : undefined}
        actions={
          showTimeFilter && (
            <TimeFilterDropdown currentFilter={timeFilter} onSetTimeFilter={setTimeFilter} />
          )
        }
      />
      <div
        style={{ width: '100%' }}
      >
        <When condition={!data.length}>
          <EmptyChart $height="500px" $minHeight="500px" />
        </When>
        <When condition={data.length >= 1}>
          <ResponsiveContainer width="100%" height={300}>
            <ReBarChart
              data={preparedData}
              margin={{
                top: 10,
                right: 0,
                left: dataFormat === 'percent' ? -18 : -28,
                bottom: 20
              }}
              width={90}
            >
              <Tooltip
                content={<ChartTooltip />}
                cursor={<ChartCursor $fill={colors.bgTertiary} />}
              />
              <CartesianGrid vertical={false} stroke={colors.borderTranslucent} />
              <XAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                tickMargin={16}
                padding={{ left: 24, right: 24 }}
                dataKey="label"
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickMargin={10}
                tick={{ fill: colors.fgSecondary, fontSize: 12 }}
                minTickGap={1}
                ticks={getChartDataMaxValue(data) <= 2 ? [0, 1, 2, 3, 4] : undefined}
                tickFormatter={(value) => (dataFormat === 'percent' ? `${value}%` : value)}
              />
              {areaKeys.map((key, index) => (
                <Bar
                  key={key}
                  maxBarSize={48}
                  dataKey={key}
                  fill={barColors[index % barColors.length].fill}
                  shape={(
                    <CustomShape
                      fill={barColors[index % barColors.length].fill}
                      isEveryDataPointZero={isEveryDataPointZero}
                      barFormat={barFormat}
                    />
                  )}
                  stackId={barFormat === 'stacked' && areaKeys?.length >= 2 ? 'a' : undefined}
                />
              ))}
            </ReBarChart>
          </ResponsiveContainer>
        </When>
      </div>
    </>
  )
}
