import { isUuid } from './../../libs/uuid'
import { useParams } from 'react-router-dom'
import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchCandidateJobs, fetchAllCandidateJobsCommunicating } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  FetchCandidatesSearchParams
} from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'

interface UseQueryOpts {
  enabled?: boolean
  refetchInterval?: number
}

export const candidateJobsQuery = (
  jobId: string,
  searchParams: FetchCandidatesSearchParams = {},
  filterParams?: string | undefined
) => queryOptions({
  queryKey: [queryKeys.candidateJobs, jobId, searchParams, filterParams],
  queryFn: async () => await fetchCandidateJobs(jobId, searchParams),
  staleTime: 60 * 1000,
  enabled: isUuid(jobId),
  retry: 0
})

export const useCandidateJobsQuery = (
  searchParams: FetchCandidatesSearchParams = {},
  useQueryOpts: UseQueryOpts = {},
  filterParams?: string | undefined
): UseQueryResult<CandidateJobExpanded[]> => {
  const { jobId } = useParams()
  if (isNil(jobId)) {
    throw new Error('Missing jobId')
  }

  const query = useQuery({
    ...candidateJobsQuery(jobId, searchParams, filterParams),
    ...useQueryOpts
  })
  return query
}

export const allCandidateJobsCommunicatingQuery = (lastCommunicatedWithUserId?: string) => queryOptions({
  queryKey: [queryKeys.candidateJobsCommunicating, lastCommunicatedWithUserId],
  queryFn: async () => await fetchAllCandidateJobsCommunicating(lastCommunicatedWithUserId),
  staleTime: 60 * 1000
})

interface UseCandidateJobsCommunicatingOptions extends UseQueryOpts {
  lastCommunicatedWithUserId?: string
}

export const useAllCandidateJobsCommunicatingQuery = (
  options: UseCandidateJobsCommunicatingOptions = {}
): UseQueryResult<CandidateJobExpanded[]> => {
  const { lastCommunicatedWithUserId, ...queryOptions } = options

  return useQuery({
    ...allCandidateJobsCommunicatingQuery(lastCommunicatedWithUserId),
    enabled: queryOptions.enabled ?? true,
    retry: (failureCount) => failureCount < 3,
    ...queryOptions
  })
}
