import { useMemo } from 'react'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import * as Dialog from 'src/components/primitives/dialog'
import * as S from './view-holidays-dialog.styled'
import { Paragraph } from 'src/components/primitives/typography'
import { format } from 'date-fns'
import { Flex } from 'src/components/primitives/flex'
import { useHolidays } from 'src/hooks/use-holidays'

export const ViewHolidaysDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.VIEW_HOLIDAYS), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { holidays, passedHolidays } = useHolidays()

  if (!holidays) return <></>

  return (
    <Dialog.Root
      id={DialogId.VIEW_HOLIDAYS}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.VIEW_HOLIDAYS, newState: value })
      }}
      $maxWidth="640px"
      $height="half"
      $innerPadding={{ top: 0, right: 0, bottom: 0, left: 0 }}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="US Holidays"
          onClose={() => {
            closeDialog(DialogId.VIEW_HOLIDAYS)
          }}
        />
        <Dialog.Content>
          <S.DialogContent>
            <S.HolidaysList>
              {passedHolidays?.slice(-3).map((holiday) => (
                <S.HolidaysListItem key={holiday.date} $passedHoliday>
                  <Flex $align="center" $gap={12}>
                    <Paragraph size="SM" $color="fgPrimary">{holiday.name}</Paragraph>
                  </Flex>
                  <Paragraph size="SM" $color="fgPrimary">{format(holiday.date, 'MMMM d, yyyy')}</Paragraph>
                </S.HolidaysListItem>
              ))}
              {holidays.map((holiday, index) => (
                <S.HolidaysListItem key={holiday.date}>
                  <Flex $align="center" $gap={12}>
                    <Paragraph size="SM" $color="fgPrimary">{holiday.name}</Paragraph>
                    {index === 0 ? <S.NextHolidayBadge>Next</S.NextHolidayBadge> : null}
                  </Flex>
                  <Paragraph size="SM" $color="fgPrimary">{format(holiday.date, 'MMMM d, yyyy')}</Paragraph>
                </S.HolidaysListItem>
              ))}
            </S.HolidaysList>
          </S.DialogContent>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
