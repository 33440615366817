import styled, { css } from 'styled-components'
import { ImageUploadStyleProps } from '.'

export const ImageUpload = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 1.5rem;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 0.25rem;
  padding: 0.625rem;
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
  `}
`

export const UploadArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const UploadHandle = styled.label<{ $disabled?: boolean }>`
  width: auto;
  padding: 0.375rem;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.tintBg};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
  }
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const RemoveButton = styled.button`
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: transparent;
`

export const Preview = styled.div<ImageUploadStyleProps>`
  width: ${({ theme, $previewSize }) => theme.spacing[$previewSize ?? 40]};
  height: ${({ theme, $previewSize }) => theme.spacing[$previewSize ?? 40]};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: ${({ $previewShape }) => ($previewShape === 'rounded' ? '4px' : '999px')};
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
