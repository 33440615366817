import { useChannel } from 'ably/react'
import { useAtomValue } from 'jotai'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useGetAtsIntegrationDetails } from 'src/hooks/queries/use-get-integration-details'
import queryClient from 'src/hooks/query-client'
import { AtsIntegrationType, MergeAtsIntegrationSyncStatus } from 'src/libs/api/backend/merge'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { queryKeys } from 'src/libs/query-keys'
import { atsChannelAtom, candidateSearchChannelAtom } from 'src/stores/websocket-channels'
import { LoadingCandidates } from './loading-candidates'
import { Button, Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './applicant-candidates-ats.styled'
import { useSession } from 'src/hooks/use-session'
import { useApplicantsOverviewStatusQuery } from 'src/hooks/queries/use-applicants-overview-status'
import { isRefinementErrored, isSourcing, JobSearchRefinementType } from 'src/libs/api/backend/jobs'
import type { JobSourcingState, Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { ApplicantsStatus } from './applicants-status'
import { RefinementFilter } from '../refinement-filter'
import { ApplicantsActionsBulk } from './applicants-actions-bulk'
import { ToggleCandidateView } from '../toggle-candidate-view'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useLocalStorage } from 'usehooks-ts'
import { IfElse } from '../if-else'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CandidateJobStage, APPLICANT_STAGES } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { CandidateDetailsCard } from '../candidate-details-card'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { SourcingStatus } from '../sourcing'
import { PageHeader } from '../page-header'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { updateApplicantsRefinements } from 'src/hooks/queries/use-job-search-refinement'
import { useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'

interface ApplicantsCandidatesProps {
  jobId: Job['id']
  applicantsRefinementId: JobSearchRefinement['id'] | undefined
  stage: APPLICANT_STAGES
}

export const ApplicantsCandidates = ({ jobId, applicantsRefinementId, stage }: ApplicantsCandidatesProps): JSX.Element => {
  const navigate = useNavigate()
  const [isRecommending, setIsRecommending] = useState(false)
  const { data: candidateIntegrationDetails } = useGetAtsIntegrationDetails(AtsIntegrationType.Candidate)
  const { data: job } = useJobQuery()
  const { data: attachmentIntegrationDetails } = useGetAtsIntegrationDetails(AtsIntegrationType.Attachment)
  const { data: applicantsOverviewStatus } = useApplicantsOverviewStatusQuery(jobId)
  const { data: applicantsRefinements, refetch: refetchJobSearchRefinement } = useListJobSearchRefinementsQuery(jobId, { type: JobSearchRefinementType.APPLICANTS })
  const { data: candidateJobs, isPending: isCandidateJobsPending, refetch: refetchCandidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    jobSearchRefinementId: applicantsRefinementId,
    applicantStage: stage,
    favorite: stage === APPLICANT_STAGES.SHORTLISTED,
    archived: stage === APPLICANT_STAGES.ARCHIVED
  }, { enabled: !isNil(applicantsRefinementId) })
  const { org } = useSession()
  const atsChannel = useAtomValue(atsChannelAtom)
  const [currViewMode] = useLocalStorage(LocalStorageKey.APPLICANT_VIEW_MODE, ViewMode.DEFAULT)
  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])
  const parentRef = useRef<HTMLDivElement>(null)
  const currentRefinement = useMemo(() => applicantsRefinements?.find((refinement) => refinement.id === applicantsRefinementId), [applicantsRefinements, applicantsRefinementId])
  useEffect(() => {
    setIsRecommending(isSourcing(currentRefinement?.sourcingState))
  }, [currentRefinement])

  const newSearchRefinement = useMemo(() => {
    return isNil(currentRefinement?.lastSourcingRequestedAt)
  }, [currentRefinement?.lastSourcingRequestedAt])

  const syncingCandidates = useMemo(() => {
    return !!org?.mergeAtsIntegration && (
      candidateIntegrationDetails?.finishedInitialSync === false ||
      attachmentIntegrationDetails?.finishedInitialSync === false
    )
  }, [org?.mergeAtsIntegration, candidateIntegrationDetails?.finishedInitialSync, attachmentIntegrationDetails?.finishedInitialSync])

  useChannel({ channelName: atsChannel, skip: !atsChannel }, (message) => {
    const { name, data } = message
    if (name === EVENT_TYPE.SYNCING_CANDIDATES || name === EVENT_TYPE.SYNCING_ATTACHMENTS) {
      if (data.status === MergeAtsIntegrationSyncStatus.DONE) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.atsIntegrationDetails, AtsIntegrationType.Candidate]
        })
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.atsIntegrationDetails, AtsIntegrationType.Attachment]
        })
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.applicantsOverviewStatus, jobId]
        })
      }
    }
  })

  // This channel is used to let the FE know if the JobSearchRefinement has a visual update
  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  useChannel({ channelName: candidateSearchChannel, skip: !candidateSearchChannel }, (message) => {
    const { name: eventName } = message
    if (applicantsRefinementId && eventName === applicantsRefinementId) {
      const {
        sourcingState,
        lastSourcingRequestedAt
      } = message.data ?? {}

      if (sourcingState) {
        updateApplicantsRefinements({
          jobId,
          applicantsRefinementId,
          jobSearchRefinement: {
            sourcingState,
            lastSourcingRequestedAt: typeof lastSourcingRequestedAt === 'string'
              ? new Date(lastSourcingRequestedAt)
              : lastSourcingRequestedAt ?? new Date()
          }
        })
        const isSourcingState = isSourcing(sourcingState as JobSourcingState)
        if (isSourcingState) {
          if (renderedCandidates.length > 0) {
            setRenderedCandidates([])
          }
        } else {
          void refetchCandidateJobs()
        }
      }

      void refetchJobSearchRefinement()
    }
  })

  const virtualizer = useVirtualizer({
    count: renderedCandidates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300,
    overscan: 5,
    getItemKey: (index) => renderedCandidates[index].id
  })

  const isEmpty = useMemo((): boolean => renderedCandidates.length === 0, [renderedCandidates])

  useEffect(() => {
    setRenderedCandidates([])
    virtualizer.scrollToOffset(-100000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (candidateJobs) {
      setRenderedCandidates(candidateJobs)
    }
  }, [candidateJobs])

  const header = useMemo(() => {
    return (
      <PageHeader heading="Applicants" marginBottom={20} />
    )
  }, [])

  if (syncingCandidates || !job) {
    return (
      <S.Container>
        {header}
        <S.StatusContainer>
          <LoadingCandidates
            updatedAt={candidateIntegrationDetails?.updatedAt ?? new Date()}
            isCandidateSyncing={candidateIntegrationDetails?.finishedInitialSync ?? false}
            isAttachmentsSyncing={attachmentIntegrationDetails?.finishedInitialSync ?? false}
          />
        </S.StatusContainer>
      </S.Container>
    )
  }

  if (applicantsOverviewStatus?.candidatesCount === 0) {
    return (
      <S.Container>
        {header}
        <S.StatusContainer>
          <S.EmptyWrapper>
            <Icon name={Icons.emptyApplicant} size={120} />
            <Flex $direction="column" $gap={16} $flex='1'>
              <Caption size="LG">No applicants yet</Caption>
              <Flex $direction="column">
                <Paragraph size="SM">Applicants from your ATS will</Paragraph>
                <Paragraph size="SM">appear here automatically.</Paragraph>
              </Flex>
            </Flex>
          </S.EmptyWrapper>
        </S.StatusContainer>
      </S.Container>
    )
  }

  return (
    <S.Container
      ref={parentRef}
    >
      {header}
      <S.CandidatesViewContainer>
        <Flex
          $gap={12}
          $justify="space-between"
          $align="center"
          $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
        >
          <ApplicantsStatus
            count={applicantsOverviewStatus?.applicantsCount ?? 0}
            label="Total Applied"
            selected={false}
          />
          <ApplicantsStatus
            count={applicantsOverviewStatus?.approved ?? 0}
            label="Approved"
            total={applicantsOverviewStatus?.applicantsCount ?? 0}
            onClick={() => {
              navigate((RouteBuilder.build('JOBS_APPLICANTS', { jobId }, { stage: APPLICANT_STAGES.APPROVED })))
            }}
            selected={false}
          />
          <ApplicantsStatus
            count={applicantsOverviewStatus?.archived ?? 0}
            label="Archived"
            total={applicantsOverviewStatus?.applicantsCount ?? 0}
            onClick={() => {
              navigate((RouteBuilder.build('JOBS_APPLICANTS', { jobId }, { stage: APPLICANT_STAGES.ARCHIVED })))
            }}
            selected={false}
          />
          <ApplicantsStatus
            count={applicantsOverviewStatus?.pending ?? 0}
            label="Pending"
            total={applicantsOverviewStatus?.applicantsCount ?? 0}
            onClick={() => {
              navigate((RouteBuilder.build('JOBS_APPLICANTS', { jobId }, { stage: APPLICANT_STAGES.INCOMING })))
            }}
            selected={false}
          />
          <ApplicantsStatus
            count={applicantsOverviewStatus?.thisWeek ?? 0}
            label="This Week"
            selected={false}
          />
        </Flex>
        <S.CandidatesViewActionsBar>
          <Flex $gap={12} $width="fit-content" $align="center">
            <RefinementFilter
              job={job}
              isRecommending={isRecommending}
              setIsRecommending={setIsRecommending}
              newSearchRefinement={newSearchRefinement}
              searchRefinement={currentRefinement}
              criteria={currentRefinement?.searchCriteria}
              isErrored={isRefinementErrored(currentRefinement?.sourcingState)}
              filterType='applicantCandidates'
            />
            {/* <SortApplicants /> */}
          </Flex>
          <Flex $width="fit-content" $flex='1 1 auto' $align="center">
            <Paragraph size="XS">Showing of {applicantsOverviewStatus?.applicantsCount} applicants.</Paragraph>
            <Button $fontSize={12} $variant="link" $colorTheme="muted" >View all</Button>
          </Flex>
          <ApplicantsActionsBulk
            jobId={jobId}
            candidateJobIds={renderedCandidates.map((candidateJob) => candidateJob.id)}
          />
          <ToggleCandidateView
            order={[ViewMode.TABLE, ViewMode.DEFAULT]}
            localStorageKey={LocalStorageKey.APPLICANT_VIEW_MODE}
          />
        </S.CandidatesViewActionsBar>
        {renderedCandidates.length > 0 &&
          <IfElse
            condition={currViewMode === ViewMode.TABLE}
            ifNode={
              <S.CandidatesTablePagesContentInner
                key={`${jobId}-${applicantsRefinementId}`}
                data-component="ApplicantsTablePagesContentInner"
                $padding={0}
                $maxWidth='auto'
              >
                <CandidatesSourcedTable
                  candidateJobs={renderedCandidates}
                  visibleColumns={[
                    COLUMN.FAVORITE,
                    COLUMN.NAME,
                    COLUMN.JOB_TITLE,
                    COLUMN.CRITERIA,
                    COLUMN.CRITERIA_EXPANDED,
                    COLUMN.APPLICANT_STAGE_ACTIONS
                  ]}
                  pageHeaderHeight={190}
                />
              </S.CandidatesTablePagesContentInner>
            }
            elseNode={
              <div
                style={{
                  minHeight: virtualizer.getTotalSize(),
                  position: 'relative',
                  maxWidth: CANDIDATES_PAGES_MAX_WIDTH
                }}
              >
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const applicant = renderedCandidates[virtualRow.index]
                  if (isNil(applicant)) {
                    return <Fragment key={virtualRow.key} />
                  }
                  return (
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: virtualRow?.start ?? 0
                      }}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                    >
                      <CandidateDetailsCard
                        candidateJob={applicant}
                        actionType='applicants'
                        viewMode={currViewMode}
                        type='applicants'
                      />
                    </div>
                  )
                })}
              </div>
            }
          />
        }
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <SourcingStatus
            job={job}
            isRecommending={isRecommending}
            isEmpty={isEmpty}
            isLoading={isCandidateJobsPending && !!applicantsRefinementId}
            lastRequestedAt={currentRefinement?.lastSourcingRequestedAt ?? new Date()}
            isSourcingView={!!currentRefinement && !newSearchRefinement}
            isManualSourceView={false}
            sourcingState={currentRefinement?.sourcingState}
            isArchived={false}
            viewMode={currViewMode}
            onUpdateSearchRefinementCriteria={() => {}}
            filterType='applicantCandidates'
          />
        </div>
      </S.CandidatesViewContainer>
    </S.Container>
  )
}
