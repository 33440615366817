import { motion } from 'framer-motion'
import styled from 'styled-components'

export const SuccessAndShareOnLinkedInContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px 0;
`
