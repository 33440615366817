import styled, { css, keyframes } from 'styled-components'
import { CommonFieldStyles } from '../common'
import type { Variant, Size } from './input'
import { isNil } from 'lodash'

export const InputWithIcon = styled.div<{
  $variant: Variant
  $size: Size
  $height?: number
  $isLoading: boolean
  $isDisabled: boolean
}>`
  width: 100%;
  height: ${({ $size, $height }) => {
    if (!isNil($height)) {
      return `${$height}px`
    } else if ($size === 'small') {
      return '2rem'
    } else {
      return '2.5rem'
    }
  }};
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'flat':
        return theme.colors.bgTertiary
      default: {
        return theme.colors.borderTranslucent
      }
    }
  }};
  background-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'flat':
        return theme.colors.bgTertiary
      default: {
        return theme.colors.bgPrimary
      }
    }
  }};
  padding: 0.625rem;
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'all')};
  svg {
    opacity: ${({ $isLoading, $isDisabled }) => ($isLoading || $isDisabled ? 0.5 : 1)};
  }
  input {
    width: 100%;
    color: ${({ theme }) => theme.colors.fgPrimary};
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: 1;
    border: none;
    outline: none;
    background-color: transparent;
    opacity: ${({ $isLoading, $isDisabled }) => ($isLoading || $isDisabled ? 0.5 : 1)};
    pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'all')};
  }
`

const IconAndNote = styled.div`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    line-height: 1;
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`

export const Leading = styled(IconAndNote)``

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  right: 0.625rem;
  width: 1rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    animation: ${spinning} 0.5s linear infinite;
  }
`

export const Input = styled(CommonFieldStyles)<{
  $variant: Variant
  $size: Size
  height?: number | undefined
  padding?: string | undefined
  $isDisabled?: boolean
}>`
  height: ${({ $size, height }) => {
    if (!isNil(height)) {
      return `${height}px !important`
    } else if ($size === 'small') {
      return '2rem'
    } else {
      return '2.5rem'
    }
  }};

  padding: ${({ padding }) => padding}

  ${({ $variant }) =>
    $variant === 'naked' && css`
      border: none !important;
      padding: 0 !important;
      background-color: transparent !important;
    `
  }
  ${({ $variant, $isDisabled }) =>
    $variant === 'naked' && $isDisabled && css`
      opacity: 0.5;
      pointer-events: none;
      background-color: transparent !important;
    `
  }
`

export const Trailing = styled(IconAndNote)`
  width: auto;
  min-width: 1rem;
  margin-left: 1rem;
`
