import type { EmailSequence } from 'src/models/sequence'
import type { Benefit } from 'src/components/blocks/benefits-list'
import { SequenceStepType } from './api/backend/sequences'

// isSeparateStep: boolean refers to steps that are not part of the default onboarding flow
export const ACCOUNT_ONBOARDING_STEPS = [
  {
    id: 'company-details',
    title: 'Company Details',
    pathname: 'ONBOARDING_COMPANY_PROFILE',
    image: 'onboarding/company-profile.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: false
  },
  {
    id: 'job-position',
    title: 'Job Position',
    pathname: 'ONBOARDING_JOB_POSITION',
    image: 'onboarding/company-profile.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: false
  },
  {
    id: 'connected-accounts',
    title: 'Connected Accounts',
    pathname: 'ONBOARDING_CONNECTED_ACCOUNTS',
    image: 'onboarding/connect-accounts.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: false
  },
  {
    id: 'browser-extension',
    title: 'Pin’s Chrome Extension',
    pathname: 'ONBOARDING_BROWSER_EXTENSION',
    image: 'onboarding/chrome-extension.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: true
  },
  {
    id: 'invite-team',
    title: 'Invite Team',
    pathname: 'ONBOARDING_INVITE_TEAM',
    image: 'onboarding/invite-team.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: false
  },
  {
    id: 'schedule-demo',
    title: 'Schedule a Demo',
    pathname: 'ONBOARDING_SCHEDULE_DEMO',
    image: 'onboarding/schedule-a-demo-2.jpg',
    isFinalStep: false,
    isSeparateStep: false,
    isChromeOnly: false,
    skipWhenAlreadyBooked: true
  },
  {
    id: 'success',
    title: 'You’re all set',
    pathname: 'ONBOARDING_SUCCESS',
    image: 'onboarding/success.jpg',
    isFinalStep: true,
    isSeparateStep: false,
    isChromeOnly: false
  },
  {
    id: 99,
    title: 'Welcome to Pin',
    pathname: 'ONBOARDING_WELCOME',
    image: 'onboarding/welcome.jpg',
    isFinalStep: false,
    isSeparateStep: true,
    isChromeOnly: false
  },
  {
    id: 'join-team',
    title: 'Join Team',
    pathname: 'ONBOARDING_JOIN_TEAM',
    image: 'onboarding/invite-team.jpg',
    isFinalStep: false,
    isSeparateStep: true,
    isChromeOnly: false
  }
]

export const JOB_SETUP_STEPS = [
  {
    id: 0,
    title: 'Job description',
    description: 'Create your Job description',
    pathname: 'JOBS_NEW'
  },
  {
    id: 1,
    title: 'Outreach sequence',
    description: 'Automate candidate outreach',
    pathname: 'JOBS_NEW_EMAIL_SEQUENCE'
  }
]

export const INITIAL_EMAIL_SEQUENCE_STEPS: EmailSequence['sequenceSteps'] = [
  {
    position: 0,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 0,
    subject: '',
    body: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null
  },
  {
    position: 1,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 3,
    subject: 'First follow up',
    body: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null
  },
  {
    position: 2,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 5,
    subject: 'Next follow up',
    body: '',
    sendingEmailAlias: null,
    sendingLinkedInAccountId: null
  }
]

export const ATS_IMPORT_OPTIONS = [
  {
    brand: 'Indeed',
    logoUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADQklEQVR4AcXXA7AjWxSF4fPGtm3btm3btm3btm3btm3bSO7/1pg3SWe0qr7qUtde7T7GWQDBm8SXujJe9ss9sYtN7sl+GS/1JNHHfYyleDI4pfSTs/IW53kr52WIpLdcBPgkrHSR67ifW9JdIogRl4cnk9Xiwa/HQzZKajHidHhGOcLvzwnJJkY8HZ5cjvLnckLS/FACEMLJajzJk6fPmbd8A536jaV1jxG07um5Vj2G03/0DB49ecpPslHCyzfDvUgXPMmr169p02skvqJkwIRKLikcC5GM6GmKcuX6LTxJX/EqnwukkRt4kg3b9xE4djZM6JSYsKmcC52C6OmLOypwXdKJ+XT0/XGQsdMX/3TQf+I7TGoChkmDL20tFFAYKV4NkEDO4CCjpi78YbgfDYwXMj1Fg2ahfqAc1AuUnYzBM+HzXZF3BdIVc1bgjMQ3QB15a6WAjpo0ITJSNXA2WgfIwUC/uZnuOy+LfOWnbOCs6B4gc7E6PHj0BAexSRsDjBWsFIipI68SJBttA+VidrTy7IxTiyNRqnEhdGUOhixHx8od2LLnEC5koQH2WyngVXIGy0wbHfm0iGV4WqQP5O0FqdtB7CYQtjaM24CLOWCAe1YKeA+bmrJBsjJIp31TtCp45O4Jqd4Nbwxha4H/ijBwOS7mngFsVi9BId14033nY3+4Snikavvt8KBVYd0RXIzdAHarBaKGTMdYv3k4G7w8xNLwMBrurwJ4KQVVRsKL17gYmwHuWy0gJAuegSXhykD0hhCkCkSsC/UmwJV7KJYuwQF3CuiVTNlyzbBvPAaL9sKhS/DmLRZz0AAT3CoQMjkla7fDZrfzC1logHry1p0CpX6tgE3aGiChnP0HBc5JIgN4lUH/oMA48fb15/im5XuglgrY7O7+rGb9+n/Aq/TwtMCUBRqYTJJ/ESIp9dr2w830F6/f/xVFlA38JFt2HaRGi57U/KhG85607D6Mk2cv4Ua2SST56U9pajnBn8sZyejszzibnPxDw/O6ujZILRt/48Jku2SwujoKL33l5i8uzQZ8uuburBG9SiYZY2FxapNzH/fJ+DtXyfGllSyQA3JPbGKX+3JQFkk7SSjeXRn8P2dRK5CJI06CAAAAAElFTkSuQmCC'
  },
  {
    brand: 'Zip Recruiter',
    logoUrl:
      'https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v9drsv7kviovnjd8j6ew'
  },
  {
    brand: 'Front',
    logoUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADQklEQVR4AcXXA7AjWxSF4fPGtm3btm3btm3btm3btm3bSO7/1pg3SWe0qr7qUtde7T7GWQDBm8SXujJe9ss9sYtN7sl+GS/1JNHHfYyleDI4pfSTs/IW53kr52WIpLdcBPgkrHSR67ifW9JdIogRl4cnk9Xiwa/HQzZKajHidHhGOcLvzwnJJkY8HZ5cjvLnckLS/FACEMLJajzJk6fPmbd8A536jaV1jxG07um5Vj2G03/0DB49ecpPslHCyzfDvUgXPMmr169p02skvqJkwIRKLikcC5GM6GmKcuX6LTxJX/EqnwukkRt4kg3b9xE4djZM6JSYsKmcC52C6OmLOypwXdKJ+XT0/XGQsdMX/3TQf+I7TGoChkmDL20tFFAYKV4NkEDO4CCjpi78YbgfDYwXMj1Fg2ahfqAc1AuUnYzBM+HzXZF3BdIVc1bgjMQ3QB15a6WAjpo0ITJSNXA2WgfIwUC/uZnuOy+LfOWnbOCs6B4gc7E6PHj0BAexSRsDjBWsFIipI68SJBttA+VidrTy7IxTiyNRqnEhdGUOhixHx8od2LLnEC5koQH2WyngVXIGy0wbHfm0iGV4WqQP5O0FqdtB7CYQtjaM24CLOWCAe1YKeA+bmrJBsjJIp31TtCp45O4Jqd4Nbwxha4H/ijBwOS7mngFsVi9BId14033nY3+4Snikavvt8KBVYd0RXIzdAHarBaKGTMdYv3k4G7w8xNLwMBrurwJ4KQVVRsKL17gYmwHuWy0gJAuegSXhykD0hhCkCkSsC/UmwJV7KJYuwQF3CuiVTNlyzbBvPAaL9sKhS/DmLRZz0AAT3CoQMjkla7fDZrfzC1logHry1p0CpX6tgE3aGiChnP0HBc5JIgN4lUH/oMA48fb15/im5XuglgrY7O7+rGb9+n/Aq/TwtMCUBRqYTJJ/ESIp9dr2w830F6/f/xVFlA38JFt2HaRGi57U/KhG85607D6Mk2cv4Ua2SST56U9pajnBn8sZyejszzibnPxDw/O6ujZILRt/48Jku2SwujoKL33l5i8uzQZ8uuburBG9SiYZY2FxapNzH/fJ+DtXyfGllSyQA3JPbGKX+3JQFkk7SSjeXRn8P2dRK5CJI06CAAAAAElFTkSuQmCC'
  },
  {
    brand: 'Zoho Recruit',
    logoUrl:
      'https://www.zohoservices.dotsquares.com/apps.11350.8570248a-1e5e-4540-ac37-1abcbf30d9a5.e91665473035557.png'
  },
  {
    brand: 'Workable',
    logoUrl:
      'https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2,f_auto,g_center,q_auto:good/v1/gcs/platform-data-workable/contentbuilder/Chapter%20Thumbnail%20-%201080x1080px.png'
  }
]

export const CONNECTED_ACCOUNTS_BENEFITS: Benefit[] = [
  {
    title: 'Seamless Candidate Communication',
    description:
      'Effortlessly email potential hires directly through the app, streamlining your recruitment process.',
    icon: 'mail-arrow-right'
  },
  {
    title: 'Sync Contacts for Efficiency',
    description:
      'Never double-email a candidate. Avoid redundancy and maintain a polished, professional approach.',
    icon: 'contact'
  },
  {
    title: 'Simplify Scheduling',
    description:
      'Make scheduling meetings with candidates a breeze. Save time and build your dream team.',
    icon: 'calendar-days'
  }
]

export const DEFAULT_REFINEMENT_SUGGESTIONS = [
  { tag: 'Worked at a Series A company', query: 'Worked at a Series A company' },
  { tag: 'First 50 employees', query: 'First 50 employees' },
  { tag: 'Based in NYC', query: 'Based in NYC' },
  { tag: 'Aerospace Industry', query: 'Aerospace Industry' },
  { tag: '2+ years experience', query: '2+ years experience' },
  { tag: 'Top Tier University', query: 'Top Tier University' },
  { tag: 'Knows React', query: 'Knows React' }
]

interface AtsIntegrationAppUrl {
  mergeIntegrationSlug: string
  url: string | null
}

export const ATS_INTEGRATION_APP_URLS: AtsIntegrationAppUrl[] = [
  {
    mergeIntegrationSlug: 'applicantstack',
    url: 'https://www.applicantstack.com/login/'
  },
  {
    mergeIntegrationSlug: 'ashby',
    url: 'https://app.ashbyhq.com/signin'
  },
  {
    mergeIntegrationSlug: 'bamboohr',
    url: 'https://app.bamboohr.com/login/'
  },
  {
    mergeIntegrationSlug: 'breezy',
    url: 'https://app.breezy.hr/signin'
  },
  {
    mergeIntegrationSlug: 'cats',
    url: 'https://login.catsone.com'
  },
  {
    mergeIntegrationSlug: 'clayhr',
    url: 'https://www.clayhr.com/'
  },
  {
    mergeIntegrationSlug: 'clockwork',
    url: 'https://account.clockworkrecruiting.com/session/new'
  },
  {
    mergeIntegrationSlug: 'comeet',
    url: 'https://app.comeet.co'
  },
  {
    mergeIntegrationSlug: 'cornerstone-talentlink',
    url: 'https://www.cornerstoneondemand.com/'
  },
  {
    mergeIntegrationSlug: 'engageats',
    url: 'https://www.engage-ats.com/'
  },
  {
    mergeIntegrationSlug: 'fountain',
    url: 'https://web.fountain.com/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'freshteam',
    url: 'https://login.freshworks.com/email-login/'
  },
  {
    mergeIntegrationSlug: 'greenhouse',
    url: 'https://app.greenhouse.io/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'greenhouse-job-boards-api',
    url: 'https://app.greenhouse.io/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'homerun',
    url: 'https://app.homerun.co'
  },
  {
    mergeIntegrationSlug: 'hr-cloud',
    url: 'https://corehr.hrcloud.com/'
  },
  {
    mergeIntegrationSlug: 'jazzhr',
    url: 'https://app.jazz.co/'
  },
  {
    mergeIntegrationSlug: 'jobscore',
    url: 'https://hire.jobscore.com/login'
  },
  {
    mergeIntegrationSlug: 'jobsoid',
    url: 'https://app.jobsoid.com/login'
  },
  {
    mergeIntegrationSlug: 'jobvite',
    url: 'https://id.jobvite.com/'
  },
  {
    mergeIntegrationSlug: 'lano',
    url: 'https://fms.lano.io/login'
  },
  {
    mergeIntegrationSlug: 'lever',
    url: 'https://hire.lever.co/auth/login'
  },
  {
    mergeIntegrationSlug: 'oracle-taleo',
    url: 'https://www.oracle.com/human-capital-management/taleo/signin/'
  },
  {
    mergeIntegrationSlug: 'polymer',
    url: 'https://app.polymer.co/'
  },
  {
    mergeIntegrationSlug: 'recruiterflow',
    url: 'https://recruiterflow.com/login'
  },
  {
    mergeIntegrationSlug: 'sage-hr',
    url: 'https://id.sage.com/login'
  },
  {
    mergeIntegrationSlug: 'sap-successfactors',
    url: null
  },
  {
    mergeIntegrationSlug: 'smartrecruiters',
    url: 'https://www.smartrecruiters.com/account/sign-in'
  },
  {
    mergeIntegrationSlug: 'talentlyft',
    url: 'https://accounts.talentlyft.com/login'
  },
  {
    mergeIntegrationSlug: 'talentreef',
    url: 'https://login.jobappnetwork.com/login'
  },
  {
    mergeIntegrationSlug: 'teamtailor',
    url: 'https://app.teamtailor.com/login'
  },
  {
    mergeIntegrationSlug: 'tellent',
    url: null
  },
  {
    mergeIntegrationSlug: 'ukg-pro-recruiting',
    url: null
  },
  {
    mergeIntegrationSlug: 'workable',
    url: 'https://id.workable.com/'
  },
  {
    mergeIntegrationSlug: 'workday',
    url: null
  }
]

export interface LoginPageTestimonialQuote {
  quote: string
  name: string
  context: string
  headshot: string
}

export const LOGIN_PAGE_TESTIMONIAL_QUOTES: LoginPageTestimonialQuote[] = [
  {
    quote: 'Pin has supercharged our sourcing, helping our team build more top of funnel efficiently.',
    name: 'Nick Patrick',
    context: 'CEO & Co-founder at Radar',
    headshot: 'nick-patrick-radar.jpg'
  },
  {
    quote: "As a small people and talent team, we don't have a ton of time to spend hours sourcing and messaging. Pin has made it possible for us to focus on the people side of things!",
    name: 'Miles Randle',
    context: 'Head of People & Talent at Flip CX',
    headshot: 'miles-randle-flip.png'
  },
  {
    quote: "Pin gave us the ability to find candidates that didn't appear on LinkedIn Recruiter. The platform is easy to use and is continuing to evolve! We'll continue to use the product for years to come in an effort to explore new talent sources.",
    name: 'Ryan Levy',
    context: 'Managing Partner at Cruit Group',
    headshot: 'ryan-levy-cruit-group.jpeg'
  },
  {
    quote: 'In terms of Recruitment Tech, Pin is a must have for any company looking to scale both quickly and efficiently.',
    name: 'Steven Jambor',
    context: 'Talent Acquisition Specialist',
    headshot: 'steven-jambor.jpeg'
  },
  {
    quote: "Pin's intuitive UX made it easy to use right away, simplifying job descriptions and finding spot-on candidates. It's already outperforming other established recruiting products, and I haven't been this energized about a recruiting tool in years.",
    name: 'Ben Caggia',
    context: 'Advisor at Syelo',
    headshot: 'ben-caggia-syelo.jpeg'
  },
  {
    quote: 'Pin delivered exactly what we needed. Within just two weeks of using the product, we hired both a software engineer and a financial planner. The speed and accuracy were unmatched.',
    name: 'Fahad Hassan',
    context: 'CEO & Co-founder at Range',
    headshot: 'fahad-hassan-range.jpg'
  }
]
