import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  width: 100%;
  padding: 0 2.5rem;
  display: flex;
  justify-content: center;
`

export const Card = styled.div`
  width: 100%;
  padding: 40px 80px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`
