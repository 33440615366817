import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Icon, Icons } from 'src/components/primitives/icon'
import { SequenceStepType } from 'src/libs/api/backend/sequences'

interface SequenceStepTypeDisplay {
  title: string
  description?: string
  icon: React.ReactNode
}

export const sequenceStepTypeDisplay = (type?: SequenceStepType): SequenceStepTypeDisplay => {
  if (!type) {
    return {
      title: '',
      icon: <Icon name={Icons.mail} size={12} color="fgPrimary" />
    }
  }

  const displayConfigs = {
    email: {
      title: 'Email',
      description: 'Email',
      icon: <Icon name={Icons.mail} size={12} color="tintFg" />
    },
    linkedin: {
      title: 'LinkedIn Message',
      description: 'LinkedIn Message',
      icon: <BrandIcon name="linkedinOriginal" size={12} color="original" />
    }
  }

  const displayMap: Record<SequenceStepType, SequenceStepTypeDisplay> = {
    [SequenceStepType.AUTOMATED_EMAIL]: displayConfigs.email,
    [SequenceStepType.MANUAL_EMAIL]: displayConfigs.email,
    [SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE]: displayConfigs.linkedin,
    [SequenceStepType.MANUAL_LINKEDIN_MESSAGE]: displayConfigs.linkedin,
    [SequenceStepType.MANUAL_TASK]: {
      title: 'Manual Task',
      description: 'Manual Task',
      icon: <Icon name={Icons.checkCircle} size={12} color="warningFg" />
    },
    [SequenceStepType.MANUAL_PHONE_CALL]: {
      title: 'Call / Voicemail Drop',
      icon: <Icon name={Icons.phone} size={12} color="positiveFg" />
    },
    [SequenceStepType.MANUAL_TEXT_MESSAGE]: {
      title: 'Text Message',
      icon: <Icon name={Icons.messageCircle} size={12} color="positiveFg" />
    }
  }
  return displayMap[type]
}
