import type { FetchReportArgs } from './reports_types'

export const buildReportFetchParams = (args: FetchReportArgs): Record<string, string | boolean> => {
  const params: Record<string, string | boolean> = {
    timeFrame: args.timeFrame,
    compareToPreviousPeriod: args.compareToPreviousPeriod ?? false
  }

  if (args.jobId) {
    params.jobId = args.jobId
  }

  if (args.timezone) {
    params.timezone = args.timezone
  }

  return params
}
