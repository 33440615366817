import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { LogoSymbol } from 'src/components/primitives/logo'
import * as S from './status-view.styled'
import type { Color, Spacing } from 'src/styles/theme/types'

export type StatusViewVariant = 'normal' | 'negative'

interface StatusViewProps {
  pin?: 'normal' | 'sad' | 'happy' | 'searching' | null
  animatePin?: boolean
  variant?: StatusViewVariant
  heading?: string
  description?: string
  children?: React.ReactNode
  $contentMaxWidth?: number
  $gap?: Spacing
}

export const StatusView = ({
  pin = null,
  animatePin = false,
  variant = 'normal',
  heading,
  description,
  children,
  $contentMaxWidth,
  $gap = 24
}: StatusViewProps): JSX.Element => {
  const getCaptionColor = (): Color => {
    switch (variant) {
      case 'negative': {
        return 'negativeFg'
      }
      default: {
        return 'fgSecondary'
      }
    }
  }

  return (
    <S.StatusView $variant={variant}>
      <S.Inner $contentMaxWidth={$contentMaxWidth} $gap={$gap}>
        {pin && <LogoSymbol variant={pin} width={40} height={43} animated={animatePin} />}
          <Flex $direction="column" $gap={heading ? 8 : 0} $align="center">
            {heading && (
              <Caption size="XS" $color={getCaptionColor()} $align="center">
                {heading}
              </Caption>
            )}
            {description && (
              <Paragraph size="XS" $color="fgSecondary" $align="center">
                {description}
              </Paragraph>
            )}
          {children}
          </Flex>
      </S.Inner>
    </S.StatusView>
  )
}
