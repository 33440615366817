import { useMemo } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { InboxSidebar } from 'src/components/blocks/inbox-sidebar'
import * as PageWithSidebar from 'src/components/layouts/page-with-sidebar'
import { useAllCandidateJobsCommunicatingQuery, useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useInboxSidebar } from 'src/hooks/use-inbox-sidebar'
import { useIsGlobalRoute } from 'src/hooks/use-is-global-route'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'

const GlobalInboxSidebar = (): JSX.Element => {
  const { sidebarLooksExpanded, isPinned, toggleSidebarPinned, setIsHovered, isHovered } = useInboxSidebar()
  const [searchParams] = useSearchParams()
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const { isPending, data: candidateJobs } = useAllCandidateJobsCommunicatingQuery({ lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined })

  return (
    <InboxSidebar
      isExpanded={sidebarLooksExpanded}
      isPinned={isPinned}
      toggleSidebarPinned={toggleSidebarPinned}
      setIsHovered={setIsHovered}
      isHovered={isHovered}
      candidateJobs={candidateJobs}
      isPending={isPending}
    />
  )
}

const JobInboxSidebar = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage')
  const candidateJobStage = stage as CandidateJobStage
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')
  const { sidebarLooksExpanded, isPinned, toggleSidebarPinned, setIsHovered, isHovered } = useInboxSidebar()

  const currentStage = useMemo(() => {
    if (stage === 'null') {
      return undefined
    }
    if (Object.values(CandidateJobStage).includes(candidateJobStage)) {
      return candidateJobStage
    } else {
      return CandidateJobStage.COMMUNICATING
    }
  }, [candidateJobStage, stage])

  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: currentStage,
    lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined
  })
  return (
    <InboxSidebar
      isExpanded={sidebarLooksExpanded}
      isPinned={isPinned}
      toggleSidebarPinned={toggleSidebarPinned}
      setIsHovered={setIsHovered}
      isHovered={isHovered}
      candidateJobs={candidateJobs}
      isPending={isPending}
    />
  )
}

export const InboxLayout = (): JSX.Element => {
  const { sidebarLooksExpanded, isPinned } = useInboxSidebar()
  const isGlobalRoute = useIsGlobalRoute()

  return (
    <PageWithSidebar.Wrapper>
      <PageWithSidebar.Sidebar isExpanded={sidebarLooksExpanded}>
        {isGlobalRoute ? <GlobalInboxSidebar /> : <JobInboxSidebar />}
      </PageWithSidebar.Sidebar>
      <PageWithSidebar.Content isExpanded={isPinned}>
        <Outlet />
      </PageWithSidebar.Content>
    </PageWithSidebar.Wrapper>
  )
}
