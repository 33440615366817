import styled from 'styled-components'
import type { EmptyChartStyleProps } from './empty-chart'

export const EmptyChart = styled.div<EmptyChartStyleProps>`
  width: 100%;
  height: ${({ $height }) => $height};
  min-height: ${({ $minHeight }) => $minHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem;
  gap: 1.2rem;
`
