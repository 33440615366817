import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './index.styled'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue } from './criteria-pill'
import type { CriteriaKey, StandardCriteria } from 'src/libs/api/backend/candidate_search'
import { useCallback, useMemo, useState } from 'react'
import { CriteriaProperties } from '../constants'
import { Dropdown } from 'src/components/primitives/dropdown'

interface MultiSelectProps {
  selectedItems: StandardCriteria[]
  onUpdate: (items: StandardCriteria[]) => void
  criteriaKey: CriteriaKey
  useNegative?: boolean
  useOptional?: boolean
  disabled: boolean
}

export const MultiSelect = ({
  selectedItems,
  onUpdate,
  criteriaKey,
  useNegative = true,
  useOptional = true,
  disabled = false
}: MultiSelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { options } = useMemo(() => {
    const properties = CriteriaProperties.get(criteriaKey)
    return {
      options: properties?.options as Map<string, string> | undefined,
      type: properties?.type
    }
  }, [criteriaKey])

  const selectedItemsSet = useMemo(() => new Set(selectedItems.map((item) => item.name)), [selectedItems])

  const availableOptions = useMemo(() => {
    const allOptions = Array.from(options?.entries() ?? [])
    return allOptions.filter(([value]) => !selectedItemsSet.has(value))
  }, [options, selectedItemsSet])

  const toggleItem = useCallback((name: string) => {
    if (selectedItemsSet.has(name)) {
      onUpdate(selectedItems.filter((item) => item.name !== name))
    } else {
      onUpdate([...selectedItems, { name, optional: false, negative: false }])
    }
  }, [onUpdate, selectedItems, selectedItemsSet])

  return (
    <S.MultiSelectWrapper $disabled={disabled}>
      <S.MultiSelectInner>
        {selectedItems.map((item, index) => (
          <CriteriaPill
            key={index}
            value={{
              label: options?.get(item.name) ?? item.name,
              optional: item.optional ?? true,
              negative: item.negative ?? false
            }}
            useNegative={useNegative}
            useOptional={useOptional}
            onUpdate={(key: string, value: CriteriaPillValue) => {
              const updatedCriteriaValue = [...selectedItems]
              updatedCriteriaValue[index] = {
                ...updatedCriteriaValue[index],
                [key]: value
              }
              onUpdate(updatedCriteriaValue)
            }}
            onRemove={() => {
              const updatedCriteriaValue = selectedItems.filter((_, i) => i !== index)
              onUpdate(updatedCriteriaValue)
            }}
          />
        ))}
        {availableOptions.length > 0 && (
          <Dropdown
            disabled={disabled}
            onOpenChange={(isOpen) => {
              setIsOpen(isOpen)
            }}
            trigger={
              <span>
                <S.AddNewButton
                  disabled={disabled}
                  data-state={isOpen || selectedItems.length === 0 ? 'open' : 'closed'}
                >
                  <Icon name={Icons.plus} size={12} color='positiveBg' />
                  Add New
                </S.AddNewButton>
              </span>
            }
            items={availableOptions.map(([value, title]) => ({
              id: value,
              title,
              onSelect: () => {
                toggleItem(value)
              }
            }))}
          />
        )}
      </S.MultiSelectInner>
    </S.MultiSelectWrapper>
  )
}
