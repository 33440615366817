import styled from 'styled-components'

export const ExtendTrialBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[16]} ${({ theme }) => theme.spacing[20]};
`
