import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`
