import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import type { PipelineTeamMemberActivities } from 'src/libs/api/backend/reports_pipeline_insights'
import { Table } from 'src/components/primitives/table'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import { TableReportNumberCell } from 'src/pages/job/reports/report-components'
import { DefaultTableHeader } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { useMemo, useState } from 'react'
import { Avatar } from 'src/components/primitives/avatar'
import { useReportsShouldCompareToPreviousPeriod } from 'src/hooks/use-reports-should-compare-to-previous-period'
import { useLocation } from 'react-router-dom'
import { Spacer } from 'src/components/primitives/spacer/spacer'
import { When } from 'src/components/blocks/when'
import { Button } from 'src/components/primitives/button'

interface TeamActivityChartProps {
  data: PipelineTeamMemberActivities[]
}

const calculatePercentage = (value: number, baseValue: number): number => {
  if (baseValue === 0 && value > 0) return 1
  return baseValue ? Number((value / baseValue).toFixed(2)) : 0
}

interface TeamActivityTableData {
  id: string
  userName: string
  profilePhotoUrl: string | null | undefined
  reviewed: number
  pastReviewed?: number
  accepted: number
  pastAccepted?: number
  outreached: number
  pastOutreached?: number
  read: number
  pastRead?: number
  replies: number
  pastReplies?: number
  interested: number
  pastInterested?: number
  scheduled: number
  pastScheduled?: number
}

type SortableTeamActivityColumns = 'reviewed' | 'accepted' | 'outreached' | 'read' | 'replies' | 'interested' | 'scheduled'

const sortByTeamActivityColumn = (field: SortableTeamActivityColumns) => {
  return (rowA: TeamActivityTableData, rowB: TeamActivityTableData) => {
    const valueA = rowA[field] ?? 0
    const valueB = rowB[field] ?? 0
    return valueA - valueB
  }
}

export const TeamActivityChart = ({ data }: TeamActivityChartProps): JSX.Element => {
  const { shouldCompare } = useReportsShouldCompareToPreviousPeriod()
  const { pathname } = useLocation()
  const isAllPositionsReport = pathname.includes('all/reports')
  const [showAllActivities, setShowAllActivities] = useState(false)

  const tableData = useMemo((): TeamActivityTableData[] => {
    return data.map((member) => ({
      id: member.userId,
      userName: member.userName,
      profilePhotoUrl: member.profilePhotoUrl,
      reviewed: member.reviewed,
      pastReviewed: member.pastReviewed,
      accepted: member.accepted,
      pastAccepted: member.pastAccepted,
      outreached: member.outreached,
      pastOutreached: member.pastOutreached,
      read: member.read,
      pastRead: member.pastRead,
      replies: member.replies,
      pastReplies: member.pastReplies,
      interested: member.interested,
      pastInterested: member.pastInterested,
      scheduled: member.scheduled,
      pastScheduled: member.pastScheduled
    }))
  }, [data])

  const getValueChangeDirection = (current: number, past: number): 'up' | 'down' | undefined => {
    if (current === 0 && past === 0) return undefined
    return current > past ? 'up' : 'down'
  }

  const columns: Array<TableColumnDef<TeamActivityTableData>> = useMemo(() => [
    {
      columnId: 'teamMember',
      size: 'minmax(min-content, 3fr)',
      visible: true,
      header: <DefaultTableHeader title="Team Member" minHeight={40} />,
      sortingFn: (a: TeamActivityTableData, b: TeamActivityTableData) => {
        return a.userName.localeCompare(b.userName)
      },
      cell: (info: TeamActivityTableData) => (
        <Flex $gap={8} $align="center">
          <Avatar
            $type="photo"
            $size={24}
            initials={info.userName.charAt(0)}
            photoUrl={info.profilePhotoUrl}
          />
          <Caption size="XS" $lineHeight={1}>{info.userName}</Caption>
        </Flex>
      )
    },
    {
      columnId: 'reviewed',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Reviewed" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('reviewed'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.reviewed}
          percentage={shouldCompare ? calculatePercentage(info.reviewed, info.pastReviewed ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.reviewed, info.pastReviewed ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'accepted',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Accepted" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('accepted'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.accepted}
          percentage={shouldCompare ? calculatePercentage(info.accepted, info.pastAccepted ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.accepted, info.pastAccepted ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'outreached',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Outreached" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('outreached'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.outreached}
          percentage={shouldCompare ? calculatePercentage(info.outreached, info.pastOutreached ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.outreached, info.pastOutreached ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'read',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Read" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('read'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.read}
          percentage={shouldCompare ? calculatePercentage(info.read, info.pastRead ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.read, info.pastRead ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'replies',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Replies" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('replies'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.replies}
          percentage={shouldCompare ? calculatePercentage(info.replies, info.pastReplies ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.replies, info.pastReplies ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'interested',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Interested" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('interested'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.interested}
          percentage={shouldCompare ? calculatePercentage(info.interested, info.pastInterested ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.interested, info.pastInterested ?? 0) : undefined}
        />
      )
    },
    {
      columnId: 'scheduled',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Scheduled" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('scheduled'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.scheduled}
          percentage={shouldCompare ? calculatePercentage(info.scheduled, info.pastScheduled ?? 0) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.scheduled, info.pastScheduled ?? 0) : undefined}
        />
      )
    }
  ], [shouldCompare])

  return (
    <ChartWrapper>
      <ChartHeader title="Team Activity" />
      <Table<TeamActivityTableData>
        tableData={tableData}
        columns={columns}
        emptyState={<EmptyChart $minHeight='80px' />}
        visibleRowsCount={showAllActivities ? undefined : 5}
      />
      <When condition={isAllPositionsReport && !showAllActivities && tableData.length > 5}>
        <Spacer $size={12} />
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width="100%"
          $fontSize={12}
          onClick={() => {
            setShowAllActivities(!showAllActivities)
          }}
        >
          See All
        </Button>
      </When>
    </ChartWrapper>
  )
}
