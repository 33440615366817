import type { CandidateEducation } from 'src/libs/api/backend/candidate_jobs'
import * as S from './candidate-education-list.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { format } from 'date-fns'
import { CandidateDetailsSectionHeading } from '../candidate-details/candidate-details'
import type { CandidateExperiencesAndEducationListStyleProps } from '../candidate-experiences-list'

interface CandidateEducationListProps extends CandidateExperiencesAndEducationListStyleProps {
  educations: CandidateEducation[]
}

export const CandidateEducationList = ({ educations, $padding = { top: 12, bottom: 8, left: 24, right: 24 } }: CandidateEducationListProps): JSX.Element => {
  if (!educations || educations.length === 0) {
    return <></>
  }

  return (
    <S.Wrapper $padding={$padding}>
      <CandidateDetailsSectionHeading heading="Education" />
      {educations.slice(0, 2).map((education) => (
        <S.Card key={education.school}>
          <Avatar
            $size={40}
            $shape="soft"
            $type="logo"
            company={{ name: education.school, url: education.website, logoUrl: education.logoUrl }}
            fallbackIcon={{ name: 'building-2' }}
          />
          <S.Details>
            <Caption size="SM">{education.school}</Caption>
            <Flex $align="center" $gap={8}>
              {education.degree && (
                <Paragraph size="XS" $color="fgPrimary">{education.degree}</Paragraph>
              )}
              <Paragraph size="XS" $color="fgSecondary">
                {education.startDate && `${format(education.startDate, 'yyyy')}${education.endDate ? ` - ${format(education.endDate, 'yyyy')}` : ''}`}
              </Paragraph>
            </Flex>
          </S.Details>
        </S.Card>
      ))}
    </S.Wrapper>
  )
}
