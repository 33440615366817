import { useSetAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { exportCandidateJobsToCsv } from 'src/libs/api/backend/candidate_jobs'
import type { FetchCandidatesSearchParams } from 'src/libs/api/backend/candidate_jobs'
import { sleep } from 'src/libs/sleep'
import { closeNotificationAtom, notifyAtom, notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface ReturnType {
  exportCandidates: () => Promise<void>
}

export const useExportCandidatesToCsv = (searchParams?: FetchCandidatesSearchParams): ReturnType => {
  const { jobId } = useParams()
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)
  const closeNotification = useSetAtom(closeNotificationAtom)

  const exportCandidates = async (): Promise<void> => {
    notify({
      type: 'toast',
      variant: 'neutral',
      position: 'bottom-right',
      icon: 'loader',
      isLoading: true,
      autoClose: false,
      message: 'Generating CSV file...'
    })

    try {
      await exportCandidateJobsToCsv({ jobId: jobId ?? '', searchParams })
      closeNotification(null, 'Generating CSV file')
      await sleep(500) // give it a bit of time to switch between notifications
      notifySuccess({
        message: 'Exported candidates into CSV file. Please check your downloads folder.'
      })
    } catch (error) {
      closeNotification(null, 'Generating CSV file')
      await sleep(500) // give it a bit of time to switch between notifications
      notifyError({
        message: 'Unfortunately, there was an error downloading this file. If the error persists, please get in touch.'
      })
    }
  }

  return { exportCandidates }
}
