import { useParams, useSearchParams } from 'react-router-dom'
import { Avatar } from 'src/components/primitives/avatar'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './inbox-sidebar.styled'
import { CandidateJobStatus, CandidateJobStatusType } from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobStatusDisplay
} from 'src/libs/api/backend/candidate_jobs'
import { When } from '../when'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import type { Color } from 'src/styles/theme/types'
import { useIsGlobalRoute } from 'src/hooks/use-is-global-route'
import { useMemo } from 'react'
import { StandaloneCheckbox } from 'src/components/forms/checkbox/standalone-checkbox'

export interface ItemProps {
  candidateJob: CandidateJobExpanded
  timePassed: string
  isExpanded: boolean
  company: string
  companyWebsite?: string | null
  companyLogoUrl?: string | null
  isSelected: boolean
  hasAtLeastOneSelected: boolean
  onSelect: ({ isSelected, candidateJobId, isShiftKey }: { isSelected: boolean, candidateJobId: string, isShiftKey: boolean }) => void
}

interface GetStatusReturnType {
  icon?: IconName
  text: string
  color?: Color
}

const getColorThemeForStatus = (statusDisplay: CandidateJobStatusDisplay | null): Color => {
  switch (statusDisplay?.type) {
    case CandidateJobStatusType.SUCCESS:
      return 'positiveBg'
    case CandidateJobStatusType.ERROR:
      return 'negativeBg'
    case CandidateJobStatusType.WARNING:
      return 'warningBg'
    default:
      return 'fgSecondary'
  }
}

const getIconForStatus = (statusDisplay: CandidateJobStatusDisplay | null): IconName => {
  switch (statusDisplay?.status) {
    case CandidateJobStatus.HIRED:
      return Icons.heartHandshake
    case CandidateJobStatus.REJECTED:
      return Icons.archive
    case CandidateJobStatus.PREVIOUSLY_BOUNCED:
    case CandidateJobStatus.PREVIOUSLY_UNSUBSCRIBED:
    case CandidateJobStatus.PREVIOUSLY_MESSAGED:
      return Icons.mailWarning
    case CandidateJobStatus.BOUNCED:
    case CandidateJobStatus.EMAIL_NOT_FOUND:
      return Icons.mailX
    case CandidateJobStatus.MISSING_EMAIL:
    case CandidateJobStatus.MISSING_LINKEDIN:
    case CandidateJobStatus.SEARCHING_FOR_EMAIL:
      return Icons.mailQuestion
    case CandidateJobStatus.IN_SEQUENCE:
      return Icons.mails
    case CandidateJobStatus.RESPONDED:
      return Icons.mailCheck
    case CandidateJobStatus.WAITING_ON_CANDIDATE_TO_RESPOND:
    case CandidateJobStatus.CANDIDATE_WAITING_FOR_RESPONSE:
      return Icons.messagesSquare
    case CandidateJobStatus.CANDIDATE_UNRESPONSIVE:
      return Icons.sleepSnooze
    default:
      return Icons.info
  }
}

export const Item = ({
  candidateJob,
  timePassed,
  // company,
  // companyWebsite,
  // companyLogoUrl,
  isExpanded = true,
  isSelected,
  hasAtLeastOneSelected,
  onSelect
}: ItemProps): JSX.Element => {
  const { jobId } = useParams()
  const awaitingResponse = candidateJob.candidate.awaitingResponse ?? false
  const photoUrl = candidateJob.candidate.profilePhotoUrl ?? null
  const isGlobalRoute = useIsGlobalRoute()
  const [searchParams] = useSearchParams()
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const getConversationStatus = (): GetStatusReturnType => {
    const status: CandidateJobStatusDisplay | null = candidateJob.statusDisplay
    return {
      icon: getIconForStatus(status),
      text: status?.title ?? status?.subtitle ?? '',
      color: getColorThemeForStatus(status)
    }
  }

  const href = useMemo(() => {
    const baseUrl = isGlobalRoute
      ? RouteBuilder.build('ALL_INBOX_CANDIDATE_JOBS', { candidateJobId: candidateJob.id })
      : RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', { jobId, candidateJobId: candidateJob.id })

    return lastCommunicatedWithUserId ? `${baseUrl}?lastCommunicatedWithUserId=${lastCommunicatedWithUserId}` : baseUrl
  }, [isGlobalRoute, jobId, candidateJob.id, lastCommunicatedWithUserId])

  const handleCheckboxChange = (e: React.MouseEvent<HTMLDivElement>) => {
    onSelect({
      isSelected: !isSelected,
      candidateJobId: candidateJob.id,
      isShiftKey: e.shiftKey
    })
  }

  return (
    <S.Item>
      <S.ItemCheckbox
        $isSelected={isSelected}
        $hasAtLeastOneSelected={hasAtLeastOneSelected}
        onClick={handleCheckboxChange}
      >
        <StandaloneCheckbox
          name="checkbox"
          defaultChecked={isSelected}
          hiddenLabel
          $marginBottom={0}
          onChange={() => {}}
        />
      </S.ItemCheckbox>
      <S.ItemInner to={href} $isSelected={isSelected}>
        <Avatar
          key={photoUrl}
          $size={40}
          $status={awaitingResponse ? 'tintBg' : undefined}
          photoUrl={photoUrl}
          initials={candidateJob.candidate.name}
          // company={{ name: company, url: companyWebsite, logoUrl: companyLogoUrl }}
          $type="photo"
          icon={{
            name: getConversationStatus().icon ?? 'x-octagon',
            color: getConversationStatus().color ?? 'fgSecondary'
          }}
        />
        <When condition={isExpanded}>
          <S.ItemTile>
            <S.TileTitle>
              <Caption size="SM" $ellipsis={true}>
                {candidateJob.candidate.name}
              </Caption>
              {candidateJob.favorite && (
                <S.Favorite>
                  <Icon name={Icons.starFill} size={12} color="warningBg" />
                </S.Favorite>
              )}
            </S.TileTitle>
            {/*
              <Paragraph size="XS">{candidateJobStageDisplay[candidateJob.stage]}</Paragraph>
            */}
            <Paragraph size="XS" $color={getConversationStatus().color} $whiteSpace="nowrap">
              {getConversationStatus().text}
            </Paragraph>
          </S.ItemTile>
          <Caption size="XS" $color="fgTertiary">
            {timePassed}
          </Caption>
        </When>
      </S.ItemInner>
    </S.Item>
  )
}
