export const queryKeys = {
  avatars: 'avatars',
  applicantsOverviewStatus: 'applicantsOverviewStatus',
  atsRejectionReasons: 'atsRejectionReasons',
  billingCheckout: 'billingCheckout',
  billingPricing: 'billingPricing',
  calendar: 'calendar',
  calendarAvailabilityPreference: 'calendarAvailabilityPreference',
  candidate: 'candidate',
  candidateActivities: 'candidateActivities',
  candidateJob: 'candidateJob',
  candidateJobCounts: 'candidateJobCounts',
  candidateJobs: 'candidates',
  candidateJobsCommunicating: 'candidateJobsCommunicating',
  candidateJobsCommunicatingCounts: 'candidateJobsCommunicatingCounts',
  candidatesByLinkedinUrls: 'candidatesByLinkedinUrls',
  candidateSequencePause: 'candidateSequencePause',
  candidateSequenceStepMessageReviews: 'candidateSequenceStepMessageReviews',
  companyLogo: 'companyLogo',
  companyPreferences: 'companyPreferences',
  companySuggestions: 'companySuggestions',
  createJobSequence: 'createJobSequence',
  customerSubscriptions: 'customerSubscriptions',
  customOutreach: 'customOutreach',
  departments: 'departments',
  projects: 'projects',
  existingOrgs: 'existingOrgs',
  job: 'orgJob',
  jobByDepartments: 'jobByDepartments',
  jobListings: 'jobListings',
  jobs: 'orgJobs',
  jobSearchRefinements: 'jobSearchRefinements',
  jobSequence: 'jobSequence',
  jobStats: 'jobStats',
  locationSuggestions: 'locationSuggestions',
  majorSuggestions: 'majorSuggestions',
  me: 'me',
  linkedInAccounts: 'meLinkedInAccounts',
  mentionedTimesAvailability: 'mentionedTimesAvailability',
  mergeIntegrations: 'mergeIntegrations',
  mergeJobs: 'mergeJobs',
  mergeLinkJobs: 'mergeLinkJobs',
  notifications: 'notifications',
  notificationsCount: 'notificationsCount',
  notificationsPreferences: 'notificationsPreferences',
  orgInviteDirectory: 'orgInviteDirectory',
  orgInvites: 'orgInvites',
  orgJoinRequests: 'orgJoinRequests',
  orgTrialSummary: 'orgTrialSummary',
  orgUsers: 'orgUsers',
  schoolSuggestions: 'schoolSuggestions',
  session: 'session',
  suggestedResponse: 'suggestedResponse',
  teamMembersToBeInvited: 'orgInvitees',
  jobSequenceState: 'jobSequenceState',
  atsIntegrationDetails: 'atsIntegrationDetails',
  atsIntegrationStatus: 'atsIntegrationStatus',
  timezonesAndHolidays: 'timezonesAndHolidays',
  apiStatus: 'apiStatus',
  jobSearchRefinement: 'jobSearchRefinement',
  atsInterviewStages: 'atsInterviewStages',
  aiGeneratedSentence: 'aiGeneratedSentence',
  // ManualQueue is suppose to represent any type of message that shows up the Ready-To-Send-Page (ManualTasks, ManaulEmail-CandidateSequenceStepMessageReviews)
  manualQueue: 'manualQueue',
  reports: 'reports'
} as const
