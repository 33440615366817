import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './email-fallback-settings.styled'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { Form } from 'src/components/forms'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useSession } from 'src/hooks/use-session'
import { Select } from 'src/components/forms/select'
import { EmailFallbackType, EmailFallbackTypeLabel } from 'src/libs/api/backend/jobs'

const emailFallbackSettings = z.object({
  autoSkipEmailStepsOnMissingEmail: z.boolean()
})

type EmailFallbackSettingsForm = z.infer<typeof emailFallbackSettings>

interface EmailFallbackSettingsProps {
  onChange: ({ autoSkipEmailStepsOnMissingEmail }: { autoSkipEmailStepsOnMissingEmail: boolean }) => void
}

export const EmailFallbackSettings = ({ onChange }: EmailFallbackSettingsProps): JSX.Element => {
  const { isPending, data: emailSequence } = useJobSequenceQuery()
  const { userHasViewerRole } = useSession()
  const { register, submit } = useForm<EmailFallbackSettingsForm>({
    schema: emailFallbackSettings
  })

  const handleSubmit = (data: EmailFallbackSettingsForm): void => {
    console.log(data)
  }

  return (
    <Flex $align="center" $justify="space-between">
      <Flex $direction="column" $gap={2}>
        <Caption size="SM">Fallback option</Caption>
        <Paragraph size="XS" $color="fgSecondary">
          When email is not available, perform this action.
        </Paragraph>
      </Flex>
      <S.FormWrapper>
        <Form onSubmit={submit(handleSubmit)}>
          <Select
            placeholder={'Select email fallback'}
            showIcons={false}
            hiddenLabel={true}
            $marginBottom={0}
            defaultValue={
              emailSequence?.autoSkipEmailStepsOnMissingEmail
                ? EmailFallbackType.SKIP_TO_NEXT_STEP
                : EmailFallbackType.ASK_ME
            }
            loading={isPending}
            disabled={userHasViewerRole}
            items={[
              {
                value: EmailFallbackType.SKIP_TO_NEXT_STEP,
                title: EmailFallbackTypeLabel[EmailFallbackType.SKIP_TO_NEXT_STEP]
              },
              {
                value: EmailFallbackType.ASK_ME,
                title: EmailFallbackTypeLabel[EmailFallbackType.ASK_ME]
              }
            ]}
            onValueChange={(value) => {
              if (userHasViewerRole) {
                return
              }
              onChange({ autoSkipEmailStepsOnMissingEmail: value === EmailFallbackType.SKIP_TO_NEXT_STEP })
            }}
            name="emailFallback"
            register={register}
            $fontSize={12}
          />
        </Form>
      </S.FormWrapper>
    </Flex>
  )
}
