import styled, { css } from 'styled-components'
import { CHART_COLORS } from '../../base/constants'

export const Wrapper = styled.div`
  position: relative;
  height: calc(100% - 44px - 1rem);
  padding-left: 2.25rem;
`

export const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
`

export const Bars = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  z-index: 1;
`

export const Bar = styled.div`
  flex: 1 1 10%;
  height: 100%;
  position: relative;
`

export const BarsLegend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
`

export const BarLegend = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes['12']};
  color: ${({ theme }) => theme.colors.fgTertiary};
`

export const BarBadge = styled.div<{ $value: number }>`
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  height: 20px;
  border-radius: 99px;
  padding: 2px 8px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: ${({ theme }) => theme.fontSizes['10']};
    color: ${CHART_COLORS.BLUE_MID};
    line-height: 1;
  }
  ${({ $value }) => $value < 40 && css`
    top: -28px;
  `}
`

export const BarCurrent = styled.div<{ $height: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ $height }) => $height};
  background-color: ${CHART_COLORS.BLUE_MID};
`

export const BarGrowth = styled.div<{ $height: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ $height }) => $height};
  background-color: ${CHART_COLORS.GREEN_MINT};
`

export const Grid = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const GridLine = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: ${({ theme }) => theme.fontSizes['12']};
    color: ${({ theme }) => theme.colors.fgTertiary};
    line-height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2.25rem;
    width: calc(100% - 2.25rem);
    height: 100%;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
  }
`
