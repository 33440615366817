import { SEO } from 'src/components/primitives/seo'
import * as S from './outreach-errors.styled'
import { Caption } from 'src/components/primitives/typography'
import { CandidatesTable } from 'src/components/tables/candidates-table'
import { EmptyState } from 'src/components/blocks/empty-state'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from 'src/components/tables/candidate-table-actions'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useLoaderData } from 'react-router-dom'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Spacer } from 'src/components/primitives/spacer'
import { useChannel } from 'ably/react'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useAtomValue } from 'jotai'
import { candidateSearchChannelAtom } from 'src/stores/websocket-channels'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { InOutreachErrorsLoaderData } from 'src/libs/loaders/in-outreach'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { CandidateJobRejectionReason, CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'
import { Badge } from 'src/components/primitives/badge'
import { selectedRowsAtom } from 'src/stores/table'
import { useMemo } from 'react'
import { pluralize } from 'src/libs/pluralize'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'

const DEFAULT_TITLE = 'Outreach · Errors'

type CandidateJobStatusInUseForErrors =
  | CandidateJobStatus.PREVIOUSLY_MESSAGED
  | CandidateJobStatus.MISSING_EMAIL
  | CandidateJobStatus.BOUNCED
  | CandidateJobStatus.MISSING_LINKEDIN

interface HeaderProps {
  candidateJobs?: CandidateJobExpanded[]
  selectedCandidateJobs?: CandidateJobExpanded[]
  candidateJobIdsPerStatus?: Record<CandidateJobStatusInUseForErrors, string[]>
}

const Header = ({ candidateJobs, selectedCandidateJobs, candidateJobIdsPerStatus }: HeaderProps): JSX.Element => {
  const { rejectCandidate } = useRejectCandidate()
  const { setCandidateSequencePause } = usePauseCandidateSequence()

  const DROPDOWN_STATUS_ITEMS: Array<{
    status: CandidateJobStatusInUseForErrors
    title: string
    icon: React.ReactNode
    actions?: Array<'RESUME_OUTREACH' | 'ARCHIVE'>
  }> = [
    {
      status: CandidateJobStatus.PREVIOUSLY_MESSAGED,
      title: 'Previously Messaged',
      icon: 'history',
      actions: ['RESUME_OUTREACH', 'ARCHIVE']
    },
    {
      status: CandidateJobStatus.MISSING_EMAIL,
      title: 'Missing Email',
      icon: 'mail-question',
      actions: ['ARCHIVE']
    },
    {
      status: CandidateJobStatus.BOUNCED,
      title: 'Bounced Email',
      icon: 'mail-x',
      actions: ['ARCHIVE']
    },
    {
      status: CandidateJobStatus.MISSING_LINKEDIN,
      title: 'LinkedIn Message Error',
      icon: <div style={{ paddingLeft: 3 }}><BrandIcon name="linkedin" size={12} color="fgSecondary" /></div>,
      actions: ['RESUME_OUTREACH', 'ARCHIVE']
    }
  ]

  const buildStatusDropdownItems = (candidateJobIdsPerStatus: Record<CandidateJobStatusInUseForErrors, string[]>): MenuItemProps[] => {
    return DROPDOWN_STATUS_ITEMS.map(({ status, title, icon, actions }) => {
      const count = candidateJobIdsPerStatus?.[status]?.length ?? 0

      return {
        title,
        value: status,
        icon,
        trailingIcon: (
          <Flex $align="center" $gap={4}>
            <div style={{ width: '20px' }} />
            <Badge $variant="bgTertiary">{count}</Badge>
            <Icon name="chevron-right" size={12} color="fgSecondary" />
          </Flex>
        ),
        isDisabled: count === 0,
        isSelectable: count > 0,
        subitems: [
          {
            title: `Apply to ${pluralize(count, 'Candidate')}`,
            type: 'node',
            content: <S.DropdownFirstLabel><span>Apply to {pluralize(count, 'Candidate')}</span></S.DropdownFirstLabel>
          },
          ...(actions?.includes('RESUME_OUTREACH')
            ? [
                {
                  title: 'Resume Outreach',
                  type: 'item' as const,
                  icon: 'play-circle',
                  variant: 'tint' as const,
                  onSelect: () => {
                    setCandidateSequencePause({ candidateJobIds: candidateJobIdsPerStatus[status], pause: false })
                  }
                }
              ]
            : []),
          {
            title: `Archive ${pluralize(count, 'Candidate', { hideCount: true })}`,
            type: 'item',
            icon: 'archive',
            variant: 'tint',
            onSelect: () => {
              rejectCandidate({
                candidateJobIds: candidateJobIdsPerStatus[status],
                rejectionReason: CandidateJobRejectionReason.OTHER
              })
            }
          }
        ]
      }
    })
  }

  return (
    <S.OutreachErrorsHeader>
      <Caption as='h1' size='MD' $whiteSpace='nowrap'>
        {DEFAULT_TITLE}
      </Caption>
      <Dropdown
        trigger={
          <Button nested $variant="raised" $colorTheme="normal" $height={24} trailingIcon="chevron-down" $fontSize={12}>
            Bulk Actions
          </Button>
        }
        items={[
          {
            title: 'Error Types',
            type: 'node',
            content: <S.DropdownFirstLabel><span>Error Types</span></S.DropdownFirstLabel>
          },
          ...buildStatusDropdownItems(candidateJobIdsPerStatus ?? {
            [CandidateJobStatus.BOUNCED]: [],
            [CandidateJobStatus.PREVIOUSLY_MESSAGED]: [],
            [CandidateJobStatus.MISSING_EMAIL]: [],
            [CandidateJobStatus.MISSING_LINKEDIN]: []
          }),
          { title: 'Separator', type: 'separator' },
          { title: 'Apply To All', type: 'sublabel' },
          {
            title: selectedCandidateJobs?.length
              ? `Archive ${pluralize(selectedCandidateJobs?.length, 'Selected Candidate')}`
              : `Archive All ${pluralize(candidateJobs?.length ?? 0, 'Candidate')}`,
            icon: 'archive',
            variant: 'tint',
            onSelect: () => {
              const candidateJobsToUse = selectedCandidateJobs?.length ? selectedCandidateJobs : candidateJobs
              rejectCandidate({
                candidateJobIds: candidateJobsToUse?.map(candidateJob => candidateJob.id) ?? [],
                rejectionReason: CandidateJobRejectionReason.OTHER
              })
            }
          }
        ]}
        menuPosition="end"
        size="small"
      />
    </S.OutreachErrorsHeader>
  )
}

const OutreachErrorsPage = (): JSX.Element => {
  const { jobId } = useLoaderData() as InOutreachErrorsLoaderData
  const { isPending: isPendingJob, data: job } = useJobQuery()
  const { isPending: isPendingCandidateJobs, data: candidateJobs } = useCandidateJobsQuery({
    errored: true
  })

  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  useChannel({ channelName: candidateSearchChannel, skip: !candidateSearchChannel }, EVENT_TYPE.PROSPECTING_ERROR, (message) => {
    const { jobId: messageJobId } = message.data ?? {}
    if (messageJobId === jobId) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  const selectedRowIds = useAtomValue(selectedRowsAtom)

  const selectedCandidateJobs = useMemo(() => {
    return candidateJobs?.filter(candidate => selectedRowIds.has(candidate.id)) ?? []
  }, [candidateJobs, selectedRowIds])

  const getCandidateJobIdsPerStatus = useMemo(() => {
    const statusMap: Record<CandidateJobStatusInUseForErrors, string[]> = {
      [CandidateJobStatus.PREVIOUSLY_MESSAGED]: [],
      [CandidateJobStatus.MISSING_EMAIL]: [],
      [CandidateJobStatus.BOUNCED]: [],
      [CandidateJobStatus.MISSING_LINKEDIN]: []
    }

    const candidateJobsToUse = selectedCandidateJobs?.length ? selectedCandidateJobs : candidateJobs

    if (candidateJobsToUse) {
      candidateJobsToUse.forEach(candidateJob => {
        const status = candidateJob.statusDisplay?.status as CandidateJobStatusInUseForErrors
        if (status && statusMap[status]) {
          statusMap[status].push(candidateJob.id)
        }
      })
    }

    return statusMap
  }, [candidateJobs, selectedCandidateJobs])

  return (
    <S.OutreachErrorsPageInner>
      <SEO title={DEFAULT_TITLE} />
      <S.OutreachErrorsPageWrapper>
        <S.OutreachErrorsPage>
          <Header
            selectedCandidateJobs={selectedCandidateJobs}
            candidateJobs={candidateJobs}
            candidateJobIdsPerStatus={getCandidateJobIdsPerStatus}
          />
          <Spacer $size={4} />
          <CandidatesTable
            visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.ERROR, COLUMN.ERROR_ACTIONS]}
            isLoading={isPendingCandidateJobs || isPendingJob}
            candidateJobs={candidateJobs}
            emptyState={
              <EmptyState
                heading="No candidates"
                description="Looks like you don't have any prospects yet, start sourcing candidates and any errors will show up right here."
                svg="userCards"
              />
            }
            selectedRowsActions={
              <>
                <RejectButton />
                <WriteEmailButton candidateJobs={candidateJobs} />
                <ExportToAtsButton candidateJobs={candidateJobs} job={job} />
              </>
            }
          />
        </S.OutreachErrorsPage>
      </S.OutreachErrorsPageWrapper>
    </S.OutreachErrorsPageInner>
  )
}

export default OutreachErrorsPage
