import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { Table } from 'src/components/primitives/table'
import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { TableCellContentWithTooltip, TableReportNumberCell } from 'src/pages/job/reports/report-components'
import { DefaultTableHeader } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import type { SequenceStepActivity } from 'src/libs/api/backend/reports_communication_insights'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { useMemo, useState } from 'react'
import { Icon } from 'src/components/primitives/icon'
import { useLocation, useParams } from 'react-router-dom'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { When } from 'src/components/blocks/when'

interface SequenceStepActivityChartProps {
  data: SequenceStepActivity[]
}

const calculatePercentage = (value: number, baseValue: number): number => {
  return baseValue ? Number((value / baseValue).toFixed(2)) : 0
}

type SortableStepActivityColumns = 'sent' | 'opened' | 'replied' | 'scheduled'

const sortByStepActivityColumn = (field: SortableStepActivityColumns) => {
  return (rowA: SequenceStepActivity, rowB: SequenceStepActivity) => {
    const valueA = rowA[field] ?? 0
    const valueB = rowB[field] ?? 0
    return valueA - valueB
  }
}

export const SequenceStepActivityChart = ({ data }: SequenceStepActivityChartProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: emailSequence } = useJobSequenceQuery(jobId)
  const { pathname } = useLocation()
  const isAllPositionsReport = pathname.includes('all/reports')
  const [showAllActivities, setShowAllActivities] = useState(false)

  const columns: Array<TableColumnDef<SequenceStepActivity>> = useMemo(
    () => [
      {
        columnId: 'position',
        size: '28px',
        visible: true,
        header: <DefaultTableHeader title="#" minHeight={40} />,
        cell: (info) => <Caption size="XS">{info.position + 1}</Caption>
      },
      {
        columnId: 'subject',
        size: 'minmax(min-content, 3fr)',
        visible: true,
        header: <DefaultTableHeader title="Subject" minHeight={40} />,
        sortingFn: (a: SequenceStepActivity, b: SequenceStepActivity) => {
          const currentStepA = a.sequenceStep.subject ?? ''
          const currentStepB = b.sequenceStep.subject ?? ''
          return currentStepA.toLowerCase().localeCompare(currentStepB.toLowerCase())
        },
        cell: (info) => {
          const currentStep = emailSequence?.sequenceSteps?.find((step) => step.id === info.sequenceStep.id)
          const stepType = currentStep?.type
          const isLinkedInStep = stepType === 'AUTOMATED_LINKEDIN_MESSAGE' || stepType === 'MANUAL_LINKEDIN_MESSAGE'
          const subject = info.sequenceStep.subject

          return (
            <Flex $align="center" $gap={isAllPositionsReport ? 12 : 8}>
              {isLinkedInStep ? <BrandIcon name='linkedinOriginal' size={12} color="fgSecondary" /> : <Icon name="mail" size={12} />}
              <Flex $direction="column" $gap={2}>
                {
                  (subject && subject.length >= 42)
                    ? (
                        <TableCellContentWithTooltip visibleContent={subject} tooltipContent={subject} threshold={42} />
                      )
                    : (
                        <Caption size="XS" $whiteSpace="nowrap">
                          {subject}
                        </Caption>
                      )
                }
                {
                  isAllPositionsReport && (
                    <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                      {info.sequenceStep.jobTitle}
                    </Paragraph>
                  )
                }
              </Flex>
            </Flex>
          )
        }
      },
      {
        columnId: 'sender',
        size: 'minmax(min-content, 2fr)',
        visible: true,
        header: <DefaultTableHeader title="Sender" minHeight={40} />,
        sortingFn: (a: SequenceStepActivity, b: SequenceStepActivity) => {
          const currentStepA = a.user.name ?? ''
          const currentStepB = b.user.name ?? ''
          return currentStepA.toLowerCase().localeCompare(currentStepB.toLowerCase())
        },
        cell: (info) => (
          <Flex $gap={8} $align="center">
            <Avatar
              $type="photo"
              $size={24}
              initials={info.user.name.charAt(0)}
              photoUrl={info.user.profilePhoto}
            />
            <Caption size="XS">{info.user.name}</Caption>
          </Flex>
        )
      },
      {
        columnId: 'sent',
        size: 'minmax(min-content, 1fr)',
        visible: true,
        header: <DefaultTableHeader title="Sent" minHeight={40} />,
        headerAlign: 'center',
        sortingFn: sortByStepActivityColumn('sent'),
        cell: (info) => <TableReportNumberCell value={info.sent} />
      },
      {
        columnId: 'opened',
        size: 'minmax(min-content, 1fr)',
        header: <DefaultTableHeader title="Opened" minHeight={40} />,
        headerAlign: 'center',
        sortingFn: sortByStepActivityColumn('opened'),
        cell: (info) => (
          <TableReportNumberCell
            value={info.opened}
            percentage={calculatePercentage(info.opened, info.sent)}
          />
        )
      },
      {
        columnId: 'replied',
        size: 'minmax(min-content, 1fr)',
        header: <DefaultTableHeader title="Replied" minHeight={40} />,
        headerAlign: 'center',
        sortingFn: sortByStepActivityColumn('replied'),
        cell: (info) => (
          <TableReportNumberCell
            value={info.replied}
            percentage={calculatePercentage(info.replied, info.sent)}
          />
        )
      },
      {
        columnId: 'scheduled',
        size: 'minmax(min-content, 1fr)',
        header: <DefaultTableHeader title="Scheduled" minHeight={40} />,
        headerAlign: 'center',
        sortingFn: sortByStepActivityColumn('scheduled'),
        cell: (info) => (
          <TableReportNumberCell
            value={info.scheduled}
            percentage={calculatePercentage(info.scheduled, info.sent)}
          />
        )
      }
    ],
    [emailSequence, isAllPositionsReport]
  )

  const tableData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        id: item.sequenceStep.id
      })),
    [data]
  )

  return (
    <ChartWrapper>
      <ChartHeader title="Sequence Step Activity" />
      <Table<SequenceStepActivity & { id: string }>
        tableData={tableData}
        columns={columns}
        emptyState={<EmptyChart $minHeight="80px" />}
        visibleRowsCount={showAllActivities ? undefined : 5}
      />
      <When condition={isAllPositionsReport && !showAllActivities && tableData.length > 5}>
        <Spacer $size={12} />
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width="100%"
          $fontSize={12}
          onClick={() => {
            setShowAllActivities(!showAllActivities)
          }}
        >
          See All
        </Button>
      </When>
    </ChartWrapper>
  )
}
