import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { TileChart } from 'src/components/charts/primitives/tile-chart'
import type { TeamActivityCountByHour } from 'src/libs/api/backend/reports_team_activities'

interface TeamActivityCountByHourChartProps {
  data: TeamActivityCountByHour[]
}

export const TeamActivityCountByHourChart = ({ data }: TeamActivityCountByHourChartProps): JSX.Element => {
  return (
    <ChartWrapper>
      <TileChart
        chartTitle="All Activities Over Time"
        legendTitle="Number of Activity"
        tooltipTitle="Activities"
        data={data}
      />
    </ChartWrapper>
  )
}
