import styled from 'styled-components'

export const PieChart = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4.25rem;
`

export const PieChartInner = styled.div`
  width: auto;
`

export const Legend = styled.ul`
  position: relative;
  transform: translateX(calc(128px + 4rem));
  li {
    transform: translateX(18px);
  }
`
