import * as S from './disabled.styled'

interface DisabledProps {
  when?: boolean
  opacity?: number
  children: React.ReactNode
}

export const Disabled = ({ when = false, opacity = 0.5, children }: DisabledProps): JSX.Element => {
  return <S.Disabled $disabled={when} $opacity={opacity}>{children}</S.Disabled>
}
