import styled, { css } from 'styled-components'
import type { StyleProps } from './options-list'
import { motion } from 'framer-motion'

export const OptionsList = styled.ul<StyleProps>`
  list-style-type: none;
  border: ${({ $variant }) => ($variant === 'outline' ? 'solid 1px' : 'none')};
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: ${({ $variant }) =>
    $variant === 'outline'
      ? 'none'
      : '0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02)'};
`

export const Option = styled.li`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  min-height: 3.5rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.bgSecondary};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const OptionUserDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const OptionConfirmation = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 4px;
  z-index: 20;
`

export const OptionConfirmationInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.negativeTranslucent5};
  border-radius: ${({ theme }) => theme.spacing[6]};
  padding: 0.5rem 0.75rem 0.5rem 1rem;
`

export const OptionConfirmationTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]}
  flex: 1;
  min-width: 0;
  overflow: hidden;
`

export const OptionAvatar = styled.div`
  padding-right: 0.75rem;
`

export const OptionLabel = styled.div`
  flex: 1 0 50%;
  p {
    &:nth-child(1) {
      font-size: ${({ theme }) => theme.fontSizes[14]};
      font-weight: ${({ theme }) => theme.fontWeights[500]};
      line-height: ${({ theme }) => theme.lineHeights['1.43']};
    }
    &:nth-child(2) {
      font-size: ${({ theme }) => theme.fontSizes[12]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      line-height: ${({ theme }) => theme.lineHeights['1.33']};
      color: ${({ theme }) => theme.colors.fgSecondary};
      letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
      margin-top: 0.125rem;
    }
  }
`

export const AddOption = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  // border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  display: flex;
  align-items: center;
  /* height: 2.5rem; */
  margin-top: 0.65rem;
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;
    gap: 0.75rem;
  }
`

export const AddOptionInput = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  flex: 1 1 50%;
  padding-left: 0.4rem;
  input {
    outline: none;
    height: 1.5rem;
    flex: 1 1 90%;
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    color: ${({ theme }) => theme.colors.fgPrimary};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    border: none !important;
    ${({ $disabled }) => $disabled && css`
      pointer-events: none;
      background-color: inherit;
      padding: inherit;
    `}
    &::placeholder {
      color: ${({ theme }) => theme.colors.fgTertiary};
    }
  }
  div {
    display: flex;
    align-items: center;
    p {
      font-size: ${({ theme }) => theme.fontSizes[12]};
      flex: 1 1 auto;
      margin-top: 0;
      visibility: hidden;
    }
  }
`

export const AddIcon = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddOptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
  margin-bottom: 0.5rem;
  border-radius: 4px;
`
