import type { Color, FontSize, LineHeight } from 'src/styles/theme/types'
import * as S from './typography.styled'

export type Size = '2XL' | 'XL' | 'LG' | 'MD' | 'SM' | 'XS' | '2XS'

export interface StyleProps {
  $fontSize?: FontSize
  $color?: Color
  $transform?: 'none' | 'uppercase' | 'capitalize'
  $whiteSpace?: 'normal' | 'nowrap' | 'pre-wrap'
  $hasGradient?: boolean
  $align?: 'left' | 'center' | 'right'
  $fontWeight?: 400 | 500 | 700
  $lineHeight?: LineHeight
  $ellipsis?: boolean
}

export interface TypographyProps extends StyleProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label' | 'caption'
  id?: string
  size?: Size
  style?: React.CSSProperties
  children: React.ReactNode | string
}

const getFontSize = (size: Size): FontSize => {
  switch (size) {
    case '2XL': {
      return 32 as FontSize
    }
    case 'XL': {
      return 24 as FontSize
    }
    case 'LG': {
      return 20 as FontSize
    }
    case 'MD': {
      return 16 as FontSize
    }
    case 'SM': {
      return 14 as FontSize
    }
    case 'XS': {
      return 12 as FontSize
    }
    case '2XS': {
      return 10 as FontSize
    }
    default: {
      return 16 as FontSize
    }
  }
}

export const Heading = ({
  as = 'h1',
  id,
  size = 'LG',
  $color = 'fgPrimary',
  $transform = 'none',
  $whiteSpace = 'normal',
  $hasGradient = false,
  $align = 'left',
  $ellipsis = false,
  $lineHeight,
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Heading
      as={as}
      id={id}
      $fontSize={getFontSize(size)}
      $color={$color}
      $transform={$transform}
      $whiteSpace={$whiteSpace}
      $hasGradient={$hasGradient}
      $align={$align}
      $lineHeight={$lineHeight}
      $ellipsis={$ellipsis}
    >
      {children}
    </S.Heading>
  )
}

export const Caption = ({
  as = 'p',
  id,
  size = 'MD',
  $color = 'fgPrimary',
  $transform = 'none',
  $whiteSpace = 'normal',
  $hasGradient = false,
  $align = 'left',
  $ellipsis = false,
  $lineHeight,
  $fontWeight = 500,
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Caption
      as={as}
      id={id}
      $fontSize={getFontSize(size)}
      $color={$color}
      $transform={$transform}
      $whiteSpace={$whiteSpace}
      $hasGradient={$hasGradient}
      $align={$align}
      $lineHeight={$lineHeight}
      $ellipsis={$ellipsis}
      $fontWeight={$fontWeight}
    >
      {children}
    </S.Caption>
  )
}

export const Paragraph = ({
  as = 'p',
  size = 'SM',
  $color = 'fgSecondary',
  $transform = 'none',
  $whiteSpace = 'normal',
  $hasGradient = false,
  $align = 'left',
  $lineHeight,
  $fontWeight = 400,
  $ellipsis = false,
  style,
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Paragraph
      as={as}
      $fontSize={getFontSize(size)}
      $color={$color}
      $transform={$transform}
      $whiteSpace={$whiteSpace}
      $hasGradient={$hasGradient}
      $align={$align}
      $lineHeight={$lineHeight}
      $fontWeight={$fontWeight}
      $ellipsis={$ellipsis}
      style={style}
    >
      {children}
    </S.Paragraph>
  )
}

export const Span = ({
  as = 'span',
  size = 'SM',
  $color = 'fgSecondary',
  $transform = 'none',
  $whiteSpace = 'normal',
  $hasGradient = false,
  $align = 'left',
  $fontWeight = 400,
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Paragraph
      as={as}
      $fontSize={getFontSize(size)}
      $color={$color}
      $transform={$transform}
      $whiteSpace={$whiteSpace}
      $hasGradient={$hasGradient}
      $fontWeight={$fontWeight}
      $align={$align}
    >
      {children}
    </S.Paragraph>
  )
}

export const Mention = ({
  as = 'span',
  size = 'SM',
  $color = 'tintBg',
  $transform = 'none',
  $align = 'left',
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Mention
      as={as}
      $fontSize={getFontSize(size)}
      $color={$color}
      $transform={$transform}
      $whiteSpace="nowrap"
      $align={$align}
    >
      {children}
    </S.Mention>
  )
}

export const Highlight = ({
  size = 'SM',
  $transform = 'none',
  $align = 'left',
  children
}: TypographyProps): JSX.Element => {
  return (
    <S.Highlight
      $fontSize={getFontSize(size)}
      $transform={$transform}
      $align={$align}
    >
      {children}
    </S.Highlight>
  )
}

export interface LinkParams extends TypographyProps {
  target?: '_blank' | '_self'
  href: string
}

export const Link = ({
  size = 'SM',
  $transform = 'none',
  $align = 'left',
  target = '_self',
  href,
  children,
  $whiteSpace = 'normal',
  $fontWeight = 400
}: LinkParams): JSX.Element => {
  return (
    <S.Link
      $fontSize={getFontSize(size)}
      $transform={$transform}
      $align={$align}
      target={target}
      href={href}
      $fontWeight={$fontWeight}
      $whiteSpace={$whiteSpace}
    >
      {children}
    </S.Link>
  )
}
