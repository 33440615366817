import styled from 'styled-components'
import { CommonFieldStyles } from '../common'
import type { StyleProps } from './textarea'

export const Textarea = styled(CommonFieldStyles) <StyleProps & { $autoGrow: boolean }>`
  resize: none;
  height: auto;
  border-radius: 4px;
  line-height: ${({ theme }) => theme.lineHeights['1.43']};
  scrollbar-width: thin;
  ${({ $variant }) => $variant === 'ghost' && `
    background-color: transparent;
    border-color: transparent;
    &:focus {
      outline: none;
    }
  `}
  ${({ $autoGrow }) => $autoGrow && `
    overflow-y: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
  &:disabled {
    opacity: 0.7;
  }
`
