import styled, { css } from 'styled-components'
import type { CandidateExperiencesAndEducationListStyleProps } from '../candidate-experiences-list'

export const Wrapper = styled.div<CandidateExperiencesAndEducationListStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${({ $padding }) => $padding && css`
    padding: ${$padding.top}px ${$padding.right}px ${$padding.bottom}px ${$padding.left}px;
  `}
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
