import { isNil } from 'lodash'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useSession } from './use-session'
import { useGetDepartmentQuery } from './queries/use-departments'

interface UseCurrentJobDepartmentReturnType {
  departmentName: string
}

export const useCurrentJobDepartment = (): UseCurrentJobDepartmentReturnType => {
  const { data: job } = useJobQuery()
  const { departments } = useGetDepartmentQuery()
  const { org } = useSession()

  if (isNil(job?.departmentId)) {
    return {
      departmentName: org?.name ?? ''
    }
  }

  const department = departments.find((department) => department.id === job?.departmentId)

  return {
    departmentName: department?.name ?? ''
  }
}
