import { CandidatePhoneNumberLookupStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import * as S from './candidate-phone-numbers-list.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Spinner } from 'src/components/primitives/spinner'
import { useEffect, useState } from 'react'
import { useUpdateCandidate } from 'src/hooks/mutations/use-update-candidate'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives'
import { copyToClipboard } from 'src/utils/copy-to-clipboard'
import { IfElse } from '../if-else'
import { EditPhoneNumber } from './edit-phone-number'
import { formatPhoneNumber } from 'src/utils/format-phone-number'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'

interface CandidatePhoneNumbersListProps {
  variant?: 'LIST' | 'BUTTON'
  candidateJob: CandidateJobExpanded
  isLookingUpPhoneNumbers: boolean
  textMessageBody?: string
}

export const CandidatePhoneNumbersList = ({ variant = 'LIST', candidateJob, isLookingUpPhoneNumbers, textMessageBody }: CandidatePhoneNumbersListProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const { phoneNumbers: initialPhoneNumbers = [], phoneNumberLookupStage } = candidateJob.candidate
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const { updateCandidateJob } = useUpdateCandidate(candidateJob.id)
  const [isEditable, setIsEditable] = useState<string | null>(null)

  useEffect(() => {
    setPhoneNumbers(initialPhoneNumbers)
  }, [initialPhoneNumbers])

  const handleUpdate = (phoneNumbers: string[]): void => {
    try {
      updateCandidateJob({
        updatedCandidate: {
          id: candidateJob.candidate.id,
          name: candidateJob.candidate.name,
          location: candidateJob.candidate.location,
          phoneNumbers
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
  if (phoneNumbers.length > 0) {
    return (
      <>
        {phoneNumbers.map((phoneNumber, index) => (
          <S.PhoneNumber $variant={variant} key={phoneNumber}>
            <S.ContactTitle>
              <Icon name={Icons.phone} size={12} />
              <IfElse
                condition={isEditable === phoneNumber}
                ifNode={
                  <EditPhoneNumber
                    defaultValue={phoneNumber}
                    onUpdate={(value) => {
                      const updated = [...phoneNumbers]
                      updated[index] = value
                      setPhoneNumbers(updated)
                      handleUpdate(updated)
                    }}
                    onCancel={() => {
                      setIsEditable(null)
                    }}
                  />
                }
                elseNode={
                  <Caption size="XS" $align="left" $fontWeight={400} $color={variant === 'LIST' ? 'fgPrimary' : 'fgSecondary'} >
                    {formatPhoneNumber(phoneNumber)}
                  </Caption>
                }
              />
            </S.ContactTitle>
            <Dropdown
              trigger={
                <Button
                  nested
                  leadingIcon={Icons.moreVertical}
                  $width={16}
                  $height={16}
                  $fontSize={12}
                  $variant="ghost"
                  $colorTheme="muted"
                  disabled={isEditable === phoneNumber}
                />
              }
              items={[
                {
                  id: 'copy-phone-number',
                  title: 'Copy',
                  icon: 'copy',
                  onSelect: async () => {
                    await copyToClipboard(phoneNumber)
                  }
                },
                ...(phoneNumber && textMessageBody
                  ? [
                      {
                        id: 'send-text-message',
                        title: 'Send Text Message',
                        icon: 'message-circle',
                        href: `sms:${phoneNumber}&body=${textMessageBody}`,
                        isDisabled: isLookingUpPhoneNumbers || userHasViewerRole,
                        itemTooltip: {
                          text: userHasViewerRole ? viewerRoleNotice('send text messages') : undefined,
                          position: 'right' as const
                        }
                      }
                    ]
                  : []),
                {
                  id: 'separator',
                  title: 'ActionsSeparator',
                  type: 'separator'
                },
                {
                  id: 'edit',
                  title: 'Edit',
                  icon: 'pen-square',
                  onSelect: () => {
                    setIsEditable(phoneNumber)
                  },
                  isDisabled: userHasViewerRole,
                  // isSelectable: !userHasViewerRole,
                  itemTooltip: {
                    text: userHasViewerRole ? viewerRoleNotice('edit phone numbers') : undefined,
                    position: 'right'
                  }
                },
                {
                  id: 'remove',
                  title: 'Remove',
                  icon: 'trash',
                  variant: 'negative',
                  onSelect: () => {
                    const updated = [...phoneNumbers]
                    updated.splice(index, 1)
                    setPhoneNumbers(updated)
                    handleUpdate(updated)
                  },
                  isDisabled: userHasViewerRole,
                  // isSelectable: !userHasViewerRole,
                  itemTooltip: {
                    text: userHasViewerRole ? viewerRoleNotice('remove phone numbers') : undefined,
                    position: 'right'
                  }
                }
              ]}
              menuPosition="end"
              size="small"
            />
          </S.PhoneNumber>
        ))}
      </>
    )
  }
  if (isLookingUpPhoneNumbers) {
    return (
      <S.PhoneNumber $variant={variant}>
        <S.ContactTitle>
          <Icon name={Icons.phone} size={12} />
          <Paragraph size="XS">
            Looking for phone number…
          </Paragraph>
        </S.ContactTitle>
        <Spinner size={12} />
      </S.PhoneNumber>
    )
  }
  if (phoneNumberLookupStage === CandidatePhoneNumberLookupStage.NOT_FOUND) {
    return (
      <S.PhoneNumber $variant={variant}>
        <S.ContactTitle>
          <Icon name={Icons.phone} size={12} />
          <Paragraph size="XS" $color={variant === 'LIST' ? 'fgSecondary' : 'fgSecondary'}>
            No phone number found
          </Paragraph>
        </S.ContactTitle>
      </S.PhoneNumber>
    )
  }
  return <></>
}
