import { z } from 'zod'
import Api from '..'
import type { FetchReportArgs } from './reports_types'
import { buildReportFetchParams } from './reports_utils'

const teamMemberActivitiesParser = z.object({
  userId: z.string().uuid(),
  userName: z.string(),
  profilePhotoUrl: z.string().nullish(),
  searches: z.number(),
  approved: z.number(),
  rejected: z.number(),
  manualTasks: z.number(),
  manualEmails: z.number(),
  manualLinkedinMessages: z.number(),
  scheduled: z.number(),
  pastSearches: z.number(),
  pastApproved: z.number(),
  pastRejected: z.number(),
  pastManualTasks: z.number(),
  pastManualEmails: z.number(),
  pastManualLinkedinMessages: z.number(),
  pastScheduled: z.number()
})

export type TeamMemberActivities = z.infer<typeof teamMemberActivitiesParser>

const teamActivitiesOverviewPeriodParser = z.object({
  total: z.number(),
  pastTotal: z.number().optional(),
  searches: z.number(),
  pastSearches: z.number().optional(),
  approved: z.number(),
  pastApproved: z.number().optional(),
  rejected: z.number(),
  pastRejected: z.number().optional(),
  manualTasks: z.number(),
  pastManualTasks: z.number().optional(),
  manualEmails: z.number(),
  pastManualEmails: z.number().optional(),
  scheduled: z.number(),
  pastScheduled: z.number().optional()
})

export type TeamActivitiesOverviewPeriod = z.infer<typeof teamActivitiesOverviewPeriodParser>

const teamActivitiesOvertimeParser = z.object({
  date: z.coerce.date(),
  friendlyDate: z.string(),
  total: z.number(),
  searches: z.number().optional(),
  approved: z.number().optional(),
  rejected: z.number().optional(),
  manualTasks: z.number().optional(),
  manualEmails: z.number().optional(),
  scheduled: z.number().optional()
})

export type TeamActivitiesOvertime = z.infer<typeof teamActivitiesOvertimeParser>

const teamActivityCountByHourParser = z.object({
  dayOfWeek: z.number(),
  hour: z.number(),
  friendlyDayOfWeek: z.string(),
  friendlyHour: z.string(),
  count: z.number(),
  percentage: z.number().nullable().transform(val => val ?? 0)
})

export type TeamActivityCountByHour = z.infer<typeof teamActivityCountByHourParser>

const teamActivitiesReportParser = z.object({
  overview: teamActivitiesOverviewPeriodParser.optional().nullable(),
  // overview: z.object({
  //   dataPoints: teamActivitiesOverviewPeriodParser.optional().nullable(),
  //   pastDataPoints: teamActivitiesOverviewPeriodParser.nullish()
  // }).optional().nullable().transform(val => ({
  //   dataPoints: val?.dataPoints ?? {
  //     total: 0,
  //     pastTotal: 0,
  //     searches: 0,
  //     pastSearches: 0,
  //     approved: 0,
  //     pastApproved: 0,
  //     rejected: 0,
  //     pastRejected: 0,
  //     manualTasks: 0,
  //     pastManualTasks: 0,
  //     manualEmails: 0,
  //     pastManualEmails: 0,
  //     scheduled: 0,
  //     pastScheduled: 0
  //   },
  //   pastDataPoints: val?.pastDataPoints
  // })),
  teamMemberActivities: z.array(teamMemberActivitiesParser),
  teamActivitiesOvertime: z.array(teamActivitiesOvertimeParser),
  teamActivityCountByHour: z.array(teamActivityCountByHourParser)
})

export type TeamActivitiesReport = z.infer<typeof teamActivitiesReportParser>

export async function fetchTeamActivitiesReport (args: FetchReportArgs): Promise<TeamActivitiesReport> {
  const params = buildReportFetchParams(args)
  const { data } = await Api.get('/reports/team_activities', params)

  return teamActivitiesReportParser.parse(data)
}
