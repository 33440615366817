import { format, isWithinInterval, addMinutes, differenceInCalendarDays, isToday, isYesterday, isTomorrow, formatDistanceToNow, isFuture } from 'date-fns'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph, Span } from 'src/components/primitives/typography'
import { Button } from 'src/components/primitives/button'
import * as S from './elements.styled'
import { EmailMessageType, LinkedInMessageType } from 'src/libs/api/backend/candidate_activities'
import type { Attachment, CandidateNote, EmailMessage, EmailMessageClassification, LinkedInMessage, MentionedTime } from 'src/libs/api/backend/candidate_activities'
import type { ManualTask } from 'src/libs/api/backend/manual_task'
import { groupBy, isNil } from 'lodash'
import { When } from '../when'
import { ManualMessageType } from 'src/libs/api/backend/sequences'
import type { ManualMessage } from 'src/libs/api/backend/sequences'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { UnsafeHTML } from 'src/components/primitives/unsafe-html/unsafe-html'
import { CalendarIcon } from '../calendar-icon'
import { Badge } from 'src/components/primitives/badge'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import React, { useCallback, useMemo, useState } from 'react'
import type { PropsWithChildren } from 'react'
import { Icons } from '../../primitives/icon'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useParams } from 'react-router-dom'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { downloadAttachment } from 'src/libs/api/backend/attachments'
import { useDeleteCalendarEvent } from 'src/hooks/mutations/use-delete-calendar-event'
import { AttachmentList } from '../attachments-list'
import { useGetMentionedTimesAvailability } from 'src/hooks/queries/use-get-mentioned-times-availability'
import type { Color } from 'src/styles/theme/types'
import { useSetAtom } from 'jotai'
import { DialogId, openAlertAtom, openDialogAtom } from 'src/stores/dialogs'
import { cleanSenderAndEmail } from 'src/utils/cleanSenderAndEmail'
import { REGEX_EMAIL_TAG } from 'src/libs/regex'

interface EmailContentProps {
  $isSequenceMessage?: boolean
  messageType?: ManualMessageType
  subject?: string
  body?: string
  attachments?: Attachment[]
  emailDetailsExpanded?: boolean
  senderName?: string
  senderEmail?: string
  from?: string | null
  to?: string[] | null
  cc?: string[] | null
  bcc?: string[] | null
}

interface OutboundEmailProps {
  emailMessage: EmailMessage
  attachments?: Attachment[]
  onReply?: (emailMessageId: EmailMessage['id']) => void
  onForward?: (emailMessageId: EmailMessage['id']) => void
  emailDetailsExpanded?: boolean
}

export interface MentionedTimesStyleProps {
  $variant: 'positive' | 'negative' | 'neutral'
}

export interface MentionedTimesProps {
  text: string
  mentionedTimes: MentionedTime[]
}

export const MentionedTimes = ({
  text,
  mentionedTimes
}: MentionedTimesProps): JSX.Element => {
  const { featureFlags } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const { candidateJobId } = useParams()
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })

  const { data, isLoading } = useGetMentionedTimesAvailability({
    startEndTimes: mentionedTimes.map(({ start, end }) => ({ start, end }))
  })

  if (isLoading) {
    return <>{text}</>
  }

  const $variant = isNil(data) ? 'negative' : 'positive'
  const tooltip = isNil(data) ? 'You are busy' : `You are available on ${format(data.start, 'EEEE, MMMM d, yyyy')} at ${format(data.start, 'h:mm a')}`

  return (
    <Tooltip
      trigger={
        <S.MentionedTimes
          $variant={$variant}
          disabled={!featureFlags?.includes(FeatureFlags.CALENDAR)}
          onClick={() => {
            if (featureFlags?.includes(FeatureFlags.CALENDAR) && mentionedTimes?.[0]?.start && mentionedTimes?.[0]?.end) {
              openDialog({
                id: DialogId.CALENDAR,
                payload: {
                  candidateJob,
                  scheduleNewEvent: {
                    start: new Date(mentionedTimes?.[0]?.start),
                    end: new Date(mentionedTimes?.[0]?.end)
                  }
                }
              })
            }
          }}
        >
          {text}
        </S.MentionedTimes>
      }
    >
      {tooltip}
    </Tooltip>
  )
}

const EmailContent = ({
  messageType = ManualMessageType.EMAIL,
  $isSequenceMessage,
  subject,
  body,
  attachments,
  emailDetailsExpanded,
  senderEmail,
  to,
  cc,
  bcc
}: EmailContentProps): JSX.Element => {
  const triggerDownloadAttachment = useCallback(async (attachment: Attachment) => {
    const downloadUrl = await downloadAttachment(attachment.id)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', attachment.fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  return (
    <S.EmailContent $isSequenceMessage={$isSequenceMessage}>
      <S.EmailContentHeader>
        <When condition={!!emailDetailsExpanded}>
          <When condition={!isNil(senderEmail) && senderEmail.length > 0}>
            <S.EmailContentHeaderRow $isSequenceMessage={$isSequenceMessage}>
              <Paragraph size="XS" $color="fgSecondary">From</Paragraph>
              <Caption size="XS" $fontWeight={500}>{senderEmail}</Caption>
            </S.EmailContentHeaderRow>
          </When>
          <When condition={!isNil(to) && to.length > 0}>
            <S.EmailContentHeaderRow $isSequenceMessage={$isSequenceMessage}>
              <Paragraph size="XS" $color="fgSecondary">To</Paragraph>
              <Caption size="XS" $fontWeight={500}>{to?.map(getEmailAddress).join(', ')}</Caption>
            </S.EmailContentHeaderRow>
          </When>
          <When condition={!isNil(cc) && cc.length > 0}>
            <S.EmailContentHeaderRow $isSequenceMessage={$isSequenceMessage}>
              <Paragraph size="XS" $color="fgSecondary">Cc</Paragraph>
              <Caption size="XS" $fontWeight={500}>{cc?.map(getEmailAddress).join(', ')}</Caption>
            </S.EmailContentHeaderRow>
          </When>
          <When condition={!isNil(bcc) && bcc.length > 0}>
            <S.EmailContentHeaderRow $isSequenceMessage={$isSequenceMessage}>
              <Paragraph size="XS" $color="fgSecondary">Bcc</Paragraph>
              <Caption size="XS" $fontWeight={500}>{bcc?.map(getEmailAddress).join(', ')}</Caption>
            </S.EmailContentHeaderRow>
          </When>
        </When>
        <When condition={messageType === ManualMessageType.EMAIL}>
          <S.EmailContentHeaderRow $isSequenceMessage={$isSequenceMessage}>
            <Paragraph size="XS" $color="fgSecondary">Subject</Paragraph>
            {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
            <Caption size="XS" $fontWeight={500} $ellipsis>{subject || '(No Subject)'}</Caption>
          </S.EmailContentHeaderRow>
        </When>
      </S.EmailContentHeader>
      <S.EmailBody>
        <Paragraph size="XS" $lineHeight={1.33} $whiteSpace='pre-wrap' $color='fgPrimary' as='span'>
          <UnsafeHTML html={body ?? ''} />
        </Paragraph>
      </S.EmailBody>
      {attachments && attachments.length > 0 && (
        <AttachmentList
          attachments={attachments}
          onTriggerDownload={triggerDownloadAttachment}
        />
      )}
    </S.EmailContent>
  )
}

const getEmailAddress = (addressString: string): string => {
  const emailAddressParts = addressString.match(/<(.*)>/)
  if (emailAddressParts) {
    return emailAddressParts[1]
  }
  return addressString
}

interface EmailHeaderProps {
  senderName?: string
  senderEmail?: string
  from?: string | null
  to?: string[] | null
  cc?: string[] | null
  bcc?: string[] | null
  isInbound?: boolean
  isSequenceMessage?: boolean
  manualMessage?: ManualMessage | null
  emailDetailsExpanded?: boolean
  onEmailDetailsExpand?: (isExpanded: boolean) => void
}

export const EmailHeader = ({ isInbound, manualMessage, emailDetailsExpanded, onEmailDetailsExpand, ...message }: EmailHeaderProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const sender = orgUsers?.find((orgUser) => orgUser.emailAccounts.find((emailAccount) => emailAccount.id === manualMessage?.sendingEmailAccountId))
  // const sendingEmailAccount = sender?.emailAccounts.find((emailAccount) => emailAccount.id === manualMessage?.sendingEmailAccountId)

  const activityTitle = useMemo((): { from: string, to: string[] } => {
    if (isInbound) {
      const { name } = cleanSenderAndEmail(message.from ?? '')

      return {
        from: name ?? 'Sent',
        to: message.to?.map(recipient => {
          const { name } = cleanSenderAndEmail(recipient)
          return name ?? recipient
        }) ?? []
      }
    }

    const { name } = cleanSenderAndEmail(message.from ?? '')

    return {
      from: sender?.name ?? name ?? 'Sent',
      to: message.to?.map(recipient => {
        const { name, email } = cleanSenderAndEmail(recipient)
        return name ?? email ?? recipient
      }) ?? []
    }
  }, [isInbound, message, sender])

  return (
    <Flex $align="center" $gap={8}>
      <FromToHeaderNotice from={activityTitle.from} to={activityTitle.to} />
      <Button
        ariaLabel="Expand email details"
        $variant="outline"
        $colorTheme="muted"
        $height={18}
        $width={18}
        $fontSize={12}
        iconSize={12}
        leadingIcon={Icons.ellipsis}
        onClick={() => onEmailDetailsExpand?.(!emailDetailsExpanded)}
      />
    </Flex>
  )
}

const EmailContainer = ({ children, $isSequenceMessage }: PropsWithChildren<{ $isSequenceMessage?: boolean }>): JSX.Element => {
  return (
    <S.Email $isSequenceMessage={$isSequenceMessage}>
      {children}
    </S.Email>
  )
}

interface ManualEmailProps {
  messageType?: ManualMessageType
  manualMessage: ManualMessage
  attachments?: Attachment[]
  emailDetailsExpanded?: boolean
}

export const ManualEmail = ({
  messageType,
  manualMessage,
  attachments,
  emailDetailsExpanded
}: ManualEmailProps): JSX.Element => {
  return (
    <EmailContainer>
      <EmailContent
        messageType={messageType}
        subject={manualMessage.type === ManualMessageType.EMAIL ? manualMessage.subject ?? undefined : undefined}
        body={manualMessage.body}
        attachments={attachments}
        emailDetailsExpanded={emailDetailsExpanded}
        // to={manualMessage.to}
        cc={manualMessage.cc}
        bcc={manualMessage.bcc}
        // date="Sending"
      />
    </EmailContainer>
  )
}

const replaceMentionedTimes = (body: EmailMessage['strippedText'], mentionedTimes: EmailMessageClassification['mentionedTimes']): string | undefined => {
  if (!mentionedTimes) return body ?? undefined
  const groupedMentionedTimes = groupBy(mentionedTimes, 'matchingText')
  Object.keys(groupedMentionedTimes).forEach(matchingText => {
    const mentionedTimesComponent = `<mentionedtimes text="${matchingText}" mentionedTimes="${encodeURIComponent(JSON.stringify({ mentionedTimes: groupedMentionedTimes[matchingText] }))}"></mentionedtimes>`
    const regex = new RegExp(`(^|\\s)${matchingText}(?=\\s|$)`, 'g')
    body = body?.replace(regex, (match, p1) => {
      // Check if this occurrence has already been replaced
      if (match.includes('<mentionedtimes')) {
        return match // Skip replacement if already processed
      }
      return `${p1}${mentionedTimesComponent}`
    })
  })

  return body ?? undefined
}

const replaceEmailTagWithEncodingString = (body: EmailMessage['strippedText']): string | undefined => {
  return body?.replace(REGEX_EMAIL_TAG, (_, email) => {
    return `&lt;${email}&gt;`
  })
}

export const OutboundEmail = ({ emailMessage, attachments, onReply, emailDetailsExpanded }: OutboundEmailProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const mentionedTimes = emailMessage?.emailMessageClassifications?.[0]?.mentionedTimes
  const futureMentionedTimes = mentionedTimes?.filter(({ start, end }) => isFuture(start) && isFuture(end))
  const hasMentionedTimes = (futureMentionedTimes?.length ?? 0) >= 1

  const isSequenceMessage = emailMessage.type === EmailMessageType.SENT && !isNil(emailMessage.candidateSequenceStep)

  const preparedBody = !hasMentionedTimes ? emailMessage.strippedText : replaceMentionedTimes(emailMessage.strippedText, futureMentionedTimes)
  const preparedBodyWithEmailTags = replaceEmailTagWithEncodingString(preparedBody)
  return (
    <EmailContainer $isSequenceMessage={isSequenceMessage}>
      <EmailContent
        $isSequenceMessage={isSequenceMessage}
        emailDetailsExpanded={emailDetailsExpanded}
        subject={emailMessage.subject ?? undefined}
        body={preparedBodyWithEmailTags ?? undefined}
        attachments={attachments}
        senderEmail={emailMessage.from ?? undefined}
        to={emailMessage.to}
        cc={emailMessage.cc}
        bcc={emailMessage.bcc}
      />
      <When condition={!isNil(onReply)}>
        <S.InboundEmailActions>
          <Button
            $variant="raised"
            $colorTheme="muted"
            $height={24}
            $fontSize={12}
            leadingIcon="reply"
            onClick={() => {
              if (!isNil(onReply)) {
                onReply(emailMessage.id)
              }
            }}
            disabled={userHasViewerRole}
          >
            Reply
          </Button>
        </S.InboundEmailActions>
      </When>
    </EmailContainer>
  )
}

const InboundEmail = ({ emailMessage, onReply }: OutboundEmailProps): JSX.Element => {
  return (
    <EmailContainer>
      <EmailContent
        subject={emailMessage.subject ?? undefined}
        body={emailMessage.strippedText ?? undefined}
      />
      <When condition={!isNil(onReply)}>
        <S.InboundEmailActions>
          <Button
            $variant="raised"
            $colorTheme="muted"
            $height={24}
            $fontSize={12}
            leadingIcon="reply"
            onClick={() => {
              if (!isNil(onReply)) {
                onReply(emailMessage.id)
              }
            }}
          >
            Reply
          </Button>
        </S.InboundEmailActions>
      </When>
    </EmailContainer>
  )
}

export const EmailInboxActivity = ({ emailMessage, onReply }: OutboundEmailProps): JSX.Element => {
  if (emailMessage.candidateSequenceId != null || emailMessage.type === EmailMessageType.SENT) {
    return <OutboundEmail emailMessage={emailMessage} onReply={onReply} />
  }

  return <InboundEmail emailMessage={emailMessage} onReply={onReply} />
}

interface CommentProps {
  isEditable: boolean
  candidateNote: CandidateNote
  onCommentUpdate: (noteId: string, body: string) => void
}

export const Comment = ({ isEditable, candidateNote, onCommentUpdate }: CommentProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [newContent, setNewContent] = useState('')
  if (isEditing) {
    return (
      <InboxCommentEditor
        onDataChanged={(data) => {
          setNewContent(data)
        }}
        onCancel={() => {
          setIsEditing(false)
        }}
        onAddComment={() => {
          onCommentUpdate(candidateNote.id, newContent)
          setIsEditing(false)
        }}
        confirmText='Update comment'
        initialContent={candidateNote.body ?? ''}
      />
    )
  }

  return (
    <S.Comment $isEditable={isEditable}>
      <Paragraph as='span' size="XS" $whiteSpace='pre-wrap' $color="fgPrimary">
        <UnsafeHTML html={candidateNote.body ?? ''} />
      </Paragraph>
      <S.EditButton>
        <Button
          leadingIcon={Icons.pencil}
          $width={20}
          $height={20}
          $fontSize={12}
          $variant='raised-light'
          $colorTheme='muted'
          onClick={() => {
            setIsEditing(true)
          }}
        />
      </S.EditButton>
    </S.Comment>
  )
}

interface DescriptionProps {
  children: React.ReactNode
  $color?: Color
}

interface FromToHeaderNoticeProps {
  from?: string
  to?: string[]
}

export const FromToHeaderNotice = ({ from, to }: FromToHeaderNoticeProps): JSX.Element => {
  return (
    <S.FromToHeaderNotice data-component="TimelineActivityFromToHeaderNotice">
      <Caption as='span' size="XS" $color="fgPrimary" $whiteSpace="nowrap" $ellipsis>{from ?? ''}</Caption>
      {to && to.length >= 1 && (
        <Paragraph as='span' size="XS" $color="fgTertiary" $whiteSpace="nowrap" $ellipsis>to {to.join(', ')}</Paragraph>
      )}
    </S.FromToHeaderNotice>
  )
}

export const Description = ({ children, $color = 'fgSecondary' }: DescriptionProps): JSX.Element => {
  return (
    <S.Description data-component="TimelineActivityDescription">
      <Paragraph as='span' size="XS" $color={$color} $whiteSpace="nowrap" $ellipsis>{children}</Paragraph>
    </S.Description>
  )
}

export const PositiveDescription = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.Description>
      <Paragraph size="XS" $color='positiveFg'>{children}</Paragraph>
    </S.Description>
  )
}

export const ErrorDescription = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <S.Description>
      <Paragraph size="XS" $color='negativeFg'>{children}</Paragraph>
    </S.Description>
  )
}

export interface CalendarEntryStyleProps {
  $variant?: 'card' | 'plain'
}

interface CalendarEntryProps extends CalendarEntryStyleProps {
  calendarEvent: CalendarEvent
  isSuggestedEvent?: boolean
}

export const CalendarEntry = ({
  calendarEvent,
  isSuggestedEvent = true,
  $variant = 'card'
}: CalendarEntryProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const openAlert = useSetAtom(openAlertAtom)
  const { deleteEvent } = useDeleteCalendarEvent()

  const formatSlot = (slot: string | Date): string => {
    return format(slot, 'h.mma').toLowerCase()
  }

  // const formatAttendee = (attendee: string): string => {
  //   const m = attendee.trim().match(/(.*)?<(.*)>/)
  //   return m ? m[1] ?? m[2] : ''
  // }

  const getEventStatus = (): { type: 'UPCOMING' | 'PASSED' | 'TODAY' | 'FUTURE', text: string } | null => {
    const now = new Date()
    const thirtyMinutesFromNow = addMinutes(now, 30)
    const slotStartDate = calendarEvent.start
    const slotEndDate = calendarEvent.end

    // Upcoming events
    if (isWithinInterval(now, { start: slotStartDate, end: slotEndDate })) {
      return { type: 'UPCOMING', text: 'NOW' }
    } else if (isWithinInterval(slotStartDate, { start: now, end: thirtyMinutesFromNow })) {
      return { type: 'UPCOMING', text: 'STARTING SOON' }
    }

    // In the future
    if (slotStartDate > now) {
      if (isToday(slotStartDate)) {
        return { type: 'TODAY', text: 'Today' }
      } else if (isTomorrow(slotStartDate)) {
        return { type: 'FUTURE', text: 'Tomorrow' }
      } else {
        const description = formatDistanceToNow(slotStartDate, { addSuffix: true })
        return { type: 'FUTURE', text: description }
      }
    }

    // In the past
    if (now > slotEndDate) {
      if (isToday(slotEndDate)) {
        return { type: 'PASSED', text: 'Earlier today' }
      } else if (isYesterday(slotEndDate)) {
        return { type: 'PASSED', text: 'Yesterday' }
      } else {
        const daysAgo = differenceInCalendarDays(now, slotEndDate)
        return { type: 'PASSED', text: `${daysAgo} days ago` }
      }
    }
    return null
  }

  const eventStatus = getEventStatus()
  const hasBadge = eventStatus && eventStatus.type === 'UPCOMING'
  const isFutureEvent = eventStatus && eventStatus.type === 'FUTURE'
  const scheduledBy = orgUsers?.find((orgUser) => orgUser.id === calendarEvent.userId)?.name
  // const attendees = calendarEvent.attendees as string[]

  return (
    <S.CalendarEntry $isFutureEvent={Boolean(isFutureEvent)} $variant={$variant}>
      <CalendarIcon timestamp={calendarEvent.start} />
      <S.CalendarEntryInner>
        <Paragraph size="XS" $color="fgSecondary"><Span size="XS" $fontWeight={500} $color="fgPrimary">{scheduledBy}</Span> scheduled a meeting</Paragraph>
        <Flex $direction="row">
          <S.CalendarEntryMainInfo>
            <Paragraph size="XS" $color="fgPrimary">
              {calendarEvent.title ?? ''}
            </Paragraph>
            <S.CalendarEntryTime>
              {formatSlot(calendarEvent.start)} – {formatSlot(calendarEvent.end)}
            </S.CalendarEntryTime>
            {!isSuggestedEvent && hasBadge && (
              <Badge $variant="positiveLight">{eventStatus.text}</Badge>
            )}
          </S.CalendarEntryMainInfo>
          {
            !isSuggestedEvent && (
              <Flex
                $align={hasBadge ? 'center' : 'flex-start'}
                $justify="flex-end"
                $width="25%"
                $height="full"
              >
                {hasBadge && calendarEvent.meetingLink
                  ? <Button
                      $variant="fill"
                      $colorTheme="tint"
                      $height={24}
                      $fontSize={12}
                      leadingIcon="video"
                      href={calendarEvent.meetingLink}
                    >
                      Join
                    </Button>
                  : <S.CalenderEntryTimestamp>
                      <Description>
                        {eventStatus?.text}
                      </Description>
                    </S.CalenderEntryTimestamp>
                }
              </Flex>
            )
          }
        </Flex>
        {
          !isSuggestedEvent && (
            <Button
              leadingIcon="trash"
              ariaLabel="Delete scheduled event"
              $width={16}
              $height={16}
              $variant="ghost"
              $colorTheme="muted"
              $fontSize={10}
              onClick={() => {
                openAlert({
                  message: 'Are you sure you want to delete this event?',
                  description: 'This action can not be reversed',
                  cancelText: 'Cancel',
                  confirmText: 'Delete',
                  onConfirm: () => {
                    if (calendarEvent.id) {
                      deleteEvent({ id: calendarEvent.id })
                    }
                  }
                })
              }}
            />
          )
        }
      </S.CalendarEntryInner>
    </S.CalendarEntry>
  )
}

interface LinkedInMessageHeaderProps {
  message: LinkedInMessage
  candidateName: string
}

export const LinkedInMessageHeader = ({ message, candidateName }: LinkedInMessageHeaderProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const sender = orgUsers?.find((orgUser) => orgUser.linkedInAccounts.find((linkedInAccount) => linkedInAccount.id === message?.linkedInAccountId))
  const linkedInAccount = sender?.linkedInAccounts.find((linkedInAccount) => linkedInAccount.id === message?.linkedInAccountId)
  // const orgUserLinkedInName = linkedInAccount?.unipileUserName
  const isInbound = message.type === LinkedInMessageType.RECEIVED

  const activityTitle = useMemo((): { from: string, to: string[] } => {
    if (isInbound) {
      return {
        from: candidateName,
        to: [linkedInAccount?.unipileUserName ?? '']
      }
    }
    return {
      from: linkedInAccount?.unipileUserName ?? 'X',
      to: [candidateName]
    }
  }, [isInbound, candidateName, linkedInAccount?.unipileUserName])

  return (
    <FromToHeaderNotice from={activityTitle.from} to={activityTitle.to} />
  )
}

interface LinkedInMessageProps {
  linkedInMessage: LinkedInMessage
  onReply?: (linkedInMessage: LinkedInMessage) => void
}

export const LinkedInMessageActivity = ({ linkedInMessage, onReply }: LinkedInMessageProps): JSX.Element => {
  return (
    <EmailContainer>
      <EmailContent
        messageType={ManualMessageType.LINKEDIN}
        body={linkedInMessage.message}
      />
      <When condition={!isNil(onReply)}>
        <S.InboundEmailActions>
          <Button
            $variant="raised"
            $colorTheme="muted"
            $height={24}
            $fontSize={12}
            leadingIcon="reply"
            onClick={() => {
              if (!isNil(onReply)) {
                onReply(linkedInMessage)
              }
            }}
          >
            Reply
          </Button>
        </S.InboundEmailActions>
      </When>
    </EmailContainer>
  )
}

interface ManualTaskProps {
  manualTask: ManualTask
}

export const ManualTaskActivity = ({
  manualTask
}: ManualTaskProps): JSX.Element => {
  return (
    <S.ManualTask>
      <Paragraph as='span' size="XS" $whiteSpace='pre-wrap' $color="fgPrimary">
        <UnsafeHTML html={manualTask.body ?? ''} />
      </Paragraph>
    </S.ManualTask>
  )
}
