import type { CriteriaKey, JobTitleCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { isDuplicatedCriteria } from 'src/utils/refinement-criteria'
import { Icon, Icons } from 'src/components/primitives/icon'
import { isNil } from 'lodash'

interface JobRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: JobTitleCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: JobTitleCriteria[]) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const JobRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: JobRowProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const rowRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [addNewClicked, setAddNewClicked] = useState(criteriaValue.length === 0)
  const [collapsed, setCollapsed] = useState<boolean | undefined>(undefined)

  useLayoutEffect(() => {
    if (criteriaValue.length > 1 && isNil(collapsed)) {
      const measureDiv = document.createElement('div')
      measureDiv.style.cssText = `
        position: absolute;
        visibility: hidden;
        height: auto;
        width: ${rowRef.current?.offsetWidth}px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        pointer-events: none;
        top: -9999px;
      `
      document.body.appendChild(measureDiv)

      // Render all items to measure
      const content = criteriaValue.map((item) =>
        `
          <div style="height: 32px; padding: 0 12px; border-radius: 16px;">
            ${item.name}
          </div>
        `
      ).join('')
      measureDiv.innerHTML = content

      const shouldExpand = measureDiv.scrollHeight <= 40 // Approximate single line height

      if (shouldExpand) {
        setCollapsed(false)
      }

      document.body.removeChild(measureDiv)
      setCollapsed(true)
    }
  }, [collapsed, criteriaValue])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && inputValue && document.activeElement === inputRef.current) {
        const newJobTitle = {
          name: inputValue,
          optional: false,
          negative: false
        }
        const isJobDuplicated = isDuplicatedCriteria(newJobTitle, criteriaValue, 'name')
        if (!isJobDuplicated) {
          const newItems = [...criteriaValue, newJobTitle]
          onCriteriaUpdate(criteriaKey, newItems)
          setCollapsed(false)
        }
        setInputValue('')
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [criteriaKey, criteriaValue, inputValue, onCriteriaUpdate])

  useEffect(() => {
    if (!disabled && addNewClicked) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 50)
    }
  }, [disabled, addNewClicked])

  return (
    <S.RowContainer>
      <S.CriteriaMultiValuesField $disabled={disabled} ref={rowRef}>
        {(isNil(collapsed) || collapsed
          ? criteriaValue.length > 0
            ? [criteriaValue[0]]
            : []
          : criteriaValue
        ).map((jobTitle, index) => {
          return (
            <CriteriaPill
              key={index}
              value={{
                label: jobTitle.name,
                optional: false,
                negative: jobTitle.negative ?? false
              }}
              onUpdate={(key: string, value: CriteriaPillValue) => {
                const updatedCriteriaValue = [...criteriaValue]
                updatedCriteriaValue[index] = {
                  ...updatedCriteriaValue[index],
                  [key]: value
                }
                onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
              }}
              onRemove={() => {
                const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
                onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
              }}
              useOptional={false}
            />
          )
        })}
        {collapsed && (
          <S.MoreButton
            onClick={() => {
              setCollapsed(false)
            }}
          >
            + {criteriaValue.length - 1} more
          </S.MoreButton>
        )}
        {addNewClicked
          ? <S.SearchInput
              ref={inputRef}
              tabIndex={0}
              placeholder='Add New'
              onBlur={() => {
                setAddNewClicked(false)
                setInputValue('')
              }}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              autoFocus={true}
              disabled={disabled}
              data-1p-ignore
            />
          : <S.AddNewButton
              disabled={disabled}
              data-state={criteriaValue.length === 0 ? 'open' : 'closed'}
              onClick={() => {
                setAddNewClicked(true)
              }}
            >
              <Icon name={Icons.plus} size={12} color='positiveBg' />
              Add New
            </S.AddNewButton>
        }
      </S.CriteriaMultiValuesField>
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
