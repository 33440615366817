import { NAVIGATION_WIDTH } from 'src/styles/constants'
import styled from 'styled-components'

export const SettingsContent = styled.div<{ $maxWidth: string }>`
  padding: ${({ theme }) => theme.spacing[24]};
  max-width: ${({ $maxWidth }) => $maxWidth};
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const Sidebar = styled.div`
  width: ${NAVIGATION_WIDTH.LG.OPEN};
  height: 100%;
  border-radius: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[24]} ${({ theme }) => theme.spacing[16]};
  overflow-y: auto;
  overflow-x: hidden;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-bottom-right-radius: 8px;
  height: 100%;
  overflow: auto;
  padding: 0;
  flex: 1;
`
