import { Flex } from 'src/components/primitives'
import * as S from './candidates.styled'
import { Caption, Link, Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'
import { ArrowRightSvg } from 'src/components/primitives/svgs'
import { Icon } from 'src/components/primitives/icon'
import { Tooltip } from 'src/components/primitives/tooltip'
import { TooltipTrigger } from './report-components.styled'

interface DefaultTableHeaderProps {
  title: string
  $align?: 'center' | 'flex-start'
  $justify?: 'center' | 'flex-start'
  $padding?: { left?: number, right?: number }
}

export const DefaultTableHeader = ({ title, $justify = 'center', $align = 'center', $padding }: DefaultTableHeaderProps): JSX.Element => (
  <S.ReportTabularHeader $justify={$justify} $align={$align} $padding={$padding}>
    {title}
  </S.ReportTabularHeader>
)

interface TableReportNumberCellProps {
  value: number
  percentage?: number
  valueChangeDirection?: 'up' | 'down'
}

export const TableReportNumberCell = ({ value, percentage, valueChangeDirection }: TableReportNumberCellProps): JSX.Element => {
  return (
    <Flex $gap={10} $align="center" $justify='center'>
      <Caption size="XS" $color="fgSecondary" $fontWeight={400} $lineHeight={1}>
        {value.toLocaleString()}
      </Caption>
      {!isNil(percentage) && (
        <Flex $align="center" $gap={4} $width="fit-content">
          <Paragraph size="2XS" $color={valueChangeDirection === 'up' ? 'positiveFg' : valueChangeDirection === 'down' ? 'warningFg' : 'fgTertiary'} $lineHeight={1}>
            {percentage >= 100 ? '+999%' : `${Math.round(percentage * 100)}%`}
          </Paragraph>
          {valueChangeDirection && (
            <Icon
              name={valueChangeDirection === 'up' ? 'arrow-up' : 'arrow-down'}
              size={10}
              color={valueChangeDirection === 'up' ? 'positiveFg' : 'warningFg'}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}

// TxtWithArrow
//  pct should be between 0 and 1
interface TxtWithArrowProps {
  txt: string
  theme: 'positive' | 'warning' | 'neutral'
}
export const TxtWithArrow = ({ txt, theme }: TxtWithArrowProps): JSX.Element => {
  const themes = {
    positive: ['positiveFaded', 'positiveFg'],
    warning: ['warningFaded', 'warningFg'],
    neutral: ['bgTertiary', 'fgTertiary']
  }
  const [bg, fg] = themes[theme]
  return (
    <Flex
      $gap={40} $justify="center">
      <S.Relative>
        <S.AbsoluteAndCentered>
          <ArrowRightSvg width={88} />
        </S.AbsoluteAndCentered>
        <S.ChangeBadge $bgThemeColor={bg}>
          <Caption size="2XS" $color={fg} $whiteSpace="nowrap">
            {txt}
          </Caption>
        </S.ChangeBadge>
      </S.Relative>
    </Flex>
  )
}

export const TableReportTextCell = ({ value }: { value: string }): JSX.Element => {
  return (
    <Caption size="XS" $fontWeight={400}>
      {value}
    </Caption>
  )
}

interface TableCellContentWithTooltipProps {
  visibleContent: string
  tooltipContent: string
  threshold?: number
  href?: string
}

export const TableCellContentWithTooltip = ({ visibleContent, tooltipContent, threshold = 28, href }: TableCellContentWithTooltipProps): JSX.Element => {
  if (visibleContent.length <= threshold) {
    if (href) {
      return (
        <Link size="XS" $whiteSpace="nowrap" href={href} $fontWeight={500}>
          {visibleContent}
        </Link>
      )
    }
    return <Caption size="XS" $whiteSpace="nowrap">{visibleContent}</Caption>
  }

  return (
    <Tooltip
      trigger={
        <TooltipTrigger>
          {href
            ? <Link
                size="XS"
                $whiteSpace="nowrap"
                href={href}
                $fontWeight={500}
              >
                {visibleContent}
              </Link>
            : <Caption
                size="XS"
                $whiteSpace="nowrap"
              >
                {tooltipContent?.slice(0, threshold)}…
              </Caption>
          }
        </TooltipTrigger>
      }
    >
      {visibleContent}
    </Tooltip>
  )
}
