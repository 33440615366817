import styled from 'styled-components'
import { CHART_COLORS } from 'src/components/charts/base/constants'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'key bar bar';
  grid-column-gap: 1rem;
  height: 2rem;
`

export const RowKey = styled.div`
  grid-area: key;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export const Bar = styled.div`
  grid-area: bar;
  position: relative;
  width: 100%;
  height: 100%;
`

export const BarFill = styled.div<{ $value: number, $isEmpty: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $value }) => $value <= 0 ? '2px' : `${$value}%`};
  height: 100%;
  background-color: ${({ $isEmpty, $value, theme }) => $isEmpty || $value <= 0 ? theme.colors.borderTranslucent : CHART_COLORS.BLUE_MID};
`

export const BarFillBadge = styled.div<{ $hasOffset: boolean }>`
  position: absolute;
  top: 50%;
  right: ${({ $hasOffset }) => $hasOffset ? '-40px' : '4px'};
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  height: 20px;
  border-radius: 99px;
  padding: 2px 8px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: ${({ theme }) => theme.fontSizes['10']};
    color: ${CHART_COLORS.BLUE_MID};
    line-height: 1;
  }
`

export const RowChange = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  z-index: 2;
`
