import { ReportsTimeframeSelector } from 'src/components/charts/primitives/reports-timeframe-selector'
import { PageHeader } from '../page-header'
import { useCurrentJobDepartment } from 'src/hooks/use-current-job-department'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Button } from 'src/components/primitives/button'
import { ReportType } from 'src/libs/api/backend/reports_types'
import { useLocation } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { Icons } from 'src/components/primitives/icon'

interface ReportsPageHeaderProps {
  reportType: ReportType
}

interface GetHeaderDetailsReturnType {
  heading: string
  href: string
}

export const ReportsPageHeader = ({ reportType }: ReportsPageHeaderProps): JSX.Element => {
  const { data: job } = useJobQuery()
  const { pathname } = useLocation()
  const { departmentName } = useCurrentJobDepartment()

  const isAllReportsPage = pathname.includes(RouteBuilder.build('ALL_REPORTS'))

  const getHeaderDetails = (reportType: ReportType): GetHeaderDetailsReturnType => {
    let reportName = 'Reports'
    let reportHref = RouteBuilder.build('ALL_REPORTS')

    switch (reportType) {
      case ReportType.COMMUNICATION_INSIGHTS:
        reportName = 'Communications Insights'
        reportHref = `${reportHref}/communications-insights`
        break
      case ReportType.PIPELINE_INSIGHTS:
        reportName = 'Pipeline Insights'
        reportHref = `${reportHref}/pipeline-insights`
        break
      case ReportType.TEAM_ACTIVITIES:
        reportName = 'Team Activities'
        reportHref = `${reportHref}/team-activity`
        break
    }

    return {
      heading: job?.title
        ? `${departmentName} · ${job?.title} ${reportName}`
        : `All Positions · ${reportName}`,
      href: reportHref
    }
  }

  return (
    <PageHeader
      heading={getHeaderDetails(reportType).heading}
      actions={
        <>
          {!isAllReportsPage && (
            <Button
              leadingIcon={Icons.externalLink}
              $variant="raised"
              $colorTheme="normal"
              $height={24}
              $fontSize={12}
                href={getHeaderDetails(reportType).href}
              >
                See All Positions
            </Button>
          )}
          <ReportsTimeframeSelector />
        </>
      }
    />
  )
}
