import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { VerticalBarFunnelChart } from 'src/components/charts/primitives/vertical-bar-funnel-chart'
import type { OutreachConversions } from 'src/libs/api/backend/reports_communication_insights'

interface OutreachConversionChartProps {
  data?: OutreachConversions[]
}

export const OutreachConversionChart = ({ data }: OutreachConversionChartProps): JSX.Element => {
  return (
    <ChartWrapper>
      <ChartHeader title="Outreach Conversion" />
      {
        data && data.length > 0
          ? (
              <VerticalBarFunnelChart
                data={data?.map((item, index) => ({
                  label: `Step ${item.position + 1}`,
                  value: Math.round(
                    data
                      .slice(0, index + 1)
                      .reduce((acc, curr) => acc + curr.conversionRate, 0) * 100
                  )
                }))}
              />
            )
          : (
              <EmptyChart />
            )
      }
    </ChartWrapper>
  )
}
