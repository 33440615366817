import { useMemo, useState } from 'react'
import { Button, Flex, Spacer } from 'src/components/primitives'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph, Span } from 'src/components/primitives/typography'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom } from 'src/stores/dialogs'
import { NpsSurvey } from 'src/components/blocks/on-screen-surveys/nps-survey'
import { useSubmitNpsFeedback } from 'src/hooks/mutations/use-submit-nps-feedback'
import { Form, Textarea } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { useUpdateUserTrackingAndStatuses } from 'src/hooks/mutations/use-update-user-tracking-and-statuses'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { When } from 'src/components/blocks/when'
import * as S from './nps-survey-dialog.styled'

export interface NpsSurveyDialogProps {
  selectedScore: number
}

const npsSurveySchema = z.object({
  npsComment: z.string().optional()
})

type NpsSurveySchema = z.infer<typeof npsSurveySchema>

export const NpsSurveyDialog = ({ selectedScore }: NpsSurveyDialogProps): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.NPS_SURVEY), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [score, setScore] = useState<number | null>(selectedScore ?? null)
  const { submitNpsFeedback } = useSubmitNpsFeedback()
  const { updateUserTrackingAndStatuses } = useUpdateUserTrackingAndStatuses()
  const [_, setLastCollectedNpsFeedbackAt] = useLocalStorage<string | null>(LocalStorageKey.LAST_COLLECTED_NPS_FEEDBACK_AT, null)
  const [view, setView] = useState<'SUCESS_AND_SHARE_ON_LINKEDIN' | 'FORM'>('FORM')

  const { formData, register, submit } = useForm<NpsSurveySchema>({
    schema: npsSurveySchema
  })

  const handleSubmit = async (): Promise<void> => {
    console.log('formData: ', formData)
  }

  const handleSubmitFeedback = (): void => {
    if (!score) {
      return
    }
    setIsSubmitting(true)
    submitNpsFeedback({
      rating: score,
      comment: formData?.npsComment?.toString() ?? undefined,
      onSuccess: () => {
        updateUserTrackingAndStatuses({ lastCollectedNpsFeedbackAt: new Date().toISOString() })
        setLastCollectedNpsFeedbackAt(new Date().toISOString())
        setIsSubmitting(false)
        if (score >= 9) {
          setView('SUCESS_AND_SHARE_ON_LINKEDIN')
        } else {
          controlDialog({ id: DialogId.NPS_SURVEY, newState: false })
        }
      }
    })
  }

  return (
    <Dialog.Root
      id={DialogId.NPS_SURVEY}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.NPS_SURVEY, newState: value })
      }}
      $width='600px'
      $maxWidth='600px'
    >
      <Dialog.Portal>
        <Dialog.Header
          title={view === 'SUCESS_AND_SHARE_ON_LINKEDIN' ? 'Thank you for your Feedback' : 'Survey'}
          onClose={() => {
            controlDialog({ id: DialogId.NPS_SURVEY, newState: false })
          }}
        />
        <Dialog.Content>
          <When condition={view === 'FORM'}>
            <Flex $direction='column' $gap={36}>
              <Flex $direction='column' $gap={16}>
                <Caption size="SM" $color="fgPrimary" $align="center">How likely are you to recommend Pin to a friend or colleague?</Caption>
                <NpsSurvey selectedScore={score} onScoreClick={(score) => { setScore(score) }} />
              </Flex>
              <Flex $direction='column' $gap={16} $justify='flex-start'>
                <Form onSubmit={submit(handleSubmit)}>
                  <Caption size="SM" $color="fgPrimary">
                    Why did you choose this score? What can we do better,<br />or what do you like about Pin? <Span size="SM" $color="fgTertiary">(Optional)</Span>
                  </Caption>
                  <Spacer $size={24} />
                  <Textarea
                    name="npsComment"
                    label="Net Promoter Score Comment"
                    hiddenLabel
                    rows={3}
                    register={register}
                    $marginBottom={0}
                  />
                </Form>
              </Flex>
              <Button
                $variant='fill'
                $colorTheme='tint'
                $height={40}
                disabled={!score}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmitFeedback()
                }}
              >
                Send Feedback
              </Button>
            </Flex>
          </When>
          <When condition={view === 'SUCESS_AND_SHARE_ON_LINKEDIN'}>
            <S.SuccessAndShareOnLinkedInContent
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <Flex $direction='column' $align="center" $gap={12}>
                <Caption size="SM" $color="fgPrimary" $align="center">
                  Share your Pin Experience!
                </Caption>
                <Paragraph size="XS" $color="fgSecondary" $align="center">If Pin has improved your hiring flow, share your experience on LinkedIn!</Paragraph>
              </Flex>
              <Flex $width="fit-content">
                <Button
                  $variant='fill'
                  $colorTheme='tint'
                  $height={40}
                  leadingIcon="linkedin"
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fpin.com"
                >
                  Recommend Pin on LinkedIn
                </Button>
              </Flex>
            </S.SuccessAndShareOnLinkedInContent>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
