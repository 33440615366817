import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { fetchCommunicationInsightsReport } from 'src/libs/api/backend/reports_communication_insights'
import type { CommunicationInsightsOverview } from 'src/libs/api/backend/reports_communication_insights'
import { fetchPipelineInsightsReport } from 'src/libs/api/backend/reports_pipeline_insights'
import type { PipelineInsights } from 'src/libs/api/backend/reports_pipeline_insights'
import { fetchTeamActivitiesReport } from 'src/libs/api/backend/reports_team_activities'
import type { TeamActivitiesReport } from 'src/libs/api/backend/reports_team_activities'
import { TimeFrame, ReportType } from 'src/libs/api/backend/reports_types'
import type { FetchReportArgs } from 'src/libs/api/backend/reports_types'
import { queryKeys } from 'src/libs/query-keys'
import { useReportsShouldCompareToPreviousPeriod } from '../use-reports-should-compare-to-previous-period'

interface UseReportsArgs<T extends ReportType> extends Omit<FetchReportArgs, 'jobId'> {
  type: T
}

interface ReportsQueryArgs extends UseReportsArgs<ReportType> {
  jobId?: string
}

export const reportsQuery = ({ type, jobId, timeFrame, compareToPreviousPeriod }: ReportsQueryArgs) => ({
  queryKey: [queryKeys.reports, jobId, type, timeFrame, compareToPreviousPeriod],
  queryFn: async () => {
    const args = {
      jobId: jobId ?? '',
      timeFrame: timeFrame || TimeFrame.LAST_30_DAYS,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      compareToPreviousPeriod
    }

    switch (type) {
      case ReportType.COMMUNICATION_INSIGHTS:
        return await fetchCommunicationInsightsReport(args)
      case ReportType.TEAM_ACTIVITIES:
        return await fetchTeamActivitiesReport(args)
      case ReportType.PIPELINE_INSIGHTS:
        return await fetchPipelineInsightsReport(args)
      default:
        throw new Error(`Unsupported report type: ${type}`)
    }
  },
  staleTime: 60 * 1000
})

interface ReportTypeMap {
  [ReportType.COMMUNICATION_INSIGHTS]: CommunicationInsightsOverview
  [ReportType.PIPELINE_INSIGHTS]: PipelineInsights
  [ReportType.TEAM_ACTIVITIES]: TeamActivitiesReport
}

export const useReports = <T extends keyof ReportTypeMap>({ type, timeFrame, timezone }: UseReportsArgs<T>): UseQueryResult<ReportTypeMap[T]> => {
  const { jobId } = useParams()
  const { shouldCompare } = useReportsShouldCompareToPreviousPeriod()
  return useQuery(reportsQuery({
    type,
    jobId,
    timeFrame,
    timezone,
    compareToPreviousPeriod: shouldCompare
  }))
}
