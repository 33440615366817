import { EmptyState } from 'src/components/blocks/empty-state'
import { Button, Flex, Spacer } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './ready-to-send-page.styled'
import { ReadyToSendMessage } from 'src/components/blocks/ready-to-send-message'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { isNil, keyBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { When } from 'src/components/blocks/when'
import { Banner } from 'src/components/blocks/banner'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SEO } from 'src/components/primitives/seo'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Paragraph } from 'src/components/primitives/typography'
import { useRefreshManualEmailCopy } from 'src/hooks/mutations/use-refresh-manual-email-copy'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { useSetAtom } from 'jotai'
import { DialogId, openAlertAtom, openDialogAtom } from 'src/stores/dialogs'
import { useLoaderData } from 'react-router-dom'
import type { ReadyToSendLoaderData } from 'src/libs/loaders/in-outreach'
import { useJobSequenceState } from 'src/hooks/queries/use-job-sequence-state'
import type { ManualQueueItem } from 'src/libs/api/backend/manual_queue'
import type { CandidateSequenceStepMessageReviewExtended } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { ManualTaskExpanded } from 'src/libs/api/backend/manual_task'
import { ReadyToDoManualTask } from 'src/components/blocks/ready-to-do-manual-task'
import { useManualQueueQuery } from 'src/hooks/queries/use-manual-queue'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSession as useSessionQuery } from 'src/hooks/queries/use-session'
import { useSession } from 'src/hooks/use-session'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { SequenceStep } from 'src/libs/api/backend/sequences'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { stepIsOfTypeEmail } from 'src/hooks/use-email-sequence-editor'

interface ReadyToSendBannerParams {
  jobId: string
  emailSequenceActive?: boolean
  dirtyCSSMRIds: string[]
  dirtyManualTaskIds: string[]
  activeFilter: string[]
  setActiveFilter: (filter: string[]) => void
  // manualQueueItems?: Record<string, ManualQueueItem>
}

interface GetSequenceStepDisplayReturnType {
  title: string
  icon: React.ReactNode
}

const ReadyToSendBanner = ({
  jobId,
  emailSequenceActive,
  dirtyCSSMRIds,
  dirtyManualTaskIds,
  activeFilter,
  setActiveFilter
}: ReadyToSendBannerParams): JSX.Element => {
  const { featureFlags } = useSession()
  const { upsertJobSequence } = useUpsertJobSequence()
  const { refreshManualEmailCopy } = useRefreshManualEmailCopy()
  const openAlert = useSetAtom(openAlertAtom)
  const { data: sequence } = useJobSequenceQuery()
  const openDialog = useSetAtom(openDialogAtom)

  const getSequenceStepDisplay = (step: SequenceStep): GetSequenceStepDisplayReturnType => {
    if ((step.type === SequenceStepType.MANUAL_EMAIL || step.type === SequenceStepType.AUTOMATED_EMAIL) && !step.subject) {
      const firstEmailInThread = sequence?.sequenceSteps?.slice(0, sequence.sequenceSteps.findIndex(s => s.id === step.id))
        .reverse()
        .find(s => stepIsOfTypeEmail(s))

      if (firstEmailInThread?.subject) {
        return {
          title: `Re: ${firstEmailInThread.subject}`,
          icon: <Icon name={Icons.mailReply} size={14} color="tintFg" />
        }
      }
    }

    switch (step.type) {
      case SequenceStepType.MANUAL_EMAIL:
      case SequenceStepType.AUTOMATED_EMAIL:
        return {
          title: step.subject ?? 'Email',
          icon: <Icon name={Icons.mail} size={14} color="tintFg" />
        }
      case SequenceStepType.MANUAL_LINKEDIN_MESSAGE:
      case SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE:
        return {
          title: 'LinkedIn Message',
          icon: <BrandIcon name="linkedin" size={14} color="original" />
        }
      case SequenceStepType.MANUAL_TASK:
        return {
          title: step.subject ?? 'Manual Task',
          icon: <Icon name={Icons.checkCircle} size={14} color="tintFg" />
        }
      case SequenceStepType.MANUAL_PHONE_CALL:
        return {
          title: 'Call / Voicemail Drop',
          icon: <Icon name={Icons.phone} size={14} color="tintFg" />
        }
      case SequenceStepType.MANUAL_TEXT_MESSAGE:
        return {
          title: 'Text Message',
          icon: <Icon name={Icons.messageCircle} size={14} color="tintFg" />
        }
      default:
        return {
          title: 'Unknown step',
          icon: <></>
        }
    }
  }

  const sequenceStepsFilterItems = useMemo(() => {
    const items = sequence?.sequenceSteps?.map((step) => {
      const allStepsSelected = sequence.sequenceSteps && activeFilter.length === sequence.sequenceSteps.length
      return {
        title: getSequenceStepDisplay(step).title,
        icon: getSequenceStepDisplay(step).icon,
        trailingIcon: !allStepsSelected && activeFilter?.includes(step.id) ? <Icon name={Icons.check} size={14} color="tintFg" /> : undefined,
        isSelected: !allStepsSelected && activeFilter?.includes(step.id),
        onSelect: () => {
          if (sequence?.sequenceSteps && activeFilter?.length === sequence.sequenceSteps.length) {
            setActiveFilter([step.id])
          } else if (activeFilter?.includes(step.id)) {
            setActiveFilter(activeFilter.filter(id => id !== step.id))
          } else {
            setActiveFilter([...(activeFilter ?? []), step.id])
          }
        }
      }
    }) ?? []

    if (activeFilter?.length && sequence?.sequenceSteps && activeFilter.length !== sequence.sequenceSteps.length) {
      return [
        ...items,
        { title: 'Separator', type: 'separator' },
        {
          title: 'All Outreach Steps',
          onSelect: () => { setActiveFilter([]) }
        }
      ]
    }

    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence?.sequenceSteps, activeFilter, setActiveFilter])

  const pageTitle = useMemo(() => {
    return featureFlags.includes(FeatureFlags.MANUAL_TASKS) ? 'Queue' : 'Ready to send'
  }, [featureFlags])

  const { data: manualQueueItems } = useManualQueueQuery({ jobId, limit: 50 })

  const SHOW_SEND_ALL_BUTTON = false

  return (
    <S.ReadyToSendBanner $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}>
      <Flex $align='center' $justify='space-between'>
        <S.ReadyToSendHeader as="h1" size="MD" $whiteSpace="nowrap">
          Outreach &middot; {pageTitle}
        </S.ReadyToSendHeader>
        <Flex $gap={8} $justify="flex-end" $width="auto" $align="center">
          {
            SHOW_SEND_ALL_BUTTON
              ? (
                  <Button
                    $variant="fill"
                    $colorTheme="tint"
                    $height={24}
                    $fontSize={12}
                    leadingIcon={Icons.send}
                    onClick={() => { openDialog({ id: DialogId.SEND_ALL_FROM_QUEUE, payload: { queueItems: manualQueueItems } }) }}
                  >
                    Send All
                  </Button>
                )
              : <></>
          }
          <Dropdown
            trigger={
              <Button
                nested
                leadingIcon={Icons.filter}
                $variant='raised'
                $colorTheme='normal'
                $fontSize={12}
                $height={24}
              >
                {!activeFilter.length || (sequence?.sequenceSteps && activeFilter.length === sequence.sequenceSteps.length)
                  ? 'All Outreach Steps'
                  : `${activeFilter.length} of ${sequence?.sequenceSteps?.length ?? 0} Outreach Steps`
                }
              </Button>
            }
            items={sequenceStepsFilterItems ?? []}
            size="small"
            menuPosition="end"
            menuSpacing={2}
          />
          <Button
            leadingIcon={Icons.settings2}
            href={RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId })}
            $variant='raised'
            $colorTheme='normal'
            $fontSize={12}
            $height={24}
          >
            Edit Outreach
          </Button>
        </Flex>
      </Flex>
      <When condition={!emailSequenceActive && (dirtyCSSMRIds.length === 0 || dirtyManualTaskIds.length > 0)}>
        <Flex $direction='column'>
          <Banner
            icon="pause-circle"
            $variant="muted"
            actions={
              <Button
                $variant="fill"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                leadingIcon="play-circle"
                onClick={() => {
                  const newState = !emailSequenceActive
                  upsertJobSequence({
                    jobId,
                    active: newState,
                    toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                  })
                }}
              >
                Enable outreach
              </Button>
            }
          >
            Outreach is paused. Enable it to start sending emails.
          </Banner>
        </Flex>
      </When>
      <When condition={dirtyCSSMRIds.length > 0 || dirtyManualTaskIds.length > 0}>
        <Banner $variant='warning' >
          <Flex $direction='row' $align='center' $justify='space-between'>
            <Paragraph> The sequence's template has changed since these emails were generated. Rebuilding will use the current sequence template. </Paragraph> <Button
              leadingIcon={Icons.refreshCw}
              $height={24}
              $fontSize={12}
              onClick={() => {
                openAlert({
                  message: 'Are you sure you want to refresh?',
                  description: 'Any unsent edits in the queue that will be discarded',
                  onConfirm: () => {
                    refreshManualEmailCopy({ jobId })
                  }
                })
              }}
            >
              Rebuild
            </Button>
          </Flex>
        </Banner>
      </When>
    </S.ReadyToSendBanner>
  )
}

const toManualTask = (manualQueueItem: ManualQueueItem): ManualTaskExpanded | null => {
  return (manualQueueItem.type !== 'CANDIDATE_SEQUENCE_STEP_MESSAGE_REVIEW')
    ? manualQueueItem as unknown as ManualTaskExpanded
    : null
}

const toCandidateSequenceStepMessageReview = (manualQueueItem: ManualQueueItem): CandidateSequenceStepMessageReviewExtended | null => {
  return manualQueueItem.type === 'CANDIDATE_SEQUENCE_STEP_MESSAGE_REVIEW'
    ? manualQueueItem as CandidateSequenceStepMessageReviewExtended
    : null
}

const ReadyToSendPage = (): JSX.Element => {
  const { jobId } = useLoaderData() as ReadyToSendLoaderData

  const {
    data: manualQueueItems,
    isPending: isPendingManualQueueItems,
    isRefetching: isRefetchingManualQueueItems,
    refetch: refetchManualQueueItems
  } = useManualQueueQuery({ jobId, limit: 50 })

  const { isPending: isPendingOrgUsers, data: users, refetch: refetchOrgUsers } = useOrgUsersQuery()
  const { isPending: isPendingJobSequence, data: emailSequence } = useJobSequenceQuery(jobId)
  const { data: sessionData } = useSessionQuery()
  const { isPending: isPendingJobSequenceState, data: jobSequenceState } = useJobSequenceState({ jobId })
  const parentRef = useRef<HTMLDivElement>(null)
  const retryCountRef = useRef(0)

  const [activeFilter, setActiveFilter] = useState<string[]>([])

  const filteredManualQueueItems = useMemo(() => {
    if (!manualQueueItems) return {}

    if (!activeFilter.length) {
      return manualQueueItems
    }

    return Object.fromEntries(Object.entries(manualQueueItems).filter(([_, item]) => activeFilter.includes(item.sequenceStep.id)))
  }, [manualQueueItems, activeFilter])

  useEffect(() => {
    if (isRefetchingManualQueueItems) {
      return
    }

    if (!isNil(manualQueueItems) && Object.keys(manualQueueItems).length === 0 && retryCountRef.current < 1) {
      retryCountRef.current += 1
      void refetchManualQueueItems()
    }
  }, [manualQueueItems, isRefetchingManualQueueItems, refetchManualQueueItems])

  const dirtyCSSMRIds = useMemo(() => {
    return Object.values(manualQueueItems ?? {})
      ?.filter((manualQueueItem) => manualQueueItem.dirty && manualQueueItem.type === 'CANDIDATE_SEQUENCE_STEP_MESSAGE_REVIEW')
      .map((manualQueueItem) => manualQueueItem.id) ?? []
  }, [manualQueueItems])

  const dirtyManualTaskIds = useMemo(() => {
    return Object.values(manualQueueItems ?? {})
      ?.filter((manualQueueItem) => manualQueueItem.dirty && manualQueueItem.type === 'MANUAL_TASK')
      .map((manualQueueItem) => manualQueueItem.id) ?? []
  }, [manualQueueItems])

  const reconnect = useCallback((selectedEmailAccount: EmailAccount | undefined): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(selectedEmailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void refetchOrgUsers()
          clearInterval(timer)
        }
      }, 500)
    }
  }, [refetchOrgUsers])

  const usersByUserId = useMemo(() => {
    if (isNil(users)) {
      return {}
    }
    return keyBy(users, 'id')
  }, [users])

  if (isPendingOrgUsers || isPendingJobSequence || isPendingJobSequenceState || isPendingManualQueueItems || isRefetchingManualQueueItems) {
    return (
      <S.ReadyToSendPageInner>
        <SEO title='Ready To Send' />
        <S.ReadyToSendWrapper>
          <ReadyToSendBanner
            jobId={jobId}
            emailSequenceActive={emailSequence?.active}
            dirtyCSSMRIds={dirtyCSSMRIds}
            dirtyManualTaskIds={dirtyManualTaskIds}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
          <div
            style={{
              width: '100%',
              maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
              padding: `${CONTENT_PADDING}`
            }}
          >
            <LoadingSkeleton $variant="CandidateDetailsCard" />
          </div>
        </S.ReadyToSendWrapper>
      </S.ReadyToSendPageInner>
    )
  }

  if (!isNil(manualQueueItems) && Object.keys(manualQueueItems).length === 0) {
    return (
      <S.ReadyToSendPageInner>
        <SEO title='Ready To Send' />
        <S.ReadyToSendWrapper ref={parentRef}>
          <When condition={
            !!emailSequence &&
            emailSequence.active &&
            (!!jobSequenceState?.isHoliday || !jobSequenceState?.inEmailWhenToSend)
          }>
            <div style={{ maxWidth: CANDIDATES_PAGES_MAX_WIDTH }}>
              <Banner
                icon="pause-circle"
                $variant="warning"
                actions={
                  <Button
                    $variant="raised"
                    $colorTheme="tint"
                    $height={24}
                    $fontSize={12}
                    leadingIcon="settings-2"
                    href={RouteBuilder.build('SETTINGS_JOB_EMAIL_PREFERENCES', { jobId })}
                  >
                    Email Preferences
                  </Button>
                }
              >
                {jobSequenceState?.isHoliday
                  ? 'Today is a holiday. Outreach emails are paused according to your email preferences.'
                  : 'Outreach emails are paused according to your email schedule.'}
              </Banner>
            </div>
            <Spacer $size={16} />
          </When>
          <ReadyToSendBanner
            jobId={jobId}
            emailSequenceActive={emailSequence?.active}
            dirtyCSSMRIds={dirtyCSSMRIds}
            dirtyManualTaskIds={dirtyManualTaskIds}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              maxWidth: CANDIDATES_PAGES_MAX_WIDTH
            }}
          >
            <EmptyState
              heading="No one at this step"
              description="Looks like you don't have any prospects to reach out yet, start sourcing candidates."
              svg="profileCard"
              $padding={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
              }}
              actions={[
                {
                  href: `/jobs/${jobId}/candidates/sourcing`,
                  leadingIcon: 'binoculars',
                  children: 'Go to sourcing'
                }
              ]}
              />
          </div>
        </S.ReadyToSendWrapper>
      </S.ReadyToSendPageInner>
    )
  }

  return (
    <S.ReadyToSendPageInner>
      <SEO title='Queue' />
      <S.ReadyToSendWrapper ref={parentRef}>
        <ReadyToSendBanner
          jobId={jobId}
          emailSequenceActive={emailSequence?.active}
          dirtyCSSMRIds={dirtyCSSMRIds}
          dirtyManualTaskIds={dirtyManualTaskIds}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        {Object.keys(filteredManualQueueItems).length === 0
          ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                paddingLeft: 2,
                paddingBottom: 2
              }}
            >
              <EmptyState
                heading="No one in queue"
                description="Looks like you don't have any prospects at this stage to reach out to."
                svg="emailSequence2"
              />
            </div>
            )
          : (
              <S.ReadyToSendListInner>
                {Object.values(filteredManualQueueItems).map((manualQueueItem, index) => {
                  const cssmr = toCandidateSequenceStepMessageReview(manualQueueItem)
                  const manualTask = toManualTask(manualQueueItem)

                  const sendingUser = usersByUserId[manualQueueItem.sequenceStep.sendingUserId] ?? {}
                  return (
                    <div
                      style={{
                        width: '100%',
                        maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                        paddingLeft: 2,
                        paddingBottom: 2
                      }}
                      key={`${manualQueueItem.id}-${index}`}
                    >
                      <When condition={!isNil(cssmr)}>
                        <ReadyToSendMessage
                          jobId={jobId}
                          sendingUser={sendingUser}
                          manualQueueItem={manualQueueItem}
                          sendDisabled={!emailSequence?.active}
                          currentUserId={sessionData?.user?.id}
                          reconnect={reconnect}
                        />
                      </When>
                      <When condition={!isNil(manualTask)}>
                        <ReadyToDoManualTask
                          manualQueueItem={manualQueueItem}
                        />
                      </When>
                    </div>
                  )
                })}
                <Spacer $size={32} />
              </S.ReadyToSendListInner>
            )
        }
      </S.ReadyToSendWrapper>
    </S.ReadyToSendPageInner>
  )
}

export default ReadyToSendPage
