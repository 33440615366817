import { isNil } from 'lodash'
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EmptyState } from 'src/components/blocks/empty-state'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import type { CandidateJobExpanded, CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from 'src/components/primitives/seo'
import { Spacer } from 'src/components/primitives/spacer'
import { useIsGlobalRoute } from 'src/hooks/use-is-global-route'
import { CandidatesTablePagesContentInner } from 'src/pages/job/candidates/candidates.styled'

interface InboxPageProps {
  isLoading?: boolean
  candidateJobs?: CandidateJobExpanded[]
}

export const InboxPage = ({ isLoading, candidateJobs }: InboxPageProps): JSX.Element => {
  const { jobId } = useParams()

  const navigate = useNavigate()

  const { data: job } = useJobQuery()
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage')
  const candidateJobStage = stage as CandidateJobStage
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')
  const isGlobalRoute = useIsGlobalRoute()

  // This helps to navigate to the first candidate job when the page is loaded
  useEffect(() => {
    if (!isLoading && !isNil(candidateJobs) && candidateJobs.length) {
      const targetPath = isGlobalRoute ? 'ALL_INBOX_CANDIDATE_JOBS' : 'JOBS_INBOX_CANDIDATE_JOBS'
      const path = RouteBuilder.build(targetPath, {
        jobId,
        candidateJobId: candidateJobs?.[0].id
      }, {
        stage: candidateJobStage,
        lastCommunicatedWithUserId
      })
      navigate(path)
    }
  }, [isLoading, candidateJobs, jobId, candidateJobStage, lastCommunicatedWithUserId, navigate, isGlobalRoute])

  if (isLoading || isNil(candidateJobs) || candidateJobs.length) {
    return (
      <LoadingSkeleton $variant="CandidatesTable" />
    )
  }

  if (searchParams.toString().length > 0) {
    return (
      <>
        <SEO title={lastCommunicatedWithUserId ? 'My Communications' : 'All In Inbox'} />

        <CandidatesTablePagesContentInner>
          <Spacer $size={20} />
          <When condition={!job?.deleted}>
            <EmptyState
              $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
              svg="archiveDots"
              heading="No Conversations"
              description="As you contact candidates, emails and LinkedIn messages will appear here in a unified team inbox."
              actions={!isGlobalRoute
                ? [{
                    href: `/jobs/${jobId}/candidates/sourcing`,
                    leadingIcon: 'binoculars',
                    children: 'Find Candidates in Sourcing'
                  }]
                : []}
            />
          </When>
          <When condition={Boolean(job?.deleted)}>
            <EmptyStateArchivedJob />
          </When>
        </CandidatesTablePagesContentInner>
      </>
    )
  }

  return (
    <>
      <SEO title={lastCommunicatedWithUserId ? 'My Inbox' : 'All in Inbox'} />

      <CandidatesTablePagesContentInner>
        <Spacer $size={20} />
        <When condition={!job?.deleted}>
          <EmptyState
            $padding={{ top: 0, bottom: 24, left: 0, right: 0 }}
            svg="archiveDots"
            heading="No Conversations"
            description="As you contact candidates, emails and LinkedIn messages will appear here in a unified team inbox."
            actions={!isGlobalRoute
              ? [{
                  href: `/jobs/${jobId}/candidates/sourcing`,
                  leadingIcon: 'binoculars',
                  children: 'Find Candidates in Sourcing'
                }]
              : []}
          />
        </When>
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}
