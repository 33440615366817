import { useVirtualizer } from '@tanstack/react-virtual'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { EmptyState } from 'src/components/blocks/empty-state'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SourcingPageHeader } from 'src/components/blocks/sourcing'
import { ToggleCandidateView } from 'src/components/blocks/toggle-candidate-view'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { CandidateExportFileType, CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { CONTENT_PADDING, CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useChannel } from 'ably/react'
import { usePrintView } from 'src/hooks/use-print-view'
import { Logo } from 'src/components/primitives/logo'
import { IfElse } from 'src/components/blocks/if-else'
import { SEO } from 'src/components/primitives/seo'
import { useAtomValue } from 'jotai'
import { candidateDetailsChannelAtom } from 'src/stores/websocket-channels'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import { ExportCandidatesDropdown } from 'src/components/blocks/export-candidates-dropdown'
import { Spacer } from 'src/components/primitives/spacer'

const JobCandidatesShortlistedPage = (): JSX.Element => {
  const { jobId } = useParams()
  const [currViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)
  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])
  const isPrintView = usePrintView()
  const { height } = useWindowSize()

  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    source: undefined,
    favorite: true
  })
  const { isPending: isSequenceLoading, data: sequence } = useJobSequenceQuery()

  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)
  useChannel({ channelName: candidateDetailsChannel, skip: !candidateDetailsChannel }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    renderedCandidates.forEach((c) => {
      if (idsSet.has(c.candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, c.candidateId]
        })
      }
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId, {
        stage: CandidateJobStage.SOURCED,
        source: undefined,
        favorite: true
      }]
    })
  })

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: renderedCandidates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300,
    getItemKey: (index) => renderedCandidates?.[index]?.id ?? `candidate-${index}`
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')
      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  const isSequenceEmpty = useMemo(() => isSequenceStepsEmpty(sequence), [sequence])
  const isEmpty = useMemo(() => renderedCandidates.length === 0, [renderedCandidates])

  const candidatesPageHeader = useMemo(
    (): JSX.Element => (
      <SourcingPageHeader
        title='Shortlisted'
        jobId={jobId}
        isSequenceEmpty={isSequenceEmpty}
        customActions={
          !job?.deleted
            ? [
                <>
                  <ExportCandidatesDropdown
                    exportToPdfPrintUrl={RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SHORTLISTED', { jobId }, { print: true })}
                    exportOptions={[CandidateExportFileType.PDF]}
                  />
                  <ToggleCandidateView />
                </>
              ]
            : []
        }
        printUrl={RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SHORTLISTED', { jobId }, { print: true })}
        hasCandidateJobs={!isEmpty}
        maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
      />
    ),
    [job?.deleted, jobId, isEmpty, isSequenceEmpty]
  )

  if (isPending || isSequenceLoading) {
    return (
      <div>
        {candidatesPageHeader}
        <div
          style={{
            marginLeft: CONTENT_PADDING,
            maxWidth: CANDIDATES_PAGES_MAX_WIDTH
          }}
        >
          <LoadingSkeleton $variant="CandidateDetailsCard" delay={300} />
        </div>
      </div>
    )
  }

  if (isEmpty) {
    let emptyState = (
      <EmptyState
        heading="No Candidates Shortlisted"
        description="Shortlist candidates from Sourcing to create a list of favorite candidates to share with teammates."
        svg="shortlistedCandidates"
        $height="100%"
        actions={[
          {
            href: `/jobs/${jobId}/candidates/sourcing`,
            leadingIcon: 'binoculars',
            children: 'Go to Sourcing'
          }
        ]}
        delay={300}
      />
    )

    if (job?.deleted) {
      emptyState = (
        <EmptyStateArchivedJob />
      )
    }

    return (
      <div
        ref={parentRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {isEmpty ? <Spacer $size={20} /> : candidatesPageHeader}
        <div
          style={{
            // maxWidth: renderedCandidates?.length ? CANDIDATES_PAGES_MAX_WIDTH : '100%',
            maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
            flex: 1,
            // paddingBottom: spacing[48]
            marginLeft: CONTENT_PADDING
          }}
        >
          {emptyState}
        </div>
      </div>
    )
  }

  if (isPrintView) {
    return (
      <div
        ref={parentRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          overflow: 'auto'
        }}
      >
        <Logo variant="dark" size={64} />
        {candidatesPageHeader}
        {renderedCandidates.map((candidateJob, index) => (
          <div
            key={candidateJob.id}
            style={{
              width: '100%',
              maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
              breakBefore: index === 0 ? 'avoid-page' : 'page',
              breakInside: 'avoid',
              breakAfter: 'avoid-page'
            }}
          >
            <CandidateDetailsCard
              candidateJob={candidateJob}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <SEO title="Shortlisted" />
      <CompaniesPreferencesProvider>
        <IfElse
          condition={currViewMode === ViewMode.TABLE}
          ifNode={
            <div style={{ height: '100%' }}>
              {candidatesPageHeader}
              <div style={{ marginLeft: CONTENT_PADDING }}>
                <CandidatesTablePagesContentInner
                  data-component="CandidatesTablePagesContentInner"
                  $padding={0}
                  $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
                  $maxHeight={`${(height) - 64}px`}
                >
                  <CandidatesSourcedTable
                    candidateJobs={renderedCandidates ?? []}
                    visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.JOB_TITLE, COLUMN.CANDIDATE_STAGE_ACTIONS]}
                    pageHeaderHeight={64}
                  />
                </CandidatesTablePagesContentInner>
              </div>
            </div>
          }
          elseNode={
            <div
              ref={parentRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                overflow: 'auto',
                contain: 'strict',
                scrollBehavior: 'smooth'
              }}
            >
              {candidatesPageHeader}
              <div
                style={{
                  height: virtualizer.getTotalSize(),
                  position: 'relative',
                  marginLeft: CONTENT_PADDING
                }}
              >
                {virtualizer.getVirtualItems().map((virtualRow) => {
                  const candidateJob = renderedCandidates?.[virtualRow.index]
                  if (!candidateJob) {
                    return <Fragment key={virtualRow.index} />
                  }
                  return (
                    <div
                      style={{
                        width: '100%',
                        maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                        position: 'absolute',
                        top: `${virtualRow.start}px`
                      }}
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                    >
                      <CandidateDetailsCard
                        candidateJob={candidateJob}
                        actionType="sourcing"
                        viewMode={currViewMode}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          }
        />
      </CompaniesPreferencesProvider>
    </>
  )
}

export default JobCandidatesShortlistedPage
