import { useSetAtom } from 'jotai'
import { useBlocker } from 'react-router-dom'
import { openAlertAtom } from 'src/stores/dialogs'
import { useRef } from 'react'

interface AlertOnRouteChangeOptions {
  confirmAndExitText?: string
  onConfirmAndExit?: () => Promise<void>
}

export const useAlertOnRouteChange = (shouldAlert: boolean, { confirmAndExitText, onConfirmAndExit }: AlertOnRouteChangeOptions = {}): void => {
  const openAlert = useSetAtom(openAlertAtom)
  const isShowingAlert = useRef(false)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      if (currentLocation.pathname !== nextLocation.pathname) {
        isShowingAlert.current = false
      }
      return shouldAlert && currentLocation.pathname !== nextLocation.pathname
    }
  )

  if (blocker.state === 'blocked' && !isShowingAlert.current) {
    isShowingAlert.current = true
    openAlert({
      message: 'Are you sure you want to leave this page?',
      description: 'All your changes will be lost.',
      cancelText: 'Stay on page',
      confirmText: 'Discard and leave',
      confirmAndExitText,
      onCancel: () => {
        blocker.reset()
        isShowingAlert.current = false
      },
      onConfirm: () => {
        blocker.proceed()
        isShowingAlert.current = false
      },
      onConfirmAndExit: (async (): Promise<void> => {
        try {
          await onConfirmAndExit?.()
          blocker.proceed()
          isShowingAlert.current = false
        } catch (error) {
          blocker.reset()
          isShowingAlert.current = false
        }
      }) as () => void
    })
  }
}
