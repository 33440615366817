import { TimeFrame } from 'src/libs/api/backend/reports_types'
import { useQueryParams } from './use-query-params'

export const useReportsShouldCompareToPreviousPeriod = (): { shouldCompare: boolean } => {
  const { getParam } = useQueryParams()
  const timeFrame = getParam('timeFrame')
  const isNotAllTime = timeFrame && timeFrame !== TimeFrame.ALL_TIME
  const shouldCompare = isNotAllTime && getParam('compareToPreviousPeriod') === 'true'

  return { shouldCompare: Boolean(shouldCompare) }
}
