import styled from 'styled-components'

export const Plan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
`

export const SwitchToAnnualPayment = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  color: ${({ theme }) => theme.colors.tintFg};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights[1]};
`

export const Discount = styled.div`
  height: 14px;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  border-radius: 2px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.positiveBg};
  color: ${({ theme }) => theme.colors.positiveContrast};
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: 500;
`

export const ContactSales = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
  gap: ${({ theme }) => theme.spacing[2]};
`

export const NotificationBanner = styled.div<{ $paused: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[20]}`};
  align-items: center;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme, $paused }) => $paused ? theme.colors.bgTertiary : theme.colors.warningTranslucent5};
  border: ${({ theme, $paused }) => $paused ? `1px solid ${theme.colors.borderTranslucent}` : 'none'};
`

export const ButtonGroupTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing[4]};
`

export const ContactSalesBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border: 1px solid ${({ theme }) => theme.colors.bgTertiary};
`
