import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import * as S from './refinement-filter.styled'
import { SearchRefinementForm } from 'src/components/blocks/search-refinement-form'
import type { FilterType } from 'src/components/blocks/search-refinement-form'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import { isEmpty } from 'lodash'
import { ProductTourStep } from 'src/libs/product-tour'
import { JobSourcingState } from 'src/libs/api/backend/jobs'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useSetAtom } from 'jotai'
import { resetUnstructuredCustomRequirementsAtom } from 'src/stores/job-refinement'
import { openAlertAtom } from 'src/stores/dialogs'
import { useBlocker } from 'react-router-dom'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { updateApplicantsRefinements, updateListJobSearchRefinements } from 'src/hooks/queries/use-job-search-refinement'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { useGlobalError } from 'src/hooks/use-global-error'
import { ERROR_BLOCK_HEIGHT } from 'src/styles/constants'

interface RefinementFilterProps {
  criteria?: Criteria | null
  searchRefinement?: JobSearchRefinement
  isRecommending: boolean
  setIsRecommending: (isRecommending: boolean) => void
  newSearchRefinement?: boolean
  isErrored?: boolean
  filterType: FilterType
  job: Job
}

export const RefinementFilter = ({
  criteria,
  searchRefinement,
  newSearchRefinement,
  isRecommending,
  setIsRecommending,
  isErrored = false,
  filterType = 'searchRefinement',
  job
}: RefinementFilterProps): JSX.Element => {
  const { isGlobalErrorOpen } = useGlobalError()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const [opened, setOpened] = useState(!!newSearchRefinement)
  const [isDirty, setIsDirty] = useState(false)
  const resetUnstructuredCustomRequirements = useSetAtom(resetUnstructuredCustomRequirementsAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const [numberOfFilters, setNumberOfFilters] = useState(0)

  const initialCriteria = useMemo((): Criteria | undefined => {
    if (newSearchRefinement) {
      return {
        [CriteriaKey.CURRENT_JOB_TITLES]: [{
          name: job?.title ?? '',
          optional: false,
          negative: false
        }]
      }
    } else {
      return sanitizeRefinementCriteria(criteria)
    }
  }, [criteria, job?.title, newSearchRefinement])

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return isDirty && (
        currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search
      )
    }
  )

  useEffect(() => {
    if (!isEmpty(initialCriteria)) {
      const { custom_requirements: customRequirements, ...rest } = initialCriteria
      const numberOfFilters = Object.keys(rest).length + (customRequirements ?? []).length
      setNumberOfFilters(numberOfFilters)
    }
  }, [initialCriteria])

  const resetState = useCallback(() => {
    setOpened(false)
    setIsDirty(false)
    resetUnstructuredCustomRequirements()
  }, [resetUnstructuredCustomRequirements])

  const triggerClosed = useCallback(() => {
    if (isDirty) {
      openAlert({
        message: 'You have unsaved changes',
        description: 'Closing the criteria options will erase your unsaved changes.',
        cancelText: 'Keep Editing',
        confirmText: 'Discard Changes',
        isModal: false,
        onConfirm: () => {
          resetState()
        }
      })
    } else {
      resetState()
    }
  }, [isDirty, openAlert, resetState])

  useEffect(() => {
    if (blocker.state === 'blocked' && newSearchRefinement) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your unsaved criteria will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        isModal: false,
        onCancel: () => {
          blocker.reset()
        },
        onConfirm: () => {
          resetState()
          blocker.proceed()
        }
      })
    }
  }, [blocker, newSearchRefinement, openAlert, resetState])

  const onSubmit = useCallback((criteria: Criteria) => {
    if (!searchRefinement?.id) {
      return
    }
    setIsRecommending(true)
    if (filterType === 'searchRefinement') {
      updateListJobSearchRefinements({
        jobId: job.id,
        jobSearchRefinementId: searchRefinement.id,
        jobSearchRefinement: {
          sourcingState: JobSourcingState.REQUESTED,
          lastSourcingRequestedAt: new Date()
        }
      })
      updateJobSearchRefinement({
        jobId: job.id,
        jobSearchRefinementId: searchRefinement.id,
        automateAutoApproveCandidates: searchRefinement.automateAutoApproveCandidates ?? false,
        searchCriteria: criteria
      })
    } else {
      updateApplicantsRefinements({
        jobId: job.id,
        applicantsRefinementId: searchRefinement.id,
        jobSearchRefinement: {
          sourcingState: JobSourcingState.REQUESTED,
          lastSourcingRequestedAt: new Date()
        }
      })
      updateJobSearchRefinement({
        jobId: job.id,
        jobSearchRefinementId: searchRefinement.id,
        searchCriteria: criteria
      })
    }
  }, [filterType, job.id, searchRefinement, setIsRecommending, updateJobSearchRefinement])

  return (
    <RadixDropdown.Root
      open={opened}
      defaultOpen={newSearchRefinement}
      onOpenChange={(isOpen: boolean) => {
        if (!newSearchRefinement) {
          if (isOpen) {
            setOpened(true)
          } else {
            triggerClosed()
          }
        }
      }}
    >
      <S.Trigger id={ProductTourStep.SEARCH_CRITERIA}>
        <Button
          nested
          id={ProductTourStep.SEARCH_CRITERIA}
          $variant='raised'
          $colorTheme='normal'
          $height={24}
          $fontSize={12}
          leadingIcon={Icons.sparklesSolid}
          trailingIcon={Icons.chevronDown}
          disabled={isRecommending}
        >
          <Flex $align='center' $gap={6}>
            Search Criteria
            {numberOfFilters > 0 && (
              <S.NumberOfFilters>{numberOfFilters}</S.NumberOfFilters>
            )}
          </Flex>
        </Button>
      </S.Trigger>
      <RadixDropdown.Portal>
        <div style={{ position: 'relative' }}>
          <S.Overlay />
          <S.DropdownContent
            align="start"
            sideOffset={4}
          >
            <SearchRefinementForm
              job={job}
              initialCriteria={initialCriteria}
              isRecommending={isRecommending}
              onSubmit={(criteria: Criteria) => {
                onSubmit(criteria)
                setOpened(false)
              }}
              searchRefinement={searchRefinement}
              setIsDirty={setIsDirty}
              isDirty={isDirty}
              triggerClose={triggerClosed}
              isErrored={isErrored}
              filterType={filterType}
              $maxHeight={
                isGlobalErrorOpen
                  ? `calc(100vh - ${ERROR_BLOCK_HEIGHT} - 150px)`
                  : 'calc(100vh - 150px)'
              }
            />
          </S.DropdownContent>
        </div>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  )
}
