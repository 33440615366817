import { useMutation } from '@tanstack/react-query'
import queryClient from '../query-client'
import { queryKeys } from 'src/libs/query-keys'
import { setCandidateSequenceResponded as setCandidateSequenceRespondedApi } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { isNil, keyBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'

interface Args {
  candidateJobIds: string[]
  candidateSequenceIds: string[]
  interested?: boolean
  onSuccess?: () => void
}

interface Res {
  setCandidateSequenceResponded: ({ candidateJobIds, candidateSequenceIds, interested }: Args) => void
}

export const useSetCandidateSequenceResponded = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const { jobId } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ candidateJobIds, candidateSequenceIds, interested }: Args) => {
      return await setCandidateSequenceRespondedApi({ candidateSequenceIds, candidateJobIds, interested })
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when setting candidates as ${variables.interested ? 'interested' : 'responded'}: ${err.message}`
      })
    },
    onSuccess: async (candidateJobs: CandidateJobExpanded[], variables) => {
      const candidateJobsById = keyBy(candidateJobs, 'id')

      queryClient.setQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobId] }, (oldCandidateJobs) => {
        if (isNil(oldCandidateJobs)) {
          return oldCandidateJobs
        }

        return oldCandidateJobs.map((o) => {
          if (candidateJobsById[o.id]) {
            return {
              ...o,
              ...candidateJobsById[o.id]
            }
          }

          return o
        })
      })

      const candidateString = candidateJobs.length > 1 ? `${candidateJobs.length} candidates` : 'candidate'
      notifySuccess({
        icon: Icons.mails,
        message: `Successfully set ${candidateString} as ${variables.interested ? 'interested' : 'responded'}`
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateJobCounts, jobId]
        })
      ])
    }
  })

  const setCandidateSequenceResponded = (args: Args): void => {
    mutation.mutate(args)
  }

  return { setCandidateSequenceResponded }
}
