import { motion } from 'framer-motion'
import styled from 'styled-components'

export const SearchRefinementForm = styled.div<{ $isLoading: boolean }>`
  position: relative;
  background-color: ${({ theme, $isLoading }) => $isLoading ? theme.colors.fgTranslucent5 : theme.colors.bgPrimary};
  border: solid 1px;
  border-radius: ${({ theme }) => theme.spacing[4]};
  border-color: ${({ theme, $isLoading }) => $isLoading ? theme.colors.aiSolidTranslucent25 : theme.colors.aiSolidTranslucent50};
  padding: ${({ theme }) => `0 0 ${theme.spacing[10]} ${theme.spacing[6]}`};
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: ${({ $isLoading }) => $isLoading ? 0.5 : 1};

  .tiptap p.is-editor-empty:first-child::before {
    font-size: ${({ theme }) => theme.fontSizes[14]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    color: ${({ theme, $isLoading }) => $isLoading ? theme.colors.fgPrimary : theme.colors.aiSolidTranslucent50};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`

export const Wrapper = styled.div<{ $isGlobalErrorOpen: boolean, $maxHeight: string }>`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: ${({ $maxHeight }) => $maxHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: ${({ theme }) => theme.spacing[8]};
  box-shadow: 0px 2px 8px 0px rgba(115, 74, 231, 0.10), 0px 6px 24px 0px rgba(115, 74, 231, 0.10);


  /* iPads, Tablets */
  @media screen and (max-height: 1024px) {
    min-height: auto;
  }

  /* Small screens, laptops */
  @media screen and (min-height: 1025px) {
    min-height: 720px;
  }
`

export const InputFormWrapper = styled(motion.div)<{ $isLoading: boolean }>`
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]} ${theme.spacing[12]} ${theme.spacing[24]}`};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  background: linear-gradient(180deg, rgba(115, 74, 231, 0.00) 0%, rgba(115, 74, 231, 0.10) 100%), #FFF;
  border-bottom: 1px solid ${({ theme }) => theme.colors.aiSolidTranslucent10};
  border-top-left-radius: ${({ theme }) => theme.spacing[8]};
  border-top-right-radius: ${({ theme }) => theme.spacing[8]};
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  flex: 1 1 auto;
  overflow: hidden;
`

export const Icon = styled.div<{ $isLoading: boolean }>`
  display: flex;
  align-self: flex-start;
  padding: ${({ theme }) => theme.spacing[2]};
  margin-top: ${({ theme }) => theme.spacing[10]};
  opacity: ${({ $isLoading }) => $isLoading ? 0.5 : 1};
`

export const Suggestion = styled.button`
  border: none;
  height: 1.25rem;
  padding: 0.125rem 0.35rem;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  letter-spacing: 0.12px;
  line-height: 1rem;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.aiSolidFg};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`

export const SuggestedContainer = styled.div`
  padding: 0 6px;
`

export const SuggestionsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: 0.75rem 0.875rem;
  width: 100%;
  height: 4rem;
  p {
    align-self: flex-start;
    line-height: 14px;
  }
`

export const Suggestions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing[4]};
`

export const ActionsBar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  min-height: ${({ theme }) => theme.spacing[32]};
  align-items: flex-start;
`

export const AskingPinText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SourceCandidates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[16]};
  gap: ${({ theme }) => theme.spacing[12]};
  border-top: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-bottom-left-radius: ${({ theme }) => theme.spacing[8]};
  border-bottom-right-radius: ${({ theme }) => theme.spacing[8]};
  position: sticky;
`
