import { Flex } from 'src/components/primitives/flex'
import { SEO } from 'src/components/primitives/seo'
import { useReports } from 'src/hooks/queries/use-reports'
import { useQueryParams } from 'src/hooks/use-query-params'
import { TimeFrame, ReportType } from 'src/libs/api/backend/reports_types'
import { PositionActivityChart } from 'src/components/charts/built/pipeline-insights/position-activity-chart'
import { TeamActivityChart } from 'src/components/charts/built/pipeline-insights/team-activity-chart'
import { StatsCardsChart } from 'src/components/charts/built/pipeline-insights/stats-cards-chart'
import { OverviewChart } from 'src/components/charts/built/pipeline-insights/overview-chart'
import { When } from 'src/components/blocks/when'
import { isNil } from 'lodash'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton/loading-skeleton'
import { ReportsPageHeader } from 'src/components/blocks/reports-page-header/reports-page-header'

const ReportsPipelineInsightsPage = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const currentTimeFrame = getParam('timeFrame')

  const { isLoading, data } = useReports({
    type: ReportType.PIPELINE_INSIGHTS,
    timeFrame: (currentTimeFrame as TimeFrame) ?? TimeFrame.LAST_30_DAYS
  })

  return (
    <>
      <SEO title="Pipeline Insights Reports" />
      <ReportsPageHeader reportType={ReportType.PIPELINE_INSIGHTS} />
      <When condition={isLoading}>
        <LoadingSkeleton $variant="Reports" />
      </When>
      <When condition={!isLoading && !isNil(data)}>
        <Flex $direction="column" $gap={20}>
          <StatsCardsChart
            data={
              data?.pipelineStats ?? {
                timeToFirstReply: 0,
                timeToFirstInterview: null,
                outreachEffectiveness: 0,
                teamResponseTime: 0,
                openRate: 0,
                replyRate: 0,
                interestedRate: 0,
                scheduledRate: 0,
                bouncedRate: 0,
                pastOpenRate: 0,
                pastReplyRate: 0,
                pastInterestedRate: 0,
                pastScheduledRate: 0,
                pastBouncedRate: 0,
                pastTeamResponseTime: 0,
                pastTimeToFirstReply: 0
              }
            }
          />
          <OverviewChart data={data?.pipelineOverview} />
          <PositionActivityChart data={data?.positionActivities ?? []} />
          <TeamActivityChart data={data?.teamMemberActivities ?? []} />
          <div />
        </Flex>
      </When>
    </>
  )
}

export default ReportsPipelineInsightsPage
