import queryClient from 'src/hooks/query-client'
import { listJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { redirect } from 'react-router-dom'
import RouteBuilder from '../route-builder'
import { JobSearchRefinementType } from '../api/backend/jobs'
import { candidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { CandidateJobStage } from '../api/backend/candidate_jobs'
import { jobQuery } from 'src/hooks/queries/use-job'
import { jobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { mergeIntegrationsQuery } from 'src/hooks/queries/use-merge-integrations-list'

export const sourcingLoader = async ({ params: { jobId, jobSearchRefinementId }, request }: { params: { jobId: string, jobSearchRefinementId?: string }, request: Request }): Promise<Response | null> => {
  const searchParams = new URL(request.url).searchParams
  const jobSearchRefinementIdParam = searchParams.get('jobSearchRefinementId')
  if (jobSearchRefinementIdParam) {
    return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinementIdParam }))
  }

  await Promise.all([
    queryClient.ensureQueryData(listJobSearchRefinementsQuery(jobId, { type: JobSearchRefinementType.SEARCH_REFINEMENT })),
    queryClient.ensureQueryData(mergeIntegrationsQuery())
  ])

  const jobSearchRefinements = await queryClient.ensureQueryData(listJobSearchRefinementsQuery(jobId, { type: JobSearchRefinementType.SEARCH_REFINEMENT }))
  if (!jobSearchRefinementId) {
    if (jobSearchRefinements.length > 0) {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinements[0].id }))
    } else {
      return redirect(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_MANUAL', { jobId }))
    }
  }
  return null
}

export const shortlistedLoader = async ({ params: { jobId } }: { params: { jobId: string } }): Promise<Response | null> => {
  await Promise.all([
    queryClient.prefetchQuery(candidateJobsQuery(jobId, {
      stage: CandidateJobStage.SOURCED,
      source: undefined,
      favorite: true
    })),
    queryClient.prefetchQuery(jobQuery(jobId)),
    queryClient.prefetchQuery(jobSequenceQuery(jobId))
  ])
  return null
}
