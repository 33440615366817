import { VisuallyHidden } from 'src/components/primitives/visually-hidden'
import type { Color, FontSize, LineHeight, Spacing } from 'src/styles/theme/types'
import { Spacer } from 'src/components/primitives/spacer'
import * as S from './field-label.styled'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Icon, Icons } from 'src/components/primitives/icon'

export interface FieldLabelStyleProps {
  $fontSize?: FontSize
  $lineHeight?: LineHeight
  $color?: Color
}

export interface FieldLabelProps extends FieldLabelStyleProps {
  label?: string
  description?: string
  htmlFor?: string
  hiddenLabel?: boolean
  marginBottom?: Spacing
  tooltip?: string
}

export const FieldLabel = ({
  label,
  description,
  htmlFor,
  hiddenLabel = false,
  $fontSize = 14,
  $lineHeight = 1.43,
  $color = 'fgSecondary',
  marginBottom = 4,
  tooltip
}: FieldLabelProps): JSX.Element => {
  const styleProps = {
    $fontSize,
    $lineHeight,
    $color
  }

  if (hiddenLabel) {
    return (
      <VisuallyHidden>
        <S.FieldLabel htmlFor={htmlFor} {...styleProps}>
          {label}
        </S.FieldLabel>
      </VisuallyHidden>
    )
  }
  return (
    <>
      <S.FieldLabel htmlFor={htmlFor} {...styleProps}>
        {label}
        {tooltip && (
          <>
            &nbsp;
            <Tooltip
              position="right"
              trigger={<span><Icon name={Icons.info} size={12} color="fgSecondary" /></span>}
              $maxWidth={400}
            >{tooltip}</Tooltip>
          </>
        )}
      </S.FieldLabel>
      {description ? <S.Description>{description}</S.Description> : null}
      <Spacer $size={marginBottom} />
    </>
  )
}
