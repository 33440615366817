import styled from 'styled-components'
import type { EmptyStateStylingProps } from './empty-state'
// import { CONTENT_PADDING } from 'src/styles/constants'

export const EmptyState = styled.div<EmptyStateStylingProps>`
  height: ${({ $height }) => {
    if ($height === 'full') {
      return 'calc(100vh)'
    }

    if ($height === 'full-with-header') {
      return '100%'
    }

    return $height
  }};
  width: 100%;

  padding-top: ${({ $padding, theme }) => theme.spacing[$padding?.top ?? 24]};
  padding-right: ${({ $padding, theme }) => theme.spacing[$padding?.right ?? 24]};
  padding-bottom: ${({ $padding, theme }) => theme.spacing[$padding?.bottom ?? 24]};
  padding-left: ${({ $padding, theme }) => theme.spacing[$padding?.left ?? 24]};

  background: ${({ $background }) => $background};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  overflow: hidden;
`

export const EmptyStateInner = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(0, 312px);
  grid-template-rows: auto;
  align-content: start;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgTertiary};
  padding: 4rem 2.5rem;
  overflow-y: auto;
`

export const Main = styled.div`
  grid-column: 1;
`

export const MainInner = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const Visual = styled.div`
  grid-column: 2;
  display: flex;
  justify-content: center;
  svg {
    max-height: 144px;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.75rem;
`

export const Content = styled.div`
  grid-column: 1/-1;
`
