import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { Paragraph } from 'src/components/primitives/typography'
import * as S from './nps-survey.styled'

interface NpsSurveyProps {
  selectedScore?: number | null
  onScoreClick: (score: number) => void
}

export const NpsSurvey = ({ selectedScore, onScoreClick }: NpsSurveyProps): JSX.Element => {
  return (
    <>
      <S.SurveyContent>
        <S.SurveyButtons>
          {Array.from({ length: 10 }).map((_, index) => (
            <Button
              key={index}
              $variant={selectedScore === index + 1 ? 'fill' : 'raised'}
              $colorTheme={selectedScore === index + 1 ? 'tint' : 'normal'}
              $height={32}
              $width={48}
              $align="center"
              onClick={() => {
                onScoreClick(index + 1)
              }}
            >
              {index + 1}
            </Button>
          ))}
        </S.SurveyButtons>
        <Flex $align="center" $justify="space-between">
          <Paragraph size="SM" $color="fgSecondary">
            Not likely
          </Paragraph>
          <Paragraph size="SM" $color="fgSecondary">
            Very likely
          </Paragraph>
        </Flex>
      </S.SurveyContent>
    </>
  )
}
