import { FeatureRequestSurvey, NetPromoterScoreSurvey, PinBenefits, WhatAtsSurvey } from './surveys'
import { SurveyType } from './types'
import { Spacer } from 'src/components/primitives/spacer'
import * as S from './on-screen-surveys.styled'
import { useState } from 'react'
import { isNil } from 'lodash'
import { useSession } from 'src/hooks/use-session'

export const OnScreenSurveys = (): JSX.Element => {
  const { user } = useSession()

  // Survey rendering logic:
  // * NPS survey: Show once per month, for accounts older than 14 days
  // * Feedback survey: Show once per month, for accounts older than 10 days (not implemented yet)
  // * ATS survey: Ask once per account (not implemented yet)
  const [surveyType] = useState<SurveyType>(() => {
    if (!user) {
      return SurveyType.PIN_BENEFITS
    }

    const accountAge = Date.now() - new Date(user.createdAt ?? '').getTime()
    const daysSinceSignUp = accountAge / (1000 * 60 * 60 * 24)

    const lastNpsFeedbackAt = user.lastCollectedNpsFeedbackAt ? new Date(user.lastCollectedNpsFeedbackAt).getTime() : 0
    const daysSinceLastNpsFeedback = (Date.now() - lastNpsFeedbackAt) / (1000 * 60 * 60 * 24)

    if (daysSinceSignUp >= 14 && daysSinceLastNpsFeedback >= 30) {
      return SurveyType.NET_PROMOTER_SCORE
    }

    return SurveyType.PIN_BENEFITS
  })

  if (isNil(surveyType)) {
    return <></>
  }

  if (surveyType === SurveyType.PIN_BENEFITS) {
    return (
      <>
        <Spacer $size={64} />
        <PinBenefits />
      </>
    )
  }

  return (
    <S.Wrapper>
      <S.Card>
        {surveyType === SurveyType.WHAT_ATS && <WhatAtsSurvey />}
        {surveyType === SurveyType.FEATURE_REQUEST && <FeatureRequestSurvey />}
        {surveyType === SurveyType.NET_PROMOTER_SCORE && <NetPromoterScoreSurvey />}
      </S.Card>
    </S.Wrapper>
  )
}
