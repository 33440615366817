import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { HorizontalFunnelChart } from 'src/components/charts/primitives/horizontal-funnel-chart'
import type { PipelineOverview } from 'src/libs/api/backend/reports_pipeline_insights'

interface OverviewChartProps {
  data?: PipelineOverview
}

const isEmpty = (data?: PipelineOverview): boolean => {
  if (!data) return true
  return Object.values(data).every(value =>
    typeof value === 'number' && value === 0
  )
}

export const OverviewChart = ({ data }: OverviewChartProps): JSX.Element => {
  if (!data || isEmpty(data)) {
    return (
      <ChartWrapper>
        <ChartHeader title="Pipeline Overview" />
        <EmptyChart />
      </ChartWrapper>
    )
  }

  const getValue = (value?: number): number => {
    return value ?? 0
  }

  const getChange = (current?: number, past?: number): string => {
    const currentValue = getValue(current)
    const pastValue = getValue(past)
    if (pastValue === 0) {
      return currentValue === 0 ? '0%' : '100%'
    }
    const percentageChange = ((currentValue - pastValue) / pastValue) * 100
    return `${Math.abs(Math.round(percentageChange))}%`
  }

  const getChangeDirection = (current?: number, past?: number): 'up' | 'down' => {
    return getValue(current) >= getValue(past) ? 'up' : 'down'
  }

  const getFirstValidStage = (): number => {
    const values = [
      data?.reviewed,
      data?.accepted,
      data?.emailLookups,
      data?.emailOpened,
      data?.replies
    ]
    return values.findIndex(v => (v ?? 0) > 0)
  }

  const transformedData = {
    total: (() => {
      const indexOfFirstValidStage = getFirstValidStage()
      if (indexOfFirstValidStage === -1) return 0
      const values = [data?.reviewed, data?.accepted, data?.emailLookups, data?.emailOpened, data?.replies]
      return getValue(values[indexOfFirstValidStage])
    })(),
    stages: [
      {
        dataPoints: [
          {
            label: 'Candidates Reviewed',
            value: getValue(data.reviewed).toString(),
            valueChange: getChange(data.reviewed, data.pastReviewed),
            valueChangeDirection: getChangeDirection(data.reviewed, data.pastReviewed),
            hideBar: getValue(data.reviewed) === 0
          }
        ]
      },
      {
        dataPoints: [
          {
            label: 'Candidates Accepted',
            value: getValue(data.accepted).toString(),
            valueChange: getChange(data.accepted, data.pastAccepted),
            valueChangeDirection: getChangeDirection(data.accepted, data.pastAccepted),
            hideBar: getValue(data.accepted) === 0
          },
          {
            label: 'Declined',
            value: (getValue(data.reviewed) - getValue(data.accepted)).toString(),
            valueChange: getChange(
              data.reviewed - data.accepted,
              data.pastReviewed - data.pastAccepted
            ),
            valueChangeDirection: getChangeDirection(
              data.reviewed - data.accepted,
              data.pastReviewed - data.pastAccepted
            )
          }
        ]
      },
      {
        dataPoints: [
          {
            label: 'Candidates Outreached',
            value: getValue(data.emailLookups).toString(),
            valueChange: getChange(data.emailLookups, data.pastEmailLookups),
            valueChangeDirection: getChangeDirection(data.emailLookups, data.pastEmailLookups)
          },
          {
            label: 'Emails Not Found',
            value: (getValue(data.accepted) - getValue(data.emailLookups)).toString(),
            valueChange: getChange(
              data.accepted - data.emailLookups,
              data.pastAccepted - data.pastEmailLookups
            ),
            valueChangeDirection: getChangeDirection(
              data.accepted - data.emailLookups,
              data.pastAccepted - data.pastEmailLookups
            )
          }
        ]
      },
      {
        dataPoints: [
          {
            label: 'Emails Opened',
            value: getValue(data.emailOpened).toString(),
            valueChange: getChange(data.emailOpened, data.pastEmailOpened),
            valueChangeDirection: getChangeDirection(data.emailOpened, data.pastEmailOpened)
          },
          {
            label: 'Not Opened',
            value: (getValue(data.emailLookups) - getValue(data.emailOpened) - getValue(data.bounced)).toString(),
            valueChange: getChange(
              data.emailLookups - data.emailOpened - data.bounced,
              data.pastEmailLookups - data.pastEmailOpened - data.pastBounced
            ),
            valueChangeDirection: getChangeDirection(
              data.emailLookups - data.emailOpened - data.bounced,
              data.pastEmailLookups - data.pastEmailOpened - data.pastBounced
            )
          },
          {
            label: 'Email Bounced',
            value: getValue(data.bounced).toString(),
            valueChange: getChange(data.bounced, data.pastBounced),
            valueChangeDirection: getChangeDirection(data.bounced, data.pastBounced)
          }
        ]
      },
      {
        dataPoints: [
          {
            label: 'Replies',
            value: getValue(data.replies).toString(),
            valueChange: getChange(data.replies, data.pastReplies),
            valueChangeDirection: getChangeDirection(data.replies, data.pastReplies)
          },
          {
            label: 'No Reply',
            value: (getValue(data.emailOpened) - getValue(data.replies)).toString(),
            valueChange: getChange(
              data.emailOpened - data.replies,
              data.pastEmailOpened - data.pastReplies
            ),
            valueChangeDirection: getChangeDirection(
              data.emailOpened - data.replies,
              data.pastEmailOpened - data.pastReplies
            )
          }
        ]
      }
    ]
  }

  return (
    <ChartWrapper>
      <ChartHeader title="Pipeline Overview" />
      <HorizontalFunnelChart data={transformedData} />
    </ChartWrapper>
  )
}
