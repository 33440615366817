import styled from 'styled-components'

export const PreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  padding-top: 1.5rem;
`

export const PreferenceRowWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 2px;
`

export const PreferenceRowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[20]};
  gap: ${({ theme }) => theme.spacing[16]};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
`

export const PreferenceRowSubTitle = styled.div`
  margin-top: 1px;
`

export const Action = styled.div`
  flex: 0 1 244px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const AllHolidaysLink = styled.button`
  all: unset;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.tintFg};
  cursor: pointer;
`
