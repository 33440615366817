import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notifyErrorAtom } from 'src/stores/notifications'
import { aiGeneratedSentence } from 'src/libs/api/backend/gpt'
import { useSetAtom } from 'jotai'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  jobId: string
  candidateId?: string | undefined
  personalizationInstructions: string
  templateBody: string
  onSuccess?: (response: string) => void
  onError?: () => void
}

interface Res {
  isGeneratingAiSentence: boolean
  generateAiSentence: (args: Args) => void
}

export const useAiGeneratedSentence = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async (args: Args) => await aiGeneratedSentence(args),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while generating AI sentence: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (aiGeneratedSentence, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.aiGeneratedSentence]
      })

      if (variables.onSuccess) {
        variables.onSuccess(aiGeneratedSentence)
      }
    }
  })

  const generateAiSentence = (args: Args): void => {
    mutation.mutate(args)
  }

  return {
    isGeneratingAiSentence: mutation.isPending,
    generateAiSentence
  }
}
