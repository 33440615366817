import styled, { css, keyframes } from 'styled-components'
import type { Variant, Size, ItemLayout } from './dropdown'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import type { Keyframes } from 'styled-components/dist/types'
import type { FontWeight, Spacing } from 'src/styles/theme/types'

const showMenuAndMoveDown = (): Keyframes => keyframes`
  0% { opacity: 0; transform: translateY(2px) scale(0.98); }
  100% { opacity: 1; transform: translateY(0px) scale(1); }
`

const showMenuAndMoveUp = (): Keyframes => keyframes`
  0% { opacity: 0; transform: translateY(-2px) scale(0.98); }
  100% { opacity: 1; transform: translateY(0px) scale(1); }
`

export const DropdownMenuRoot = styled(RadixDropdown.Root)`
  position: relative;
`

interface DropdownMenuContentStyleProps {
  $maxHeight?: string
  $minWidth?: string
  $maxWidth?: string
  $menuWidth?: 'auto' | 'full' | number
  $hasStickyItem: boolean
  $hasStickyHeader: boolean
  $layout: ItemLayout
  $menuSpacing?: number
}

export const DropdownMenuContent = styled(RadixDropdown.Content)<DropdownMenuContentStyleProps>`
  position: relative;
  width: ${({ $menuWidth }) => typeof $menuWidth === 'number' ? `${$menuWidth}px` : $menuWidth === 'full' ? 'var(--radix-dropdown-menu-trigger-width)' : 'auto'};
  min-width: ${({ $minWidth }) => $minWidth ?? '220px'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? 'auto'};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  padding: ${({ $hasStickyItem, $hasStickyHeader, $layout }) => $hasStickyHeader
    ? '0 0 0'
    : $hasStickyItem
      ? '0.375rem 0 0 0'
      : $hasStickyHeader
        ? '0.375rem 0 0 0'
        : $layout === 'block'
          ? '0'
          : '0.375rem 0'
  };
  z-index: ${({ theme }) => theme.zindeces.dropdown};
  max-height: ${({ $maxHeight }) => $maxHeight ?? 'auto'};
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  &[data-state='open'] {
    &[data-side='top'] {
      transform-origin: bottom center;
      animation: ${showMenuAndMoveDown} 0.15s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-side='bottom'] {
      transform-origin: top center;
      animation: ${showMenuAndMoveUp} 0.15s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  ${({ $menuSpacing }) => $menuSpacing && $menuSpacing > 0 && css`
    display: flex;
    flex-direction: column;
    gap: ${$menuSpacing}px;
  `}
`

export const Searchbar = styled.div<{ $stickyTopPosition: number }>`
  position: sticky;
  top: ${({ $stickyTopPosition }) => $stickyTopPosition}px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
  input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: ${({ theme }) => theme.fontSizes[14]};
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  &:before {
    content: '';
    position: absolute;
    top: -0.375rem;
    width: 100%;
    height: 0.375rem;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    z-index: 2;
  }
`

export const SearchInputWrapper = styled.div<{ $variant?: 'default' | 'outline' }>`
  display: flex;
  align-items: center;
  gap: 0.65rem;
  ${({ $variant, theme }) => $variant === 'outline'
  ? css`
      border: solid 1px;
      border-color: ${theme.colors.borderTranslucent};
      border-radius: 4px;
      padding: ${theme.spacing[8]};
      margin: 0 ${theme.spacing[6]} ${theme.spacing[6]} ${theme.spacing[6]};
    `
  : css`
    margin: 0.5rem 0.5rem 0.85rem 0.85rem;
  `
}
`

export const NoFilterResult = styled.div`
  /* Have a larger padding on the right side for optical alignment */
  padding: 1.25rem 1rem 0.85rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`

export const TopLevelSelect = styled.div`
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  padding-bottom: 0.375rem;
  margin-bottom: 0.375rem;
`

export const DropdownMenuSubContent = styled(RadixDropdown.SubContent)`
  min-width: 220px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
  padding: 0.375rem 0;
  z-index: ${({ theme }) => theme.zindeces.dropdown};
`

export const Trigger = styled(RadixDropdown.Trigger)<{ $fullWidth?: boolean, disabled?: boolean }>`
  position: relative;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
  user-select: none;
  ${({ $fullWidth }) => (
    $fullWidth
    ? `
        flex: 1;
        width: 100%;
        overflow: hidden;
      `
    : ''
  )}
  &[data-state='open'] {
    background-color: transparent;
    box-shadow: ${({ theme }) => theme.boxShadows['light-active']};
    border-radius: 4px;
  }
`

interface MenuItemInnerProps {
  $variant: Variant
  $size: Size
  $isActive?: boolean
  $layout?: ItemLayout
  $margin?: {
    top?: Spacing
    right?: Spacing
    bottom?: Spacing
    left?: Spacing
  }
}

export const MenuItemInner = styled.div<MenuItemInnerProps>`
  width: 100%;
  height: ${({ $size }) => ($size === 'withSubTitle' ? '50px' : $size === 'normal' ? '32px' : '26px')};
  padding: ${({ $layout }) => $layout === 'block'
    ? '0'
    : '0 0.375rem'
  };
  ${({ $margin }) => $margin && css`
    margin-top: ${$margin.top ?? 0}px;
    margin-right: ${$margin.right ?? 0}px;
    margin-bottom: ${$margin.bottom ?? 0}px;
    margin-left: ${$margin.left ?? 0}px;
  `}
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  ${({ $isActive, theme }) => $isActive && css`
    background-color: ${theme.colors.tintTranslucent5};
  `}
`

interface ItemStyleProps {
  $variant: Variant
  $isSelectable: boolean
  $isActive?: boolean
  $layout?: ItemLayout
}

const itemStyles = css<ItemStyleProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ $layout }) => $layout === 'block'
    ? '0'
    : '0 0.375rem'
  };
  cursor: default;
  user-select: none;
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &[data-highlighted],
  &:hover {
    outline: none;
    cursor: pointer;
    ${MenuItemInner} {
      background-color: ${({ theme, $variant }) => {
        switch ($variant) {
          case 'tint': {
            return theme.colors.tintTranslucent5
          }
          case 'muted': {
            return theme.colors.bgTertiary
          }
          case 'positive': {
            return theme.colors.positiveTranslucent5
          }
          case 'negative': {
            return theme.colors.negativeTranslucent5
          }
          case 'warning': {
            return theme.colors.warningTranslucent10
          }
          case 'aiSolid': {
            return theme.colors.aiSolidTranslucent5
          }
          default: {
            return theme.colors.fgTranslucent10
          }
        }
      }};
    }
  }
  ${({ $layout, theme }) => $layout === 'block' && `
    height: 2.75rem;
    border-bottom: solid 1px;
    border-color: ${theme.colors.borderOpaque};
    padding: 0 5rem 0 0.75rem;
    &[data-highlighted],
    &:hover {
      background-color: ${theme.colors.fgTranslucent5};
      ${MenuItemInner} {
        background-color: transparent;
      }
    }
  `}
  &[data-disabled] {
    cursor: auto !important;
    /* pointer-events: none; */
    /* padding: 0.25rem 0.375rem 0.25rem 0.475rem; */
    opacity: 0.5;
  }
  ${({ $isSelectable }) =>
    !$isSelectable &&
    'pointer-events: none !important;'
  }
`

export const DropdownMenuItem = styled(RadixDropdown.Item)<ItemStyleProps>`
  ${itemStyles}
`

export const DropdownMenuItemSubTrigger = styled(RadixDropdown.SubTrigger)<{
  $variant: Variant
  $isSelectable: boolean
  $isActive?: boolean
}>`
  ${itemStyles}
`

export const ItemIcon = styled.span`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TrailingIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface ItemTextProps {
  $variant: Variant
  $size: Size
  $fontWeight?: FontWeight
  $layout?: ItemLayout
  $hasOnlySubTitle?: boolean
  $isActive?: boolean
}

export const ItemText = styled.span<ItemTextProps>`
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${({ $size, $hasOnlySubTitle }) => $size === 'withSubTitle' ? '0.25rem' : $hasOnlySubTitle ? '0rem' : '0rem'};
  span {
    font-size: ${({ theme, $size }) => theme.fontSizes[$size === 'normal' ? 14 : 12]};
    font-weight: ${({ theme, $fontWeight }) => theme.fontWeights[$fontWeight ?? 500]};
    line-height: ${({ theme }) => theme.lineHeights['1.43']};
    color: ${({ theme, $variant }) => {
      switch ($variant) {
        case 'tint': {
          return theme.colors.tintBg
        }
        case 'muted': {
          return theme.colors.fgSecondary
        }
        case 'positive': {
          return theme.colors.positiveBg
        }
        case 'negative': {
          return theme.colors.negativeBg
        }
        case 'warning': {
          return theme.colors.warningBg
        }
        case 'aiSolid': {
          return theme.colors.aiSolidFg
        }
        default: {
          return theme.colors.fgPrimary
        }
      }
    }};
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    &:nth-child(2) {
      font-size: ${({ theme, $size }) => theme.fontSizes[($size === 'normal' || $size === 'withSubTitle') ? 12 : 10]};
      font-weight: ${({ theme }) => theme.fontWeights[400]};
      line-height: ${({ theme }) => theme.lineHeights['1.2']};
      color: ${({ theme }) => theme.colors.fgSecondary};
    }
  }
  ${({ $hasOnlySubTitle, theme, $size }) => $hasOnlySubTitle && `
    span {
      font-size: ${theme.fontSizes[($size === 'normal' || $size === 'withSubTitle') ? 12 : 10]};
      font-weight: 400;
      line-height: ${theme.lineHeights['1.2']};
      color: ${theme.colors.fgSecondary};
    }
  `}
  ${({ $isActive, theme }) => $isActive && css`
    span {
      color: ${theme.colors.tintFg};
    }
  `}
`

export const DropdownMenuSeparator = styled(RadixDropdown.Separator)`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.borderTranslucent};
  margin: 0.5rem 0;
`

export const DropdownMenuSpacer = styled(RadixDropdown.Separator)`
  height: 2rem;
`

export const DropdownMenuLabel = styled(RadixDropdown.Label)`
  margin: 0.75rem 0.75rem 0 0.95rem;
  &:first-child {
    margin-top: 0.375rem;
  }
  span {
    font-size: ${({ theme }) => theme.fontSizes[10]};
    color: ${({ theme }) => theme.colors.fgTertiary};
    letter-spacing: 0.2px;
    line-height: 1rem;
    text-transform: uppercase;
  }
`

export const DropdownMenuSublabel = styled(RadixDropdown.Label)`
  margin: 0.5rem 0.75rem 0 0.95rem;
  height: 22px;
  display: flex;
  align-items: center;
  span {
    font-size: ${({ theme }) => theme.fontSizes[10]};
    color: ${({ theme }) => theme.colors.fgTertiary};
    letter-spacing: 0.2px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
  }
`

export const DropdownMenuItemDescription = styled(RadixDropdown.Label)<{ $selectable?: boolean }>`
  margin: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[10]}`};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  line-height: 1rem;
  letter-spacing: 0.12px;
  white-space: pre-line;
  display: flex;
  ${({ $selectable }) => $selectable && css`
    cursor: pointer;
  `}
`

export const MenuItemTitle = styled.span<{ $layout?: ItemLayout }>`
  display: inline-flex;
  ${({ $layout, theme }) => $layout === 'compact'
    ? css`
        padding: 0;
        gap: ${theme.spacing[4]};
      `
    : css`
      padding: ${theme.spacing[0]} ${theme.spacing[4]};
      gap: ${theme.spacing[8]};
    `
  }
  align-items: center;
  flex: 1;
  overflow: hidden;
`

export const MenuItemInnerContent = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  justify-content: flex-start;
  flex: 1;
  min-width: 0;
`

export const StickyAction = styled.div<{ $isDisabled?: boolean }>`
  display: block;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: calc(32px + (0.375rem * 2));
  margin-top: 0.5rem;
  padding: 0.375rem 0;
  opacity: 1;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 2;
  ${({ $isDisabled }) => $isDisabled && css`
    cursor: not-allowed;
    * {
      cursor: not-allowed !important;
    }
  `}
`

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 3;
`
