import { useMutation, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { candidateJobStageDisplay, createCandidatesByIdentifiers } from 'src/libs/api/backend/candidate_jobs'
import type { CreateCandidatesByIdentifier, CreateCandidatesByIdentifierArgs } from 'src/libs/api/backend/candidate_jobs'
import { useSetAtom } from 'jotai'
import { notifyAtom, notifyErrorAtom } from 'src/stores/notifications'
import { Icons } from 'src/components/primitives/icon'
import { pluralize } from 'src/libs/pluralize'

interface Args extends CreateCandidatesByIdentifierArgs {
  onSuccess?: (data: CreateCandidatesByIdentifier) => void
  onError?: () => void
}

interface Res {
  createCandidatesByIdentifier: (args: Args) => void
}

export const useCreateCandidateByIdentifier = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const notify = useSetAtom(notifyAtom)

  const mutation = useMutation({
    mutationFn: async (args: Args) => await createCandidatesByIdentifiers(args),
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while creating your candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (data, { jobId, onSuccess, stage }) => {
      const { duplicatedIdentifiers, candidatesInActiveSequence, candidates } = data
      if (duplicatedIdentifiers.length > 0) {
        notify({
          type: 'toast',
          variant: 'tint',
          position: 'bottom-right',
          icon: Icons.info,
          message: `${pluralize(duplicatedIdentifiers.length, 'candidate')} already exists in this stage`
        })
      }
      const newCandidateJobs = candidates.length - duplicatedIdentifiers.length - candidatesInActiveSequence.length
      if (newCandidateJobs > 0) {
        notify({
          type: 'toast',
          variant: 'positive',
          position: 'bottom-right',
          icon: Icons.checkCheck,
          message: `${pluralize(newCandidateJobs, 'candidate')} added to ${candidateJobStageDisplay[stage]}`
        })
      }

      onSuccess?.(data)

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  const createCandidatesByIdentifier = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createCandidatesByIdentifier }
}
