import { isNil } from 'lodash'
import type { ReactNode } from 'react'
import { Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue, CustomRequirementCriteria, SchoolCompanyCriteria, LocationCriteria, MinMaxCriteria, JobTitleCriteria, BooleanCriteria, NameOptionalCriteria, StandardCriteria, DegreeMajorPairCriteria, SearchEntityCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaProperties } from '../constants'
import * as S from './index.styled'
import { MinMaxRow } from './min-max-row'
import { CustomRequirementRow } from './custom-criteria'
import { SchoolRow } from './school-row'
import { LocationRow } from './location-row'
import { CompanyRow } from './company-row'
import { JobRow } from './job-row'
import { BooleanRow } from './boolean-row'
import { LanguageRow } from './language-row'
import { StandardSelectionArrayRow } from './standard-selection-array-row'
import { DegreeMajorRow } from './degree-major-row'
import { CheckboxField } from './appendix'
import { Icons } from 'src/components/primitives/icon'
import { useSetAtom } from 'jotai'
import { removeUnstructuredCustomRequirementAtom, setUnstructuredCustomRequirementAtom } from 'src/stores/job-refinement'
import { SearchEntityRow } from './search-entity-row'
interface CriteriaRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: CriteriaValue | undefined
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey, index?: number) => void
  updated: boolean
  disabled: boolean
  onRowClick?: (criteriaKey: CriteriaKey) => void
  criteriaLimitations?: {
    blockedCriteria: string[] | null | undefined
    criteriaImprovementToReplace: string | null | undefined
  }
}

const isCriteriaBlocked = (blockedCriteria: string[], criteriaValue: string): boolean => {
  return blockedCriteria.some((blockedString: string) => blockedString === criteriaValue)
}

export const CriteriaRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  updated,
  disabled,
  onRowClick,
  criteriaLimitations
}: CriteriaRowProps): JSX.Element => {
  const removeUnstructuredCustomRequirement = useSetAtom(removeUnstructuredCustomRequirementAtom)
  const updateUnstructuredCustomRequirement = useSetAtom(setUnstructuredCustomRequirementAtom)
  const { type } = CriteriaProperties.get(criteriaKey) ?? {}
  let children: ReactNode | null = null
  if (!isNil(criteriaValue)) {
    if (Array.isArray(criteriaValue)) {
      if (type === 'job-title') {
        children = (
          <JobRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as JobTitleCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'language') {
        children = (
          <LanguageRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as NameOptionalCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'degree-major') {
        children = (
          <DegreeMajorRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as DegreeMajorPairCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'custom-requirement') {
        // Custom requirement row
        children = (
          <Flex $direction='column' $gap={8}>
            {criteriaValue.map((customCriteria, index) => {
              const originalValue = customCriteria as CustomRequirementCriteria
              return (
                <CustomRequirementRow
                  key={index}
                  criteriaValue={originalValue}
                  onUpdate={(key: string, value: number | string | boolean) => {
                    const updatedCriteriaValue = [...criteriaValue] as CustomRequirementCriteria[]
                    updatedCriteriaValue[index] = {
                      ...originalValue,
                      [key]: value
                    }
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                    if (key === 'requirement') {
                      // Only track if the custom field text is updated
                      updateUnstructuredCustomRequirement(index)
                    }
                  }}
                  onRemove={() => {
                    onCriteriaRemove(criteriaKey, index)
                    removeUnstructuredCustomRequirement(index)
                  }}
                  disabled={disabled}
                  errored={isCriteriaBlocked(criteriaLimitations?.blockedCriteria ?? [], originalValue.requirement)}
                  limitedResults={criteriaLimitations?.criteriaImprovementToReplace === originalValue.requirement}
                />
              )
            })}
          </Flex>
        )
      } else if (type === 'school') {
        // School/Company row
        children = (
          <SchoolRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as SchoolCompanyCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'company') {
        children = (
          <CompanyRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as SchoolCompanyCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'location') {
        children = (
          <LocationRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as LocationCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else if (type === 'entity-group') {
        children = (
          <SearchEntityRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as SearchEntityCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      } else {
        children = (
          <StandardSelectionArrayRow
            criteriaKey={criteriaKey}
            criteriaValue={criteriaValue as StandardCriteria[]}
            onCriteriaUpdate={onCriteriaUpdate}
            onCriteriaRemove={onCriteriaRemove}
            disabled={disabled}
          />
        )
      }
    } else if (type === 'boolean') {
      children = (
        <BooleanRow
          criteriaKey={criteriaKey}
          criteriaValue={criteriaValue as BooleanCriteria}
          onUpdate={onCriteriaUpdate}
          onRemove={() => {
            onCriteriaRemove(criteriaKey)
          }}
          disabled={disabled}
        />
      )
    } else if (type === 'min-max') {
      const value = criteriaValue as MinMaxCriteria
      children = (
        <Flex $direction='column' $gap={8}>
          <MinMaxRow
            criteriaKey={criteriaKey}
            criteriaValue={value}
            onUpdate={(value: Partial<MinMaxCriteria>) => {
              onCriteriaUpdate(criteriaKey, {
                ...criteriaValue,
                ...value
              })
            }}
            onRemove={() => {
              onCriteriaRemove(criteriaKey)
            }}
            disabled={disabled}
          />
          {criteriaKey === CriteriaKey.DEGREE && (
            <CheckboxField
              criteriaKey={criteriaKey}
              criteriaValue={value.equivalent_work_experience ?? false}
              label='or relevant experience in the field'
              onUpdate={(checked: boolean) => {
                onCriteriaUpdate(criteriaKey, {
                  ...criteriaValue,
                  equivalent_work_experience: checked
                })
              }}
              disabled={disabled}
              tooltip={{
                icon: Icons.helpCircle,
                text: 'Candidate has an equivalent work experience',
                position: 'right'
              }}
            />
          )}
        </Flex>
      )
    }
  }

  return (
    <S.RowWrapper>
      <S.CriteriaRowWrapper
        $updated={updated}
        onClick={() => {
          if (updated) {
            onRowClick?.(criteriaKey)
          }
        }}
      >
        <S.LabelContainer>
          <Caption size='XS' $fontWeight={400}>{CriteriaProperties.get(criteriaKey)?.label}</Caption>
        </S.LabelContainer>
        {children}
      </S.CriteriaRowWrapper>
    </S.RowWrapper>
  )
}
