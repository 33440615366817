import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icons, Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'

interface CriteriaSelectionProps {
  value: boolean
  onUpdate: (key: string, value: boolean) => void
  disabled: boolean
  fullWidth?: boolean
  type?: 'value' | 'optional'
}
export const CriteriaSelection = ({
  value,
  onUpdate,
  disabled,
  fullWidth = false,
  type = 'optional'
}: CriteriaSelectionProps): JSX.Element => {
  const options = useMemo((): MenuItemProps[] => {
    if (type === 'value') {
      return ([
        {
          id: 'yes',
          title: 'Yes',
          onSelect: () => {
            onUpdate('value', true)
          },
          variant: value ? 'tint' : 'neutral',
          trailingIcon: value ? Icons.check : undefined
        },
        {
          id: 'no',
          title: 'No',
          onSelect: () => {
            onUpdate('value', false)
          },
          variant: value ? 'neutral' : 'tint',
          trailingIcon: value ? undefined : Icons.check
        }
      ])
    }
    return ([
      {
        id: 'preferred',
        title: 'Preferred',
        icon: Icons.pinOff,
        size: 'small',
        variant: value ? 'tint' : 'neutral',
        onSelect: () => {
          onUpdate('optional', true)
        },
        trailingIcon: value ? Icons.check : undefined
      },
      {
        id: 'required',
        title: 'Required',
        icon: Icons.pin,
        size: 'small',
        variant: value ? 'neutral' : 'tint',
        onSelect: () => {
          onUpdate('optional', false)
        },
        trailingIcon: value ? undefined : Icons.check
      }
    ])
  }, [onUpdate, type, value])
  const valueLabel = useMemo((): { label: string, icon?: IconName, iconColor?: string } => {
    if (type === 'value') {
      return { label: value ? 'Yes' : 'No' }
    }
    return {
      label: value ? 'Preferred' : 'Required',
      icon: value ? Icons.pinOff : Icons.pin,
      iconColor: value ? 'fgTertiary' : 'tintBg'
    }
  }, [value, type])

  const selectedValue = useMemo((): string => {
    if (type === 'value') {
      return value ? 'yes' : 'no'
    }
    return value ? 'preferred' : 'required'
  }, [value, type])

  return (
    <Dropdown
      $minWidth='140px'
      $menuWidth={fullWidth ? 'full' : 'auto'}
      triggerFullWidth={fullWidth}
      size='small'
      disabled={disabled}
      fontWeight={400}
      layout='compact'
      selectedValue={selectedValue}
      trigger={
        <Button
          nested
          $variant='outline'
          $width={fullWidth ? 'full' : 100}
          $height={32}
          $fontSize={12}
          $fontWeight={400}
          trailingIcon={Icons.chevronsUpDownSmall}
          $align='space-between'
          disabled={disabled}
          leadingIcon={valueLabel.icon
            ? <Icon name={valueLabel.icon} color={valueLabel.iconColor} size={12} />
            : undefined
          }
        >
          {valueLabel.label}
        </Button>
      }
      items={options}
    />
  )
}
