import { useAtomValue, useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchRefinementForm } from 'src/components/blocks/search-refinement-form'
import type { FilterType } from 'src/components/blocks/search-refinement-form'
import * as Dialog from 'src/components/primitives/dialog'
import { useCreateJobSearchRefinement } from 'src/hooks/mutations/use-create-job-search-refinement'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import { updateApplicantsRefinements, updateListJobSearchRefinements } from 'src/hooks/queries/use-job-search-refinement'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { JobSearchRefinementType, JobSourcingState } from 'src/libs/api/backend/jobs'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import RouteBuilder from 'src/libs/route-builder'
import { controlDialogAtom, DialogId, isDialogOpenAtom, openAlertAtom } from 'src/stores/dialogs'
import { resetUnstructuredCustomRequirementsAtom } from 'src/stores/job-refinement'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'

export interface SearchCriteriaDialogProps {
  searchRefinement?: JobSearchRefinement
  job: Job
  initialCriteria?: Criteria
  filterType: FilterType
  isErrored?: boolean
}

export const SearchCriteriaDialog = ({
  searchRefinement,
  job,
  initialCriteria,
  filterType,
  isErrored = false
}: SearchCriteriaDialogProps): JSX.Element => {
  const navigate = useNavigate()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const { createJobSearchRefinement } = useCreateJobSearchRefinement()

  const [isDirty, setIsDirty] = useState(false)
  const openAlert = useSetAtom(openAlertAtom)
  const resetUnstructuredCustomRequirements = useSetAtom(resetUnstructuredCustomRequirementsAtom)
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.SEARCH_CRITERIA), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const [isRecommending, setIsRecommending] = useState(false)
  const newSearchRefinement = useMemo(() => {
    return isNil(searchRefinement)
  }, [searchRefinement])

  const closeDialog = useCallback(() => {
    setIsRecommending(false)
    controlDialog({
      id: DialogId.SEARCH_CRITERIA,
      newState: false
    })
  }, [controlDialog])

  const onSubmit = useCallback((criteria: Criteria) => {
    setIsRecommending(true)
    if (filterType === 'searchRefinement') {
      if (searchRefinement) {
        updateListJobSearchRefinements({
          jobId: job.id,
          jobSearchRefinementId: searchRefinement.id,
          jobSearchRefinement: {
            sourcingState: JobSourcingState.REQUESTED,
            lastSourcingRequestedAt: new Date()
          }
        })
        updateJobSearchRefinement({
          jobId: job.id,
          jobSearchRefinementId: searchRefinement.id,
          automateAutoApproveCandidates: searchRefinement.automateAutoApproveCandidates ?? false,
          searchCriteria: criteria,
          onSuccess: () => {
            closeDialog()
            // invalidateCandidateJobs()
          }
        })
      } else {
        createJobSearchRefinement({
          jobId: job.id,
          searchCriteria: criteria,
          type: JobSearchRefinementType.SEARCH_REFINEMENT,
          onSuccess: (jobSearchRefinement) => {
            navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId: job.id, jobSearchRefinementId: jobSearchRefinement.id }))
            closeDialog()
          }
        })
      }
    } else {
      if (searchRefinement?.id) {
        updateApplicantsRefinements({
          jobId: job.id,
          applicantsRefinementId: searchRefinement.id,
          jobSearchRefinement: {
            sourcingState: JobSourcingState.REQUESTED,
            lastSourcingRequestedAt: new Date()
          }
        })
        updateJobSearchRefinement({
          jobId: job.id,
          jobSearchRefinementId: searchRefinement.id,
          searchCriteria: criteria,
          onSuccess: () => {
            closeDialog()
          }
        })
      } else {
        createJobSearchRefinement({
          jobId: job.id,
          searchCriteria: criteria,
          type: JobSearchRefinementType.APPLICANTS,
          onSuccess: (applicantsRefinement) => {
            navigate(RouteBuilder.build('JOBS_APPLICANTS', { jobId: job.id, applicantsRefinementId: applicantsRefinement.id }))
            closeDialog()
          }
        })
      }
    }
  }, [closeDialog, createJobSearchRefinement, filterType, job.id, navigate, searchRefinement, updateJobSearchRefinement])

  const resetState = useCallback(() => {
    setIsDirty(false)
    resetUnstructuredCustomRequirements()
    controlDialog({
      id: DialogId.SEARCH_CRITERIA,
      newState: false
    })
  }, [controlDialog, resetUnstructuredCustomRequirements])

  const triggerClosed = useCallback(() => {
    if (isDirty) {
      openAlert({
        message: 'You have unsaved changes',
        description: 'Closing the criteria options will erase your unsaved changes.',
        cancelText: 'Keep Editing',
        confirmText: 'Discard Changes',
        isModal: false,
        onConfirm: () => {
          resetState()
        }
      })
    } else {
      resetState()
    }
  }, [isDirty, openAlert, resetState])

  return (
    <Dialog.Root
      $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
      $width={CANDIDATES_PAGES_MAX_WIDTH}
      id='SearchCriteriaDialog'
      isOpen={isDialogOpen}
      $innerPadding={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
      onOpenChange={(isOpen: boolean) => {
        if (!isOpen) {
          triggerClosed()
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Content>
          <SearchRefinementForm
            job={job}
            initialCriteria={initialCriteria}
            isRecommending={isRecommending}
            onSubmit={onSubmit}
            searchRefinement={searchRefinement}
            isNewRefinement={newSearchRefinement}
            setIsDirty={setIsDirty}
            isDirty={isDirty}
            triggerClose={triggerClosed}
            isErrored={isErrored}
            filterType={filterType}
            $maxHeight='95vh'
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
