import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { deleteJobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useNavigate, useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import RouteBuilder from 'src/libs/route-builder'
import { isNil } from 'lodash'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  jobSearchRefinementId: JobSearchRefinement['id']
}

interface Res {
  deleteSearchRefinement: ({ jobSearchRefinementId }: Args) => void
}

export const useDeleteJobSearchRefinement = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const { jobId } = useParams()

  if (!jobId) {
    throw new Error('Job ID is required')
  }

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: async ({ jobSearchRefinementId }: Args) => {
      await deleteJobSearchRefinement({ jobId, jobSearchRefinementId })
    },
    onMutate: async ({ jobSearchRefinementId }: Args) => {
      const queryData = queryClient.getQueriesData<JobSearchRefinement[]>({
        queryKey: [queryKeys.jobSearchRefinements, jobId]
      })
      const existingRefinements = queryData.flatMap(([_, data]) => data ?? [])
      queryClient.setQueriesData<JobSearchRefinement[]>({
        queryKey: [queryKeys.jobSearchRefinements, jobId]
      }, (prev) => {
        if (isNil(prev)) {
          return prev
        }
        return prev.filter((refinement) => refinement.id !== jobSearchRefinementId)
      })

      const getTargetId = (): string | null => {
        const index = existingRefinements.findIndex(
          (refinement) => refinement.id === jobSearchRefinementId
        )
        return index !== -1 && index + 1 < existingRefinements.length
          ? existingRefinements[index + 1].id
          : null
      }

      const targetId = getTargetId()
      if (!isNil(targetId)) {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', {
          jobId,
          jobSearchRefinementId: targetId
        }))
      } else {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_MANUAL', { jobId }))
      }
    },
    onSuccess: () => {
      notifySuccess({
        message: 'Search was deleted successfully.'
      })
    },
    onError: (err, { jobSearchRefinementId }: Args) => {
      notifyError({
        message: `An error occurred when deleting your search: ${err.message}`
      })
      navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING', {
        jobId,
        jobSearchRefinementId
      }))
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.jobSearchRefinements, jobId] })
    }
  })

  const deleteSearchRefinement = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteSearchRefinement }
}
