import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { fetchAtsIntegrationStatus } from 'src/libs/api/backend/merge'
import type { AtsIntegrationStatus, IntegrationStatus } from 'src/libs/api/backend/merge'
import { queryKeys } from 'src/libs/query-keys'

const isAtsIngesting = (status: IntegrationStatus | undefined): boolean => {
  return status?.finishedInitialSync === false
}

export const useAtsIntegrationStatus = (orgId: string | undefined): {
  isPending: boolean
  isImporting: boolean
  isJobImporting: boolean
  data: Awaited<AtsIntegrationStatus> | undefined
} => {
  const { data, isPending } = useQuery({
    queryKey: [queryKeys.atsIntegrationStatus, orgId],
    queryFn: async () => await fetchAtsIntegrationStatus(orgId ?? ''),
    staleTime: 60 * 1000,
    enabled: !isNil(orgId)
  })

  const isImporting =
    isAtsIngesting(data?.application) ||
    isAtsIngesting(data?.attachment) ||
    isAtsIngesting(data?.candidate) ||
    isAtsIngesting(data?.job) ||
    isAtsIngesting(data?.jobInterviewStage)

  return {
    isPending,
    isImporting,
    isJobImporting: isAtsIngesting(data?.job),
    data
  }
}
