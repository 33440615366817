import type { Color } from 'src/styles/theme/types'
import styled from 'styled-components'

export const ConnectedAccount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02); */
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
`

// Move the ConnectedAccount padding and border properties to a top half and a bottom half
export const ConnectedAccountTopHalf = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;
  background: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.75rem 0.75rem 0.5rem 1rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  gap: 0.5rem;
`

export const ConnectedAccountPermissions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
  padding: 0.75rem 0.75rem 0.75rem 2.25rem;
  gap: 0.5rem;
`

export const ConnectedAccountInUse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 2.25rem;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const JobsListTrigger = styled.button`
  all: unset;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  transform: translateY(-0.02rem);
`

export const ConnectedAccountEmailSignature = styled.div<{ $hasTopBorder: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1rem 1rem 2.25rem;
  border-top: ${({ $hasTopBorder }) => $hasTopBorder ? 'solid 1px' : 'none'};
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const StatusIndicator = styled.div<{ $color: Color }>`
  margin-top: 1px;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 999px;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
`

export const EmailAccountGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  row-gap: 0.75rem;
  column-gap: 1rem;
  align-items: center;
`

export const ConnectedAccountsEmailAliases = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0.75rem 1rem 0.75rem 2.25rem;
  border-bottom: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const EmailAliasBadge = styled.div`
  padding: 4px 6px;
  border-radius: 4px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  font-size: ${({ theme }) => theme.fontSizes['12']};
  font-weight: ${({ theme }) => theme.fontWeights['500']};
  line-height: 1;
  color: ${({ theme }) => theme.colors.fgPrimary};
`
