import { Button } from 'src/components/primitives/button'
import { Icons } from 'src/components/primitives/icon'
import { Flex } from 'src/components/primitives'
import { ReadyToSendCard } from '../ready-to-send-card'
import { useMemo, useState } from 'react'
import { CandidatePhoneNumbersList } from '../candidate-phone-numbers-list'
import { useInvalidateCandidateAfterPhoneLookup } from 'src/hooks/use-invalidate-candidate-after-phone-lookup'
import { useAtomValue, useSetAtom } from 'jotai'
import { candidateDetailsChannelAtom } from 'src/stores/websocket-channels'
import * as S from './ready-to-do-manual-task.styled'
import { CandidatePhoneNumberLookupStage } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { When } from '../when'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { Caption } from 'src/components/primitives/typography'
import { PhoneNumberLookupButton } from '../phone-number-lookup-button'
import type { ManualQueueItem } from 'src/libs/api/backend/manual_queue'
import type { ManualTaskExpanded } from 'src/libs/api/backend/manual_task'
import { useMarkManualTaskCompleted } from 'src/hooks/mutations/use-mark-manual-task-completed'
import { InboxCommentEditor } from '../inbox-comment-editor'
import { deserializeEditorContent } from '../editor/serializers'
import { Editor } from '../editor'
import { Box } from 'src/components/primitives/box'
import { useSession } from 'src/hooks/use-session'
import { useSkipManualTask } from 'src/hooks/mutations/use-skip-manual-task'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { convert as convertHtmlToText } from 'html-to-text'

interface ReadyToDoManualTaskProps {
  manualQueueItem: ManualQueueItem
}

export const ReadyToDoManualTask = ({
  manualQueueItem
}: ReadyToDoManualTaskProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const { candidateJob } = manualQueueItem as unknown as ManualTaskExpanded
  const [lookingUpPhoneNumber, setLookingUpPhoneNumber] = useState<boolean | undefined>(undefined)
  const [candidateNote, setCandidateNote] = useState<string>('')

  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)

  const openDialog = useSetAtom(openDialogAtom)
  const { markManualTaskCompleted, isPending } = useMarkManualTaskCompleted()
  const { skipManualTask, isPending: skipManualTaskPending } = useSkipManualTask()

  useInvalidateCandidateAfterPhoneLookup({
    candidateDetailsChannel,
    candidateId: candidateJob.candidate.id,
    candidateJobId: candidateJob.id,
    setInProgress: setLookingUpPhoneNumber
  })

  const hasPhoneNumber = useMemo(() => {
    return (
      !!lookingUpPhoneNumber ||
      candidateJob?.candidate.phoneNumberLookupStage === CandidatePhoneNumberLookupStage.NOT_FOUND ||
      (!isNil(candidateJob?.candidate.phoneNumbers) && candidateJob?.candidate.phoneNumbers.length > 0)
    )
  }, [lookingUpPhoneNumber, candidateJob?.candidate.phoneNumberLookupStage, candidateJob?.candidate.phoneNumbers])

  const hasEmailAddress = useMemo(() => {
    return !isNil(candidateJob.candidate.emails) && candidateJob.candidate.emails.length > 0
  }, [candidateJob.candidate.emails])

  return (
    <ReadyToSendCard
      candidateJob={candidateJob}
    >
      <S.CandidateContactDetails>
        <When condition={hasEmailAddress}>
          <Button $variant="outline" $colorTheme="muted" leadingIcon={Icons.mail} $fontSize={12} $height={24}>
            {candidateJob.candidate.emails?.[0]}
          </Button>
        </When>
        <When condition={!hasEmailAddress}>
          <Button
            $variant="outline"
            $colorTheme="muted"
            leadingIcon={Icons.mailPlus}
            $fontSize={12}
            $height={24}
            onClick={() => {
              openDialog({ id: DialogId.EDIT_CANDIDATE, payload: candidateJob })
            }}
            disabled={userHasViewerRole}
            tooltip={{
              text: userHasViewerRole ? viewerRoleNotice('add email addresses') : undefined,
              position: 'top'
            }}
          >
            Add email address
          </Button>
        </When>
        <PhoneNumberLookupButton
          candidateJob={candidateJob}
          inProgress={lookingUpPhoneNumber ?? false}
          setInProgress={setLookingUpPhoneNumber}
        />
        {hasPhoneNumber && (
          <CandidatePhoneNumbersList
            variant="BUTTON"
            candidateJob={candidateJob}
            isLookingUpPhoneNumbers={lookingUpPhoneNumber ?? false}
            textMessageBody={convertHtmlToText(manualQueueItem.body ?? '', {
              wordwrap: false,
              selectors: [
                { selector: 'div', options: { leadingLineBreaks: 0, trailingLineBreaks: 0 } }
              ],
              preserveNewlines: true,
              whitespaceCharacters: ' \t\r\n'
            })
              .replace(/\n{3,}/g, '\n\n') // Avoid multiple line breaks
              .replace(/\n+$/g, '') // Avoid line breaks at the end
              .replace(/\n/g, '%0A')
              .replace(/\s+/g, ' ')
              .trim()}
          />
        )}
      </S.CandidateContactDetails>
      <S.ReadyToDoManualTaskTitle>
        <Caption size='XS' $color='fgSecondary' $fontWeight={400}>Task</Caption>
        <span>{manualQueueItem.subject ?? ''}</span>
      </S.ReadyToDoManualTaskTitle>
      <Box $width='100%' $height='100%'>
        <Editor
          forceEditorFocus={false}
          isEditable={false}
          $editorHeight='fit-content'
          contentType='html'
          onDataChanged={(_: string) => { }}
          content={deserializeEditorContent(manualQueueItem.body ?? '')}
        />
      </Box>
      <S.CommentEditor>
        <S.CommentEditorInner>
          <S.CommentEditorHeader>
            <Caption size="2XS" $color="fgTertiary" $transform="uppercase">Candidate Comment</Caption>
          </S.CommentEditorHeader>
          <InboxCommentEditor
            forceEditorFocus={false}
            variant="GHOST"
            actions={false}
            onDataChanged={(data) => { setCandidateNote(data) }}
          />
        </S.CommentEditorInner>
      </S.CommentEditor>
      <S.Actions>
        <Flex $gap={12} $align="center" $width="auto">
          <Button
            $fontSize={12}
            $height={24}
            $variant="raised"
            $colorTheme="tint"
            disabled={isPending || userHasViewerRole}
            onClick={() => {
              markManualTaskCompleted({
                jobId: candidateJob.jobId,
                manualTaskId: manualQueueItem.id,
                noteBody: candidateNote
              })
            }}
            leadingIcon={Icons.checkCircle}
            tooltip={{
              text: userHasViewerRole ? viewerRoleNotice('mark as completed') : undefined,
              position: 'top'
            }}
          >
            Mark completed
          </Button>
          <Button
            $fontSize={12}
            $height={24}
            $variant="raised"
            $colorTheme="positive"
            disabled={isPending || userHasViewerRole}
            onClick={() => {
              markManualTaskCompleted({
                jobId: candidateJob.jobId,
                manualTaskId: manualQueueItem.id,
                noteBody: candidateNote,
                responded: true
              })
            }}
            leadingIcon={Icons.checkCircle}
            tooltip={{
              text: userHasViewerRole ? viewerRoleNotice('mark as reached') : undefined,
              position: 'top'
            }}
          >
            Candidate reached
          </Button>
          {/* {
            hasPhoneNumber && !lookingUpPhoneNumber && (
              <Button
                $fontSize={12}
                $height={24}
                $variant="raised"
                $colorTheme="tint"
                href={`sms:${candidateJob.candidate.phoneNumbers?.[0]}&body=Hello%20there`}
                disabled={lookingUpPhoneNumber && !candidateJob.candidate.phoneNumbers?.length}
              >
                Send Text Message
              </Button>
            )
          } */}
        </Flex>
        <Button
          $fontSize={12}
          $height={24}
          $width={92}
          $variant="ghost"
          $colorTheme="muted"
          loading={skipManualTaskPending}
          onClick={() => {
            if (!isNil(candidateJob.candidateSequence)) {
              const currentStep = (candidateJob.candidateSequence?.latestStep ?? -1) + 1
              skipManualTask({
                candidateSequenceId: candidateJob.candidateSequence.id,
                position: currentStep,
                manualTaskId: manualQueueItem.id
              })
            }
          }}
          trailingIcon={Icons.skipForward}
        >
          Skip step
        </Button>
      </S.Actions>
    </ReadyToSendCard>
  )
}
