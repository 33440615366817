import styled, { css } from 'styled-components'

export const Hours = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`
