import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import type { PipelinePositionActivities } from 'src/libs/api/backend/reports_pipeline_insights'
import { Table } from 'src/components/primitives/table'
import { Flex } from 'src/components/primitives/flex'
import { Link } from 'src/components/primitives/typography'
import { TableCellContentWithTooltip, TableReportNumberCell } from 'src/pages/job/reports/report-components'
import { DefaultTableHeader } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import { useMemo, useState } from 'react'
import { DepartmentLogo } from 'src/components/blocks/department-logo'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useLocation } from 'react-router-dom'
import { Button } from 'src/components/primitives/button'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer'
import { useWindowSize } from 'usehooks-ts'
import RouteBuilder from 'src/libs/route-builder'

interface PositionActivityChartProps {
  data: PipelinePositionActivities[]
}

const calculatePercentage = (value: number, baseValue: number): number => {
  return baseValue ? Number((value / baseValue).toFixed(2)) : 0
}

interface PositionActivityTableData {
  id: string
  jobTitle: string
  departmentId: string | null | undefined
  reviewed: number
  pastReviewed?: number
  accepted: number
  pastAccepted?: number
  outreached: number
  pastOutreached?: number
  read: number
  pastRead?: number
  replies: number
  pastReplies?: number
  interested: number
  pastInterested?: number
  scheduled: number
  pastScheduled?: number
}

type SortablePositionActivityColumns = 'reviewed' | 'accepted' | 'outreached' | 'read' | 'replies' | 'interested' | 'scheduled'

const sortByPositionActivityColumn = (field: SortablePositionActivityColumns) => {
  return (rowA: PositionActivityTableData, rowB: PositionActivityTableData) => {
    const valueA = rowA[field] ?? 0
    const valueB = rowB[field] ?? 0
    return valueA - valueB
  }
}

export const PositionActivityChart = ({ data }: PositionActivityChartProps): JSX.Element => {
  const { data: currentJob } = useJobQuery()
  const departmentId = currentJob?.departmentId
  const { pathname } = useLocation()
  const isAllPositionsReport = pathname.includes('all/reports')
  const [showAllActivities, setShowAllActivities] = useState(false)
  const { width } = useWindowSize()

  const tableData = useMemo((): PositionActivityTableData[] => {
    return data.map((position) => ({
      id: position.jobId,
      jobTitle: position.jobTitle,
      departmentId: position.departmentId,
      reviewed: position.reviewed,
      pastReviewed: position.pastReviewed,
      accepted: position.accepted,
      pastAccepted: position.pastAccepted,
      outreached: position.outreached,
      pastOutreached: position.pastOutreached,
      read: position.read,
      pastRead: position.pastRead,
      replies: position.replies,
      pastReplies: position.pastReplies,
      interested: position.interested,
      pastInterested: position.pastInterested,
      scheduled: position.scheduled,
      pastScheduled: position.pastScheduled
    }))
  }, [data])

  const totals = useMemo(() => {
    return tableData.reduce((acc, curr) => ({
      reviewed: acc.reviewed + curr.reviewed,
      accepted: acc.accepted + curr.accepted,
      outreached: acc.outreached + curr.outreached,
      read: acc.read + curr.read,
      replies: acc.replies + curr.replies,
      interested: acc.interested + curr.interested,
      scheduled: acc.scheduled + curr.scheduled
    }), {
      reviewed: 0,
      accepted: 0,
      outreached: 0,
      read: 0,
      replies: 0,
      interested: 0,
      scheduled: 0
    })
  }, [tableData])

  const columns: Array<TableColumnDef<PositionActivityTableData>> = useMemo(() => [
    {
      columnId: 'position',
      size: 'minmax(min-content, 3fr)',
      visible: true,
      header: <DefaultTableHeader title="Position" minHeight={40} />,
      sortingFn: (a: PositionActivityTableData, b: PositionActivityTableData) => {
        return a.jobTitle.localeCompare(b.jobTitle)
      },
      cell: (info: PositionActivityTableData) => {
        const href = RouteBuilder.build('JOBS_REPORTS_PIPELINE_INSIGHTS', { jobId: info.id })
        return (
        <Flex $gap={8} $align="center">
            <DepartmentLogo $size={24} departmentId={info.departmentId ?? ''} />
            <When condition={width < 1440}>
              <TableCellContentWithTooltip visibleContent={info.jobTitle} tooltipContent={info.jobTitle} href={href} />
            </When>
            <When condition={width >= 1441}>
              <Link size="XS" $whiteSpace="nowrap" href={href} $fontWeight={500}>{info.jobTitle}</Link>
            </When>
          </Flex>
        )
      }
    },
    {
      columnId: 'reviewed',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Reviewed" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('reviewed'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell value={info.reviewed} />
      )
    },
    {
      columnId: 'accepted',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Accepted" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('accepted'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.accepted}
          percentage={calculatePercentage(info.accepted, info.pastReviewed ?? 0)}
          valueChangeDirection={info.pastReviewed ? info.pastReviewed > info.accepted ? 'down' : 'up' : undefined}
        />
      )
    },
    {
      columnId: 'outreached',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Outreached" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('outreached'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.outreached}
          percentage={calculatePercentage(info.outreached, info.pastOutreached ?? 0)}
          valueChangeDirection={info.pastOutreached ? info.pastOutreached > info.outreached ? 'down' : 'up' : undefined}
        />
      )
    },
    {
      columnId: 'read',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Read" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('read'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.read}
          percentage={calculatePercentage(info.read, info.pastRead ?? 0)}
          valueChangeDirection={info.pastRead ? info.pastRead > info.read ? 'down' : 'up' : undefined}
        />
      )
    },
    {
      columnId: 'replies',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Replies" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('replies'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.replies}
          percentage={calculatePercentage(info.replies, info.pastReplies ?? 0)}
          valueChangeDirection={info.pastReplies ? info.pastReplies > info.replies ? 'down' : 'up' : undefined}
        />
      )
    },
    {
      columnId: 'interested',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Interested" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('interested'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.interested}
          percentage={calculatePercentage(info.interested, info.pastInterested ?? 0)}
          valueChangeDirection={info.pastInterested ? info.pastInterested > info.interested ? 'down' : 'up' : undefined}
        />
      )
    },
    {
      columnId: 'scheduled',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Scheduled" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByPositionActivityColumn('scheduled'),
      cell: (info: PositionActivityTableData) => (
        <TableReportNumberCell
          value={info.scheduled}
          percentage={calculatePercentage(info.scheduled, info.pastScheduled ?? 0)}
          valueChangeDirection={info.pastScheduled ? info.pastScheduled > info.scheduled ? 'down' : 'up' : undefined}
        />
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [departmentId, totals])

  return (
    <ChartWrapper>
      <ChartHeader title="Position Activity" />
      <Table<PositionActivityTableData>
        tableData={tableData}
        columns={columns}
        emptyState={<EmptyChart $minHeight='80px' />}
        visibleRowsCount={showAllActivities ? undefined : 5}
      />
      <When condition={isAllPositionsReport && !showAllActivities && tableData.length > 5}>
        <Spacer $size={12} />
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width="100%"
          $fontSize={12}
          onClick={() => {
            setShowAllActivities(!showAllActivities)
          }}
        >
          See All Positions
        </Button>
      </When>
    </ChartWrapper>
  )
}
