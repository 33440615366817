import { useSearchParams } from 'react-router-dom'
import { InboxPage } from 'src/components/pages/inbox-page'
import { SEO } from 'src/components/primitives/seo'
import { useAllCandidateJobsCommunicatingQuery } from 'src/hooks/queries/use-candidate-jobs'

const AllJobsInboxPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const { isPending, data: candidateJobsCommunicatingData } = useAllCandidateJobsCommunicatingQuery({
    lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined
  })
  return (
    <>
      <SEO title="All Jobs • Inbox" />
      <InboxPage isLoading={isPending} candidateJobs={candidateJobsCommunicatingData} />
    </>
  )
}

export default AllJobsInboxPage
