import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { EmptyState } from 'src/components/blocks/empty-state'
import { Spacer } from 'src/components/primitives/spacer'
import { Divider } from 'src/components/primitives/divider'
import { Caption } from 'src/components/primitives/typography'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { Flex } from 'src/components/primitives/flex'
import { useJobByDepartmentsQuery } from 'src/hooks/queries/use-jobs-by-department'
import { useMemo } from 'react'
import type { JobAndDepartment } from 'src/libs/api/backend/jobs'
import { JobTile } from 'src/components/blocks/job-tile'
import { CreateSequenceDialogView, LocalStorageKey } from 'src/constants'
import { CandidatesTablePagesContentInner } from 'src/pages/job/candidates/candidates.styled'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { useLocalStorage } from 'usehooks-ts'
import { sequenceTour } from 'src/libs/product-tour'
import { ProductTourStatus } from 'src/libs/api/backend/me'
import { TrackingEventType } from 'src/types/track'
import { trackEvent } from 'src/libs/track'
import { useUpdateUserTrackingAndStatuses } from 'src/hooks/mutations/use-update-user-tracking-and-statuses'

export const EmpySequenceState = (): JSX.Element => {
  const { jobId } = useParams()
  const openDialog = useSetAtom(openDialogAtom)
  const { data: jobs } = useJobByDepartmentsQuery()
  const { userHasViewerRole } = useSession()
  const [_, setAppIsReadyForSequenceTour] = useLocalStorage(LocalStorageKey.APP_IS_READY_FOR_SEQUENCE_TOUR, false)
  const { updateUserTrackingAndStatuses } = useUpdateUserTrackingAndStatuses()

  const flattenJobs = useMemo(() => {
    if (!jobs) {
      return []
    }
    const flattenedMap = Object.values(jobs).reduce<JobAndDepartment[]>((departmentAcc, department) => {
      const projectJobs = Object.values(department).reduce<JobAndDepartment[]>((projectAcc, project) => {
        return [...projectAcc, ...Object.values(project)]
      }, [])
      return [...departmentAcc, ...projectJobs]
    }, [])

    return flattenedMap.filter((job) => job.id !== jobId)
  }, [jobId, jobs])

  if (isNil(jobId)) {
    return <></>
  }

  const startSequenceTour = (): void => {
    setAppIsReadyForSequenceTour(true)
    setTimeout(() => {
      sequenceTour.drive()
      updateUserTrackingAndStatuses({ sequenceTourStatus: ProductTourStatus.STARTED })
      trackEvent(TrackingEventType.SEQUENCE_TOUR_STARTED)
    }, 1000)
  }

  return (
    <CandidatesTablePagesContentInner>
      <Spacer $size={20} />
      <EmptyState
        heading="Outreach"
        description="Create a sequence of emails and messages that will be send to candidates automatically, to confirm their interest with the position."
        svg="emailSequence2"
        $height="100%"
        actions={[
          {
            onClick: () => {
              startSequenceTour()
              openDialog({
                id: DialogId.CREATE_SEQUENCE,
                payload: {
                  defaultView: CreateSequenceDialogView.CREATE_WITH_AI,
                  showBackToOptionsLink: false
                }
              })
            },
            leadingIcon: 'sparkles-sm',
            $variant: 'fill',
            $colorTheme: 'tint',
            children: 'Generate Starting Point With AI',
            disabled: userHasViewerRole,
            tooltip: {
              text: userHasViewerRole ? viewerRoleNotice('generate an outreach sequence') : undefined,
              position: 'top'
            }
          },
          {
            onClick: () => {
              startSequenceTour()
              openDialog({
                id: DialogId.CREATE_SEQUENCE,
                payload: {
                  defaultView: CreateSequenceDialogView.CREATE_MANUALLY,
                  showBackToOptionsLink: false
                }
              })
            },
            leadingIcon: 'pencil',
            children: 'Write Manually',
            disabled: userHasViewerRole,
            tooltip: {
              text: userHasViewerRole ? viewerRoleNotice('generate an outreach sequence') : undefined,
              position: 'top'
            }
          }
        ]}
        content={
          flattenJobs.length > 0 && (
            <>
              <Divider $spacing={{ before: 64, after: 64 }} />
              <Caption size="SM" $color="fgSecondary">Copy sequence from previous job position</Caption>
              <Spacer $size={16} />
              <Flex $direction="column" $gap={12}>
                {flattenJobs.map((job) => (
                  <JobTile
                    id={job.id}
                    key={job.id}
                    job={job}
                    isActive={false}
                    title={job.title}
                    onClick={() => {
                      openDialog({
                        id: DialogId.CREATE_SEQUENCE,
                        payload: {
                          defaultView: CreateSequenceDialogView.CREATE_MANUALLY,
                          defaultJobIdToCopySequenceFrom: job.id,
                          showBackToOptionsLink: false
                        }
                      })
                    }}
                    onPreview={() => { }}
                    $variant="raised"
                  />
                ))}
              </Flex>
            </>
          )
        }
      />
    </CandidatesTablePagesContentInner>
  )
}
