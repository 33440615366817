import { PieChart as ReChartPieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { ChartLegend } from '../chart-legend'
import * as S from './pie-chart.styled'
import { When } from 'src/components/blocks/when'
import { EmptyChart } from '../empty-chart'
import { CHART_COLORS } from '../../base/constants'
import { isNil } from 'lodash'
import { useMemo } from 'react'

interface PieSegment {
  name: string
  value: number
  pastValue?: number
  valueChangeDirection?: 'up' | 'down'
}

interface PieChartProps {
  chartTitle: string
  data: PieSegment[]
}

const segmentColors = [
  { fill: CHART_COLORS.BLUE_MID },
  { fill: CHART_COLORS.GREEN_MINT },
  { fill: CHART_COLORS.GREEN_BRIGHT }
]

export const PieChart = ({ chartTitle, data }: PieChartProps): JSX.Element => {
  const legendData = useMemo(() => data.map((segment, index) => ({
    name: segment.name,
    color: segmentColors[index % segmentColors.length].fill,
    value: `${segment.value}%`,
    valueChange: !isNil(segment.pastValue)
      ? segment.value - segment.pastValue
      : undefined,
    valueChangeDirection: segment.valueChangeDirection
  })), [data])

  return (
    <S.PieChart>
      <When condition={!data.length}>
        <EmptyChart $minHeight="128px" />
      </When>
      <When condition={data.length >= 1}>
        <S.PieChartInner>
          <ResponsiveContainer width={128} height={128}>
            <ReChartPieChart
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              }}
            >
              <Pie
                dataKey="value"
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={64}
                fill={segmentColors[0].fill}
                label={false}
                startAngle={90}
                endAngle={-270}
              >
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={segmentColors[index % segmentColors.length].fill}
                  />
                ))}
              </Pie>
            </ReChartPieChart>
          </ResponsiveContainer>
        </S.PieChartInner>
        <ChartLegend
          title={chartTitle}
          titleHidden={true}
          data={legendData}
          $orientation="column"
        />
      </When>
    </S.PieChart>
  )
}
