import { Flex } from 'src/components/primitives/flex'
import * as S from './horizontal-funnel-chart.styled'
import { Heading, Paragraph } from 'src/components/primitives/typography'
import { useState } from 'react'
import { Icon } from 'src/components/primitives/icon'

interface DataPoint {
  label: string
  value: string
  valueChange?: string
  valueChangeDirection?: 'up' | 'down' | 'neutral'
}

interface Stage {
  dataPoints: DataPoint[]
}

interface FunnelChartProps {
  data: {
    total: number
    stages: Stage[]
  }
}

interface StageProps {
  stage: Stage
  data: FunnelChartProps['data']
  stageIndex: number
}

const ChartStageMain = ({ dataPoint, isEmpty }: { dataPoint: DataPoint, isEmpty: boolean }): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const handleMouseMove = (e: React.MouseEvent): void => {
    const rect = e.currentTarget.getBoundingClientRect()
    setMousePosition({
      x: e.clientX - rect.left + 10,
      y: e.clientY - rect.top + 10
    })
  }

  return (
    <S.StageMain
      onMouseEnter={() => { setShowTooltip(true) }}
      onMouseLeave={() => { setShowTooltip(false) }}
      onMouseMove={handleMouseMove}
      $isEmpty={isEmpty}
    >
      <S.StageBlockTooltip
        $show={showTooltip}
        style={{ transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)` }}
      >
        <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
          {dataPoint.label}
        </Paragraph>
        <Paragraph size="XS" $color="fgPrimary" $whiteSpace="nowrap">
          {dataPoint.value}
        </Paragraph>
      </S.StageBlockTooltip>
    </S.StageMain>
  )
}

const ChartStage = ({ stage, data, stageIndex }: StageProps): JSX.Element => {
  const firstValidStageIndex = data.stages.findIndex(s => Number(s.dataPoints[0].value) > 0)

  return (
    <S.Stage $columnIndex={stageIndex + 1}>
      {stage.dataPoints.map((dataPoint, dataPointIndex) => {
        const percentage = (Number(dataPoint.value) / data.total) * 100
        const isFirstValidStage = stageIndex === firstValidStageIndex
        const isEmptyStage = stageIndex < firstValidStageIndex
        const showEmptyBackground = isEmptyStage || (isFirstValidStage && dataPointIndex > 0)
        const showStage = !showEmptyBackground || dataPointIndex === 0
        const hideStageConnection = isEmptyStage || isFirstValidStage

        return showStage
          ? (
              <S.StageBlock
                key={dataPointIndex}
                $value={showEmptyBackground ? data.total.toString() : dataPoint.value}
                $total={data.total}
                $blockIndex={dataPointIndex + 1}
                $isEmpty={showEmptyBackground}
              >
                {stageIndex > 0 && (
                  <S.StageConnection $isEmpty={hideStageConnection}>
                    {percentage > 10 && (
                      <S.Badge $isEmpty={hideStageConnection}>
                        {Math.floor(percentage)}%
                      </S.Badge>
                    )}
                  </S.StageConnection>
                )}
                <ChartStageMain dataPoint={dataPoint} isEmpty={showEmptyBackground} />
              </S.StageBlock>
            )
          : null
      })}
    </S.Stage>
  )
}

export const HorizontalFunnelChart = ({ data }: FunnelChartProps): JSX.Element => {
  const hasPreviousPeriodData = data.stages[data.stages.length - 1].dataPoints.some(dataPoint => dataPoint.valueChange && dataPoint.valueChange !== '0%')

  return (
    <S.Wrapper $hasPreviousPeriodData={hasPreviousPeriodData}>
      <S.StageHeaders>
        {data.stages.map((stage, index) => (
          <S.StageHeader $isFirst={index === 0} key={index}>
            <Paragraph size="XS" $color="fgSecondary">
              {stage.dataPoints[0].label}
            </Paragraph>
            <Flex $direction="column" $gap={2}>
              <Heading size="MD" $color="fgPrimary">
                {Number(stage.dataPoints[0].value).toLocaleString()}
              </Heading>
              {stage.dataPoints[0].valueChange && stage.dataPoints[0].valueChange !== '0%' && (
                <Flex $align="center" $gap={2}>
                  <Paragraph
                    size="XS"
                    $color={
                      stage.dataPoints[0].valueChangeDirection === 'up'
                        ? 'positiveFg'
                        : stage.dataPoints[0].valueChangeDirection === 'down'
                          ? 'warningFg'
                          : 'fgSecondary'
                    }
                  >
                    {stage.dataPoints[0].valueChange}
                  </Paragraph>
                  <Icon
                    name={
                      stage.dataPoints[0].valueChangeDirection === 'up'
                        ? 'arrow-up'
                        : stage.dataPoints[0].valueChangeDirection === 'down'
                          ? 'arrow-down'
                          : 'arrow-right'
                    }
                    size={12}
                    color={
                      stage.dataPoints[0].valueChangeDirection === 'up'
                        ? 'positiveFg'
                        : stage.dataPoints[0].valueChangeDirection === 'down'
                          ? 'warningFg'
                          : 'fgSecondary'
                    }
                  />
                </Flex>
              )}
            </Flex>
          </S.StageHeader>
        ))}
      </S.StageHeaders>
      <S.Chart $total={data.total}>
        {data.stages.map((stage, stageIndex) => (
          <ChartStage key={stageIndex} stage={stage} data={data} stageIndex={stageIndex} />
        ))}
      </S.Chart>
    </S.Wrapper>
  )
}
