import { useCallback, useEffect, useRef, useState } from 'react'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import { useOnClickOutside } from 'usehooks-ts'
import * as S from './index.styled'
import { Flex } from 'src/components/primitives'
import { Avatar } from 'src/components/primitives/avatar'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import type { LocationSuggestion, MajorSuggestion, SchoolSuggestion } from 'src/libs/api/backend/typeaheads'
import { titleCase } from 'src/libs/string'
import * as RadixPopover from '@radix-ui/react-popover'
import { ComboboxProvider } from '@ariakit/react'
import { usePrintView } from 'src/hooks/use-print-view'
import { getSubtitle } from 'src/utils/refinement-criteria'
import { Icon, Icons } from 'src/components/primitives/icon'
import { isNil } from 'lodash'

interface SuggestionInputProps {
  triggerSearch: (query: string | undefined) => void
  suggestions: Array<SchoolSuggestion | CompanySuggestion | LocationSuggestion | MajorSuggestion | { name: string }>
  suggestionLabel?: string
  onSelect: (suggestion: SchoolSuggestion | CompanySuggestion | LocationSuggestion | MajorSuggestion) => void
  isLoading: boolean
  items?: React.ReactNode
  disabled: boolean
  placeholder?: string
  onKeyUp?: (input: string, callback: () => void) => void
  loadingNode?: React.ReactNode
}

export const SuggestionInput = ({
  triggerSearch,
  suggestions,
  suggestionLabel,
  onSelect,
  isLoading,
  items,
  disabled,
  placeholder = 'Add New',
  onKeyUp,
  loadingNode
}: SuggestionInputProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)
  const comboboxRef = useRef<HTMLInputElement>(null)
  const listboxRef = useRef<HTMLDivElement>(null)
  const isPrintView = usePrintView()
  const [addNewClicked, setAddNewClicked] = useState(isNil(items))

  const handleResetSearch = useCallback(() => {
    triggerSearch(undefined)
    setInputValue(undefined)
  }, [triggerSearch])

  useEffect(() => {
    setOpen(!isLoading && suggestions.length > 0 && !!inputValue)
  }, [inputValue, isLoading, suggestions.length])

  useOnClickOutside(comboboxRef, () => {
    if (!open) {
      handleResetSearch()
    }
  })

  useOnClickOutside(listboxRef, () => {
    if (open) {
      handleResetSearch()
    }
  })

  useEffect(() => {
    if (!disabled && !loadingNode && addNewClicked) {
      comboboxRef.current?.focus()
    }
  }, [disabled, loadingNode, addNewClicked])

  return (
    <S.CriteriaMultiValuesField
      $disabled={disabled}
    >
      {items}
      {loadingNode}
      {addNewClicked
        ? <RadixPopover.Root
            open={open}
            onOpenChange={setOpen}
          >
            <ComboboxProvider
              defaultValue=''
              open={open}
            >
              <RadixPopover.Anchor asChild>
                <S.Combobox
                  ref={comboboxRef}
                  tabIndex={0}
                  value={inputValue ?? ''}
                  onChange={(e) => {
                    const newValue = e.target.value
                    setInputValue(newValue)
                    triggerSearch(newValue)
                  }}
                  onBlur={() => {
                    setAddNewClicked(false)
                    setInputValue('')
                  }}
                  autoFocus={true}
                  autoSelect="always"
                  $isLoading={isLoading}
                  disabled={disabled || !!loadingNode}
                  placeholder={isPrintView ? undefined : placeholder}
                  onKeyUp={(e) => {
                    if (onKeyUp && e.key === 'Enter' && inputValue) {
                      e.preventDefault()
                      setInputValue('') // Clear the input but not the query YET
                      onKeyUp(inputValue, () => {
                        triggerSearch(undefined)
                      })
                      setAddNewClicked(false)
                    }
                  }}
                  data-1p-ignore
                />
              </RadixPopover.Anchor>
              <RadixPopover.Content
                asChild
                align="start"
                sideOffset={8}
                onOpenAutoFocus={(event) => {
                  event.preventDefault()
                }}
                onInteractOutside={(event) => {
                  const target = event.target as Element | null
                  const isCombobox = target === comboboxRef.current
                  const inListbox = target && listboxRef.current?.contains(target)
                  if (isCombobox || inListbox) {
                    event.preventDefault()
                  }
                }}
              >
                <S.Results ref={listboxRef}>
                  {suggestionLabel && (
                    <S.SuggestionLabel>
                      <Paragraph size="XS">{suggestionLabel}</Paragraph>
                    </S.SuggestionLabel>
                  )}
                  <S.ResultInner>
                    {suggestions.map((s, index) => {
                      let title = s.name ?? ''
                      const subtitle = getSubtitle(s)
                      if ('city' in s) {
                        title = [s.city, s.state, s.metro, s.country, s.continent].filter(Boolean).join(', ')
                      }
                      return (
                        <S.ResultItem
                          key={`${title}-${subtitle}-${index}`}
                          focusOnHover
                          value={s.name}
                          onClick={() => {
                            onSelect(s)
                            setInputValue('')
                            triggerSearch(undefined)
                            comboboxRef.current?.focus()
                          }}
                        >
                          <Flex $gap={8} $align="center">
                            {'logoUrl' in s &&
                              <Avatar
                                $size={24}
                                $type="logo"
                                initials={s.name}
                                company={{
                                  name: s.name,
                                  logoUrl: s.logoUrl
                                }}
                              />
                            }
                            <Caption size="XS" $color="fgPrimary" $whiteSpace="nowrap">
                              {titleCase(title)}
                            </Caption>
                            {subtitle && (
                              <Caption size="XS" $color="fgTertiary" $whiteSpace="nowrap" $fontWeight={400}>
                                {subtitle}
                              </Caption>
                            )}
                          </Flex>
                        </S.ResultItem>
                      )
                    })}
                  </S.ResultInner>
                </S.Results>
              </RadixPopover.Content>
            </ComboboxProvider>
          </RadixPopover.Root>
        : <S.AddNewButton
            disabled={disabled}
            data-state={!items ? 'open' : 'closed'}
            onClick={() => {
              setAddNewClicked(true)
            }}
          >
            <Icon name={Icons.plus} size={12} color='positiveBg' />
            Add New
          </S.AddNewButton>
      }
    </S.CriteriaMultiValuesField>
  )
}
