import * as S from './index.styled'
import { SearchEntityType, TimeScope } from 'src/libs/api/backend/candidate_search'
import type { CriteriaKey, CriteriaValue, SearchEntityCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'

interface SearchEntityRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: SearchEntityCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const SearchEntityRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: SearchEntityRowProps): JSX.Element => {
  const [_, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])

  const [entityIcons, setEntityIcons] = useState<Record<string, string>>({})

  const items = useMemo(() => {
    if (criteriaValue.length === 0) {
      return undefined
    }
    return criteriaValue.map((entityValue, index) => {
      return (
        <CriteriaPill
          key={index}
          value={{
            label: entityValue.name ?? '',
            icon: entityValue.logoUrl
              ? entityValue.logoUrl
              : entityValue.name
                ? entityIcons[entityValue.name]
                : undefined,
            subtitle: entityValue.time_scope === TimeScope.CURRENT
              ? 'Current'
              : entityValue.time_scope === TimeScope.PREVIOUS
                ? 'Past'
                : undefined,
            timeScope: entityValue.time_scope,
            optional: entityValue.optional ?? true,
            negative: entityValue.negative ?? false
          }}
          onUpdate={(key: string, value: CriteriaPillValue) => {
            const updatedCriteriaValue = [...criteriaValue]
            updatedCriteriaValue[index] = {
              ...updatedCriteriaValue[index],
              [key]: value
            }
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
          onRemove={() => {
            const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
        />
      )
    })
  }, [criteriaKey, criteriaValue, entityIcons, onCriteriaUpdate])
  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={false}
        disabled={disabled}
        items={items}
        triggerSearch={setQueryDebounced}
        suggestions={[]}
        onSelect={(suggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              name: suggestion.name,
              linkedin: (suggestion as CompanySuggestion).linkedin ?? undefined,
              domain: (suggestion as CompanySuggestion).website ?? undefined,
              logoUrl: (suggestion as CompanySuggestion).logoUrl ?? undefined,
              optional: false,
              negative: false
            }]
          )
          if ('logoUrl' in suggestion && suggestion.logoUrl) {
            setEntityIcons({
              ...entityIcons,
              [suggestion.name]: suggestion.logoUrl
            })
          }
        }}
        onKeyUp={(input) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              name: input,
              linkedin: undefined,
              domain: undefined,
              logoUrl: undefined,
              optional: false,
              negative: false,
              time_scope: TimeScope.ANY,
              type: SearchEntityType.INDIVIDUAL
            }]
          )
        }}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
