import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FileDropUpload } from 'src/components/forms/file-drop-upload'
import { useForm } from 'src/hooks/use-form'
import { validateLinkedInUrl } from 'src/libs/validations'
import { z } from 'zod'
import { CsvSummary } from './csv-summary'
import { Button, Flex, VisualDivider } from 'src/components/primitives'
import { Form, Textarea } from 'src/components/forms'
import * as S from './add-candidate-form.styled'
import { useValidateCandidatesCsv } from 'src/hooks/mutations/use-validate-candidates-csv'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import type { AddCandidatesState } from './type'
import { REGEX_LINKEDIN_URL } from 'src/libs/regex'
import { useSession } from 'src/hooks/use-session'
import { pluralize } from 'src/libs/pluralize'
import { Spinner } from 'src/components/primitives/spinner'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'

const candidateSchema = z.object({
  candidateUniqueIds: z.string().nullish()
})

export type CandidateSchema = z.infer<typeof candidateSchema>

const validatingCandidateIdentifiers = (candidateUniqueIds: string): {
  linkedins: string[]
  errors: string[]
} | null => {
  const linkedins: string[] = []
  const errors: string[] = []
  const trimmed = candidateUniqueIds.trim()
  if (!trimmed) {
    return null
  }
  trimmed.split(/[\s,\n]+/).forEach((url) => {
    const trimmedId = url.trim().replace(/\/$/, '')
    if (REGEX_LINKEDIN_URL.test(trimmedId)) {
      const validatedLinkedin = validateLinkedInUrl(trimmedId)
      if (validatedLinkedin) {
        linkedins.push(validatedLinkedin)
      } else {
        errors.push(trimmedId)
      }
    } else {
      errors.push(trimmedId)
    }
  })
  return {
    linkedins,
    errors
  }
}

interface AddCandidateListProps {
  jobId: string
  callback: () => void
  state: AddCandidatesState
  setState: (state: AddCandidatesState) => void
}

export const AddCandidateList = ({
  jobId,
  callback,
  state,
  setState
}: AddCandidateListProps): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const closeDialog = useSetAtom(closeDialogAtom)
  const { userHasViewerRole } = useSession()
  const [idInputErrors, setIdInputErrors] = useState<string[]>([])
  const [analyzingCsv, setAnalyzingCsv] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { validateCandidatesFromCsv } = useValidateCandidatesCsv()

  const { register, submit } = useForm<CandidateSchema>({
    schema: candidateSchema,
    initialValues: {
      candidateUniqueIds: null
    }
  })

  const onTextAreaUpdate = useCallback(() => {
    const currentValue = inputRef.current?.value ?? ''
    const validatedCandidates = validatingCandidateIdentifiers(currentValue)
    if (isNil(validatedCandidates)) {
      setIdInputErrors([])
      setState({
        ...state,
        validatedCandidateIdentifiers: null
      })
      return
    }
    const { linkedins, errors } = validatedCandidates
    setIdInputErrors(errors)
    setState({
      ...state,
      validatedCandidateIdentifiers: {
        linkedins
      }
    })
  }, [setState, state])

  const handleSubmit = useCallback(async (): Promise<void> => {
    setIsSubmitting(true)
    callback()
    setIsSubmitting(false)
  }, [callback])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const uploadingCsvFile = useMemo(() => {
    return !!state.uploadedFileS3Url || analyzingCsv
  }, [analyzingCsv, state.uploadedFileS3Url])

  const containsCandidateIdentifiers = useMemo(() => {
    return !!state.validatedCandidateIdentifiers
  }, [state.validatedCandidateIdentifiers])

  const numberOfValidCandidates = useMemo(() => {
    if (state.uploadedFileS3Url) {
      return state.validationResult?.validRows.length ?? 0
    }
    if (state.validatedCandidateIdentifiers) {
      return state.validatedCandidateIdentifiers.linkedins.length
    }
    return 0
  }, [state.uploadedFileS3Url, state.validatedCandidateIdentifiers, state.validationResult?.validRows.length])

  const disabledSubmit = useMemo(() => {
    return (
      !!userHasViewerRole ||
      (!state.validationResult && !state.validatedCandidateIdentifiers) ||
      isSubmitting ||
      analyzingCsv ||
      !!(state.validationResult?.validationError)
    )
  }, [userHasViewerRole, state.validationResult, state.validatedCandidateIdentifiers, isSubmitting, analyzingCsv])

  const resetFileUpload = useCallback(() => {
    setState({
      ...state,
      validationResult: null,
      uploadedFileS3Url: null
    })
  }, [setState, state])

  const uploadedFileComponent = useMemo(() => {
    const uploadButton = (
      <Button
        $variant="ghost"
        $colorTheme="muted"
        leadingIcon={Icons.uploadCloud}
        $height={16}
        $width={16}
        $borderRadius={4}
        onClick={() => {
          resetFileUpload()
        }}
      />
    )
    if (analyzingCsv) {
      return (
        <S.AnalyzingCsvFile>
          <S.AnalyzingFileName>
            <Spinner size={16} />
            <Paragraph size="SM" $color="fgSecondary" $whiteSpace="nowrap" $ellipsis>
              {uploadedFile?.name}
            </Paragraph>
          </S.AnalyzingFileName>
          <Caption size="SM" $color="fgSecondary" $whiteSpace="nowrap">
            Looking for candidates…
          </Caption>
        </S.AnalyzingCsvFile>
      )
    }
    if (state.validationResult?.validRows.length === 0) {
      return (
        <Flex $align="center" $gap={16}>
          <Flex $align="center" $gap={8} $width='fit-content'>
            <Icon name={Icons.file} size={16} />
            <Paragraph size="SM" $color="fgSecondary">
              {uploadedFile?.name}
            </Paragraph>
          </Flex>
          <Flex $align="center" $flex='1 1 0' $justify='flex-start'>
            <Caption size="SM" $color="fgSecondary">
              No valid candidates found
            </Caption>
          </Flex>
          {uploadButton}
        </Flex>
      )
    }
    if (numberOfValidCandidates > 0) {
      return (
        <S.AnalyzingCsvFile>
          <S.AnalyzingFileName>
            <Icon name={Icons.file} size={16} />
            <Paragraph size="SM" $color="fgSecondary">
              {uploadedFile?.name}
            </Paragraph>
          </S.AnalyzingFileName>
          <Caption size="SM" $color="positiveFg" $whiteSpace="nowrap">
            {pluralize(numberOfValidCandidates, 'valid candidate')} found
          </Caption>
          {uploadButton}
        </S.AnalyzingCsvFile>
      )
    }
    return null
  }, [analyzingCsv, numberOfValidCandidates, resetFileUpload, state.validationResult?.validRows.length, uploadedFile?.name])

  return (
    <Form onSubmit={submit(handleSubmit)}>
      <CsvSummary
        validationResult={state.validationResult}
        idInputErrors={idInputErrors}
      />
      {!containsCandidateIdentifiers && (
        <FileDropUpload
          heading="Upload CSV file"
          accept={['csv']}
          onUploadStart={() => {
            setAnalyzingCsv(true)
            resetFileUpload()
          }}
          setUploadedFile={setUploadedFile}
          onFileRemoved={() => {
            resetFileUpload()
          }}
          onUpload={(urls) => {
            const fileUrl = urls[0]
            // We only support one file at a time
            if (!isNil(jobId)) {
              validateCandidatesFromCsv({
                csvS3Key: fileUrl,
                jobId,
                onSuccess: (validationResult) => {
                  setState({
                    ...state,
                    validationResult,
                    uploadedFileS3Url: fileUrl
                  })
                  setAnalyzingCsv(false)
                  const { validRows, invalidRows, validationError } = validationResult
                  const hasValidRowRecommendations = validRows.some((row) => row.recommendations.length > 0)
                  if (!validationError &&
                    invalidRows.length === 0 &&
                    !hasValidRowRecommendations &&
                    validRows.length > 0
                  ) {
                    // Immediately jump to the next step if there are no invalid rows
                    callback()
                  }
                },
                onError: (error) => {
                  setAnalyzingCsv(false)
                  setState({
                    ...state,
                    validationResult: {
                      validRows: [],
                      invalidRows: [],
                      validationError: error.message,
                      headers: []
                    }
                  })
                }
              })
            }
          }}
          uploadedFileComponent={uploadedFileComponent}
        />
      )}
      {!uploadingCsvFile && !containsCandidateIdentifiers && (
        <VisualDivider $spacingTop={24} $spacingBottom={24}>Or</VisualDivider>
      )}
      {!uploadingCsvFile && (
        <Textarea
          ref={inputRef}
          name="candidateUniqueIds"
          placeholder="https://www.linkedin.com/in/username"
          label="Candidate LinkedIn URLs"
          rows={6}
          register={register}
          onChange={onTextAreaUpdate}
          $marginBottom={0}
        />
      )}
      <S.ActionButtons>
        <Button
          type="submit"
          $variant="fill"
          $colorTheme="tint"
          $height={40}
          loading={isSubmitting}
          disabled={disabledSubmit || numberOfValidCandidates === 0}
        >
          Add {numberOfValidCandidates > 0 ? pluralize(numberOfValidCandidates, 'candidate') : 'candidates'}
        </Button>
        <Button
          $variant="outline"
          $colorTheme="muted"
          $height={40}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            closeDialog(DialogId.ADD_CANDIDATE)
          }}
        >
          Cancel
        </Button>
      </S.ActionButtons>
    </Form>
  )
}
