import { useCallback, useEffect, useMemo, useState } from 'react'
import type { Dispatch } from 'react'
import { isNil, isEqual } from 'lodash'
import Api from 'src/libs/api'
import type { EmailSequence, EmailSequenceStep, OutreachPreferencesData } from 'src/models/sequence'
import { useOrgUsersQuery } from './queries/use-org-users'
import { useSession } from 'src/hooks/use-session'
import { useParams } from 'react-router-dom'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SequenceReply, SequenceStepType, sequenceStepParser } from 'src/libs/api/backend/sequences'
import type { SequenceReplyType } from 'src/libs/api/backend/sequences'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useSetAtom } from 'jotai'
import { convertSubjectToText, parseComponentToVariable, parseVariableToComponent } from 'src/components/blocks/editor/extensions/variable-parser'
import { sequenceHasUnsavedChangesAtom } from 'src/stores/sequence'
import { LocalStorageKey } from 'src/constants'
import { useLocalStorage } from 'usehooks-ts'

interface Args {
  initialState: EmailSequence
  autoGenerateEmails?: boolean
  sequenceSize?: number
}

interface UseEmailSequenceEditorReturn {
  isLoading: boolean
  sequenceState: EmailSequence
  generatedEmails: EmailSequence['sequenceSteps']
  emailsBeingGenerated: number[] | undefined
  setEmailsBeingGenerated: Dispatch<React.SetStateAction<number[] | undefined>>
  isReadyForNextStep: () => boolean
  handleGenerateStepSuggestion: (stepIndex?: number) => Promise<null | undefined>
  handleConfirmStepSuggestion: (confirm: boolean, stepIndex?: number) => void
  handleAddStep: () => void
  handleDataChange: (updatedStep: EmailSequenceStep) => void
  handleRemoveStep: (stepToRemove: EmailSequenceStep) => void
  handleUpdateReplyType: (currentStep: EmailSequenceStep, replyType: SequenceReplyType) => void
  handleUpdateSequencePreferences: (updatedPreferences: OutreachPreferencesData) => void
  handleReorderSteps: (updatedSteps: EmailSequenceStep[]) => void
  hasEmptySequenceStep: () => boolean
  getSendingEmailAccount: ({
    sendingEmailAccountId,
    currentUserId
  }: {
    sendingEmailAccountId?: string | undefined
    currentUserId?: string | undefined
  }) => EmailAccount
  getStepSubjectAndPlaceholder: (stepPosition: number) => {
    subject: string | null
    placeholder: string | null
  }
  hasUnsavedChanges: boolean
  userEmailAccounts: EmailAccount[]
  setHasUnsavedChanges: Dispatch<React.SetStateAction<boolean>>
  validateSequenceState: (sequence: EmailSequence) => Promise<EmailSequence | null>
  getNewPosition: () => number
}

export const generateInitialSequence = (sequenceSize: number): EmailSequence => {
  return {
    id: null,
    sequenceSteps: Array.from({ length: sequenceSize }, (_, index) => ({
      position: index,
      type: SequenceStepType.AUTOMATED_EMAIL,
      waitDays: 0,
      subject: `Email ${index + 1}`,
      body: '',
      sendingEmailAlias: null,
      sendingLinkedInAccountId: null
    }))
  }
}

export const parseInitialState = (initialState: EmailSequence): EmailSequence => {
  return {
    ...initialState,
    sequenceSteps: initialState.sequenceSteps?.map((step) => ({
      ...step,
      subject: parseVariableToComponent(step.subject),
      body: parseVariableToComponent(step.body, step.personalizationInstructions)
    }))
  }
}

export const stepIsOfTypeEmail = (step: EmailSequenceStep): boolean => {
  return step.type === SequenceStepType.AUTOMATED_EMAIL || step.type === SequenceStepType.MANUAL_EMAIL
}

export const stepTypeIsEmail = (type?: SequenceStepType): boolean => {
  if (!type) return false
  return type === SequenceStepType.AUTOMATED_EMAIL || type === SequenceStepType.MANUAL_EMAIL
}

export const stepIsOfTypeLinkedIn = (step: EmailSequenceStep): boolean => {
  return step.type === SequenceStepType.AUTOMATED_LINKEDIN_MESSAGE || step.type === SequenceStepType.MANUAL_LINKEDIN_MESSAGE
}

export const stepIsOfTypeManualTask = (step: EmailSequenceStep): boolean => {
  return step.type === SequenceStepType.MANUAL_TASK ||
    step.type === SequenceStepType.MANUAL_PHONE_CALL ||
    step.type === SequenceStepType.MANUAL_TEXT_MESSAGE
}

export const stepTypeIsManualTask = (type?: SequenceStepType): boolean => {
  if (!type) return false
  return type === SequenceStepType.MANUAL_TASK ||
    type === SequenceStepType.MANUAL_PHONE_CALL ||
    type === SequenceStepType.MANUAL_TEXT_MESSAGE
}

export const sortSteps = (steps: EmailSequence['sequenceSteps']): EmailSequence['sequenceSteps'] => {
  if (isNil(steps)) {
    return []
  }

  const sortedSteps = steps.sort((a, b) => {
    if (a.deleted && !b.deleted) return 1
    if (!a.deleted && b.deleted) return -1
    return 0
  })

  return sortedSteps.map((step, index) => ({
    ...step,
    position: index
  }))
}

export const getSendingEmailAccountForSequence = ({
  sendingEmailAccountId,
  currentUserId,
  userEmailAccounts
}: {
  sendingEmailAccountId?: string
  currentUserId?: string
  userEmailAccounts: EmailAccount[]
}): EmailAccount => {
  if (currentUserId) {
    return (
      userEmailAccounts.find((account) => account.userId === currentUserId) ??
      userEmailAccounts.find(account => account.isPrimary) ??
      userEmailAccounts[0]
    )
  }
  return (
    userEmailAccounts.find((account) => account.id === sendingEmailAccountId) ??
    userEmailAccounts.find((account) => account.userId === currentUserId) ??
    userEmailAccounts.find(account => account.isPrimary) ??
    userEmailAccounts[0]
  )
}

export const validateSequence = async ({
  sequence,
  defaultSendingAccount,
  notifyError
}: {
  sequence: EmailSequence
  defaultSendingAccount: EmailAccount
  notifyError?: (error: { message: string }) => void
}): Promise<EmailSequence | null> => {
  const stepsWithSender = sequence.sequenceSteps?.map((step) => {
    const { id, ...stepWithoutId } = step
    const isValidUUID = sequenceStepParser.shape.id.safeParse(id).success
    const validatedStep = {
      ...(isValidUUID ? { id } : {}),
      ...stepWithoutId,
      subject: convertSubjectToText(step.subject),
      body: parseComponentToVariable(step.body)
    }

    if (!step.sendingUserId || !step.sendingEmailAccountId) {
      return {
        ...validatedStep,
        sendingUserId: step.sendingUserId ?? defaultSendingAccount.userId,
        sendingEmailAccountId: step.sendingEmailAccountId ?? defaultSendingAccount.id
      }
    } else {
      return validatedStep
    }
  })

  const firstStepOfTypeEmail = stepsWithSender?.find(step => stepIsOfTypeEmail(step))
  if (firstStepOfTypeEmail && (isNil(firstStepOfTypeEmail.subject) || firstStepOfTypeEmail.subject === '')) {
    notifyError?.({ message: 'The first email in an outreach sequence must have a subject.' })
    return null
  }

  const linkedInMessageSteps = stepsWithSender?.filter(stepIsOfTypeLinkedIn)
  if (linkedInMessageSteps && linkedInMessageSteps?.length > 1) {
    notifyError?.({ message: 'Only one LinkedIn message step is allowed in a sequence.' })
    return null
  }

  const validatedSteps = sortSteps(stepsWithSender)
  return {
    ...sequence,
    sequenceSteps: validatedSteps
  }
}

export const useEmailSequenceEditor = ({
  initialState,
  autoGenerateEmails = true,
  sequenceSize = 3
}: Args): UseEmailSequenceEditorReturn => {
  const { jobId } = useParams()
  const { user } = useSession()
  const [isLoading, setIsLoading] = useState(true)
  // ignore the first update since the editor will modify the content on mount
  const [initializedBody, setInitializedBody] = useState<Set<number>>(new Set())

  const [sequenceState, setSequenceState] = useState<EmailSequence>(initialState)
  const [generatedEmails, setGeneratedEmails] = useState<EmailSequence['sequenceSteps']>()
  const [emailsBeingGenerated, setEmailsBeingGenerated] = useState<number[]>()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [userEmailAccounts, setUserEmailAccounts] = useState<EmailAccount[]>([])
  const setSequenceHasUnsavedChanges = useSetAtom(sequenceHasUnsavedChangesAtom)
  const [_, setUnsavedSequence] = useLocalStorage<EmailSequence | null>(LocalStorageKey.SEQUENCE, null)

  const [stepSuggestionBeingGenerated, setStepSuggestionBeingGenerated] = useState<{
    position: number
    emailBodySuggestion: string
  } | null>(null)

  useEffect(() => {
    // Keep atom in sync with local state
    setSequenceHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges, setSequenceHasUnsavedChanges])

  useEffect(() => {
    if (hasUnsavedChanges) {
      setUnsavedSequence(sequenceState)
    } else {
      setUnsavedSequence(null)
    }
  }, [hasUnsavedChanges, sequenceState, setUnsavedSequence])

  const notifyError = useSetAtom(notifyErrorAtom)

  const { data: orgUsers } = useOrgUsersQuery()

  const linkedInAccounts = useMemo(() => {
    return orgUsers?.flatMap((user) => user.linkedInAccounts) ?? []
  }, [orgUsers])

  useEffect(() => {
    setUserEmailAccounts(orgUsers?.flatMap((user) => user.emailAccounts) ?? [])
  }, [orgUsers])

  const getNewPosition = useCallback((): number => {
    return (
      sequenceState?.sequenceSteps?.reduce((max, current) => {
        return current.position > max ? current.position + 1 : max + 1
      }, 0) ?? 1
    )
  }, [sequenceState?.sequenceSteps])

  const getSendingEmailAccount = useCallback(({
    sendingEmailAccountId,
    currentUserId
  }: {
    sendingEmailAccountId?: string
    currentUserId?: string
  }): EmailAccount => {
    return getSendingEmailAccountForSequence({ sendingEmailAccountId, currentUserId, userEmailAccounts })
  }, [userEmailAccounts])

  const getStepSubjectAndPlaceholder = useCallback((stepPosition: number): { subject: string | null, placeholder: string | null } => {
    if (stepPosition < 0) {
      stepPosition = 0
    }

    const step = sequenceState.sequenceSteps?.at(stepPosition)
    if (stepPosition === 0) {
      return {
        subject: step?.subject ?? '',
        placeholder: null
      }
    }

    if (
      step?.type === SequenceStepType.MANUAL_TASK &&
      (isNil(step.subject) || step?.subject === '')
    ) {
      return {
        subject: '',
        placeholder: null
      }
    }

    if (!isNil(step?.subject)) {
      return {
        subject: step.subject,
        placeholder: null
      }
    }

    for (let i = stepPosition - 1; i >= 0; i--) {
      const prevStep = sequenceState.sequenceSteps?.at(i)
      if (!isNil(prevStep?.subject)) {
        return {
          subject: null,
          placeholder: prevStep.subject
        }
      }
    }

    return {
      subject: `New email ${stepPosition}`,
      placeholder: null
    }
  }, [sequenceState.sequenceSteps])

  useEffect(() => {
    if (initialState && !generatedEmails) {
      const sortedSteps = initialState.sequenceSteps
        ?.filter((step) => !step.deleted)
        .sort((a, b) => a.position - b.position)

      const stateWithSortedSteps = {
        ...initialState,
        sequenceSteps: sortedSteps
      }
      setSequenceState(stateWithSortedSteps)
      setGeneratedEmails(sortedSteps)
      setIsLoading(false)
    }
  }, [generatedEmails, initialState])

  useEffect(() => {
    const generateEmails = async (): Promise<void> => {
      if (isNil(initialState) && autoGenerateEmails) {
        if (isNil(jobId)) {
          return
        }
        try {
          const cachedSequence = sequenceState
          setIsLoading(false)
          for (const i of Array.from({ length: sequenceSize }).keys()) {
            try {
              setEmailsBeingGenerated([i])
              const email = await generateEmail({
                jobId,
                position: i
              })
              if (email) {
                const foundStep = findStepByPosition({
                  sequenceSteps: cachedSequence.sequenceSteps,
                  position: i
                })
                const updatedStep = {
                  ...foundStep,
                  position: i,
                  body: email ?? '<p>New email</p>',
                  sendingUserId: getSendingEmailAccount({ currentUserId: user?.id })?.userId,
                  sendingEmailAccountId: getSendingEmailAccount({ currentUserId: user?.id })?.id,
                  sendingLinkedInAccountId: null,
                  sendingEmailAlias: null
                }

                if (!isNil(cachedSequence.sequenceSteps)) {
                  cachedSequence.sequenceSteps[i] = updatedStep
                }

                setEmailsBeingGenerated((prev) => prev?.filter((prev) => prev !== i))
                setGeneratedEmails(cachedSequence.sequenceSteps)
                setSequenceState(cachedSequence)
              } else {
                console.error(`Failed to generate email for step ${i}`)
                break
              }
            } catch (e) {
              break
            }
          }
        } catch (err) {
          notifyError({
            message: 'An error occured while generating, try again in a moment'
          })
          setEmailsBeingGenerated([])
        }
      }
      if (isNil(initialState) && !autoGenerateEmails && user && userEmailAccounts.length) {
        setSequenceState({
          ...sequenceState,
          sequenceSteps: sequenceState.sequenceSteps?.map((step) => ({
            ...step,
            sendingUserId: getSendingEmailAccount({ currentUserId: user?.id })?.userId,
            sendingEmailAccountId: getSendingEmailAccount({ currentUserId: user?.id })?.id
          }))
        })
      }
    }
    void generateEmails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerateEmails, jobId, user?.id])

  const findStepByPosition = ({
    sequenceSteps,
    position
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    position: number
  }): EmailSequenceStep | undefined => {
    return sequenceSteps?.find((step) => step.position === position)
  }

  const generateEmail = useCallback(async ({
    jobId,
    position
  }: {
    jobId: string
    position: number
  }): Promise<string> => {
    if (isNil(jobId)) {
      throw new Error('jobId missing')
    }

    const getPreviousSequenceSteps = (currentStep: number): Array<string | null> => {
      if (!Array.isArray(sequenceState.sequenceSteps)) {
        console.warn('Couldn\'t access previous steps to generate the next email')
        return []
      }

      if (currentStep === 0) {
        return []
      }

      const result: Array<string | null> = []
      for (let i = 0; i < currentStep; i++) {
        const step = sequenceState.sequenceSteps[i].body
        const isEmptyBody = isNil(step) || step === '' || step === '<div></div>'
        if (isEmptyBody) {
          result.push(null)
        } else {
          result.push(step)
        }
      }

      return result
    }

    const response = await Api.post('/sequences/gpt/generate_step', null, {
      jobId,
      previousSequenceSteps: getPreviousSequenceSteps(position),
      stepPosition: position
    })

    if (response.status !== 200) {
      throw new Error('Failed to generate email suggestion')
    }

    const generatedStep = response?.data?.step as string
    return generatedStep?.replace(/\n/g, '<br />')
  }, [sequenceState.sequenceSteps])

  const replaceStep = useCallback(({
    sequenceSteps,
    updatedStep
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    updatedStep: EmailSequenceStep
  }): EmailSequence['sequenceSteps'] => {
    const updatedSteps = sequenceSteps?.map((step) => {
      if (step.position === updatedStep.position) {
        return updatedStep
      }
      return step
    })

    return updatedSteps
  }, [])

  const handleAddStep = useCallback(() => {
    let cachedState = sequenceState
    if (isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const newPosition = getNewPosition()
    const previousStep = sequenceState.sequenceSteps?.[newPosition - 1]

    const newStepPartial: EmailSequenceStep = {
      id: newPosition.toString(),
      position: newPosition,
      type: SequenceStepType.AUTOMATED_EMAIL,
      waitDays: newPosition > 2 ? 5 : [0, 3, 3][newPosition],
      subject: previousStep && stepIsOfTypeEmail(previousStep) ? null : '',
      body: '',
      sendingUserId:
        previousStep?.sendingUserId ?? getSendingEmailAccount({ currentUserId: user?.id }).userId,
      sendingEmailAccountId:
        previousStep?.sendingEmailAccountId ??
        getSendingEmailAccount({ currentUserId: user?.id }).id,
      sendingEmailAlias: previousStep?.sendingEmailAlias,
      sendingLinkedInAccountId: previousStep?.sendingLinkedInAccountId
    }

    const updatedSteps = [...sequenceState.sequenceSteps, newStepPartial]
    cachedState = {
      ...cachedState,
      sequenceSteps: updatedSteps
    }
    setSequenceState(cachedState)
  }, [getNewPosition, getSendingEmailAccount, sequenceState, user?.id])

  const isReadyForNextStep = useCallback((): boolean => {
    if (isNil(sequenceState.sequenceSteps)) {
      return false
    }

    if (!Array.isArray(sequenceState.sequenceSteps) || sequenceState.sequenceSteps.length === 0) {
      return false
    }

    return true
  }, [sequenceState])

  const hasEmptySequenceStep = useCallback((): boolean => {
    if (isNil(sequenceState.sequenceSteps)) {
      return true
    }

    const hasEmptyStep = sequenceState.sequenceSteps.some(step => isNil(step.body) || step.body === '' || step.body === '<div></div>')
    return hasEmptyStep
  }, [sequenceState])
  /**
   * @param stepIndex: number | undefined
   *  - number: generate an email suggestion for the step at the specified index
   *  - undefined: generate an email suggestion for a new step
   */
  const handleGenerateStepSuggestion = useCallback(async (stepIndex?: number) => {
    if (isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const isAddingNewStep = isNil(stepIndex)
    if (!isAddingNewStep) {
      const focusedStep = sequenceState.sequenceSteps[stepIndex]
      if (!isNil(focusedStep.emailBodySuggestion)) {
        return
      }

      // UPDATE STATE TO SHOW WE ARE GENERATING A NEW EMAIL
      setEmailsBeingGenerated([stepIndex])

      // START GENERATING A NEW EMAIL SUGGESTION
      let emailBodySuggestion
      try {
        emailBodySuggestion = await generateEmail({
          jobId: jobId ?? '',
          position: stepIndex
        })
      } catch (err) {
        setEmailsBeingGenerated([])
        notifyError({
          message: 'An error occured while generating, try again in a moment'
        })

        return
      }

      // UPDATE STATE WITH GENERATE EMAIL SUGGESTION
      const updatedSteps = replaceStep({
        sequenceSteps: sequenceState.sequenceSteps,
        updatedStep: {
          ...focusedStep,
          emailBodySuggestion
        }
      })

      setSequenceState({ ...sequenceState, sequenceSteps: updatedSteps })
      setEmailsBeingGenerated([])
    } else {
      // Create and add the new step immediately
      const newPosition = getNewPosition()
      const previousStep = sequenceState.sequenceSteps?.[newPosition - 1]
      const sendingUserId = getSendingEmailAccount({ currentUserId: user?.id }).userId
      const sendingEmailAccountId = getSendingEmailAccount({ currentUserId: user?.id }).id
      const newStepPartial: EmailSequenceStep = {
        id: newPosition.toString(),
        position: newPosition,
        type: SequenceStepType.AUTOMATED_EMAIL,
        waitDays: newPosition > 2 ? 5 : [0, 3, 3][newPosition],
        subject: previousStep && stepIsOfTypeEmail(previousStep) ? null : '',
        body: '',
        sendingUserId,
        sendingEmailAccountId,
        sendingEmailAlias: null,
        sendingLinkedInAccountId: null
      }

      // Add the empty step to the sequence
      setSequenceState({
        ...sequenceState,
        sequenceSteps: [...sequenceState.sequenceSteps, newStepPartial]
      })

      // Start generation process
      setEmailsBeingGenerated([newPosition])
      try {
        const generatedEmail = await generateEmail({
          jobId: jobId ?? '',
          position: newPosition
        })

        // Store generated email body suggestion separately
        // This is required so other step updates (e.g. type or subjects)
        // are not being overwritten again by updating the sequenceState here.
        setStepSuggestionBeingGenerated({
          position: newPosition,
          emailBodySuggestion: generatedEmail
        })
      } catch (err) {
        setEmailsBeingGenerated([])
        notifyError({
          message: 'An error occurred while generating, try again in a moment'
        })
      }
      setEmailsBeingGenerated([])
    }
  }, [sequenceState, replaceStep, generateEmail, jobId, notifyError, getNewPosition, getSendingEmailAccount, user?.id])

  useEffect(() => {
    if (!stepSuggestionBeingGenerated) return

    const { position, emailBodySuggestion } = stepSuggestionBeingGenerated
    const stepIndex = sequenceState.sequenceSteps?.findIndex(
      step => step.position === position
    )

    if (stepIndex === undefined || stepIndex === -1) return

    const step = sequenceState?.sequenceSteps?.[stepIndex]

    if (!step) return

    // Only update emailBodySuggestion if the step is still an email type
    if (stepIsOfTypeEmail(step)) {
      const updatedSteps = [...sequenceState.sequenceSteps ?? []]
      updatedSteps[stepIndex] = {
        ...step,
        emailBodySuggestion
      }

      setSequenceState(prev => ({
        ...prev,
        sequenceSteps: updatedSteps
      }))
    }

    setStepSuggestionBeingGenerated(null)
  }, [stepSuggestionBeingGenerated, sequenceState.sequenceSteps])

  const handleConfirmStepSuggestion = useCallback((confirm: boolean, stepIndex?: number): void => {
    if (isNil(sequenceState.sequenceSteps)) {
      return
    }

    const cachedSequenceSteps = sequenceState.sequenceSteps
    if (!Array.isArray(cachedSequenceSteps) || cachedSequenceSteps.length === 0) {
      return
    }

    const focusedStepIndex = stepIndex ?? sequenceState.sequenceSteps.length - 1
    const focusedStep = sequenceState.sequenceSteps.at(focusedStepIndex)
    if (isNil(focusedStep)) {
      return
    }

    // set the body equal to the email body suggestion
    const suggestion = confirm
      ? (focusedStep?.body ?? '') + focusedStep.emailBodySuggestion
      : (focusedStep?.body ?? '')

    const cleanedUpBody = parseVariableToComponent(suggestion.replace(/^<div><\/div>/, ''))

    cachedSequenceSteps[focusedStepIndex].body = cleanedUpBody
    cachedSequenceSteps[focusedStepIndex].emailBodySuggestion = undefined

    // update the state
    setSequenceState({
      ...sequenceState,
      sequenceSteps: cachedSequenceSteps
    })
    setGeneratedEmails(cachedSequenceSteps)
  }, [sequenceState, setSequenceState, setGeneratedEmails])

  const removeStep = useCallback(({
    sequenceSteps,
    stepToRemove
  }: {
    sequenceSteps: EmailSequence['sequenceSteps']
    stepToRemove: EmailSequenceStep
  }): EmailSequence['sequenceSteps'] => {
    if (!sequenceSteps) return []

    const filteredSteps = sequenceSteps.filter((step: EmailSequenceStep) => {
      if (stepToRemove.id && step.id) {
        return step.id !== stepToRemove.id
      } else {
        return step.position !== stepToRemove.position
      }
    })

    return filteredSteps.map((step: EmailSequenceStep, index: number) => {
      const updatedStep = { ...step }
      updatedStep.position = index
      return updatedStep
    })
  }, [])

  const handleRemoveStep = useCallback((stepToRemove: EmailSequenceStep): void => {
    const currentSteps = [...(sequenceState.sequenceSteps ?? [])]

    const updatedSteps = removeStep({
      sequenceSteps: currentSteps,
      stepToRemove
    })

    if (!updatedSteps) {
      return
    }

    // If we are removing an email step where the next step is a reply to that one,
    // we want to copy over the subject to the next remaining step.
    if (stepIsOfTypeEmail(stepToRemove) && !isNil(stepToRemove.subject)) {
      const nextEmailStep = updatedSteps.find(step => stepIsOfTypeEmail(step) && isNil(step.subject))

      if (nextEmailStep) {
        const nextStepIndex = updatedSteps.findIndex(step => step.position === nextEmailStep.position)
        updatedSteps[nextStepIndex] = {
          ...nextEmailStep,
          subject: stepToRemove.subject
        }
      }
    }

    // This is necessary to avoid "colliding" body contents on steps.
    // Otherwise, the following step would have the body content of the removed step.
    setInitializedBody(new Set())

    setGeneratedEmails(updatedSteps)
    setSequenceState(prevState => ({
      ...prevState,
      sequenceSteps: updatedSteps
    }))
    setHasUnsavedChanges(true)
  }, [removeStep, sequenceState.sequenceSteps])

  const handleDataChange = useCallback((updatedStep: EmailSequenceStep): void => {
    const stepIndex = sequenceState.sequenceSteps?.findIndex(
      (step) => step.position === updatedStep.position
    )
    if (isNil(stepIndex)) {
      return
    }

    if (!initializedBody.has(stepIndex) && updatedStep.body !== sequenceState.sequenceSteps?.[stepIndex]?.body) {
      // Skip the first body update per step since the editor will modify the content on mount
      setInitializedBody((prev) => prev.add(stepIndex))
      return
    }

    let newSequenceSteps = [...(sequenceState.sequenceSteps ?? [])]
    if (stepIndex !== -1) {
      newSequenceSteps[stepIndex] = updatedStep
    }
    // If the step type has changed, we want to tackle a few things:
    // * We always want to set EMAIL steps as a reply to the last email step
    //   no matter, if there is a different step type (i.e. LINKEDIN) in between or not.
    // * LINKEDIN or MANUAL_TASK steps are always a new thread, as there is no reply that our system captures to either of these step types.
    //   (We are hiding the reply type selection dropdown for LINKEDIN & MANUAL_TASK steps.)
    // * If the first step is of type EMAIL, it always needs to be a new thread.
    //   Whether it is a new thread or not is simply defined by the subject (i.e. `null` = new thread, `whatever` = reply to previous thread)
    //
    // Additionally, there are some edge cases. E.g. (1) is EMAIL, (2) is LINKEDIN, (3) is EMAIL, (4) is EMAIL again
    // and when (4) is set to being a new thread, we want to keep that as a new thread because we consider it to be intentionally.
    const currentStep = sequenceState?.sequenceSteps?.[stepIndex]
    if (currentStep) {
      const stepTypeHasChanged = currentStep.type !== updatedStep.type
      const stepTypeHasChangedToManualTask = stepTypeHasChanged && stepTypeIsManualTask(updatedStep.type)
      const stepTypeHasChangedFromManualTask = stepTypeHasChanged && stepTypeIsManualTask(currentStep.type)
      const stepTypeHasChangedToDifferentManualTask = stepTypeHasChanged && stepTypeIsManualTask(currentStep.type) && stepTypeIsManualTask(updatedStep.type) && currentStep.type !== updatedStep.type

      if (stepTypeHasChanged) {
        // console.log('[STEP TYPE CHANGE DETECTED]', {
        //   position: updatedStep.position,
        //   from: currentStep.type,
        //   to: updatedStep.type
        // })

        newSequenceSteps = newSequenceSteps.map((step, index) => index === stepIndex ? updatedStep : step)

        if ((stepTypeHasChangedToManualTask || stepTypeHasChangedFromManualTask) && !stepTypeHasChangedToDifferentManualTask) {
          setGeneratedEmails(newSequenceSteps)

          newSequenceSteps[stepIndex] = {
            ...updatedStep,
            subject: '',
            body: '',
            lookupPhoneNumber: stepTypeHasChangedFromManualTask ? false : updatedStep.lookupPhoneNumber
          }
        }

        if (stepIsOfTypeEmail(updatedStep)) {
          if (stepIndex === 0) {
            // First step should always be a new thread
            newSequenceSteps[0] = {
              ...newSequenceSteps[0],
              // If there is an existing subject, we want to keep it when only sweeting between email step types
              subject: updatedStep.subject ?? ''
            }
          } else {
            // Find the latest/previous email step to reply to
            const latestFoundEmailStep = newSequenceSteps
              .slice(0, stepIndex)
              .reverse()
              .find(stepIsOfTypeEmail)

            newSequenceSteps[stepIndex] = {
              ...newSequenceSteps[stepIndex],
              subject: latestFoundEmailStep ? null : ''
            }

            // Update any following email steps
            for (let i = stepIndex + 1; i < newSequenceSteps.length; i++) {
              if (stepIsOfTypeEmail(newSequenceSteps[i]) && isNil(newSequenceSteps[i].subject)) {
                const latestFoundEmailStep = newSequenceSteps
                  .slice(0, i)
                  .reverse()
                  .find(stepIsOfTypeEmail)

                newSequenceSteps[i] = {
                  ...newSequenceSteps[i],
                  subject: latestFoundEmailStep ? null : ''
                }
              }
            }
          }
        }

        // We need to make sure the sendingLinkedInAccountId is set for LinkedIn steps
        if (stepIsOfTypeLinkedIn(updatedStep) && updatedStep.sendingLinkedInAccountId === null) {
          const linkedInAccount = linkedInAccounts.find((account) => account.userId === updatedStep.sendingUserId)
          newSequenceSteps[stepIndex] = {
            ...updatedStep,
            sendingLinkedInAccountId: linkedInAccount?.id ?? null
          }
        }

        // Some edge case, but if a user changes a step from LINKEDIN/MANUAL_TASK to EMAIL,
        // and as a result sets the upcoming step to be a reply, and then
        // switches the previous step back from EMAIL to LINKEDIN/MANUAL_TASK, we want to make
        // sure the upcoming step reply type is editable, if it is the first of type email
        // in the sequence..
        if (stepIsOfTypeLinkedIn(updatedStep) || stepIsOfTypeManualTask(updatedStep)) {
          // Find the next email step after this one
          const nextEmailStep = newSequenceSteps
            .slice(stepIndex + 1)
            .find(stepIsOfTypeEmail)

          if (nextEmailStep) {
            // Check if there are any email steps before this one
            const hasAnyPreviousEmailSteps = newSequenceSteps
              .some((step, index) => index < nextEmailStep.position && stepIsOfTypeEmail(step))

            if (!hasAnyPreviousEmailSteps) {
              // If this is the first email in the sequence, make it a new thread
              const nextStepIndex = newSequenceSteps.findIndex(step => step.position === nextEmailStep.position)
              newSequenceSteps[nextStepIndex] = {
                ...nextEmailStep,
                subject: ''
              }
            }
          }
        }
      } else {
        newSequenceSteps[stepIndex] = updatedStep
      }

      const senderHasChanged =
        updatedStep.sendingUserId !== currentStep.sendingUserId ||
        updatedStep.sendingEmailAccountId !== currentStep.sendingEmailAccountId ||
        updatedStep.sendingLinkedInAccountId !== currentStep.sendingLinkedInAccountId

      // If the sender has changed (no matter if EMAIL or LINKEDIN), we need to update all FUTURE step senders
      if (senderHasChanged) {
        newSequenceSteps = newSequenceSteps.map((step, index) => {
          if (index <= stepIndex) return step

          if (stepIsOfTypeLinkedIn(step)) {
            const linkedInAccount = linkedInAccounts.find(
              account => account.userId === updatedStep.sendingUserId
            )
            return {
              ...step,
              sendingUserId: updatedStep.sendingUserId,
              sendingLinkedInAccountId: linkedInAccount?.id ?? null
            }
          }

          if (stepIsOfTypeEmail(step)) {
            return {
              ...step,
              sendingUserId: updatedStep.sendingUserId,
              sendingEmailAccountId: updatedStep.sendingEmailAccountId
            }
          }

          return step
        })
      }

      if (!isEqual(currentStep, updatedStep)) {
        setHasUnsavedChanges(true)
      }

      // if we update the first step's from address,
      // and if the other steps haven't had their from address updated,
      // then set their from address to the updated from address
      const modifyingFirstStep = newSequenceSteps.length > 0 && stepIndex === 0
      const allStepsShareCommonSendingAddress = sequenceState.sequenceSteps?.every((step) => step.sendingEmailAccountId === currentStep.sendingEmailAccountId)
      const allStepsShareCommonSendingLinkedInAccount = sequenceState.sequenceSteps?.every((step) => step.sendingLinkedInAccountId === currentStep.sendingLinkedInAccountId)
      const modifyingSendingAddress = updatedStep.sendingEmailAccountId !== currentStep.sendingEmailAccountId || updatedStep.sendingUserId !== currentStep.sendingUserId || updatedStep.sendingEmailAlias !== currentStep.sendingEmailAlias
      const modifyingSendingLinkedInAccount = updatedStep.sendingLinkedInAccountId !== currentStep.sendingLinkedInAccountId

      if (modifyingFirstStep && allStepsShareCommonSendingLinkedInAccount && modifyingSendingLinkedInAccount) {
        const updatedRemainingSteps = newSequenceSteps.slice(stepIndex + 1).map((step) => ({
          ...step,
          sendingLinkedInAccountId: updatedStep.sendingLinkedInAccountId
        }))
        newSequenceSteps = [updatedStep, ...updatedRemainingSteps]
      } else if (modifyingFirstStep && allStepsShareCommonSendingAddress && modifyingSendingAddress) {
        const updatedRemainingSteps = newSequenceSteps.slice(stepIndex + 1).map((step) => ({
          ...step,
          sendingEmailAccountId: updatedStep.sendingEmailAccountId,
          sendingUserId: updatedStep.sendingUserId,
          sendingEmailAlias: updatedStep.sendingEmailAlias
        }))
        newSequenceSteps = [updatedStep, ...updatedRemainingSteps]
      }
    }

    setSequenceState({
      ...sequenceState,
      sequenceSteps: newSequenceSteps
    })
  }, [initializedBody, linkedInAccounts, sequenceState])

  const handleUpdateReplyType = useCallback((
    currentStep: EmailSequenceStep,
    replyType: SequenceReplyType
  ): void => {
    if (replyType === SequenceReply.NEW_THREAD) {
      handleDataChange({
        ...currentStep,
        subject: ''
      })
    }
    if (replyType === SequenceReply.REPLY_TO_PREVIOUS_THREAD) {
      handleDataChange({
        ...currentStep,
        subject: null
      })
    }
  }, [handleDataChange])

  const handleUpdateSequencePreferences = useCallback((updatedPreferences: OutreachPreferencesData): void => {
    const currentPreferences: OutreachPreferencesData = {
      autoArchiveAfterDays: sequenceState.autoArchiveAfterDays ?? 15,
      dailyEmailLimit: sequenceState.dailyEmailLimit ?? 100,
      autoSkipEmailStepsOnMissingEmail: sequenceState.autoSkipEmailStepsOnMissingEmail ?? false
    }
    if (!isEqual(currentPreferences, updatedPreferences)) {
      setHasUnsavedChanges(true)
    }
    setSequenceState((prevState) => ({
      ...prevState,
      ...updatedPreferences
    }))
  }, [sequenceState, setHasUnsavedChanges, setSequenceState])

  const handleReorderSteps = useCallback((updatedSteps: EmailSequenceStep[]): void => {
    const updatedStepsWithPositionAndWaitDays = updatedSteps.map((step, index) => ({
      ...step,
      position: index,
      waitDays: index > 2 ? 5 : [0, 3, 3][index]
    }))

    const updatedStepsWithSubjects = updatedStepsWithPositionAndWaitDays.map((step, index, array) => {
      if (!stepIsOfTypeEmail(step)) {
        return step
      }

      // Keep existing subject lines
      if (!isNil(step.subject)) {
        return step
      }

      const previousSteps = array.slice(0, index).reverse()
      const previousEmailStep = previousSteps.find(stepIsOfTypeEmail)
      const hasLinkedInStepInBetween = previousSteps.some((prevStep, i) => {
        return stepIsOfTypeLinkedIn(prevStep) && (!previousEmailStep || i < previousSteps.indexOf(previousEmailStep))
      })

      // Find the closest email subject (helpful when e.g. moving reply steps or LinkedIn steps)
      if (!previousEmailStep || hasLinkedInStepInBetween) {
        const closestPreviousSubject = previousSteps
          .find(s => stepIsOfTypeEmail(s) && !isNil(s.subject))?.subject

        const nextSteps = array.slice(index + 1)
        const closestNextSubject = nextSteps
          .find(s => stepIsOfTypeEmail(s) && !isNil(s.subject))?.subject

        return {
          ...step,
          subject: closestPreviousSubject ?? closestNextSubject ?? ''
        }
      }

      return {
        ...step,
        subject: null
      }
    })

    setGeneratedEmails(updatedStepsWithSubjects)
    setSequenceState({
      ...sequenceState,
      sequenceSteps: updatedStepsWithSubjects
    })
  }, [setSequenceState, sequenceState])

  const validateSequenceState = useCallback(async (sequence: EmailSequence): Promise<EmailSequence | null> => {
    const defaultSendingAccount = getSendingEmailAccount({ currentUserId: user?.id })
    return await validateSequence({
      sequence,
      defaultSendingAccount,
      notifyError
    })
  }, [getSendingEmailAccount, user?.id, notifyError])

  return {
    isLoading,
    sequenceState,
    generatedEmails,
    emailsBeingGenerated,
    setEmailsBeingGenerated,
    handleAddStep,
    isReadyForNextStep,
    handleGenerateStepSuggestion,
    handleConfirmStepSuggestion,
    handleDataChange,
    handleRemoveStep,
    handleUpdateReplyType,
    handleUpdateSequencePreferences,
    handleReorderSteps,
    hasEmptySequenceStep,
    getSendingEmailAccount,
    getStepSubjectAndPlaceholder,
    getNewPosition,
    userEmailAccounts,
    validateSequenceState,
    hasUnsavedChanges,
    setHasUnsavedChanges
  }
}
