import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria, CriteriaValue } from 'src/libs/api/backend/candidate_search'
import * as S from './refinement-filter-criteria-styled'
import { Caption } from 'src/components/primitives/typography'
import { Fragment, useCallback, useEffect, useRef } from 'react'
import { CriteriaRow } from './criteria-row'
import { isNil } from 'lodash'
import { removeUpdatedCriteriaAtom } from 'src/stores/job-refinement'
import { useSetAtom } from 'jotai'
import { removeCriteriaFromOrderedGroup } from 'src/utils/refinement-criteria'
import type { CriteriaOrderedGroup } from 'src/utils/refinement-criteria'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { DemographicsRow } from './criteria-row/demographics-row'

interface RefinementFilterCriteriaProps {
  criteria: Criteria | undefined
  setCriteria: (criteria: Criteria) => void
  searchRefinement?: JobSearchRefinement
  updatedGeneratedCriteria: CriteriaKey[]
  criteriaOrder: CriteriaOrderedGroup[]
  setCriteriaOrder: (criteriaOrder: CriteriaOrderedGroup[]) => void
  disabled: boolean
  newlyAddedCriteria: CriteriaKey | undefined
  setNewlyAddedCriteria: (criteriaKey: CriteriaKey | undefined) => void
  handleUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
}

export const RefinementFilterCriteria = ({
  criteria,
  setCriteria,
  criteriaOrder = [],
  setCriteriaOrder,
  updatedGeneratedCriteria,
  disabled,
  newlyAddedCriteria,
  setNewlyAddedCriteria,
  handleUpdate,
  searchRefinement
}: RefinementFilterCriteriaProps): JSX.Element => {
  const { blockedCriteria, criteriaImprovementToReplace } = searchRefinement ?? {}
  const newCriteriaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (newlyAddedCriteria) {
      newCriteriaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      setNewlyAddedCriteria(undefined)
    }
  }, [newlyAddedCriteria, setNewlyAddedCriteria])

  const removeUpdatedCriteria = useSetAtom(removeUpdatedCriteriaAtom)

  const onRowClick = useCallback((criteriaKey: CriteriaKey) => {
    removeUpdatedCriteria(criteriaKey)
  }, [removeUpdatedCriteria])

  const handleRemove = useCallback((criteriaKey: CriteriaKey, index?: number): void => {
    if (isNil(criteriaOrder) || isNil(criteria)) {
      return
    }
    let currentCriteria = { ...criteria }
    let deletingCriteria = true
    if (criteriaKey === CriteriaKey.DEMOGRAPHICS) {
      const {
        [CriteriaKey.SEX]: _removedSex,
        [CriteriaKey.RACE_CATEGORY]: _removedRaceCategory,
        [CriteriaKey.VETERAN_STATUS]: _removedVeteranStatus,
        [CriteriaKey.DISABILITY_STATUS]: _removedDisabilityStatus,
        [CriteriaKey.DEMOGRAPHICS]: _removedDemographics,
        ...updatedCriteria
      } = currentCriteria
      currentCriteria = updatedCriteria
    } else {
      const value = currentCriteria[criteriaKey]
      if (Array.isArray(value) && !isNil(index)) {
        const updatedCriteriaValue = value.filter((_, i) => i !== index)
        if (updatedCriteriaValue.length === 0) {
          const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
          currentCriteria = updatedCriteria
        } else {
          deletingCriteria = false
          currentCriteria = {
            ...currentCriteria,
            [criteriaKey]: updatedCriteriaValue
          }
        }
      } else {
        const { [criteriaKey]: _removedCriteria, ...updatedCriteria } = currentCriteria
        currentCriteria = updatedCriteria
      }
    }
    setCriteria(currentCriteria)
    if (deletingCriteria) {
      setCriteriaOrder(removeCriteriaFromOrderedGroup(criteriaOrder, criteriaKey))
    }
  }, [criteria, criteriaOrder, setCriteria, setCriteriaOrder])

  return (
    <S.Wrapper>
      {criteria && criteriaOrder?.map((orderedGroup) => {
        const { groupKey, orderedCriteria } = orderedGroup
        if (orderedCriteria.length === 0) {
          return <Fragment key={groupKey} />
        }
        return (
          <S.CriteriaGroup key={groupKey}>
            <S.CriteriaGroupHeader>
              <Caption as='h5' size='2XS' $color='fgTertiary'>{groupKey}</Caption>
            </S.CriteriaGroupHeader>
            {orderedCriteria.map((criteriaKey) => {
              const isUpdated = updatedGeneratedCriteria.includes(criteriaKey)
              if (criteriaKey === CriteriaKey.DEMOGRAPHICS) {
                return (
                  <div
                    ref={criteriaKey === newlyAddedCriteria ? newCriteriaRef : undefined}
                    key={criteriaKey}
                  >
                    <DemographicsRow
                      criteria={criteria}
                      onCriteriaUpdate={handleUpdate}
                      onCriteriaRemove={handleRemove}
                      updated={isUpdated}
                      disabled={disabled}
                      onRowClick={onRowClick}
                    />
                  </div>
                )
              }
              return (
                <div
                  ref={criteriaKey === newlyAddedCriteria ? newCriteriaRef : undefined}
                  key={criteriaKey}
                >
                  <CriteriaRow
                    criteriaKey={criteriaKey}
                    criteriaValue={criteria[criteriaKey]}
                    onCriteriaUpdate={handleUpdate}
                    onCriteriaRemove={handleRemove}
                    updated={isUpdated}
                    disabled={disabled}
                    onRowClick={onRowClick}
                    criteriaLimitations={{
                      blockedCriteria,
                      criteriaImprovementToReplace
                    }}
                  />
                </div>
              )
            })}
          </S.CriteriaGroup>
        )
      })}
    </S.Wrapper>
  )
}
