import { Flex } from 'src/components/primitives/flex'
import * as S from './sequence-editor.styled'
import type { EmailSequenceStep } from 'src/models/sequence'
import { Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Spinner } from 'src/components/primitives/spinner'
import { When } from '../when'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Tooltip } from 'src/components/primitives/tooltip'

interface StepSelectorProps {
  currentStep: number | null
  step: EmailSequenceStep
  stepIcon: React.ReactNode
  stepTitle: string
  stepSubtitle: string
  onStepClick: (position: number) => void
  initialStepsAreGenerating: boolean
  reorderingDisabled?: boolean
  hasError?: boolean
}

export const StepSelector = ({
  currentStep,
  step,
  stepIcon,
  stepTitle,
  stepSubtitle,
  onStepClick,
  initialStepsAreGenerating,
  reorderingDisabled,
  hasError
}: StepSelectorProps): JSX.Element => {
  // const { featureFlags } = useSession()

  const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({ id: step.id ?? step.position.toString() })

  const reorderStyle = {
    transform: CSS.Transform.toString(transform),
    left: isDragging ? 6 : 0,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 2 : 1,
    transition
  }

  return (
    <S.StepSelector
      ref={setNodeRef}
      style={reorderStyle}
      $isGenerating={false}
      $isCurrentStep={currentStep === step.position}
      onClick={() => {
        onStepClick(step.position)
      }}
      {...attributes}
      {...listeners}
      $disabled={!!reorderingDisabled}
      aria-label={`Select outreach step with the subject: ${step.subject} at position ${step.position}`}
    >
      <S.StepSelectorDragHandle>
        <Icon name={Icons.gripVertical} size={12} color="fgTertiary" />
      </S.StepSelectorDragHandle>
      <Flex $align="flex-start" $gap={8}>
        <S.StepSelectorIcon>
          {stepIcon}
        </S.StepSelectorIcon>
        <Flex $direction="column" $gap={2} $width="fit-content">
          <S.StepSelectorLine>
            <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap" $ellipsis>
              {stepTitle}
            </Paragraph>
          </S.StepSelectorLine>
          <S.StepSelectorLine>
            {
              !stepSubtitle?.length
                ? (
                    <Paragraph size="XS" $color="fgTertiary" $whiteSpace="nowrap" $ellipsis>
                      (No Subject)
                    </Paragraph>
                  )
                : (
                    <Paragraph size="XS" $color="fgPrimary" $whiteSpace="nowrap" $ellipsis>
                      {stepSubtitle?.length > 36 ? `${stepSubtitle.slice(0, 36)}…` : stepSubtitle}
                    </Paragraph>
                  )
            }
          </S.StepSelectorLine>
        </Flex>
      </Flex>
      <When condition={hasError ?? false}>
          <S.StepSelectorError>
            <Tooltip
              trigger={
                <S.StepSelectorErrorIcon>
                  <Icon name="alert-triangle" size={12} color="negativeFg" />
                </S.StepSelectorErrorIcon>
              }
            >
              Please add a message body to this step
            </Tooltip>
          </S.StepSelectorError>
      </When>
      <When condition={initialStepsAreGenerating}>
        <S.StepSelectorSpinner>
          <Spinner />
        </S.StepSelectorSpinner>
      </When>
    </S.StepSelector>
  )
}
