import { useState, useEffect } from 'react'
import { z } from 'zod'
import { Flex } from 'src/components/primitives/flex'
import { Form, ImageUpload, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { updateMe } from 'src/libs/api/backend/me'
import { useMe } from 'src/hooks/queries/use-me'
import { useSetMeQueryCache } from 'src/hooks/use-me'
import { useSetSessionQueryCache } from 'src/hooks/use-session'
import type { User } from 'src/libs/api/backend/me'
import { Combobox } from 'src/components/forms/combobox'
import { useTimezones } from 'src/hooks/use-timezones'
import { FormActionButtons } from '../form-action-buttons'
import { MAX_PROFILE_FILE_SIZE, PROFILE_MAX_WIDTH, PNG_MIME_TYPE, compressAndGenerateUrl } from 'src/utils/compress-image'
import { useSetAtom } from 'jotai'
import { notifySuccessAtom, notifyErrorAtom } from 'src/stores/notifications'
import { updateTrackedUser } from 'src/libs/track'

const userUpdateSchema = z.object({
  profilePhoto: z.union([z.instanceof(File), z.string()]).nullish(),
  name: z.string().nullish(),
  timezone: z.string().nullish()
})

export type UserUpdateData = z.infer<typeof userUpdateSchema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useUpdateUserQueryCache () {
  // functions to update the cache after a successful update to the user data
  const setMeQueryCache = useSetMeQueryCache()
  const setSessionQueryCache = useSetSessionQueryCache()
  const updateUserInCache = (user: User): void => {
    setMeQueryCache(() => user)
    setSessionQueryCache((prev) => {
      const newUser = {
        ...prev.user,
        name: user.name,
        profilePhoto: user.profilePhoto,
        timezone: user.timezone
      }
      return { ...prev, user: newUser }
    })
  }
  return { setMeQueryCache, setSessionQueryCache, updateUserInCache }
}

export const UserProfileForm = (): JSX.Element => {
  const { updateUserInCache } = useUpdateUserQueryCache()
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { submit, register, formData, setValues } = useForm<UserUpdateData>({
    schema: userUpdateSchema
  })
  const timezones = useTimezones()

  // this useEffect populates the form with the user data from the backend
  const { data, isError, isSuccess } = useMe()
  useEffect(() => {
    setValues({
      name: data?.name ?? '',
      timezone: data?.timezone ?? '',
      profilePhoto: data?.profilePhoto ?? null
    })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (formData: UserUpdateData): Promise<void> => {
    setIsSubmitting(true)

    try {
      const { profilePhoto, ...userData } = formData
      // couple of scenarios:
      // 1. profilePhoto is a File object, which means the user has uploaded a new profile photo
      // 2. profilePhoto is a string, which means the user has not uploaded a new profile photo
      // 3. profilePhoto is null, which means the user has removed their profile photo
      if (profilePhoto instanceof File) {
        if (profilePhoto.size > MAX_PROFILE_FILE_SIZE) {
          throw new Error('File is too large, please choose an image smaller than 1MB')
        }
        const uploadedTempProfilePhoto = await compressAndGenerateUrl({ file: profilePhoto, fileType: PNG_MIME_TYPE, maxWidth: PROFILE_MAX_WIDTH })
        if (uploadedTempProfilePhoto?.key) {
          const user = await updateMe({
            ...userData,
            newProfilePhoto: {
              sourceKey: uploadedTempProfilePhoto.key
            }
          })
          updateUserInCache(user)
        }
      } else if (formData.profilePhoto === null) {
        // API expects null to delete the profile photo
        const user = await updateMe({ ...userData, profilePhoto: null })
        updateUserInCache(user)
      } else {
        const user = await updateMe({
          ...userData,
          profilePhoto
        })
        updateUserInCache(user)
      }
      updateTrackedUser({
        name: formData.name ?? data?.name ?? 'N/A'
      })
      notifySuccess({
        message: 'Your profile has been updated successfully'
      })
    } catch (e) {
      console.log('[user-profile-form] handleSubmit:', e)
      notifyError({
        message: (e as Error)?.message
      })
    }

    setIsSubmitting(false)
  }

  if (isError) {
    return <div>Error while fetching user profile</div>
  }

  const profilePhoto = (formData as UserUpdateData).profilePhoto
  if (isSuccess && data) {
    return (
      <Form onSubmit={submit(handleSubmit)}>
        <ImageUpload
          value={profilePhoto}
          name="profilePhoto"
          label="Profile picture"
          $previewSize={40}
          register={register}
        />
        <Flex $gap={24}>
          <Input
            name="name"
            type="text"
            placeholder="Your full name"
            label="Full name"
            register={register}
          />
        </Flex>
        <Combobox
          name="timezone"
          icon="map-pin"
          label="Timezone"
          placeholder="Your timezone"
          defaultValue={data?.timezone ?? undefined}
          options={timezones.map(({ name, offset }) => ({ value: name, name: `(GMT${offset}) ${name}` }))}
          register={register}
          $marginBottom={0}
          $height={240}
        />
        <FormActionButtons saveText='Save changes' isSaving={isSubmitting} />
      </Form>
    )
  }

  return <></>
}
