import { PageHeader } from 'src/components/blocks/page-header'
import { Spacer } from 'src/components/primitives/spacer'
import { SEO } from '../../../components/primitives/seo'
import {
  AutoApproveCandidates,
  AutoArchiveCandidates,
  AutoScheduleMeetings,
  ExportToATS,
  NudgeUnresponsiveCandidates,
  SendOutreachEmails
} from 'src/components/blocks/automation-settings/settings'
import { Flex } from 'src/components/primitives'
import { useSession } from 'src/hooks/use-session'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { When } from 'src/components/blocks/when'
import { Banner } from 'src/components/blocks/banner'
import { Disabled } from 'src/components/primitives/disabled'

const AutomationSettingsPage = (): JSX.Element => {
  const { featureFlags, userHasViewerRole } = useSession()

  return (
    <>
      <PageHeader heading="Automation" />
      <SEO title="Automation" />
      <When condition={!!userHasViewerRole}>
        <Banner $variant="warning" icon="alert-triangle">{viewerRoleNotice('make changes to these settings')}</Banner>
        <Spacer $size={24} />
      </When>
      <Disabled when={userHasViewerRole} opacity={0.75}>
        <Flex $direction="column" $gap={12}>
          <AutoApproveCandidates />
          <SendOutreachEmails />
          {featureFlags?.includes(FeatureFlags.CALENDAR) && <AutoScheduleMeetings />}
          <AutoArchiveCandidates />
          {featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) && <ExportToATS />}
          <NudgeUnresponsiveCandidates />
          <Spacer $size={36} />
        </Flex>
      </Disabled>
    </>
  )
}

export default AutomationSettingsPage
