import styled, { css } from 'styled-components'

export const CompareDropdownItem = styled.div<{ $disabled?: boolean }>`
  width: 100%;
  padding: 4px 12px 4px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`
