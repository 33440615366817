import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { isUuid } from '../../libs/uuid'
import { fetchManualQueueItems } from 'src/libs/api/backend/manual_queue'
import type { ManualQueueQueryResult } from 'src/libs/api/backend/manual_queue'
import { queryKeys } from 'src/libs/query-keys'

export interface ManualQueueParams {
  jobId: string
  limit: number
}

export const useManualQueueQuery = ({ jobId, limit }: ManualQueueParams): UseQueryResult<ManualQueueQueryResult> => {
  return useQuery({
    queryKey: [queryKeys.manualQueue, jobId],
    queryFn: async () => await fetchManualQueueItems(jobId, limit),
    enabled: isUuid(jobId),
    staleTime: 60 * 1000
  })
}
