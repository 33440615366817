import { useEffect, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressBar } from 'src/components/primitives/progress-bar'
import * as S from './applicant-candidates-ats.styled'
import { Flex, Spacer } from 'src/components/primitives'
import { Spinner } from 'src/components/primitives/spinner'
import { Caption } from 'src/components/primitives/typography'
import { OnScreenSurveys } from '../on-screen-surveys'

interface ProgressStep {
  text: string
  percentage: number
}

const STEPS: ProgressStep[] = [
  {
    text: 'Importing applicants',
    percentage: 50
  },
  {
    text: 'Importing resumes',
    percentage: 60
  },
  {
    text: 'Ranking expertise and fit',
    percentage: 70
  },
  {
    text: 'Highlighting strengths',
    percentage: 80
  },
  {
    text: 'Showing results',
    percentage: 101
  }
]

const getCurrentStep = (progress: number): ProgressStep => {
  return STEPS.find((step, index) => {
    const nextStep = STEPS[index + 1]
    return progress <= step.percentage || !nextStep
  }) ?? STEPS[0]
}

interface ProgressStepsListProps {
  progress: number
}

const ProgressStepsList = ({ progress }: ProgressStepsListProps): JSX.Element => {
  const currentStep = getCurrentStep(progress)

  return (
    <S.ListWrapper>
      <S.List>
        <Flex $align="center" $gap={8}>
          <Spinner color="fgSecondary" />
          <Caption size="MD" $color="fgPrimary">
            {currentStep.text}
          </Caption>
        </Flex>
      </S.List>
    </S.ListWrapper>
  )
}

const TOTAL_TIME = 15 * 60 // Takes around 15 minutes to sync all candidates unfortunately
const TICK_TIME = 2 // Ticks every 2 seconds

interface LoadingCandidatesProps {
  updatedAt: Date
  isAttachmentsSyncing: boolean
  isCandidateSyncing: boolean
}

export const LoadingCandidates = ({ updatedAt, isAttachmentsSyncing, isCandidateSyncing }: LoadingCandidatesProps): JSX.Element => {
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + TICK_TIME / TOTAL_TIME
        return newProgress
      })
    }, TICK_TIME * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [updatedAt])

  useEffect(() => {
    if (progress < 50 && !isCandidateSyncing && isAttachmentsSyncing) {
      setProgress(51)
    }
  }, [isAttachmentsSyncing, isCandidateSyncing, progress])

  return (
    <StatusView
      pin="searching"
      animatePin
      $gap={0}
      $contentMaxWidth={2560}
    >
      <ProgressStepsList progress={progress} />
      <Spacer $size={36} />
      <ProgressBar progress={progress} />
      <OnScreenSurveys />
    </StatusView>

  )
}
