import styled from 'styled-components'

export const ScheduleDemo = styled.div`
  position: relative;
`

export const ScheduleDemoInner = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 769px;
  height: 100%;
  transform: translateX(-50%);
`
