import type { OutreachReplies } from 'src/libs/api/backend/reports_communication_insights'
import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { PieChart } from 'src/components/charts/primitives/pie-chart'
import { useMemo } from 'react'
import { isNil } from 'lodash'

interface OutreachRepliesChartProps {
  data: OutreachReplies[]
}

const getPercentage = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    return 0
  }
  return Math.round((numerator / denominator) * 100)
}

export const OutreachRepliesChart = ({ data }: OutreachRepliesChartProps): JSX.Element => {
  const chartData = useMemo(() => {
    const total = data.reduce((sum, d) => sum + (d.replies ?? 0), 0)
    const pastTotal = data.reduce((sum, d) => sum + (d.pastReplies ?? 0), 0)
    return data.map((d) => {
      const value = getPercentage(d.replies, total)
      const pastValue = !isNil(d.pastReplies) ? getPercentage(d.pastReplies, pastTotal) : undefined
      const valueChangeDirection = !isNil(pastValue)
        ? value - pastValue > 0
          ? 'up' as const
          : 'down' as const
        : undefined
      return ({
        name: `Outreach Step ${d.position + 1}`,
        value,
        pastValue,
        valueChangeDirection
      })
    })
  }, [data])
  return (
    <ChartWrapper>
      <ChartHeader title="Outreach Replies" />
      <PieChart
        chartTitle="Outreach Replies"
        data={chartData}
      />
    </ChartWrapper>
  )
}
