import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BookADemoButton } from 'src/components/blocks/book-a-demo-button'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { Divider } from 'src/components/primitives/divider'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import RouteBuilder from 'src/libs/route-builder'
import { useWindowSize } from 'usehooks-ts'

const OnboardingWelcome = (): JSX.Element => {
  const { width } = useWindowSize()
  const navigate = useNavigate()

  useEffect(() => {
    if (width > 1024) {
      navigate(RouteBuilder.build('ONBOARDING_COMPANY_PROFILE'))
    }
  }, [])

  return (
    <>
      <OnboardingHeader
        heading="Welcome to Pin!"
        subheading="Finish setting up on your computer."
      />
      <Paragraph size="SM" $color="fgPrimary">We&rsquo;ve sent you an email reminder.</Paragraph>
      <Divider $spacing={{ before: 40, after: 40 }} />
      <Flex $direction="column" $gap={24}>
        <Flex $direction="column" $gap={8}>
          <Caption size="MD" $color="fgPrimary">Would you like to schedule a demo call with&nbsp;us?</Caption>
          <Paragraph size="SM" $color="fgSecondary">Our team can help you get the most out of Pin.</Paragraph>
        </Flex>
        <BookADemoButton />
      </Flex>
    </>
  )
}

export default OnboardingWelcome
