import { Divider } from 'src/components/primitives/divider'
import * as S from './navigation.styled'
import { When } from '../when'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey, PinExtensionChromeStoreUrl } from 'src/constants'
import { useEffect, useMemo } from 'react'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Button, Flex, Spacer } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Avatar } from 'src/components/primitives/avatar'
import { Tooltip } from 'src/components/primitives/tooltip'
import { sleep } from 'src/libs/sleep'
import { useSession } from 'src/hooks/use-session'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { useIsChrome } from 'src/hooks/use-is-browser'
import { useUserNotificationsCountQuery } from 'src/hooks/queries/use-user-notifications-count'
import { useQueryParams } from 'src/hooks/use-query-params'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { ProductTourStep } from 'src/libs/product-tour'
import { Badge } from 'src/components/primitives/badge'
import { NotificationBanners } from './notification-banners'
import { updateTrackedUser } from 'src/libs/track'
import CONFIG from 'src/config'

interface NavigationBottomProps {
  isCollapsed: boolean
}

export const NavigationBottom = ({ isCollapsed }: NavigationBottomProps): JSX.Element => {
  const {
    user: currentUser,
    org,
    emailAccountAccessTokens,
    impersonating,
    userOrgs
  } = useSession()
  const openDialog = useSetAtom(openDialogAtom)
  const extensionVersion = usePinExtensionVersion()
  const isChrome = useIsChrome()
  const { setParam } = useQueryParams()
  const { data: notificationsCountData } = useUserNotificationsCountQuery()
  const primaryEmail = emailAccountAccessTokens?.find(account => account.isPrimary) ?? emailAccountAccessTokens?.[0]
  const [submitFeedbackNoticed, setSubmitFeedbackNoticed] = useLocalStorage(LocalStorageKey.SUBMIT_FEEDBACK_NOTICED, false)
  const [chromeExtensionNoticed, setChromeExtensionNoticed] = useLocalStorage(LocalStorageKey.CHROME_EXTENSION_NOTICED, false)

  const isChromeExtensionNotInstalled = useMemo(() => {
    return isChrome && !extensionVersion
  }, [extensionVersion, isChrome])

  useEffect(() => {
    if (extensionVersion) {
      updateTrackedUser({
        chromeExtensionInstalled: true,
        chromeExtensionVersion: extensionVersion
      })
    } else {
      updateTrackedUser({
        chromeExtensionInstalled: false
      })
    }
  }, [extensionVersion])

  return (
    <S.Bottom>
      <Divider $color="bgTertiary" $spacing={{ before: 8, after: 8 }} />
      <When condition={!isCollapsed && !chromeExtensionNoticed && isChromeExtensionNotInstalled}>
        <a href={PinExtensionChromeStoreUrl} target='_blank' rel='noopener noreferrer'>
          <S.FeatureBanner>
            <BrandIcon name='chrome' size={16} color='original' />
            <Flex $flex='1'>
              <Caption size='XS' $fontWeight={400}>Install Chrome Extension</Caption>
            </Flex>
            <Button
              leadingIcon={Icons.x}
              $variant='ghost'
              $colorTheme='normal'
              $align='flex-end'
              $width={12}
              $height={12}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setChromeExtensionNoticed(true)
              }}
            />
          </S.FeatureBanner>
        </a>
      </When>
      <NotificationBanners isCollapsed={isCollapsed} />
      <When condition={!isCollapsed && !submitFeedbackNoticed}>
        <S.FeatureBanner $showArrow={true}>
          <DivAsButton
            ariaLabel='Open dialog to submit feedback'
            onClick={() => {
              if (!submitFeedbackNoticed) {
                setSubmitFeedbackNoticed(true)
              }
              openDialog({ id: DialogId.SEND_FEEDBACK })
            }}
          >
            <Flex $align="center" $gap={12}>
              <Icon name={Icons.megaphone} size={16} />
              <Flex $flex='1'>
                <Caption size='XS' $fontWeight={400}>Submit Feedback</Caption>
              </Flex>
            </Flex>
          </DivAsButton>
          <Button
            leadingIcon={Icons.x}
            $variant='ghost'
            $colorTheme='normal'
            $align='flex-end'
            $width={12}
            $height={12}
            onClick={() => {
              setSubmitFeedbackNoticed(true)
            }}
          />
        </S.FeatureBanner>
      </When>
      <Flex
        $direction={isCollapsed ? 'column' : 'row'}
        $gap={12}
        $align="center"
        $justify="space-between"
      >
        <Dropdown
          triggerFullWidth={!isCollapsed}
          trigger={
            <S.UserProfileButton $impersonating={impersonating ?? false} id={ProductTourStep.MENU_ACCOUNT_SETTINGS}>
              <Avatar
                $shape="circle"
                $type="photo"
                $size={24}
                initials={currentUser?.name}
                photoUrl={currentUser?.profilePhoto}
                ariaLabel="Toggle account menu"
              />
              <When condition={!isCollapsed}>
                <Caption as="span" $color="fgSecondary" size="SM" $ellipsis>
                  {currentUser?.name}
                </Caption>
              </When>
              {
                isCollapsed && notificationsCountData && notificationsCountData.count > 0
                  ? (
                      <S.BadgeWrapper $isFixed={true}>
                        <Badge $variant="tint">
                          {notificationsCountData.count > 20 ? '20+' : notificationsCountData.count}
                        </Badge>
                      </S.BadgeWrapper>
                    )
                  : <></>
              }
            </S.UserProfileButton>
          }
          modal={false}
          menuPosition="start"
          selectedValue={org?.id}
          items={[
            {
              id: 'account',
              title: currentUser?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            ...(userOrgs && userOrgs?.length > 1
              ? [
                  {
                    id: 'change-team-separator',
                    title: 'ChangeTeamSeparator',
                    type: 'node',
                    content: <Spacer $size={8} />
                  },
                  {
                    id: 'change-team',
                    title: 'Change Team',
                    type: 'item',
                    icon: (
                      <Avatar
                        $type="logo"
                        $size={16}
                        $border={false}
                        photoUrl={org?.logoUrl}
                        initials={org?.name}
                      />
                    ),
                    trailingIcon: Icons.chevronRight,
                    subitems: (() => {
                      const hasDuplicateOrgNames = userOrgs.some(org => userOrgs.filter(o => o.name === org.name).length > 1)

                      return userOrgs.map(userOrg => {
                        const redirectUrl = `${window.location.origin}/login/redirect`
                        const switchOrgUrl = `${CONFIG.API_DOMAIN}/auth/org/${userOrg.id}?redirect=${redirectUrl}`
                        const isCurrentOrg = userOrg.id === org?.id

                        return {
                          id: userOrg.id,
                          title: hasDuplicateOrgNames
                            ? <Flex $align="center" $gap={4} $width="fit-content">
                                <Caption size='XS'>{userOrg.name}</Caption>
                                <Paragraph size='XS'> • {userOrg.domain}</Paragraph>
                              </Flex>
                            : userOrg.name,
                          onSelect: () => { window.location.href = switchOrgUrl },
                          isSelected: isCurrentOrg,
                          trailingIcon: isCurrentOrg ? <Icon name={Icons.check} size={14} color="tintFg" /> : undefined,
                          icon: (
                            <Avatar
                              $type="logo"
                              $size={16}
                              $border={false}
                              photoUrl={userOrg?.logoUrl}
                              initials={userOrg?.name}
                            />
                          ),
                          type: 'item'
                        }
                      })
                    })()
                  }]
              : []
            ),
            {
              id: 'account-separator',
              title: 'AccountSeparator',
              type: 'separator'
            },
            ...(isChromeExtensionNotInstalled
              ? [{
                  id: 'install-extension',
                  title: 'Chrome Extension',
                  href: PinExtensionChromeStoreUrl,
                  target: '_blank',
                  icon: <BrandIcon name='chrome' size={16} color='original' />
                },
                {
                  id: 'account-separator-2',
                  title: 'AccountSeparator',
                  type: 'separator'
                }]
              : []
            ),
            ...(isCollapsed
              ? [{
                  id: 'notifications',
                  title: 'Notifications',
                  icon: Icons.bell,
                  trailingIcon: notificationsCountData && notificationsCountData.count > 0
                    ? (
                      <S.BadgeInDropdown>
                        <S.Badge>
                          {notificationsCountData.count > 20 ? '20+' : notificationsCountData.count}
                        </S.Badge>
                      </S.BadgeInDropdown>
                      )
                    : undefined,
                  onSelect: () => {
                    openDialog({ id: DialogId.NOTIFICATIONS, payload: { navigationIsCollapsed: isCollapsed } })
                  }
                },
                {
                  id: 'feedback',
                  title: 'Send Feedback',
                  icon: Icons.megaphone,
                  onSelect: () => {
                    if (!submitFeedbackNoticed) {
                      setSubmitFeedbackNoticed(true)
                    }
                    openDialog({ id: DialogId.SEND_FEEDBACK })
                  }
                }]
              : []
            ),
            {
              id: 'settings',
              title: 'Settings',
              icon: 'settings',
              onSelect: async () => {
                await sleep(10)
                setParam('settings', SettingParams.PROFILE)
                openDialog({ id: DialogId.DEFAULT_SETTINGS })
              }
              // href: jobId
              //   ? RouteBuilder.build('SETTINGS_JOB_ACCOUNT_PROFILE', { jobId })
              //   : RouteBuilder.build('SETTINGS_ACCOUNT_PROFILE')
            },
            {
              id: 'settings-separator',
              title: 'SettingsSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: '/logout'
            }
          ]}
        />
        <When condition={!isCollapsed}>
          <Flex $gap={4} $width='auto' $align='flex-end'>
            <Tooltip
              trigger={
                <S.FeatureButton
                  aria-label='Send feedback'
                  onClick={() => {
                    if (!submitFeedbackNoticed) {
                      setSubmitFeedbackNoticed(true)
                    }
                    openDialog({ id: DialogId.SEND_FEEDBACK })
                  }}
                >
                  <Icon name={Icons.megaphone} />
                </S.FeatureButton>
              }
            >
              Send feedback
            </Tooltip>
            <Tooltip
              trigger={
                <S.FeatureButton
                  aria-label="Notifications"
                  onClick={() => {
                    openDialog({ id: DialogId.NOTIFICATIONS, payload: { navigationIsCollapsed: isCollapsed } })
                  }}
                >
                  <Icon name={Icons.bell} />
                  {(notificationsCountData && notificationsCountData.count > 0)
                    ? (
                        <S.Badge>
                          {notificationsCountData.count > 20 ? '20+' : notificationsCountData.count}
                        </S.Badge>
                      )
                    : null
                  }
                </S.FeatureButton>
              }
            >
              Notifications
            </Tooltip>
          </Flex>
        </When>
      </Flex>
    </S.Bottom>
  )
}
