import type { EmailAccount, UserRole } from './api/backend/users'
import type { Org } from './api/backend/orgs'
import type { TrackingEventType } from 'src/types/track'
import posthog from 'posthog-js'
import type { Session } from './api/backend/session'

declare const window: Window & { dataLayer: Array<Record<string, unknown>> }

export const trackEvent = (
  event: TrackingEventType,
  properties?: Record<string, string | number | boolean | string[] | unknown>
): void => {
  console.debug('[TRACK]', {
    event,
    properties
  })
  if (process.env.NODE_ENV === 'development') {
    return
  }
  // Google Tag Manager
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({
    ...properties,
    event
  })
  // PostHog
  posthog.capture(event, properties)
}

export enum SubscriptionStatus {
  SUBSCRIBED = 'subscribed',
  TRIAL = 'trial',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  PAUSED = 'paused'
}

interface UserProperties {
  emailAccount?: Partial<EmailAccount>
  name: string
  org?: Org | null
  subscriptionStatus?: SubscriptionStatus | string
  subscriptionIssue?: Session['subscriptionIssue'] | boolean
  userRole?: UserRole
  chromeExtensionInstalled?: boolean
  chromeExtensionVersion?: string
  featureFlags?: string
  heardAbout?: string
}

export const identifyTrackedUser = (userId: string, userProperties: UserProperties): void => {
  const baseProperties = {
    emailAccountId: userProperties?.emailAccount?.id,
    emailAccountType: userProperties?.emailAccount?.type,
    orgId: userProperties?.org?.id,
    orgName: userProperties?.org?.name,
    orgDomain: userProperties?.org?.domain,
    userRole: userProperties?.userRole,
    subscriptionStatus: userProperties?.subscriptionStatus,
    chromeExtensionInstalled: userProperties?.chromeExtensionInstalled,
    chromeExtensionVersion: userProperties?.chromeExtensionVersion
  }

  console.debug('[IdentifyTrackedUser]', baseProperties)

  if (process.env.NODE_ENV === 'development') {
    return
  }

  posthog.identify(userId, {
    name: userProperties?.name,
    email: userProperties?.emailAccount?.email,
    ...baseProperties
  })
  if (userProperties?.org != null) {
    posthog.group('company', userProperties.org.id, {
      name: userProperties.org.name,
      domain: userProperties.org.domain,
      createdAt: userProperties.org.createdAt,
      subscriptionStatus: userProperties?.subscriptionStatus
    })
  }

  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({
    event: 'identifyUser',
    userId,
    subscriptionStatus: userProperties?.subscriptionStatus
  })
}

export const updateTrackedUser = (properties: Partial<UserProperties>): void => {
  console.debug('[UpdateTrackedUser]', properties)

  if (process.env.NODE_ENV === 'development') {
    return
  }

  posthog.capture('$set', {
    $set: properties
  })

  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push({
    event: 'updateUser',
    ...properties
  })
}

export const resetTracking = (): void => {
  window.dataLayer = window.dataLayer ?? []
  // @ts-expect-error documented function https://developers.google.com/tag-platform/devguides/datalayer#reset
  window.dataLayer.push(() => { this.reset() })
  posthog.reset()
}
