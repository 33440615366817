import { Button, Flex } from 'src/components/primitives'

import { useSession } from 'src/hooks/use-session'
import * as S from './navigation.styled'
import { Caption } from 'src/components/primitives/typography'
import { differenceInDays } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { sleep } from 'src/libs/sleep'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useSetAtom } from 'jotai'
import { SUBSCRIPTION_ISSUE } from 'src/libs/api/backend/session'
import { When } from '../when'
import CONFIG from 'src/config'
interface NotificationBannersProps {
  isCollapsed: boolean
}

export const NotificationBanners = ({ isCollapsed }: NotificationBannersProps): JSX.Element | null => {
  const {
    org,
    subscribed,
    trialActive,
    extendedTrialActive,
    subscriptionIssue,
    userHasViewerRole
  } = useSession()
  const { setParam } = useQueryParams()
  const openDialog = useSetAtom(openDialogAtom)

  const managePlanOnClick = useCallback(async () => {
    await sleep(10)
    setParam('settings', SettingParams.SUBSCRIPTION)
    openDialog({ id: DialogId.DEFAULT_SETTINGS })
  }, [setParam, openDialog])

  const extendedTrialDeadline = useMemo(() => {
    if (!org?.stripeSubscriptionTrialActiveUntil) {
      return null
    }
    const date = new Date(org.stripeSubscriptionTrialActiveUntil)
    return new Date(date.setDate(date.getDate() - 1))
  }, [org])

  if (isCollapsed) {
    return null
  }

  return (
    <>
      <When condition={!subscribed}>
        {trialActive && !!org?.trialActiveUntil &&
          <S.FeatureBanner $gap={8}>
            <Flex $flex='1'>
              <Caption size='XS' $fontWeight={400}>
                {differenceInDays(org.trialActiveUntil, new Date())} days left in trial
              </Caption>
            </Flex>
            <Button
              $variant='ghost'
              $colorTheme='tint'
              $align='flex-end'
              $height={24}
              $width='auto'
              $fontSize={12}
              onClick={managePlanOnClick}
              disabled={userHasViewerRole}
            >
              Manage plan
            </Button>
          </S.FeatureBanner>
        }
        {extendedTrialActive && extendedTrialDeadline &&
          <S.FeatureBanner $gap={8}>
            <Flex $flex='1'>
              <Caption size='XS' $fontWeight={400}>
                {differenceInDays(new Date(extendedTrialDeadline), new Date())} days left in trial
              </Caption>
            </Flex>
            <Button
              $variant='ghost'
              $colorTheme='tint'
              $align='flex-end'
              $height={24}
              $width='auto'
              $fontSize={12}
              onClick={managePlanOnClick}
              disabled={userHasViewerRole}
            >
              Manage plan
            </Button>
          </S.FeatureBanner>
        }
        {!trialActive && !extendedTrialActive &&
          <S.FeatureBanner $gap={8} $backgroundColor='negativeFaded'>
            <Flex $flex='1'>
              <Caption size='XS' $fontWeight={400}>Account expired</Caption>
            </Flex>
            <Button
              $variant='ghost'
              $colorTheme='negative'
              $align='flex-end'
              $height={24}
              $width='auto'
              $fontSize={12}
              onClick={managePlanOnClick}
              disabled={userHasViewerRole}
            >
              Manage plan
            </Button>
          </S.FeatureBanner>
        }
      </When>
      <When condition={subscriptionIssue === SUBSCRIPTION_ISSUE.PAYMENT_FAILED}>
        <S.FeatureBanner $backgroundColor='negativeFaded'>
          <Flex $flex='1'>
            <Caption size='XS' $fontWeight={400}>Payment failed</Caption>
          </Flex>
          <Button
            $variant='ghost'
            $colorTheme='negative'
            $align='flex-end'
            $height={24}
            $width='auto'
            $fontSize={12}
            href={`${CONFIG.API_DOMAIN}/billing/portal`}
            disabled={userHasViewerRole}
          >
            Manage
          </Button>
        </S.FeatureBanner>
      </When>
    </>
  )
}
