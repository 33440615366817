import { z } from 'zod'
import Api from '..'
import type { EmailSequenceStep } from 'src/models/sequence'

// TODO: Deprecate this
export enum MessageType {
  EMAIL = 'EMAIL',
  LINKEDIN_MAIL = 'LINKEDIN_MAIL'
}

export enum ManualMessageType {
  EMAIL = 'EMAIL',
  LINKEDIN = 'LINKEDIN'
}

export enum EditorMessageType {
  EMAIL = 'EMAIL',
  LINKEDIN_MAIL = 'LINKEDIN_MAIL',
  MANUAL_TASK = 'MANUAL_TASK'
}

export enum SequenceStepGenerationState {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export enum SequenceStepType {
  AUTOMATED_EMAIL = 'AUTOMATED_EMAIL',
  MANUAL_EMAIL = 'MANUAL_EMAIL',
  AUTOMATED_LINKEDIN_MESSAGE = 'AUTOMATED_LINKEDIN_MESSAGE',
  MANUAL_LINKEDIN_MESSAGE = 'MANUAL_LINKEDIN_MESSAGE',
  MANUAL_TASK = 'MANUAL_TASK',
  MANUAL_PHONE_CALL = 'MANUAL_PHONE_CALL',
  MANUAL_TEXT_MESSAGE = 'MANUAL_TEXT_MESSAGE'
}

export function isManualStep (stepType: SequenceStepType): boolean {
  return stepType === SequenceStepType.MANUAL_EMAIL ||
    stepType === SequenceStepType.MANUAL_TASK ||
    stepType === SequenceStepType.MANUAL_PHONE_CALL ||
    stepType === SequenceStepType.MANUAL_TEXT_MESSAGE
}

export const sequenceStepParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  subject: z.string().nullish(),
  body: z.string().nullable(),
  type: z.nativeEnum(SequenceStepType),
  position: z.number(),
  waitDays: z.number(),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid(),
  sendingEmailAlias: z.string().nullable(),
  sendingLinkedInAccountId: z.string().uuid().nullable(),
  sequenceId: z.string().uuid(),
  orgId: z.string().uuid(),
  deleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  generationState: z.nativeEnum(SequenceStepGenerationState).nullish(),
  personalizationInstructions: z.string().nullish(),
  lookupPhoneNumber: z.boolean().nullish()
})

export type SequenceStep = z.infer<typeof sequenceStepParser>

export const sequenceParser = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  jobId: z.string().uuid(),
  active: z.boolean()
})

export type Sequence = z.infer<typeof sequenceParser>

export enum SequenceReply {
  NEW_THREAD = 'NEW_THREAD',
  REPLY_TO_PREVIOUS_THREAD = 'REPLY_TO_PREVIOUS_THREAD'
}

const sequenceReplyType = z.nativeEnum(SequenceReply)

export type SequenceReplyType = z.infer<typeof sequenceReplyType>

const sequenceExpanded = sequenceParser.extend({
  sequenceSteps: z.array(sequenceStepParser)
})

export type SequenceExpanded = z.infer<typeof sequenceExpanded>

export async function fetchSequence (jobId: string): Promise<SequenceExpanded> {
  const { data } = await Api.get(`/jobs/${jobId}/sequence`, {
    include: ['steps']
  })
  return sequenceExpanded.parse(data)
}

export async function setSequenceActiveStatus (jobId: string, active: boolean): Promise<Sequence> {
  const { data } = await Api.put(`/jobs/${jobId}/sequence`, null, {
    active
  })
  return sequenceParser.parse(data)
}

const candidateEmailStatsParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orgId: z.string().uuid(),
  candidateId: z.string().uuid(),
  candidateSequenceId: z.string().uuid().nullable(),
  sequenceId: z.string().uuid().nullable(),
  sequenceStepId: z.string().uuid().nullable(),
  position: z.number().int().nullable(),
  candidateSequenceStepId: z.string().uuid().nullable(),
  manualMessageId: z.string().uuid().nullable(),
  ipAddress: z.string().nullable(),
  userAgent: z.string().nullable()
})

export const candidateEmailOpensParser = candidateEmailStatsParser.nullish()

export type CandidateEmailOpens = z.infer<typeof candidateEmailOpensParser>

export const candidateEmailLinkOpensParser = candidateEmailStatsParser.extend({
  link: z.string().nullable()
}).nullish()

export type CandidateEmailLinkOpens = z.infer<typeof candidateEmailLinkOpensParser>

export const manualMessageParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  subject: z.string().nullable(),
  body: z.string(),
  candidateId: z.string().uuid(),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid().nullable(),
  // sendingLinkedInAccountId: z.string().uuid().nullable(),
  opened: z.boolean(),
  openedAt: z.coerce.date().nullable(),
  openedCount: z.number(),
  cc: z.array(z.string()).nullable(),
  bcc: z.array(z.string()).nullable(),
  candidateEmailOpens: z.array(candidateEmailOpensParser).nullish(),
  candidateEmailLinkOpens: z.array(candidateEmailLinkOpensParser).nullish(),
  type: z.nativeEnum(ManualMessageType).nullable()
})

export type ManualMessage = z.infer<typeof manualMessageParser>

const sendManualMessageParser = z.object({
  subject: z.string().nullable(),
  body: z.string(),
  referenceEmailMessageId: z.string().uuid().optional(),
  candidateIds: z.array(z.string().uuid()),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid().nullable(),
  sendingEmailAlias: z.string().nullable(),
  sendingLinkedInAccountId: z.string().uuid().nullable(),
  cc: z.array(z.string().email()).optional(),
  bcc: z.array(z.string().email()).optional(),
  attachmentUploads: z.array(z.object({
    s3Key: z.string(),
    fileName: z.string()
  })).optional()
})

export type SendManualMessage = z.infer<typeof sendManualMessageParser>

interface SendManualMessageArgs {
  manualMessage: SendManualMessage
  messageType?: EditorMessageType
}

export async function sendManualMessage ({ manualMessage, messageType }: SendManualMessageArgs): Promise<ManualMessage[]> {
  const endpoint = messageType === EditorMessageType.LINKEDIN_MAIL ? '/candidates/manual_message/linkedin' : '/candidates/manual_message/email'
  const { data } = await Api.post(endpoint, null, manualMessage)
  const { manualMessages } = z.object({
    manualMessages: z.array(manualMessageParser)
  }).parse(data)

  return manualMessages
}

export interface SendTestEmailArgs {
  emails: string[]
  sequenceStep: EmailSequenceStep
  candidateId?: string | undefined
}

export async function sendTestEmail ({ emails, sequenceStep, candidateId }: SendTestEmailArgs): Promise<void> {
  const { status } = await Api.post(`/sequence_steps/${sequenceStep.id}/test_email`, null, { emails, candidateId })

  if (status !== 200) {
    throw new Error('Error sending test email')
  }
}

interface BulkReassignSequenceSenderArgs {
  originalAccountId: string
  updatedAccountId: string
}

export async function bulkReassignSequenceSender ({ originalAccountId, updatedAccountId }: BulkReassignSequenceSenderArgs): Promise<void> {
  const { status } = await Api.post('/sequences/bulk_reassign_sequence_sender', null, {
    originalAccountId,
    updatedAccountId
  })
  if (status !== 200) {
    throw new Error('Error updating candidate')
  }
}
