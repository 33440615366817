import { Logo } from 'src/components/primitives/logo'
import * as S from './trial-expired.styled'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Avatar } from 'src/components/primitives/avatar'
import { useEffect, useMemo, useState } from 'react'
import { useSession } from 'src/hooks/queries/use-session'
import { SubscriptionSettings } from '../subscription-settings'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { ClosePinAccountFeedback } from '../close-pin-account-feedback'
import { SUBSCRIPTION_ISSUE } from 'src/libs/api/backend/session'
import { useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'

export const TrialExpired = (): JSX.Element => {
  const { data: sessionContext } = useSession()
  const navigate = useNavigate()
  const [closeAccount, setCloseAccount] = useState(false)
  const { emailAccountAccessTokens, subscriptionIssue, org, trialActive, subscribed, extendedTrialActive } = sessionContext ?? {}

  useEffect(() => {
    if (!!trialActive || !!subscribed || !!extendedTrialActive) {
      navigate(RouteBuilder.build('ROOT'))
    }
  }, [trialActive, subscribed, extendedTrialActive, navigate])

  const primaryEmail = useMemo(() => {
    return emailAccountAccessTokens?.find(account => account.isPrimary) ?? emailAccountAccessTokens?.[0]
  }, [emailAccountAccessTokens])

  const notificationBanner = useMemo(() => {
    if (org?.stripeSubscriptionPaused) {
      return (
        <>
          <Caption size='SM' $color='warningFg'>Your Subscription is Paused.</Caption>
          <Paragraph size='SM' $color='warningFg'>Resume or manage your subscription below.</Paragraph>
        </>
      )
    }
    return (
      <>
        {subscriptionIssue === SUBSCRIPTION_ISSUE.SUBSCRIPTION_EXPIRED || subscriptionIssue === SUBSCRIPTION_ISSUE.EXTENDED_TRIAL_EXPIRED
          ? <Caption size='SM' $color='warningFg'>Your subscription has expired.</Caption>
          : <Caption size='SM' $color='warningFg'>Your free trial period has ended.</Caption>
        }
        <Paragraph size='SM' $color='warningFg'>Subscribe below to continue using Pin.</Paragraph>
      </>
    )
  }, [org?.stripeSubscriptionPaused, subscriptionIssue])

  return (
    <S.Page>
      <S.Banner>
        <Logo variant="dark" size={64} />
        <Dropdown
          trigger={
            <S.UserProfileButton>
              <Avatar
                $shape="circle"
                $type="photo"
                $size={24}
                initials={sessionContext?.user?.name}
                photoUrl={sessionContext?.user?.profilePhoto}
                ariaLabel="Toggle account menu"
              />
            </S.UserProfileButton>
          }
          menuPosition="start"
          items={[
            {
              id: 'account',
              title: sessionContext?.user?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            {
              id: 'account-separator',
              title: 'AccountSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: '/logout'
            }
          ]}
        />
      </S.Banner>
      <S.Body>
        <S.TrialEndedNotificationContainer>
          { closeAccount
            ? <S.TrialCloseAccountBox>
                <ClosePinAccountFeedback
                  subscribed={false}
                  expired={true}
                  onClose={() => {
                    setCloseAccount(false)
                  }}
                />
              </S.TrialCloseAccountBox>
            : <S.TrialEndedBox>
                <SubscriptionSettings
                  expired={true}
                  notificationBanner={notificationBanner}
                  onCloseAccount={setCloseAccount}
                />
              </S.TrialEndedBox>
          }
        </S.TrialEndedNotificationContainer>
      </S.Body>
    </S.Page>
  )
}
