import { Banner } from 'src/components/blocks/banner'
import { EmailSettings } from 'src/components/blocks/email-settings'
import { PageHeader } from 'src/components/blocks/page-header'
import { When } from 'src/components/blocks/when'
import { SEO } from 'src/components/primitives/seo'
import { Spacer } from 'src/components/primitives/spacer'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'

const EmailPreferencesPage = (): JSX.Element => {
  const { userHasViewerRole } = useSession()

  return (
    <>
      <PageHeader heading="Email Preferences" />
      <SEO title="Email Preferences" />
      <When condition={!!userHasViewerRole}>
        <Banner $variant="warning" icon="alert-triangle">{viewerRoleNotice('make changes to these settings')}</Banner>
        <Spacer $size={24} />
      </When>
      <EmailSettings />
    </>
  )
}

export default EmailPreferencesPage
