import { queryOptions, useQuery } from '@tanstack/react-query'
import type { UseQueryResult } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { fetchAllCandidateJobsCommunicatingCounts } from 'src/libs/api/backend/candidate_jobs'
import type { AllCandidateJobsCommunicatingCounts } from 'src/libs/api/backend/candidate_jobs'

export const candidateJobsCommunicatingCountsQuery = () => queryOptions({
  queryKey: [queryKeys.candidateJobsCommunicatingCounts],
  queryFn: async () => await fetchAllCandidateJobsCommunicatingCounts(),
  staleTime: 60 * 1000
})

export const useCandidateJobsCommunicatingCountsQuery = (): UseQueryResult<AllCandidateJobsCommunicatingCounts> => {
  return useQuery(candidateJobsCommunicatingCountsQuery())
}
