import { COLUMN } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import * as S from './table.styled'
import { Fragment, useCallback, useMemo } from 'react'
import { isNil } from 'lodash'
import { Button } from '../button'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { useAtomValue, useSetAtom } from 'jotai'
import { clearSelectedRowsAtom, selectAllRowsAtom, selectedRowsAtom, setTableSortAtom, sortAtom } from 'src/stores/table'
import { isSourcingTableExpandedAtom } from 'src/stores/job-refinement'
import { Icons } from '../icon'

interface TableHeaderProps<T> {
  columns: Array<TableColumnDef<T>>
  setTableRows: React.Dispatch<React.SetStateAction<T[]>>
  tableRows: T[]
  originalTableData: T[]
  onToggleExpandableColumn?: (columnId: string) => void
  setColumns?: React.Dispatch<React.SetStateAction<Array<TableColumnDef<T>>>>
  selectionActions?: React.ReactNode
  stickyHeader?: boolean
}

export const TableHeader = <T extends { id: string }>({
  columns,
  setTableRows,
  tableRows,
  originalTableData,
  setColumns,
  selectionActions,
  stickyHeader = false
}: TableHeaderProps<T>): JSX.Element => {
  const sort = useAtomValue(sortAtom)
  const setTableSort = useSetAtom(setTableSortAtom)
  const selectedRows = useAtomValue(selectedRowsAtom)
  const clearSelectedRows = useSetAtom(clearSelectedRowsAtom)
  const selectAllRows = useSetAtom(selectAllRowsAtom)
  const setTableIsExpanded = useSetAtom(isSourcingTableExpandedAtom)

  const sortTableRows = useCallback((sortFn: ((rowA: T, rowB: T) => number) | undefined, direction: 'asc' | 'desc' | undefined) => {
    if (!sortFn) {
      return
    }
    const sortedTableRows = [...originalTableData]
    if (direction === 'asc') {
      sortedTableRows.sort(sortFn)
    } else if (direction === 'desc') {
      sortedTableRows.sort((rowA, rowB) => sortFn(rowB, rowA))
    }
    setTableRows(sortedTableRows)
  }, [originalTableData, setTableRows])

  const { selectedRowsCount, allRowsSelected } = useMemo(() => {
    const selectedRowsCount = selectedRows.size
    return {
      selectedRowsCount,
      allRowsSelected: selectedRowsCount === tableRows.length
    }
  }, [selectedRows.size, tableRows.length])

  const handleExpandableColumnVisibility = useCallback((columnId: string, dependentExpandableColumnId: string, newState: boolean) => {
    setTableIsExpanded(prev => ({
      ...prev,
      [dependentExpandableColumnId]: newState
    }))
    setColumns?.(prev => prev.map((prevCol) => {
      if (prevCol.columnId === columnId) {
        return { ...prevCol, collapsed: newState }
      } else if (prevCol.columnId === dependentExpandableColumnId) {
        return { ...prevCol, collapsed: !newState }
      }
      return prevCol
    }))
  }, [setColumns, setTableIsExpanded])

  const isActionSelected = useMemo(() => {
    return selectedRowsCount >= 1 && selectionActions
  }, [selectedRowsCount, selectionActions])

  const selectionColumn = useMemo(() => (
    <IndeterminateCheckbox
      checked={allRowsSelected}
      indeterminate={selectedRowsCount > 0}
      name={COLUMN.SELECTION_COLUMN_ID}
      onChange={() => {
        if (allRowsSelected) {
          // If all rows are selected, we want to clear the selection
          clearSelectedRows()
        } else {
          // If some rows are selected or none are selected, we want to select all rows
          selectAllRows(tableRows.map(row => row.id))
        }
      }}
    />
  ), [allRowsSelected, selectedRowsCount, clearSelectedRows, selectAllRows, tableRows])

  const sortColumn = useCallback((columnId: string, sortingFn?: (rowA: T, rowB: T) => number) => {
    if (sortingFn) {
      if (sort?.id === columnId) {
        if (sort.direction === 'asc') {
          setTableSort(undefined)
          sortTableRows(sortingFn, undefined)
        } else {
          setTableSort({ id: columnId, direction: 'asc' })
          sortTableRows(sortingFn, 'asc')
        }
      } else {
        setTableSort({ id: columnId, direction: 'desc' })
        sortTableRows(sortingFn, 'desc')
      }
    }
  }, [sort, setTableSort, sortTableRows])

  if (isActionSelected) {
    return (
      <S.Head $stickyHeader={stickyHeader}>
        <tr>
          <S.ColumnHead>
            <S.ColumnHeadInner>
              {selectionColumn}
            </S.ColumnHeadInner>
          </S.ColumnHead>
          <S.HeadActions
            style={{
              gridColumn: `span ${columns.length - 1}`
            }}
          >
            <Caption size="XS" $whiteSpace="nowrap">
              {selectedRowsCount} selected
            </Caption>
            {selectionActions}
          </S.HeadActions>
        </tr>
      </S.Head>
    )
  }

  return (
    <S.Head $stickyHeader={stickyHeader}>
      <tr>
        {columns.map((column) => {
          if (column.customizedHeader && !column.collapsed) {
            return <Fragment key={column.columnId}>{column.header}</Fragment>
          }
          let header = column.collapsed && column.collapsedHeader
            ? column.collapsedHeader
            : column.header
          if (column.columnId === COLUMN.SELECTION_COLUMN_ID) {
            header = selectionColumn
          }
          let sortButton = null
          if (!isNil(column.sortingFn) && !column.collapsed) {
            sortButton = (
              <Button
                $variant="ghost"
                $colorTheme="muted"
                leadingIcon={
                  sort?.id !== column.columnId
                    ? Icons.arrowUpDown
                    : sort?.direction === 'asc'
                      ? Icons.arrowUpNarrowWide
                      : Icons.arrowDownWideNarrow
                }
                $width={12}
                $height={12}
                iconSize={10}
                onClick={(e) => {
                  e.stopPropagation()
                  sortColumn(column.columnId, column.sortingFn)
                }}
              />
            )
          }
          let expandButton = null
          if (column.dependentExpandableColumnId) {
            expandButton = (
              <Button
                $variant="flat"
                $colorTheme="muted"
                leadingIcon={column.collapsed ? Icons.chevronsLeft : Icons.chevronsRight}
                $width={12}
                $height={12}
                iconSize={10}
                onClick={() => {
                  if (column.dependentExpandableColumnId) {
                    handleExpandableColumnVisibility(column.columnId, column.dependentExpandableColumnId, !column.collapsed)
                  }
                }}
              />
            )
          }
          return (
            <S.ColumnHead
              key={column.columnId}
              colSpan={column.colSpan ?? 1}
              $sortable={!!column.sortingFn}
              $hasDependentColumn={!!column.dependentExpandableColumnId}
              $hasTitle={header?.props.title}
              onClick={() => {
                sortColumn(column.columnId, column.sortingFn)
              }}
            >
              <S.ColumnHeadInner $justify={column.headerAlign}>
                {header}
                {(!!sortButton || !!expandButton) && (
                  <Flex $align="center" $justify="space-between" $gap={8} $width="auto">
                    {sortButton}
                    {expandButton}
                  </Flex>
                )}
              </S.ColumnHeadInner>
            </S.ColumnHead>
          )
        })}
      </tr>
    </S.Head>
  )
}
