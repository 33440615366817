import styled from 'styled-components'
import type { ChartLegendStyleProps } from './chart-legend'

export const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export const LegendInner = styled.ul<ChartLegendStyleProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ $orientation }) => $orientation};
  list-style-type: none;
  ${({ $orientation }) =>
    $orientation === 'row' &&
    `
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
  `}
  ${({ $orientation }) =>
    $orientation === 'column' &&
    `
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: center;
  `}
  li {
    width: ${({ $orientation }) => $orientation === 'column' ? '100%' : 'auto'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
`

export const Icon = styled.span<{ $color: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: ${({ $color }) => $color};
`

export const ValueChange = styled.div`
  min-width: 30px;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
`
