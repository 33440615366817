import { AnimatePresence } from 'framer-motion'
import { useState, useEffect, useMemo } from 'react'
import { Form, Textarea } from 'src/components/forms'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import { useForm } from 'src/hooks/use-form'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { z } from 'zod'
import * as S from './surveys.styled'
import { Spacer } from 'src/components/primitives/spacer'
import { BookADemoButton } from '../book-a-demo-button'
import { Icon } from 'src/components/primitives/icon'
import { useMergeIntegrationsListQuery } from 'src/hooks/queries/use-merge-integrations-list'
// import { updateTrackedUser } from 'src/libs/track'
import { isNil, shuffle } from 'lodash'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { NpsSurvey } from './nps-survey'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'

enum SurveyWrapperView {
  FORM = 'FORM',
  SUCCESS = 'SUCCESS'
}

interface SurveyWrapperProps {
  view?: SurveyWrapperView
  formContent: React.ReactNode
  successContent: React.ReactNode
}

const SurveyWrapper = ({
  formContent,
  successContent,
  view = SurveyWrapperView.FORM
}: SurveyWrapperProps): JSX.Element => {
  return (
    <S.SurveyWrapper>
      <AnimatePresence mode="wait" presenceAffectsLayout>
        <S.SurveyWrapperView
          key={view}
          initial={view === SurveyWrapperView.FORM ? false : { opacity: 0, y: 20, height: 0 }}
          animate={{ opacity: 1, y: 0, height: 'auto' }}
          exit={{ opacity: 0, y: -20, height: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          {view === SurveyWrapperView.FORM ? formContent : successContent}
        </S.SurveyWrapperView>
      </AnimatePresence>
    </S.SurveyWrapper>
  )
}

interface SurveyHeadingProps {
  children: React.ReactNode
}

export const SurveyHeading = ({ children }: SurveyHeadingProps): JSX.Element => {
  return (
    <>
      <Caption size="SM" $color="fgPrimary" $align="center">
        {children}
      </Caption>
      <Spacer $size={24} />
    </>
  )
}

export const WhatAtsSurvey = (): JSX.Element => {
  const [view, setView] = useState<SurveyWrapperView>(SurveyWrapperView.FORM)
  const { data: mergeIntegrations = [] } = useMergeIntegrationsListQuery()

  const filteredIntegrations = useMemo(() => {
    const listedAts = ['applicantstack', 'ashby', 'bamboohr', 'breezy', 'cats', 'clayhr', 'clockwork']
    return mergeIntegrations.filter(integration => listedAts.includes(integration.slug))
  }, [mergeIntegrations])

  const handleSubmit = (atsName: string): void => {
    // updateTrackedUser({ ats: atsName })
    console.log('atsName', atsName)
    setTimeout(() => {
      setView(SurveyWrapperView.SUCCESS)
    }, 500)
  }

  return (
    <SurveyWrapper
      view={view}
      formContent={
        <>
          <SurveyHeading>Which ATS do you use?</SurveyHeading>
          <S.WhatAtsSurveyContent>
            <Button onClick={() => { handleSubmit('None') }}>I don't use an ATS</Button>
            {filteredIntegrations.map((integration) => (
              <Button
                key={integration.slug}
                $variant="raised"
                $width='full'
                $colorTheme="normal"
                leadingIcon={<img src={integration.square_image} width={32} height={32} />}
                onClick={() => { handleSubmit(integration.name) }}
              >
                {integration.name}
              </Button>
            ))}
            <Button onClick={() => { handleSubmit('Other') }}>Other</Button>
          </S.WhatAtsSurveyContent>
        </>
      }
      successContent={
        <Caption size="SM" $color="fgPrimary" $align="center">
          Thank you for submitting your answer!
        </Caption>
      }
    />
  )
}

const featureRequestSchema = z.object({
  request: z.string().optional()
})

type FeatureRequestSchema = z.infer<typeof featureRequestSchema>

export const FeatureRequestSurvey = (): JSX.Element => {
  const [view, setView] = useState<SurveyWrapperView>(SurveyWrapperView.FORM)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { submitFeedback } = useSubmitFeedback()

  const { submit, register } = useForm<FeatureRequestSchema>({
    schema: featureRequestSchema
  })

  const handleSubmit = async (formData: FeatureRequestSchema): Promise<void> => {
    setIsSubmitting(true)
    submitFeedback({
      feedback: {
        body: `Feature Request: ${formData.request}`,
        type: FeedbackType.FEATURE_REQUEST
      },
      onSuccess: () => {
        setIsSubmitting(false)
        setView(SurveyWrapperView.SUCCESS)
      }
    })
  }

  return (
    <SurveyWrapper
      view={view}
      formContent={
        <>
          <SurveyHeading>Do you have any feature requests?</SurveyHeading>
          <Form onSubmit={submit(handleSubmit)}>
            <Textarea
              name="featureRequest"
              label="Your feature request"
              hiddenLabel
              rows={4}
              register={register}
              $marginBottom={16}
            />
            <Button
              type="submit"
              $variant="fill"
              $colorTheme="tint"
              $height={40}
              loading={isSubmitting}
            >
              Send Feedback
            </Button>
          </Form>
        </>
      }
      successContent={
        <Caption size="SM" $color="fgPrimary" $align="center">
          Thank you for submitting your answer!
        </Caption>
      }
    />
  )
}

export const NetPromoterScoreSurvey = (): JSX.Element => {
  const [view, setView] = useState<SurveyWrapperView>(SurveyWrapperView.FORM)
  const openDialog = useSetAtom(openDialogAtom)
  const [lastCollectedNpsFeedbackAt] = useLocalStorage<string | null>(LocalStorageKey.LAST_COLLECTED_NPS_FEEDBACK_AT, null)

  useEffect(() => {
    if (!isNil(lastCollectedNpsFeedbackAt)) {
      setView(SurveyWrapperView.SUCCESS)
    }
  }, [lastCollectedNpsFeedbackAt])

  return (
    <SurveyWrapper
      view={view}
      formContent={
        <>
          <SurveyHeading>How likely are you to recommend Pin to a friend or colleague?</SurveyHeading>
          <NpsSurvey onScoreClick={(score) => { openDialog({ id: DialogId.NPS_SURVEY, payload: { selectedScore: score } }) }} />
        </>
      }
      successContent={
        <Caption size="SM" $color="fgPrimary" $align="center">
          Thank you for submitting your answer!
        </Caption>
      }
    />
  )
}

interface Benefit {
  heading: string
  description: string
  image?: string
  actions?: React.ReactNode
}

const BENEFITS: Benefit[] = [
  {
    heading: 'Move beyond basic keyword search',
    description: 'By cross referencing knowledge about companies, roles, and comparing other resumes, Pin can build a much more detailed profile for each candidate and interpret your requests with a deeper understanding of your needs.'
  },
  {
    heading: 'Streamline your workflow',
    description: 'Looking to save time and keep your recruiting pipeline moving? Pin’s automation features let you auto-approve sourced candidates, send outreach emails, schedule meetings, and more with ease.'
  },
  {
    heading: 'Connect your ATS to Pin to sync candidate data',
    description: 'Connect your ATS to Pin to import candidate information and communication, making it easier to manage your recruiting pipeline.'
  },
  {
    heading: 'Customize your calendar preferences',
    description: 'Set your preferred meeting days and times, auto-invite attendees, and skip scheduling on holidays.'
  },
  {
    heading: 'Manage your connected accounts',
    description: 'In your Connected Accounts, you can let your team send outreach emails from your account, add your email signature, and connect your LinkedIn to send InMail messages.'
  },
  {
    heading: 'Ask specific questions',
    description: 'Use the “Custom AI Question” criteria to ask anything about the user. Pin will interpret the data and find an answer to filter candidates for you.'
  },
  {
    heading: 'Optimize your email outreach',
    description: 'Visit your Email Preferences to adjust your email settings to match your recruitment goals. Set daily email limits, customize your sending schedule, and enable tracking to see when candidates open your emails or click on links.'
  },
  {
    heading: 'Export candidates to CSV',
    description: 'You can easily export candidate data from Pin to organize and manage your candidate information in CSV format.'
  },
  {
    heading: 'Share feedback on candidate search results',
    description: 'When downvoting candidate search results, you can provide feedback to let us know what you didn\'t like about the candidates. This helps us improve and generate candidates that better match your search criteria.'
  },
  {
    heading: 'Automated unsubscribe requests',
    description: 'Unsubscribe requests are now handled automatically. If someone replies with an unsubscribe request or a similar phrase, they’ll be immediately removed from the sequence.'
  },
  {
    heading: 'Outreach through multiple channels',
    description: 'When creating your outreach sequence you can contact candidates by Email, LinkedIn Messages, SMS and even create custom tasks.'
  },
  {
    heading: 'Personalize your outreach with AI',
    description: 'Take your emails to the next level with AI personalized sentence tags. Pin’s AI crafts tailored, engaging messages for every candidate by using your language, tone, job description, and their profile.'
  },
  {
    heading: 'Search for candidates’ phone numbers',
    description: 'You can search for a candidate\'s phone number directly within their profile after they\'ve been added to Outreach.'
  },
  {
    heading: 'Preview emails before sending',
    description: 'Send a test email to yourself to see how it appears to candidates and make adjustments before launching your outreach sequence.'
  },
  {
    heading: 'Run multiple searches for the same job',
    description: 'Easily find the right candidates by running multiple searches within the same job. Whether you\'re hiring for several positions or testing different criteria, this can help you discover diverse candidates tailored to your needs.'
  },
  {
    heading: 'Reach out to our team',
    description: 'If you’re feeling stuck or have questions on how to best use Pin, our support team can help you. Book a call to go over your specific needs.',
    actions: <Flex $direction="column"><Spacer $size={8} /><BookADemoButton /></Flex>
  },
  {
    heading: 'Refine your search with company ratings',
    description: 'Prioritize the companies that matter most in a candidate’s experience. You can specify your preferences in the Job Settings or rate companies as you encounter them while sourcing candidates.'
  },
  {
    heading: 'Seamlessly add candidates',
    description: 'If there’s candidates you’d like to add to Pin, you can add them through the Chrome extension, LinkedIn URLs, or CSV import.'
  },
  {
    heading: 'Manage your billing and plan',
    description: 'View your current plan, track your lookup credits, and easily purchase more when needed—all in the Billing & Plan dashboard.'
  }
]

export const PinBenefits = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [shuffledBenefits] = useState(() => shuffle(BENEFITS))

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prev => (prev + 1) % shuffledBenefits.length)
    }, 8000)
    return () => { clearInterval(interval) }
  }, [shuffledBenefits.length])

  const currentBenefit = shuffledBenefits[currentIndex]

  return (
    <S.PinBenefits>
      <AnimatePresence mode="wait">
        <S.PinBenefitsCard
          key={currentIndex}
          initial={{ opacity: 0, y: 16, scale: 1.04 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -16, scale: 0.96 }}
          transition={{ duration: 0.28, ease: 'easeOut' }}
        >
          {currentBenefit.image && <S.CardVisual />}
          <S.CardContent>
            <Flex $align="center" $gap={8}>
              <Icon name='sparkles-solid' size={16} color="aiSolidFg" />
              <Caption size="MD" $color="fgPrimary" $hasGradient>{currentBenefit.heading}</Caption>
            </Flex>
            <Paragraph size="SM" $color="fgSecondary">{currentBenefit.description}</Paragraph>
            {currentBenefit.actions}
          </S.CardContent>
        </S.PinBenefitsCard>
      </AnimatePresence>
    </S.PinBenefits>
  )
}
