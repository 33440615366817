import posthog from 'posthog-js'
import Api from '..'
import { z } from 'zod'

export enum FeedbackType {
  FEATURE_REQUEST = 'Feature request',
  CUSTOMER_REQUEST = 'Customer request',
  IDEA = 'Idea',
  BUG_REPORT = 'Bug report',
  NPS_SURVEY = 'NPS survey',
  OTHER = 'Other'
}

const submitFeedbackParser = z.object({
  body: z.string(),
  type: z.nativeEnum(FeedbackType),
  jobSearchRefinementId: z.string().optional(),
  url: z.string().optional()
})

export type SubmitFeedback = z.infer<typeof submitFeedbackParser>

export async function submitFeedbackApi (feedback: SubmitFeedback): Promise<boolean> {
  // trying to use posthog to track feedback
  posthog.capture('survey sent', {
    $survey_id: '01912527-76c8-0000-1063-7af8d0a84c84',
    $survey_response: feedback.body
  })

  await Api.post('/feedback/submit', null, feedback)

  return true
}

interface NpsFeedback {
  rating: number
  comment?: string
}

export async function submitNpsFeedbackApi (feedback: NpsFeedback): Promise<boolean> {
  const captureEvents = [
    posthog.capture('survey sent', {
      $survey_id: '0194ad12-b5c6-0000-9550-9e9af749f0f6',
      $survey_response: feedback.rating
    })
  ]

  if (feedback.comment) {
    captureEvents.push(
      posthog.capture('survey sent', {
        $survey_id: '0195000d-50e3-0000-440a-c1ecc2a961d8',
        $survey_response: feedback.comment
      })
    )
  }

  await Promise.all([
    ...captureEvents,
    Api.post('/feedback/submit', null, {
      body: `NPS survey submitted\nRating: ${feedback.rating}\nComment: ${feedback.comment}`,
      type: FeedbackType.NPS_SURVEY
    })
  ])
  return true
}
