import { useMemo } from 'react'
import { closeDialogAtom, controlDialogAtom, DialogId, isDialogOpenAtom } from 'src/stores/dialogs'
import { useAtomValue, useSetAtom } from 'jotai'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './manage-sequence-pause-dates-dialog.styled'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Button } from 'src/components/primitives/button'
import { Form } from 'src/components/forms/form'
import { useForm } from 'src/hooks/use-form'
import { DatePickerInput } from 'src/components/forms/date-picker'
import { AutoExportCandidateType } from 'src/libs/api/backend/jobs'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import { isNil } from 'lodash'
import { z } from 'zod'
import { format } from 'date-fns'
import { notifyErrorAtom } from 'src/stores/notifications'
import { useSession } from 'src/hooks/use-session'

interface FormData {
  date: Date
}

export const ManageSequencePauseDatesDialog = (): JSX.Element => {
  const { data: job } = useJobQuery()
  const { updateJobPosition } = useUpdateJobPosition()
  const notifyError = useSetAtom(notifyErrorAtom)
  const { userHasViewerRole } = useSession()

  const isDialogOpen = useAtomValue(
    useMemo(() => isDialogOpenAtom(DialogId.MANAGE_SEQUENCE_PAUSE_DATES), [])
  )
  const controlDialog = useSetAtom(controlDialogAtom)
  const closeDialog = useSetAtom(closeDialogAtom)

  const { submit, register, formData } = useForm<FormData>({
    schema: z.object({
      date: z.date()
    })
  })

  const submitButtonIsDisabled = useMemo(() => {
    return !formData.date
  }, [formData.date])

  const handleSubmit = (formData: FormData): void => {
    if (!job || !formData?.date) {
      return
    }

    const formattedDate = format(formData.date, 'yyyy-MM-dd')
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const validated = !job?.customHolidays?.includes(formattedDate) && formData.date > today

    if (!validated) {
      notifyError({
        message: formData.date < today ? 'Please select a date in the future.' : 'This date is already added as custom holiday.'
      })
      return
    }

    updateJobPosition({
      updatedJob: {
        ...job,
        salaryMin: job.salaryMin ?? undefined,
        salaryMax: job.salaryMax ?? undefined,
        workspace: job.workspace ?? undefined,
        autoExportCandidate: job.autoExportCandidate ?? AutoExportCandidateType.DISABLED,
        customHolidays: [...(job.customHolidays ?? []), formattedDate]
      },
      toastMessage: 'Added new custom holiday'
    })
  }

  const handleDeleteCustomHoliday = (customHoliday: string): void => {
    if (!job) {
      return
    }

    updateJobPosition({
      updatedJob: {
        ...job,
        salaryMin: job.salaryMin ?? undefined,
        salaryMax: job.salaryMax ?? undefined,
        workspace: job.workspace ?? undefined,
        autoExportCandidate: job.autoExportCandidate ?? AutoExportCandidateType.DISABLED,
        customHolidays: job.customHolidays?.filter(date => date !== customHoliday) ?? []
      },
      toastMessage: 'Deleted custom holiday'
    })
  }

  return (
    <Dialog.Root
      id={DialogId.MANAGE_SEQUENCE_PAUSE_DATES}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.MANAGE_SEQUENCE_PAUSE_DATES, newState: value })
      }}
      $maxWidth="640px"
      modal={false}
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Custom Dates"
          onClose={() => {
            closeDialog(DialogId.MANAGE_SEQUENCE_PAUSE_DATES)
          }}
        />
        <Dialog.Content>
          <S.Content>
            <Caption as="h3" size="SM" $color="fgSecondary">
              Pin won&rsquo;t send emails on these dates.
            </Caption>
            <S.AddDateSection>
              <Form onSubmit={submit(handleSubmit)}>
                <DatePickerInput
                  name="date"
                  register={register}
                  $height={34}
                />
                <Button
                  type="submit"
                  $variant="raised"
                  $colorTheme="tint"
                  $height={32}
                  $width="48px"
                  $fontSize={12}
                  $align="center"
                  disabled={submitButtonIsDisabled || userHasViewerRole}
                >
                  Add
                </Button>
              </Form>
            </S.AddDateSection>
            <S.CustomDatesList>
              {!isNil(job?.customHolidays) && job?.customHolidays?.length > 0
                ? (
                    job.customHolidays.map((customHoliday) => (
                      <S.CustomDatesListItem key={customHoliday}>
                        <Paragraph size="SM" $color="fgSecondary">
                          {customHoliday}
                        </Paragraph>
                        <span />
                        <Button
                          $variant="ghost"
                          $colorTheme="muted"
                          leadingIcon={Icons.trash2}
                          iconSize={16}
                          $width={20}
                          $height={20}
                          onClick={() => { handleDeleteCustomHoliday(customHoliday) }}
                          disabled={userHasViewerRole}
                        />
                      </S.CustomDatesListItem>
                    ))
                  )
                : (
                    <S.EmptyState>
                      <Icon name={Icons.calendar} size={24} color="fgTertiary" />
                      <Paragraph size="SM" $color="fgSecondary">No custom holidays added</Paragraph>
                    </S.EmptyState>
                  )}
            </S.CustomDatesList>
          </S.Content>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
