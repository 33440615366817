import { useDropzone } from 'react-dropzone'
import * as S from './file-drop-upload.styled'
import { Flex } from 'src/components/primitives/flex'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph, Span } from 'src/components/primitives/typography'
import { FieldError } from '../field-error'
import { When } from 'src/components/blocks/when'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { AttachmentUpload } from 'src/components/blocks/attachments-list'
import { AttachmentUploadStatus } from 'src/components/blocks/attachments-list'
import { generateUploadUrlApi } from 'src/libs/api/backend/url'
import axios from 'axios'
import { Button } from 'src/components/primitives'
import { isNil } from 'lodash'

type AcceptedFile = 'csv' | 'pdf'

interface FileDropUploadProps {
  heading?: string
  accept: AcceptedFile[]
  maxFileSize?: number
  multiple?: boolean
  onFileRemoved?: (fileName?: string) => void
  onUploadStart?: () => void
  setUploadedFile?: (file: File) => void
  onUpload: (urls: string[]) => void
  onUploadError?: (error: string) => void
  uploadedFileComponent?: React.ReactNode | null
}

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB

export const FileDropUpload = ({
  heading = 'Upload file',
  multiple = false,
  maxFileSize = MAX_FILE_SIZE,
  onUpload,
  accept,
  onUploadError,
  onFileRemoved,
  onUploadStart,
  setUploadedFile,
  uploadedFileComponent
}: FileDropUploadProps): JSX.Element => {
  const [_, setUploadedFiles] = useState<AttachmentUpload[]>([])
  const [uploadedFilesError, setUploadedFilesError] = useState<string | null>(null)

  const updateUploadedFileStatus = useCallback((url: string, file: Partial<AttachmentUpload>) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.map((uploads) => {
        if (uploads.s3Key === url) {
          return { ...uploads, ...file }
        }
        return uploads
      })
    )
  }, [])

  const handleError = useCallback((error: string) => {
    if (onUploadError) {
      onUploadError(error)
    } else {
      setUploadedFilesError(error)
    }
  }, [onUploadError])

  const preparedAcceptFileTypes = useMemo((): Record<string, string[]> => {
    const mimeTypes: Record<string, string[]> = {
      csv: ['text/csv'],
      pdf: ['application/pdf']
    }

    const prepared: Record<string, string[]> = {}
    accept.forEach((ext) => {
      if (mimeTypes[ext]) {
        mimeTypes[ext].forEach((mimeType) => {
          prepared[mimeType] = prepared[mimeType] || []
          prepared[mimeType].push(`.${ext}`)
        })
      }
    })
    return prepared
  }, [accept])

  const uploadAttachments = useCallback(
    async (files: File[]) => {
      handleError('')
      if (!files.length) {
        return
      }
      setUploadedFile?.(files[0])
      const uploadedFiles = Array.from(files)
      const totalFilesSize =
        uploadedFiles.reduce((acc, file) => acc + file.size, 0) +
        uploadedFiles.reduce((acc, uploads) => acc + uploads.size, 0)
      if (totalFilesSize > maxFileSize) {
        handleError('Uploaded files size exceeds the allowed limit')
        return
      }

      const uploadLinks = await Promise.all(
        uploadedFiles.map(async (file: File) => {
          const response = await generateUploadUrlApi(file.name)
          setUploadedFiles((prevUploadedFiles) => {
            return [
              ...prevUploadedFiles,
              {
                id: file.name,
                fileName: file.name,
                contentType: file.type,
                size: file.size,
                s3Key: response.key,
                status: AttachmentUploadStatus.UPLOADING
              }
            ]
          })
          return { ...response, file }
        })
      )

      const uploadedUrls = await Promise.all(
        uploadLinks.map(async ({ uploadSignedUrl, key, file }) => {
          try {
            await axios.put(uploadSignedUrl, file, {
              headers: {
                'Content-Type': file.type
              }
            })
            updateUploadedFileStatus(key, { status: AttachmentUploadStatus.UPLOADED })
            return key
          } catch (error) {
            updateUploadedFileStatus(key, { status: AttachmentUploadStatus.ERROR })
            return null
          }
        })
      )
      const validUploadedUrls = uploadedUrls.filter((url) => url !== null)
      onUpload(validUploadedUrls)
    },
    [handleError, setUploadedFile, maxFileSize, onUpload, updateUploadedFileStatus]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDropAccepted: (files) => {
      onUploadStart?.()
      void uploadAttachments(files)
    },
    onFileDialogOpen: () => {
      if (!multiple) {
        onFileRemoved?.()
      }
    },
    accept: preparedAcceptFileTypes,
    multiple,
    maxSize: maxFileSize
  })

  useEffect(() => {
    if (fileRejections?.length >= 1) {
      handleError('File type not allowed')
    }
  }, [fileRejections, handleError])

  return (
    <>
      <S.Wrapper
        $hasFile={acceptedFiles?.length >= 1}
        $isDragActive={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <Flex $gap={12} $direction="column" $align="center">
          <input {...getInputProps()} />
          <When condition={!acceptedFiles.length}>
            <Icon name="upload" size={24} color="fgSecondary" />
            <Flex $gap={4} $direction="column" $align="center">
              <Caption size="SM" $color="fgSecondary">
                {heading}
              </Caption>
              <Paragraph size="XS" $color="fgTertiary">
                <Span size="XS" $color="tintBg">
                  Choose files
                </Span>{' '}
                or drag and drop
              </Paragraph>
            </Flex>
          </When>
          {!isNil(uploadedFileComponent)
            ? uploadedFileComponent
            : <When condition={acceptedFiles?.length >= 1}>
                <Flex $gap={4} $align="center" $justify="center">
                  {acceptedFiles?.map((file) => (
                    <S.AcceptedFile key={file.name}>
                      <Icon name="file" color="fgTertiary" size={12} />
                      <Span>{file.name}</Span>
                      <Button
                        $variant="ghost"
                        $colorTheme="muted"
                        leadingIcon={Icons.x}
                        $height={16}
                        $width={16}
                        $borderRadius={4}
                        onClick={() => {
                          onFileRemoved?.(file.name)
                        }}
                      />
                    </S.AcceptedFile>
                  ))}
                </Flex>
              </When>
          }
        </Flex>
      </S.Wrapper>
      {uploadedFilesError && <FieldError>{uploadedFilesError}</FieldError>}
    </>
  )
}
