import { NavLink } from 'react-router-dom'
import * as StyledButton from 'src/components/primitives/button/button.styled'
import type { Color, Spacing } from 'src/styles/theme/types'
import styled, { css } from 'styled-components'

export const Navigation = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'flex-start')};
  justify-content: space-between;
  gap: 1rem;
  z-index: ${({ theme }) => theme.zindeces.navigation};
  padding: ${({ $isCollapsed }) => ($isCollapsed ? '1rem 0' : '1rem')};
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
`

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`

export const NavigationItems = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  width: 100%;
  ${({ $isCollapsed }) => $isCollapsed && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const Subitems = styled.div<{ $isCollapsed: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding-top: 2px;
  padding-left: 1.75rem;
  ${({ $isCollapsed }) => $isCollapsed && css`
    align-items: center;
    padding-left: 0;
  `}
  &:after {
    position: absolute;
    content: '';
    width: 2px;
    height: ${({ $isCollapsed }) => $isCollapsed ? 'calc(100% - 16px)' : '100%'};
    top: ${({ $isCollapsed }) => $isCollapsed ? '2px' : '0'};
    left: ${({ $isCollapsed }) => $isCollapsed ? '18px' : '17px'};
    background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    z-index: 2;
  }
`

export const SubitemIndicator = styled.span<{ $isActiveSubItem: boolean, $isCollapsed: boolean }>`
  position: relative;
  flex: 0 0 6px;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 999px;
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'translate(12px, 0)' : 'translate(15px, 0)')};
  background-color: ${({ $isActiveSubItem, theme }) =>
    theme.colors[$isActiveSubItem ? 'fgPrimary' : 'fgTertiary']};
  z-index: 2;
  ${({ $isCollapsed, $isActiveSubItem }) => ($isCollapsed && !$isActiveSubItem) && css`
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -4px;
      left: -4px;
      border: solid 4px;
      border-color: ${({ theme }) => theme.colors.bgSecondary};
      z-index: 1;
      border-radius: 999px;
    }
  `}
`

const subitemStyles = css<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  position: relative;
  width: ${({ $isCollapsed }) => ($isCollapsed ? '2rem' : '100%')};
  // height: 1.75rem;
  height: ${({ $isCollapsed }) => $isCollapsed ? '32px' : '28px'};
  display: flex;
  align-items: center;
  background-color: ${({ $isActiveSubItem, theme }) =>
    $isActiveSubItem ? theme.colors.fgTranslucent10 : 'transparent'};
  border-radius: 4px;
  padding-right: 0.2rem;
  z-index: 3;

  ${({ $isCollapsed, $isActiveSubItem }) => $isCollapsed && css`
    background-color: ${$isActiveSubItem ? '#E4E4E6' : 'transparent'};
  `}

  &:hover {
    background-color: ${({ $isActiveSubItem, theme }) => $isActiveSubItem ? theme.colors.fgTranslucent10 : theme.colors.fgTranslucent5};
    ${({ $isCollapsed }) => $isCollapsed && css`
      background-color: #E4E4E6;
    `}
  }

  ${StyledButton.Button} {
    background-color: transparent;
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
      }
    }
  }
  ${StyledButton.Inner} {
    max-width: 100%;
  }
  ${StyledButton.Content} {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }

  @media (hover: hover) {
    ${({ $isCollapsed }) => $isCollapsed && css`
      &:hover {
        ${SubitemIndicator} {
          &:before {
            display: none;
          }
        }
        ${SubitemIcon} {
          background-color: #E4E4E6;
        }
      }
    `}
  }
`

export const Subitem = styled(NavLink)<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  ${subitemStyles}
`

export const SubitemIcon = styled.span<{ $isActiveItem: boolean, $isActiveSubItem: boolean, $isCollapsed: boolean }>`
  position: relative;
  flex: 0 0 1rem;
  top: 0;
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: ${({ $isCollapsed }) => ($isCollapsed ? 'translate(7px, 0)' : 'translate(10px, 0)')};
  // background-color: ${({ $isActiveItem, $isActiveSubItem, theme }) => $isActiveSubItem ? theme.colors.borderOpaque : $isActiveItem ? theme.colors.bgTertiary : 'transparent'};
  background-color: ${({ theme, $isActiveSubItem }) => $isActiveSubItem ? 'transparent' : theme.colors.bgSecondary};
  cursor: pointer;
  z-index: 2;
`

export const SubitemIconExpanded = styled.span`
  position: relative;
  transform: translate(5px, 0);
  margin-right: 3px;
`

export const SubitemSeparator = styled.div<{ $isCollapsed: boolean }>`
  position: relative;
  padding: 0.5rem 0.875rem 0.25rem 0.35rem;
  z-index: 3;
  ${({ $isCollapsed }) => $isCollapsed && css`
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    p {
      transform: translateX(2px);
    }
  `}
`

export const SubitemButton = styled.button<{ $isActiveSubItem: boolean, $isCollapsed: boolean, $hasIcon: boolean }>`
  ${subitemStyles}
  outline: none;
  border: none;
`

export const NavigationItem = styled.div<{ $isActive: boolean, $isCollapsed: boolean, $isDisabled?: boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  ${({ $isDisabled }) => $isDisabled && css`
    opacity: 0.5;
    pointer-events: none;
    * {
      pointer-events: none !important;
    }
  `}
`

export const BadgeWrapper = styled.div<{ $isFixed?: boolean }>`
  min-width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ $isFixed }) => $isFixed && css`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  `}
`

export const Top = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: ${({ $isCollapsed }) => ($isCollapsed ? 'center' : 'space-between')};
  padding-left: ${({ $isCollapsed, theme }) => $isCollapsed ? '0' : theme.spacing[8]};
`

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const UserProfileButton = styled.div<{ $impersonating?: boolean }>`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => theme.spacing[6]};
  border-radius: 6px;
  ${({ $impersonating, theme }) => $impersonating && `
    border: 1px solid ${theme.colors.warningBg};
    background-color: ${theme.colors.warningTranslucent10};
  `}
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, $impersonating }) => $impersonating ? theme.colors.warningTranslucent10 : theme.colors.fgTranslucent10};
    }
  }
`

export const FeatureButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.colors.fgSecondary};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.fgTranslucent10};
    }
  }
`

export const Badge = styled.span`
  background-color: ${({ theme }) => theme.colors.tintBg};
  border-radius: ${({ theme }) => theme.spacing[9999]};
  color: ${({ theme }) => theme.colors.bgPrimary};

  padding: 2px 4.5px;
  font-size: 8px;
  font-weight: 500;
  position: absolute;
  top: 2px;
  right: 2px;
`

export const BadgeInDropdown = styled.div`
  position: relative;
  flex: 0 0 1rem;
  width: auto;
  min-width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${Badge} {
    position: relative;
    top: unset;
    right: unset;
  }
`

export const FeatureBanner = styled.div<{ $showArrow?: boolean, $gap?: Spacing, $backgroundColor?: Color }>`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: ${({ theme, $gap }) => theme.spacing[$gap ?? 12]};
  padding: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  background-color: ${({ theme, $backgroundColor }) => theme.colors[$backgroundColor ?? 'tintFaded']};
  color: ${({ theme }) => theme.colors.fgPrimary};
  ${({ $showArrow }) => $showArrow &&
    `
      &::after {
        content: '';
        position: absolute;
        right: 43.515px;
        bottom: -4.971px;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: inherit;
      }
    `
  }
`
