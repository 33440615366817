import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './communication-funnel-chart.styled'
import { Icon } from 'src/components/primitives/icon'
import type { CommunicationFunnel } from 'src/libs/api/backend/reports_communication_insights'
import { titleCase } from 'src/libs/string'
import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { When } from 'src/components/blocks/when'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'

interface CommunicationFunnelChartProps {
  data: CommunicationFunnel
}

const SUBTITLE = 'Funnel starts with candidates who were sent any message during the timeframe'

export const CommunicationFunnelChart = ({ data }: CommunicationFunnelChartProps): JSX.Element => {
  if (!data || Object.keys(data).length === 0) {
    return (
      <ChartWrapper>
        <ChartHeader
          title="Communication Funnel"
          subtitle={SUBTITLE}
        />
        <EmptyChart $minHeight="300px" />
      </ChartWrapper>
    )
  }

  const maxValue = Math.max(...Object.values(data).filter((value): value is number => value !== null))
  const isEmpty = maxValue === 0

  const preparedData = Object.entries(data)
    .filter((entry): entry is [string, number] => entry[1] !== null)
    .map(([label, value]) => ({
      label,
      value,
      percentage: maxValue === 0 ? 0 : Math.round((value / maxValue) * 100)
    }))
    .sort((a, b) => b.value - a.value)
    .map((item, index, array) => {
      const previousValue = index > 0 ? array[index - 1].value : item.value
      const valueChange =
        index > 0 && previousValue !== 0
          ? `${Math.round(((previousValue - item.value) / previousValue) * 100)}%`
          : ''

      return {
        ...item,
        valueChange
      }
    })

  return (
    <ChartWrapper>
      <ChartHeader
        title="Communication Funnel"
        subtitle={SUBTITLE}
      />
      <When condition={isEmpty}>
        <EmptyChart $minHeight="300px" $height="200px" />
      </When>
      <When condition={!isEmpty}>
        <S.Wrapper>
          {preparedData.map(({ label, value, percentage, valueChange }) => (
            <S.Row key={label}>
              <S.RowKey>
                <Caption size="XS" $color="fgPrimary">
                  {titleCase(label)}
                </Caption>
                <Paragraph size="XS" $color="fgSecondary">
                  {value.toLocaleString()}
                </Paragraph>
              </S.RowKey>
              <S.Bar>
                <S.BarFill $value={isEmpty ? 100 : percentage} $isEmpty={isEmpty}>
                  <S.BarFillBadge $hasOffset={percentage < 20}>
                    <span>{isEmpty ? 'Not enough data' : `${percentage}%`}</span>
                  </S.BarFillBadge>
                </S.BarFill>
                <S.RowChange>
                  {valueChange && (
                    <>
                      <Paragraph size="2XS" $color="fgTertiary">
                        {valueChange}
                      </Paragraph>
                      <Icon name="arrow-down" size={10} color="fgTertiary" />
                    </>
                  )}
                </S.RowChange>
              </S.Bar>
            </S.Row>
          ))}
        </S.Wrapper>
      </When>
    </ChartWrapper>
  )
}
