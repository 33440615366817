import { z } from 'zod'
import Api, { ApiError } from '..'

export interface ExtractJobRequirementsInput {
  title?: string
  location?: string
  content: string
}

const jobRequirementsParser = z.object({
  title: z.string().nullable(),
  location: z.string().nullable(),
  salaryMin: z.number().nullable(),
  salaryMax: z.number().nullable(),
  workspace: z.enum(['REMOTE', 'OFFICE', 'HYBRID']).nullable()
})

export type JobRequirements = z.infer<typeof jobRequirementsParser>

export async function extractJobRequirements (input: ExtractJobRequirementsInput): Promise<JobRequirements | null> {
  try {
    const { data } = await Api.post('/gpt/extract_job_requirements', null, input)
    return jobRequirementsParser.parse(data)
  } catch (error) {
    if (error instanceof ApiError) {
      return null
    }

    throw error
  }
}

export interface ExtractIdealCandidateInput {
  title?: string
  content: string
}

const idealCandidateParser = z.object({
  description: z.string().nullable()
})

export type IdealCandidate = z.infer<typeof idealCandidateParser>

export async function extractIdealCandidate (input: ExtractIdealCandidateInput): Promise<IdealCandidate | null> {
  try {
    const { data } = await Api.post('/gpt/extract_ideal_candidate', null, input)
    return idealCandidateParser.parse(data)
  } catch (error) {
    if (error instanceof ApiError) {
      return null
    }

    throw error
  }
}

export interface AiGeneratedSentenceParams {
  jobId: string
  candidateId?: string | undefined
  personalizationInstructions: string
  templateBody: string
}

const aiGeneratedSetence = z.object({
  aiGeneratedSentence: z.string()
})

export async function aiGeneratedSentence ({
  jobId,
  candidateId,
  personalizationInstructions,
  templateBody
}: AiGeneratedSentenceParams): Promise<string> {
  const { data } = await Api.post('/gpt/ai_generated_sentence', null, {
    jobId,
    candidateId,
    personalizationInstructions,
    templateBody
  })

  const { aiGeneratedSentence } = aiGeneratedSetence.parse(data)
  return aiGeneratedSentence
}
