import styled from 'styled-components'

export const ReadyToSendSender = styled.div`
  display: grid;
  grid-template-columns: 3.25rem 1fr;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing[12]}`};
  min-height: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};
  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};
  width: 100%;
`

export const ReadyToSendSubject = styled.div`
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[12]};
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.colors.fgTranslucent5};

  input {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: 500;
    border: none;
    color: ${({ theme }) => theme.colors.fgPrimary};
    outline: none;
  }
`

export const ReadyToSendBody = styled.div`
  width: 100%;
  height: 100%;
`

export const ReadyToSendToolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  gap: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
`
