import * as S from './ready-to-send-card.styled'
import { CandidateProfile } from '../candidate-details'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { useMemo } from 'react'
import { When } from '../when'
import { SequenceStatusDropdown } from '../candidate-job-status'
import { CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'

export interface ReadyToSendCardProps {
  candidateJob: CandidateJobExpanded
}

interface ReadyToSendCardComponentProps extends ReadyToSendCardProps {
  children: React.ReactNode
}

export const ReadyToSendCard = ({
  candidateJob,
  children
}: ReadyToSendCardComponentProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()
  const openDialog = useSetAtom(openDialogAtom)

  const candidateHasEmailError = useMemo(() => {
    const status = candidateJob.statusDisplay?.status
    return status === CandidateJobStatus.SEARCHING_FOR_EMAIL ||
      status === CandidateJobStatus.EMAIL_NOT_FOUND ||
      status === CandidateJobStatus.MISSING_EMAIL
  }, [candidateJob])

  return (
    <S.ReadyToSendContainer $hasError={candidateHasEmailError}>
      <When condition={candidateHasEmailError}>
        <S.ErrorHeader>
          <Caption size="XS" $color='negativeFg'>{candidateJob.statusDisplay?.title}</Caption>
        </S.ErrorHeader>
      </When>
      <S.ReadyToSendHeader>
        <S.ReadyToSendProfile
          onClick={() => {
            openDialog({ id: DialogId.CANDIDATE_DETAILS, payload: candidateJob.id })
          }}
        >
          <CandidateProfile
            avatarSize={32}
            headingSize='SM'
            horizontalGap={8}
            verticalGap={2}
            candidate={candidateJob.candidate}
            includeLatestExperienceTimeline={true}
          />
        </S.ReadyToSendProfile>
        <Flex $align='center' $width='auto'>
          <Flex $align='center' $gap={8} $width='auto'>
            <Button
              ariaLabel="Toggle favorite status"
              $variant="outline"
              $colorTheme={candidateJob.favorite ? 'warning' : 'muted'}
              $fontSize={12}
              $height={24}
              leadingIcon={candidateJob.favorite ? Icons.starFill : Icons.star}
              onClick={() => {
                toggleFavoriteStatus({
                  candidateJobId: candidateJob.id,
                  newFavoriteStatus: !candidateJob.favorite
                })
              }}
              tooltip={{
                text: userHasViewerRole ? viewerRoleNotice(candidateJob.favorite ? 'remove from shortlist' : 'add to shortlist') : (candidateJob.favorite ? 'Remove candidate from shortlist' : 'Add candidate to shortlist'),
                position: 'top'
              }}
              disabled={userHasViewerRole}
              $disabled={userHasViewerRole}
            />
            <SequenceStatusDropdown
              candidateJob={candidateJob}
              $variant='outline'
              enabledSendEmail={false}
            />
          </Flex>
        </Flex>
      </S.ReadyToSendHeader>
      <S.Inner>
        {children}
      </S.Inner>
    </S.ReadyToSendContainer>
  )
}
