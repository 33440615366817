import Api from '..'
import { z } from 'zod'
import type { FetchReportArgs } from './reports_types'
import { buildReportFetchParams } from './reports_utils'

const communicationInsightsOverviewPeriodParser = z.object({
  sent: z.number(),
  total: z.number(),
  responded: z.number(),
  interested: z.number(),
  active: z.number(),
  finished: z.number(),
  pending: z.number(),
  openedRate: z.number(),
  scheduled: z.number(),
  opened: z.number(),
  pastSent: z.number().nullish(),
  pastTotal: z.number().nullish(),
  pastResponded: z.number().nullish(),
  pastInterested: z.number().nullish(),
  pastActive: z.number().nullish(),
  pastFinished: z.number().nullish(),
  pastPending: z.number().nullish(),
  pastOpenedRate: z.number().nullish(),
  pastScheduled: z.number().nullish(),
  pastOpened: z.number().nullish()
})

export type CommunicationInsightsOverviewPeriod = z.infer<typeof communicationInsightsOverviewPeriodParser>

const communicationFunnelParser = z.object({
  sent: z.number(),
  pastSent: z.number().nullish(),
  opened: z.number(),
  pastOpened: z.number().nullish(),
  replied: z.number(),
  pastReplied: z.number().nullish(),
  interested: z.number(),
  pastInterested: z.number().nullish(),
  scheduled: z.number(),
  pastScheduled: z.number().nullish()
})

export type CommunicationFunnel = z.infer<typeof communicationFunnelParser>

const reportsOutreachRepliesDataPointSchema = z.object({
  stepId: z.string(),
  position: z.number(),
  replies: z.number().optional(),
  pastReplies: z.number().nullish()
})

export type ReportsOutreachRepliesDataPoint = z.infer<typeof reportsOutreachRepliesDataPointSchema>

const sequenceStepActivityParser = z.object({
  sequenceStep: z.object({
    id: z.string(),
    subject: z.string().nullish(),
    jobId: z.string().nullish(),
    jobTitle: z.string().nullish()
  }),
  user: z.object({
    id: z.string(),
    name: z.string(),
    profilePhoto: z.string().nullish()
  }),
  position: z.number(),
  sent: z.number(),
  opened: z.number(),
  replied: z.number(),
  scheduled: z.number()
})

export type SequenceStepActivity = z.infer<typeof sequenceStepActivityParser>

const topSenderParser = z.object({
  user: z.object({
    id: z.string(),
    name: z.string(),
    email: z.string().nullish(),
    profilePhoto: z.string().nullish()
  }),
  sent: z.number(),
  opened: z.number(),
  replied: z.number(),
  interested: z.number(),
  scheduled: z.number()
})

export type TopSender = z.infer<typeof topSenderParser>

const outreachRepliesParser = z.object({
  stepId: z.string().optional(),
  position: z.number(),
  replies: z.number(),
  pastReplies: z.number().nullish()
})

export type OutreachReplies = z.infer<typeof outreachRepliesParser>

const channelsRepliesParser = z.object({
  emailReplies: z.number(),
  linkedinReplies: z.number(),
  pastEmailReplies: z.number().nullish(),
  pastLinkedinReplies: z.number().nullish()
})

export type ChannelsReplies = z.infer<typeof channelsRepliesParser>

const messagesSentParser = z.object({
  date: z.string(),
  linkedin: z.number(),
  email: z.number()
})

export type MessagesSent = z.infer<typeof messagesSentParser>

const emailActivitiesParser = z.object({
  date: z.string(),
  outreach: z.number(),
  outgoing: z.number(),
  incoming: z.number()
})

export type EmailActivities = z.infer<typeof emailActivitiesParser>

const eventsByTimeParser = z.object({
  dayOfWeek: z.number(),
  hour: z.number(),
  count: z.number(),
  percentage: z.number(),
  friendlyDayOfWeek: z.string(),
  friendlyHour: z.string()
})

export type EventsByTime = z.infer<typeof eventsByTimeParser>

const eventsByTimeReportParser = z.object({
  dataPoints: z.array(eventsByTimeParser),
  timezone: z.string(),
  longestStreaks: z.array(z.object({
    start: z.number(),
    end: z.number(),
    friendlyStart: z.string(),
    friendlyEnd: z.string(),
    totalPercentage: z.number()
  }))
})

export type EventsByTimeReport = z.infer<typeof eventsByTimeReportParser>

const outreachConversionsParser = z.object({
  position: z.number(),
  conversionRate: z.number()
})

export type OutreachConversions = z.infer<typeof outreachConversionsParser>

const positionActivitiesParser = z.object({
  jobId: z.string().uuid(),
  jobTitle: z.string(),
  departmentId: z.string().uuid().nullish(),
  departmentName: z.string().nullish(),
  reviewed: z.number(),
  archived: z.number(),
  outreached: z.number(),
  read: z.number(),
  replies: z.number(),
  interested: z.number()
})

export type PositionActivities = z.infer<typeof positionActivitiesParser>

const communicationInsightsOverviewParser = z.object({
  overview: communicationInsightsOverviewPeriodParser,
  outreachConversions: z.array(outreachConversionsParser),
  communicationFunnel: communicationFunnelParser,
  sequenceStepActivities: z.array(sequenceStepActivityParser),
  topSenders: z.array(topSenderParser),
  outreachReplies: z.array(outreachRepliesParser),
  channelsReplies: channelsRepliesParser,
  messagesSent: z.array(messagesSentParser),
  emailActivities: z.array(emailActivitiesParser),
  emailsOpenedByTimeSent: eventsByTimeReportParser,
  repliesByTimeReceived: eventsByTimeReportParser,
  positionActivities: z.array(positionActivitiesParser).nullish()
})

export type CommunicationInsightsOverview = z.infer<typeof communicationInsightsOverviewParser>

export async function fetchCommunicationInsightsReport (args: FetchReportArgs): Promise<CommunicationInsightsOverview> {
  const params = buildReportFetchParams(args)
  const { data } = await Api.get('/reports/communication_insights', params)
  return communicationInsightsOverviewParser.parse(data)
}
