import { useParams } from 'react-router-dom'
import { PageHeader } from 'src/components/blocks/page-header'
import { SequenceEditor } from 'src/components/blocks/sequence-editor'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { Button } from 'src/components/primitives/button'
import { isNil } from 'lodash'
import type { EmailSequence } from 'src/models/sequence'
import { SequenceContentWrapper, SequenceWrapper } from 'src/components/layouts/settings/settings.styled'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { SEO } from '../../../components/primitives/seo'
import { EmpySequenceState } from 'src/components/blocks/empty-state/empty-sequence-state'
import { useSession } from 'src/hooks/use-session'

const JobSettingsSequencePage = (): JSX.Element => {
  const { jobId } = useParams()
  const { isPending, data: emailSequence } = useJobSequenceQuery(jobId)
  const { upsertJobSequence } = useUpsertJobSequence()
  const { userHasViewerRole } = useSession()

  if (isPending || isNil(jobId)) {
    return <></>
  }

  if (isSequenceStepsEmpty(emailSequence)) {
    return (
      <>
        <SEO title="Outreach sequence" />
        <EmpySequenceState />
      </>
    )
  }

  return (
    <>
      <SEO title="Outreach sequence" />
      <SequenceContentWrapper>
        <PageHeader
          heading="Outreach sequence"
          actions={
            <Button
              $variant="outline"
              $colorTheme="tint"
              $height={24}
              $fontSize={12}
              leadingIcon={emailSequence?.active ? 'pause-circle' : 'play-circle'}
              onClick={() => {
                const newState = !emailSequence?.active
                upsertJobSequence({
                  jobId,
                  active: newState,
                  toastMessage: newState ? 'Enabled sending outreach emails' : 'Paused sending outreach emails'
                })
              }}
              disabled={isSequenceStepsEmpty(emailSequence) || userHasViewerRole}
            >
              {emailSequence?.active ? 'Pause' : 'Resume'} outreach
            </Button>
          }
        />
        <SequenceWrapper>
          <SequenceEditor
            key={jobId}
            initialState={emailSequence as EmailSequence}
            shouldAutoGenerateEmails={true}
            // isEditable={!emailSequence.active}
            actions={{
              save: {
                children: 'Save outreach sequence'
              }
            }}
          />
        </SequenceWrapper>
      </SequenceContentWrapper>
    </>
  )
}

export default JobSettingsSequencePage
