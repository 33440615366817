import { isNil } from 'lodash'
import Api from '..'
import { z } from 'zod'
import { candidateSequenceStepMessageReviewExtendedParser } from './candidate_sequence_step_message_review'
import { manualTaskExpandedSerializer } from './manual_task'
import type { Job } from './jobs'

export const manualTaskExpandedWithTypeSerializer = manualTaskExpandedSerializer.extend({
  type: z.enum(['MANUAL_TASK'])
})

const candidateSequenceStepMessageReviewWithTypeParser = candidateSequenceStepMessageReviewExtendedParser.extend({
  type: z.enum(['CANDIDATE_SEQUENCE_STEP_MESSAGE_REVIEW'])
})

export const manualQueueItemSerilizer = z.union([
  manualTaskExpandedWithTypeSerializer,
  candidateSequenceStepMessageReviewWithTypeParser
])

export type ManualQueueItem = z.infer<typeof manualQueueItemSerilizer>

export type ManualQueueQueryResult = Record<ManualQueueItem['id'], ManualQueueItem>

export async function fetchManualQueueItems (
  jobId: string | undefined,
  limit: number
): Promise<ManualQueueQueryResult> {
  if (isNil(jobId)) {
    return {}
  }

  const { data } = await Api.get(`/jobs/${jobId}/manual_queue/list`, { limit })
  return z
    .record(z.string().uuid(), manualQueueItemSerilizer)
    .parse(data)
}

interface RefreshManualQueueItemsParams {
  jobId: Job['id']
}

export async function refreshManualQueueItems ({
  jobId
}: RefreshManualQueueItemsParams): Promise<void> {
  await Api.post(`/jobs/${jobId}/manual_queue/refresh`)
}
