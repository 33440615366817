import styled from 'styled-components'

export const SurveyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 552px;
`

export const SurveyButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const SurveyComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
