import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { Job, JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { updateJobSearchRefinement as updateJobSearchRefinementApi } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { useSetAtom } from 'jotai'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  jobId: Job['id']
  jobSearchRefinementId: JobSearchRefinement['id']
  title?: JobSearchRefinement['title']
  searchCriteria?: Criteria | undefined
  automateAutoApproveCandidates?: JobSearchRefinement['automateAutoApproveCandidates']
  onSuccess?: (jobSearchRefinement: JobSearchRefinement) => void
  onError?: () => void
}

interface Res {
  updateJobSearchRefinement: (args: Args) => void
}

export const useUpdateJobSearchRefinement = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, jobSearchRefinementId, searchCriteria, title, automateAutoApproveCandidates }: Args) =>
      await updateJobSearchRefinementApi({
        jobId,
        jobSearchRefinementId,
        searchCriteria,
        title,
        automateAutoApproveCandidates
      }),
    onMutate: async (variables: Args) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.jobSearchRefinements, variables.jobId] })

      const previousJobSearchRefinements = queryClient.getQueryData<JobSearchRefinement[]>([queryKeys.jobSearchRefinements, variables.jobId])

      queryClient.setQueryData<JobSearchRefinement[]>([queryKeys.jobSearchRefinements, variables.jobId], (oldJobSearchRefinements) => {
        if (!oldJobSearchRefinements) {
          return []
        }
        return oldJobSearchRefinements.map((oldJobSearchRefinement) =>
          oldJobSearchRefinement.id === variables.jobSearchRefinementId
            ? { ...oldJobSearchRefinement, ...variables }
            : oldJobSearchRefinement
        )
      })

      return { previousJobSearchRefinements }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData([queryKeys.jobSearchRefinements, variables.jobId], context?.previousJobSearchRefinements)

      notifyError({
        message: `An error occurred when updating your search: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (jobSearchRefinement, variables) => {
      queryClient.setQueriesData<JobSearchRefinement[]>(
        {
          queryKey: [queryKeys.jobSearchRefinements, variables.jobId]
        },
        (oldJobSearchRefinements) => {
          if (!oldJobSearchRefinements) {
            return [jobSearchRefinement]
          }
          return oldJobSearchRefinements.map((oldJobSearchRefinement) =>
            oldJobSearchRefinement.id === jobSearchRefinement.id
              ? jobSearchRefinement
              : oldJobSearchRefinement
          )
        }
      )
      queryClient.setQueriesData<JobSearchRefinement>(
        {
          queryKey: [queryKeys.jobSearchRefinement, jobSearchRefinement.jobId, jobSearchRefinement.id]
        },
        (_oldJobSearchRefinement) => {
          return jobSearchRefinement
        }
      )
      if (variables.onSuccess) {
        variables.onSuccess(jobSearchRefinement)
      }
    }
  })

  const updateJobSearchRefinement = (args: Args): void => {
    mutation.mutate(args)
  }

  return { updateJobSearchRefinement }
}
