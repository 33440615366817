import { useState } from 'react'
import { AddCandidateDestination } from './add-candidate-destination'
import { AddCandidateList } from './add-candidate-list'
import type { AddCandidatesState } from './type'

enum SubmissionStage {
  CANDIDATES_LIST = 'candidates_list',
  ADD_CANDIDATES = 'add_candidates'
}

interface AddCandidateFormProps {
  jobId: string
}

export const AddCandidateForm = ({ jobId }: AddCandidateFormProps): JSX.Element => {
  const [submissionStage, setSubmissionStage] = useState<SubmissionStage>(SubmissionStage.ADD_CANDIDATES)
  const [state, setState] = useState<AddCandidatesState>({
    uploadedFileS3Url: null,
    validationResult: null,
    validatedCandidateIdentifiers: null
  })

  if (submissionStage === SubmissionStage.ADD_CANDIDATES) {
    return (
      <AddCandidateList
        jobId={jobId}
        callback={() => {
          setSubmissionStage(SubmissionStage.CANDIDATES_LIST)
        }}
        state={state}
        setState={setState}
      />
    )
  }

  return (
    <AddCandidateDestination
      jobId={jobId}
      state={state}
    />
  )
}
