import { useMemo } from 'react'
import { Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './index.styled'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Avatar } from 'src/components/primitives/avatar'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { isNil } from 'lodash'
import { DEGREE_OPTIONS, TIME_SCOPE_OPTIONS } from '../constants'
import { StandaloneSwitch } from '../../standalone-switch'
import type { TimeScope } from 'src/libs/api/backend/candidate_search'

export type CriteriaPillValue = boolean | number | TimeScope | null

interface CriteriaPillProps {
  value: {
    label?: string
    subtitle?: string
    icon?: string
    optional?: boolean
    negative?: boolean
    required?: boolean
    radiusInMiles?: number | null
    degree?: number | null
    timeScope?: TimeScope
  }
  onRemove: () => void
  onUpdate?: (key: string, value: CriteriaPillValue) => void
  useOptional?: boolean
  useNegative?: boolean
  useRange?: boolean
  tooltip?: string
}

export const CriteriaPill = ({
  value,
  onUpdate,
  onRemove,
  useOptional = true,
  useNegative = true,
  useRange = false,
  tooltip
}: CriteriaPillProps): JSX.Element => {
  const actionItems = useMemo((): MenuItemProps[] => {
    const items: MenuItemProps[] = []
    if (useOptional && onUpdate) {
      items.push({
        id: 'optional-switch',
        title: 'Optional',
        type: 'node',
        content: (
          <S.CriteriaSwitchContainer>
            <Flex $flex='1 1 0' $justify='flex-start' $gap={4} $align='center'>
              <Icon name={Icons.pin} size={12} color={value.optional ? 'fgTertiary' : 'tintBg'} />
              <Caption size='XS' $fontWeight={400}>Required</Caption>
            </Flex>
            <Flex $flex='1 1 0' $justify='flex-end' $gap={6} $align='center'>
              <Tooltip
                trigger={
                  <span>
                    <Icon name={Icons.info} size={12} color='fgTertiary' />
                  </span>
                }
              >
                If Required, this criteria will be used to filter out candidates.
              </Tooltip>
              <StandaloneSwitch
                name='optional-required'
                $variant='ghost'
                defaultChecked={!value.optional}
                onChange={(checked) => {
                  onUpdate('optional', !checked)
                }}
              />
            </Flex>
          </S.CriteriaSwitchContainer>
        )
      })
    }
    if (useNegative && onUpdate) {
      items.push({
        id: 'inverse-switch',
        title: 'Inverse',
        type: 'node',
        content: (
          <S.CriteriaSwitchContainer>
            <Flex $flex='1 1 0' $justify='flex-start' $gap={4} $align='center'>
              <Icon name={Icons.ban} size={12} color={value.negative ? 'negativeBg' : 'fgTertiary'} />
              <Caption size='XS' $fontWeight={400}>Inverse</Caption>
              <Paragraph size='XS' $fontWeight={400}>(Not)</Paragraph>
            </Flex>
            <Flex $flex='1 1 0' $justify='flex-end' $gap={6} $align='center'>
              <Tooltip
                trigger={
                  <span>
                    <Icon name={Icons.info} size={12} color='fgTertiary' />
                  </span>
                }
              >
                When Inverse is enabled with Required, candidates matching this criteria will be excluded. If Optional, candidates matching this criteria may be excluded but are not automatically filtered out.
              </Tooltip>
              <StandaloneSwitch
                name='inverse-switch'
                $variant='ghost'
                defaultChecked={value.negative}
                onChange={(checked) => {
                  onUpdate('negative', checked)
                }}
              />
            </Flex>
          </S.CriteriaSwitchContainer>
        )
      })
    }
    if (useRange && onUpdate) {
      items.push({
        id: 'range-separator',
        title: 'Range Separator',
        type: 'separator'
      }, {
        id: 'range-label',
        title: 'Search Radius',
        type: 'label'
      }, {
        id: 'exact-location',
        title: 'Exact Location',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', null)
        },
        isSelected: isNil(value.radiusInMiles)
      }, {
        id: 'within-5-miles',
        title: 'Within 5 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 5)
        },
        isSelected: value.radiusInMiles === 5
      }, {
        id: 'within-10-miles',
        title: 'Within 10 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 10)
        },
        isSelected: value.radiusInMiles === 10
      }, {
        id: 'within-20-miles',
        title: 'Within 20 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 20)
        },
        isSelected: value.radiusInMiles === 20
      }, {
        id: 'within-50-miles',
        title: 'Within 50 Miles',
        type: 'item',
        onSelect: () => {
          onUpdate('radiusInMiles', 50)
        },
        isSelected: value.radiusInMiles === 50
      })
    }
    if ('degree' in value && onUpdate) {
      items.push({
        id: 'degree-separator',
        title: 'Degree Separator',
        type: 'separator'
      })
      for (const [degree, degreeLabel] of DEGREE_OPTIONS.entries()) {
        items.push({
          id: `degree-${degreeLabel}`,
          title: degreeLabel,
          type: 'item',
          onSelect: () => {
            onUpdate('degree', degree)
          },
          isSelected: value.degree === degree
        })
      }
    }
    if ('timeScope' in value && onUpdate) {
      items.push({
        id: 'time-scope-separator',
        title: 'Time Scope Separator',
        type: 'separator'
      })
      items.push({
        id: 'time-scope-companies',
        title: 'COMPANIES',
        type: 'label'
      })
      for (const [timeScope, timeScopeLabel] of TIME_SCOPE_OPTIONS.entries()) {
        items.push({
          id: `time-scope-${timeScopeLabel}`,
          title: timeScopeLabel,
          type: 'item',
          onSelect: () => {
            onUpdate('timeScope', timeScope)
          },
          isSelected: value.timeScope === timeScope
        })
      }
    }
    if (!value.required) {
      if (items.length > 0) {
        items.push({
          title: 'delete-separator',
          type: 'separator'
        })
      }
      items.push({
        id: 'delete-pill',
        title: 'Remove',
        variant: 'negative',
        onSelect: () => {
          onRemove()
        }
      })
    }
    return items
  }, [onRemove, onUpdate, useNegative, useOptional, useRange, value])

  const hasIcon = useMemo(() => {
    return (
      !!value.icon ||
      (!value.optional && useOptional) ||
      (!!value.negative && useNegative) ||
      (!!value.required)
    )
  }, [value.icon, value.negative, value.optional, value.required, useNegative, useOptional])

  const trigger = useMemo(() => {
    return (
      <Tooltip
        position='top'
        disabled={!tooltip}
        trigger={
          <S.CriteriaPill $minWidth={70} $hasIcon={hasIcon}>
            <Flex $align='center' $gap={4} $width='auto' $flex='1 1 auto'>
              {!value.optional && useOptional && <Icon name={Icons.pin} size={12} color='tintBg' />}
              {value.negative && useNegative && <Icon name={Icons.ban} size={12} color='negativeBg' />}
              {value.required && <Icon name={Icons.lock} size={12} color='fgPrimary' />}
              {value.icon &&
                <Avatar
                  $size={16}
                  $innerPadding={0}
                  $border={false}
                  $type="logo"
                  initials={value.label}
                  company={{
                    name: value.label,
                    logoUrl: value.icon
                  }}
                />
              }
              <Caption size='XS' $fontWeight={400} $lineHeight={1}>{value.label}</Caption>
              {value.subtitle && (
                <Caption size="XS" $color="fgTertiary" $whiteSpace="nowrap" $ellipsis $fontWeight={400} $lineHeight={1}>
                  {value.subtitle}
                </Caption>
              )}
            </Flex>
            {actionItems.length > 0 && <Icon name={Icons.chevronDown} size={12} color='fgTertiary' />}
          </S.CriteriaPill>
        }
      >
        {tooltip}
      </Tooltip>
    )
  }, [actionItems.length, hasIcon, tooltip, useNegative, useOptional, value.icon, value.label, value.negative, value.optional, value.required, value.subtitle])

  if (actionItems.length === 0) {
    return trigger
  }

  return (
    <Dropdown
      size='small'
      trigger={trigger}
      items={actionItems}
    />
  )
}
