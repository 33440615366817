import { useBillingPricingQuery } from 'src/hooks/queries/use-billing-pricing'
import * as S from './subscription-settings.styled'
import { useSession } from 'src/hooks/use-session'
import { Button, Flex, Spacer } from 'src/components/primitives'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Icons } from 'src/components/primitives/icon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getCheckoutSessionUrlApi, PriceInterval, STRIPE_LOOKUP_KEY, STRIPE_PROFESSIONAL_KEYS } from 'src/libs/api/backend/billing'
import type { CustomerSubscription } from 'src/libs/api/backend/billing'
import { ButtonGroup } from 'src/components/primitives/button-group'
import { useTheme } from 'styled-components'
import CONFIG from 'src/config'
import { useCustomerSubscriptionsQuery } from 'src/hooks/queries/use-customer-subscriptions'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { Plan } from './plan'
import { BillingUsage } from './billing-usage'
import { Box } from 'src/components/primitives/box'
import { BenefitsList } from '../benefits-list'
import { BookADemoButton } from '../book-a-demo-button'
import { ExtendTrial } from './extend-trial'
import { isNil } from 'lodash'
import { LoadingSkeleton } from '../loading-skeleton'
import { When } from '../when'
import { Banner } from '../banner'
import { viewerRoleNotice } from 'src/libs/user-role-notice'

interface SubscriptionSettingsProps {
  notificationBanner?: React.ReactNode | null
  onCloseAccount?: (closeAccount: boolean) => void
  insufficientCredits?: boolean
  expired?: boolean
}

export const SubscriptionSettings = ({
  notificationBanner,
  onCloseAccount,
  insufficientCredits = false,
  expired = false
}: SubscriptionSettingsProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { data: pricing = [], isPending: isPricingPending } = useBillingPricingQuery()
  const { data, isPending: isCustomerSubscriptionsPending } = useCustomerSubscriptionsQuery()
  const { subscribed, org, userHasViewerRole, canExtendTrial } = useSession()
  const { colors } = useTheme()
  const [productInterval, setProductInterval] = useState<PriceInterval>(PriceInterval.month)
  const [items, setItems] = useState<Map<string, { price: string, quantity: number }>>(new Map())
  const [checkoutMode, setCheckoutMode] = useState<'payment' | 'subscription'>('payment')
  const { subscriptions = [], subscriptionActiveUntil, credit, orgUsers } = data ?? {}

  const subscriptionActuallyEnded = useMemo(() => {
    const subscriptionEndDate = org?.stripeSubscriptionActiveUntil ?? org?.stripeSubscriptionTrialActiveUntil
    if (!isNil(subscriptionEndDate)) {
      const endDate = new Date(subscriptionEndDate)
      endDate.setDate(endDate.getDate() - 1) // Subtract 1 day that we buffer on the BE
      endDate.setHours(endDate.getHours() + 1) // Add 1 hour for Stripe to finalize their invoice
      return new Date() > endDate
    }
    return expired
  }, [expired, org?.stripeSubscriptionActiveUntil, org?.stripeSubscriptionTrialActiveUntil])

  const userCurrentlyOnAnnualPlan = useMemo(() => {
    return subscriptions.some(subscription => subscription.interval === PriceInterval.year)
  }, [subscriptions])

  const professionalProduct = useMemo(() => {
    return pricing.find(product => {
      return product.prices.some(price => price.lookupKey && STRIPE_PROFESSIONAL_KEYS.includes(price.lookupKey))
    })
  }, [pricing])

  const creditProduct = useMemo(() => {
    return pricing.find(product => product.prices.some(price => price.lookupKey === STRIPE_LOOKUP_KEY.CREDIT_MONTHLY))
  }, [pricing])

  useEffect(() => {
    const currentProfessionalPriceId = professionalProduct?.prices.find(price => price.interval === productInterval)?.id
    if ((!subscribed || subscriptionActuallyEnded) && professionalProduct?.id && currentProfessionalPriceId && orgUsers?.activeUsersCount) {
      const currentItems = new Map([
        [professionalProduct.id, { price: currentProfessionalPriceId, quantity: orgUsers.activeUsersCount }]
      ])
      setItems(currentItems)
      setCheckoutMode('subscription')
    }
  }, [productInterval, orgUsers?.activeUsersCount, professionalProduct?.id, professionalProduct?.prices, subscribed, subscriptionActuallyEnded, org?.stripeSubscriptionActiveUntil])

  useEffect(() => {
    if (insufficientCredits && items.size === 0 && creditProduct?.id && creditProduct?.prices[0]?.id) {
      setItems(new Map([[creditProduct.id, { price: creditProduct.prices[0].id, quantity: 1 }]]))
    }
  }, [creditProduct?.id, creditProduct?.prices, insufficientCredits, items.size])

  const customersSubscriptionsByProductId = useMemo(() => {
    return subscriptions.reduce((acc: Record<string, CustomerSubscription>, subscription) => {
      return {
        ...acc,
        [subscription.productId]: subscription
      }
    }, {})
  }, [subscriptions])

  const orgHadSubscription = useMemo(() => {
    return !!org?.stripeSubscriptionActiveUntil || !!org?.stripeSubscriptionTrialActiveUntil
  }, [org?.stripeSubscriptionActiveUntil, org?.stripeSubscriptionTrialActiveUntil])

  const checkoutItems = useCallback(async () => {
    const itemsArray = Array.from(items.values())
    const { url } = await getCheckoutSessionUrlApi(itemsArray, checkoutMode)
    const checkoutWindow = window.open(url, '_blank')
    const timer = setInterval(() => {
      if (checkoutWindow?.closed) {
        clearInterval(timer)
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.customerSubscriptions]
        })
      }
    }, 500)
  }, [checkoutMode, items, queryClient])

  const orgCancelledOrPaused = useMemo(() => {
    return !!org?.stripeSubscriptionPaused || !!org?.stripeSubscriptionCancelled
  }, [org?.stripeSubscriptionPaused, org?.stripeSubscriptionCancelled])

  if (isPricingPending || isCustomerSubscriptionsPending) {
    return <LoadingSkeleton $variant='SubscriptionSettings' />
  }

  return (
    <>
      <When condition={!!userHasViewerRole}>
        <Banner $variant="warning" icon="alert-triangle">{viewerRoleNotice('make changes to your company billing')}</Banner>
        <Spacer $size={24} />
      </When>
      <Flex $direction='column' $gap={40}>
        {notificationBanner && (
          <S.NotificationBanner
            $paused={orgCancelledOrPaused}
          >
            {notificationBanner}
          </S.NotificationBanner>
        )}
        {!insufficientCredits && (
          <Flex $direction='column' $gap={16}>
            <S.Plan>
              <Caption size='MD'>{expired && !orgHadSubscription ? 'Choose a plan' : 'Plan'}</Caption>
              {(!!subscribed || orgHadSubscription)
                ? <Flex $align='center' $width='auto' $gap={8}>
                    {!userCurrentlyOnAnnualPlan &&
                      <Button $variant='outline' $colorTheme='muted' $height={24} href='mailto:hi@pin.com'>
                        <Flex $align='center' $gap={2} $width='fit-content'>
                          <S.SwitchToAnnualPayment>Switch to Annual payment</S.SwitchToAnnualPayment>
                          <S.Discount>10% OFF</S.Discount>
                        </Flex>
                      </Button>
                    }
                    <Button $variant='outline' $height={24} href={`${CONFIG.API_DOMAIN}/billing/portal`} trailingIcon={Icons.externalLink} $fontSize={12}>
                      Customer portal
                    </Button>
                  </Flex>
                : <ButtonGroup
                    $variant='ghost'
                    $gap={4}
                    $padding={4}
                    $direction='horizontal'
                    $backgroundColor={colors.bgTertiary}
                    $groupBorderRadius={6}
                    $borderRadius={4}
                  >
                    <Button
                      $height={24}
                      $variant={productInterval === PriceInterval.month ? 'raised-light' : 'ghost'}
                      $fontSize={12}
                      onClick={() => {
                        setProductInterval(PriceInterval.month)
                      }}
                      disabled={userHasViewerRole}
                    >
                      <S.ButtonGroupTitle>Monthly</S.ButtonGroupTitle>
                    </Button>
                    <Button
                      $height={24}
                      $variant={productInterval === PriceInterval.year ? 'raised-light' : 'ghost'}
                      $fontSize={12}
                      onClick={() => {
                        setProductInterval(PriceInterval.year)
                      }}
                      disabled={userHasViewerRole}
                    >
                      <S.ButtonGroupTitle>Annual</S.ButtonGroupTitle>
                      <S.Discount>10% OFF</S.Discount>
                    </Button>
                  </ButtonGroup>
              }
            </S.Plan>
            {professionalProduct && (
              <>
                {expired && canExtendTrial && <ExtendTrial items={Array.from(items.values())} />}
                <Plan
                  professionalProduct={professionalProduct}
                  customersSubscriptionsByProductId={customersSubscriptionsByProductId}
                  interval={productInterval}
                  checkoutItems={checkoutItems}
                  subscriptionActuallyEnded={subscriptionActuallyEnded}
                  expired={expired}
                />
              </>
            )}
            {!expired && (
              <S.ContactSales>
                <Paragraph size='XS'>Get a custom quote from our team for your unique needs.</Paragraph>
                <Button $colorTheme='tint' $variant='ghost' $width={101} $height={24} $fontSize={12} href='mailto:hi@pin.com' $align='center'>
                  Contact sales
                </Button>
              </S.ContactSales>
            )}
          </Flex>
        )}
        {expired && (
          <>
            <BenefitsList
              $itemSize="small"
              $variant="tint"
              benefits={[
                {
                  title: 'AI-Powered Candidate Sourcing',
                  description: 'Automatically identifies ideal candidates using in-depth knowledge of your company, leveraging over 200 million resumes and 20,000 unique skills.',
                  icon: Icons.binoculars
                },
                {
                  title: 'Time-Saving Automation',
                  description: 'Streamlines key recruiting steps like sourcing, personalizing outreach, follow-ups, and scheduling, reducing the hiring process to less than two weeks.',
                  icon: Icons.hourglass
                },
                {
                  title: 'Enhanced Candidate Experience',
                  description: 'Provides hassle-free communication and scheduling, ensuring every candidate interaction is positive and professional.',
                  icon: Icons.mails
                }
              ]}
            />
            <S.ContactSalesBox>
              <Flex $direction='column' $gap={4} $flex='1 1 auto' $width='fit-content'>
                <Caption size='SM'>
                  Have questions or not sure if Pin is for you?
                </Caption>
                <Paragraph size='XS'>
                  We’re here to help set up and get the most out of Pin.
                </Paragraph>
              </Flex>
              <BookADemoButton $variant='raised-light' $height={40} leadingIcon={null}>
                Contact Our Sales Team
              </BookADemoButton>
            </S.ContactSalesBox>
          </>
        )}
        {creditProduct && credit && !expired && !orgCancelledOrPaused && (
          <Box
            $display='flex'
            $flexDirection='column'
            $align='flex-start'
            $gap={16}
            $flex='1 1 auto'
            $height='fit-content'
            $padding={insufficientCredits
              ? undefined
              : { top: 20, right: 16, bottom: 20, left: 16 }
            }
            $width='100%'
            $borderRadius='6px'
            $background='bgPrimary'
            $shadow={insufficientCredits ? undefined : 'sm'}
          >
            <BillingUsage
              creditProduct={creditProduct}
              credit={credit}
              subscriptionActiveUntil={subscriptionActiveUntil}
              subscribed={subscribed ?? true}
              items={items}
              setItems={setItems}
              checkoutItems={checkoutItems}
              minQuantity={insufficientCredits ? 1 : 0}
            />
          </Box>
        )}
        {onCloseAccount && (!org?.stripeSubscriptionCancelled || expired) && (
          <Flex $justify='flex-end'>
            <Button
              $variant='outline'
              $colorTheme='muted'
              $height={40}
              $fontSize={14}
              onClick={() => {
                onCloseAccount(true)
              }}
              disabled={userHasViewerRole}
            >
              Close Pin Account
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}
