import { useMutation } from '@tanstack/react-query'
import { validateCandidatesFromCsvApi } from 'src/libs/api/backend/candidate_jobs'
import type { ValidateCandidatesFromCsv } from 'src/libs/api/backend/candidate_jobs'

interface Args {
  csvS3Key: string
  jobId: string
  onSuccess?: (data: ValidateCandidatesFromCsv) => void
  onError?: (error: Error) => void
}

interface Res {
  validateCandidatesFromCsv: (args: Args) => void
}

export const useValidateCandidatesCsv = (): Res => {
  const mutation = useMutation({
    mutationFn: async ({ csvS3Key, jobId }: Args) => {
      return await validateCandidatesFromCsvApi(jobId, csvS3Key)
    },
    onSuccess: (data, { onSuccess }) => {
      onSuccess?.(data)
    },
    onError: (error, { onError }) => {
      onError?.(error)
    }
  })

  const validateCandidatesFromCsv = (args: Args): void => {
    mutation.mutate(args)
  }

  return { validateCandidatesFromCsv }
}
