import { Paragraph } from 'src/components/primitives/typography'
import * as S from './vertical-bar-funnel-chart.styled'

interface DataPoint {
  label: string
  value: number
}

interface VerticalBarFunnelChartProps {
  data: DataPoint[]
}

export const VerticalBarFunnelChart = ({ data }: VerticalBarFunnelChartProps): JSX.Element => {
  const maxValue = Math.max(...data.map(({ value }) => value))
  const gridMaxValue = Math.min(Math.ceil(maxValue / 10) * 10 + 10, 100)
  const grid = Array.from({ length: 5 }, (_, index) => gridMaxValue - (index * (gridMaxValue / 4)))

  return (
    <S.Wrapper>
      <S.Inner>
        <S.Bars>
          {data.map(({ label, value }, index) => (
            <S.Bar key={label}>
              <S.BarCurrent $height={`${(value / gridMaxValue) * 100}%`}>
                <S.BarBadge $value={value}><span>{value}%</span></S.BarBadge>
              </S.BarCurrent>
              <S.BarGrowth $height={index > 0 ? `${(data[index - 1].value / gridMaxValue) * 100}%` : '0%'} />
            </S.Bar>
          ))}
        </S.Bars>
      </S.Inner>
      <S.BarsLegend>
        {data.map(({ label }) => (
          <S.BarLegend key={label}>
            <Paragraph size="XS" $color="fgSecondary">{label}</Paragraph>
          </S.BarLegend>
        ))}
      </S.BarsLegend>
      <S.Grid>
        {grid.map(line => (
          <S.GridLine key={line}>
            <span>{line}%</span>
          </S.GridLine>
        ))}
      </S.Grid>
    </S.Wrapper>
  )
}
