import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING, HEADER_PADDING } from 'src/styles/constants'
import styled from 'styled-components'
import * as RadixDropdown from '@radix-ui/react-dropdown-menu'

export const OutreachErrorsPageInner = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  contain: strict;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
`

export const OutreachErrorsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px ${CONTENT_PADDING} 0 ${CONTENT_PADDING};
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
`

export const OutreachErrorsPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  max-width: ${CANDIDATES_PAGES_MAX_WIDTH};
  width: 100%;
  height: 100%;
`

export const OutreachErrorsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${HEADER_PADDING} 0;
  gap: ${({ theme }) => theme.spacing[20]};
`

export const DropdownFirstLabel = styled(RadixDropdown.Label)`
  margin: 0.1rem 0.75rem 0 0.95rem;
  height: 22px;
  display: flex;
  align-items: center;
  span {
    font-size: ${({ theme }) => theme.fontSizes[10]};
    color: ${({ theme }) => theme.colors.fgTertiary};
    letter-spacing: 0.2px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
  }
`
