import { Caption, Paragraph } from 'src/components/primitives/typography'
import * as S from './chart-legend.styled'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'

interface DataPoint {
  name: string
  value?: number | string
  valueChange?: number | string
  valueChangeDirection?: 'up' | 'down'
  color: string
}

export interface ChartLegendStyleProps {
  $orientation?: 'row' | 'column'
}

export interface ChartLegendProps extends ChartLegendStyleProps {
  title?: string
  titleHidden?: boolean
  data: DataPoint[]
}

export const ChartLegend = ({
  title,
  titleHidden = false,
  data,
  $orientation = 'row'
}: ChartLegendProps): JSX.Element => {
  const hasAtLeastOneValueChange = data.some(
    (point) => typeof point.valueChange === 'number' && point.valueChange !== 0
  )

  return (
    <S.Legend>
      {title && !titleHidden && (
        <Caption size="SM" $color="fgSecondary">
          {title}
        </Caption>
      )}
      <S.LegendInner $orientation={$orientation}>
        {data.map((dataPoint) => (
          <li key={dataPoint.name}>
            <Flex $align="center" $gap={8}>
              <S.Icon $color={dataPoint.color} />
              <Paragraph size="XS" $color="fgSecondary">
                {dataPoint.name}
              </Paragraph>
            </Flex>
            <Flex $align="center" $justify="flex-end" $gap={8}>
              <Caption size="SM" $color="fgPrimary">
                {dataPoint.value}
              </Caption>
              {hasAtLeastOneValueChange && (
                <S.ValueChange>
                  <Paragraph
                    size="XS"
                    $color={
                      dataPoint.valueChange === 0 || dataPoint.value === 0
                        ? 'fgTertiary'
                        : dataPoint.valueChangeDirection === 'up'
                          ? 'positiveFg'
                          : 'warningFg'
                    }
                    $lineHeight={1}
                  >
                    {typeof dataPoint.valueChange === 'number' && dataPoint.valueChange !== 0
                      ? dataPoint.valueChange
                      : '0'}
                  </Paragraph>
                  <S.IconWrapper>
                    {dataPoint.valueChangeDirection !== undefined && dataPoint.valueChange !== 0 && (
                      <Icon
                        name={dataPoint.valueChangeDirection === 'up' ? 'arrow-up' : 'arrow-down'}
                        size={12}
                        color={dataPoint.valueChangeDirection === 'up' ? 'positiveFg' : 'warningFg'}
                      />
                    )}
                  </S.IconWrapper>
                </S.ValueChange>
              )}
            </Flex>
          </li>
        ))}
      </S.LegendInner>
    </S.Legend>
  )
}
