import { BarChart } from 'src/components/charts/primitives/bar-chart'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import type { MessagesSent } from 'src/libs/api/backend/reports_communication_insights'
import { format, parseISO } from 'date-fns'

interface MessagesSentChartProps {
  data: MessagesSent[]
}

export const MessagesSentChart = ({ data }: MessagesSentChartProps): JSX.Element => {
  const preparedData = data.map((point) => {
    const date = parseISO(point.date)
    const formattedDate = format(date, 'yyyy-MM-dd')

    return {
      label: formattedDate,
      Email: point.email,
      LinkedIn: point.linkedin
    }
  })

  return (
    <ChartWrapper>
      <BarChart chartTitle="Messages Sent" data={preparedData} barFormat="grouped" showTimeFilter={false} />
    </ChartWrapper>
  )
}
