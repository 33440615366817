import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 12px;
  gap: 1.5rem;
`

export const GroupSelectors = styled.div`
  padding: 0.75rem;
`

export const GroupSelectorsList = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
`

export const GroupSelector = styled.div<{ $isSelected: boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[8]};
  border-radius: 6px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ $isSelected }) => $isSelected && css`
      background-color: ${({ theme }) => theme.colors.bgPrimary};
      box-shadow: ${({ theme }) => theme.boxShadows.sm};
  `}
  ${({ $isSelected }) => !$isSelected && css`
      @media (hover: hover) {
        &:hover {
          background-color: ${({ theme }) => theme.colors.bgTertiary};
        }
      }
  `}
  h4 {
    font-size: ${({ theme }) => theme.fontSizes[20]};
    font-weight: 700;
    line-height: 1;
    color: ${({ theme }) => theme.colors.fgPrimary};
  }
`

export const ChangeBadge = styled.div`
  padding: 0.25rem 0.25rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  p {
    line-height: 1;
  }
`

export const GroupWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 1.25rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
`
