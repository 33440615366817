import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import type { CriteriaKey } from 'src/libs/api/backend/candidate_search'

export const updatedCriteriasAtom = atom<CriteriaKey[]>([])

export const removeUpdatedCriteriaAtom = atom(null, (get, set, criteriaKey: CriteriaKey) => {
  const updatedCriteria = get(updatedCriteriasAtom)
  set(updatedCriteriasAtom, updatedCriteria.filter((key) => key !== criteriaKey))
})

export const isSourcingTableExpandedAtom = atomWithStorage<Record<string, boolean>>('sourcingTableExpanded', {}, undefined, {
  getOnInit: true
})

export const unstructuredCustomRequirementsAtom = atom<Set<number>>(new Set([]))

export const setUnstructuredCustomRequirementAtom = atom(null, (get, set, position: number) => {
  const unstructuredCustomRequirements = new Set(get(unstructuredCustomRequirementsAtom))
  unstructuredCustomRequirements.add(position)
  set(unstructuredCustomRequirementsAtom, unstructuredCustomRequirements)
})

export const removeUnstructuredCustomRequirementAtom = atom(null, (get, set, position: number) => {
  const unstructuredCustomRequirements = Array.from(get(unstructuredCustomRequirementsAtom))
  const updated = []
  // Shift the positions behind the removed position by 1
  for (const requirement of unstructuredCustomRequirements) {
    if (requirement > position) {
      updated.push(requirement - 1)
    } else if (requirement < position) {
      updated.push(requirement)
    }
  }
  set(unstructuredCustomRequirementsAtom, new Set(updated))
})

export const resetUnstructuredCustomRequirementsAtom = atom(null, (_, set) => {
  set(unstructuredCustomRequirementsAtom, new Set([]))
})
