import styled, { css } from 'styled-components'
import type { JobTileStyleProps } from './job-tile'

interface JobTileProps extends JobTileStyleProps {
  $isActive: boolean
}

export const JobTile = styled.div<JobTileProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacing[16]};
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};
  border-radius: ${({ theme }) => theme.spacing[6]};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  box-shadow: ${({ theme, $isActive }) => ($isActive ? theme.boxShadows.focused : 'none')};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }

  ${({ $variant }) => $variant === 'raised' && css`
    box-shadow: ${({ theme }) => theme.boxShadows.sm};
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
  `}
`
