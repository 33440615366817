import { Badge } from 'src/components/primitives/badge'
import { Caption } from 'src/components/primitives/typography'
import * as S from './candidate-emails-list.styled'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { CandidateEmailType } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateEmail, CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { copyToClipboard } from 'src/utils/copy-to-clipboard'
import { useEffect, useState } from 'react'
import { useUpdateCandidate } from 'src/hooks/mutations/use-update-candidate'
import { EditEmail } from './edit-email'
import { IfElse } from '../if-else'
import { Flex } from 'src/components/primitives'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { WriteMessageDialogProps } from 'src/components/dialogs/write-message-dialog'
import { useSession } from 'src/hooks/use-session'
import { When } from '../when'

interface CandidateEmailsListProps {
  candidateJob: CandidateJobExpanded
  showInvalidEmails?: boolean
}

interface EmailAddressListingProps extends Partial<CandidateEmail> {
  listingType: 'emailAddresses' | 'candidateEmailAddresses'
  currentEmailIndex: number
  currentEmail: string
  emailAddresses?: string[]
  setEmailAddresses?: (emails: string[]) => void
  candidateEmailAddresses?: CandidateEmail[]
  setCandidateEmailAddresses?: (emails: CandidateEmail[]) => void
  handleUpdate: ({ emailAddresses, candidateEmailAddresses }: { emailAddresses?: string[], candidateEmailAddresses?: CandidateEmail[] }) => void
  candidateJob: CandidateJobExpanded
  showInvalidEmails?: boolean
}

const EmailAddressListing = ({
  listingType,
  candidateJob,
  currentEmail,
  currentEmailIndex,
  emailAddresses,
  setEmailAddresses,
  candidateEmailAddresses,
  setCandidateEmailAddresses,
  handleUpdate,
  verified,
  type,
  showInvalidEmails
}: EmailAddressListingProps): JSX.Element => {
  const [isEditable, setIsEditable] = useState<string | null>(null)
  const openDialog = useSetAtom(openDialogAtom)
  const { userHasViewerRole } = useSession()

  if (listingType === 'candidateEmailAddresses' && !verified && !showInvalidEmails) {
    return <></>
  }

  return (
      <S.EmailAddress>
        <S.ContactTitle>
          <Icon name={verified ? Icons.mailCheck : Icons.mailX} color={listingType === 'candidateEmailAddresses' ? (verified ? 'fgSecondary' : 'negativeFg') : 'fgPrimary'} size={12} />
          <IfElse
            condition={isEditable === currentEmail}
            ifNode={
              <EditEmail
                defaultValue={currentEmail}
                onUpdate={(value) => {
                  if (listingType === 'emailAddresses') {
                    const updated = [...emailAddresses ?? []]
                    updated[currentEmailIndex] = value
                    setEmailAddresses?.(updated)
                    handleUpdate({ emailAddresses: updated })
                  } else {
                    const updated = [...candidateEmailAddresses ?? []]
                    updated[currentEmailIndex] = {
                      ...updated[currentEmailIndex],
                      emailAddress: value
                    }
                    setCandidateEmailAddresses?.(updated)
                    handleUpdate({ candidateEmailAddresses: updated })
                  }
                }}
                onCancel={() => {
                  setIsEditable(null)
                }}
              />
            }
            elseNode={
              <Caption size="XS" $align="left" $fontWeight={400} $color={listingType === 'candidateEmailAddresses' ? (verified ? 'fgSecondary' : 'negativeFg') : 'fgPrimary'}>
                {currentEmail}
              </Caption>
            }
          />
        </S.ContactTitle>
        <Flex $direction="row" $gap={8} $align="center" $width="auto">
          {currentEmailIndex === 0 && (
            <Badge $variant="plain" $transform="capitalize">
              Primary
            </Badge>
          )}
          {
            type === CandidateEmailType.WORK && (
              <Badge $variant={verified ? 'plain' : 'negativeLight'} $transform="none">Work Email</Badge>
            )
          }
          <When condition={listingType === 'candidateEmailAddresses'}>
            {
              verified
                ? (
                    <Badge $variant="positiveLight" leadingIcon="check-check" $transform="none">Verified</Badge>
                  )
                : (
                    <Badge $variant="negativeLight" $transform="none">Invalid</Badge>
                  )
            }
          </When>
          <Dropdown
            trigger={
              <Button
                nested
                leadingIcon={Icons.moreVertical}
                $width={16}
                $height={16}
                $fontSize={12}
                $variant="ghost"
                $colorTheme="muted"
                disabled={isEditable === currentEmail}
              />
            }
            items={[
              {
                id: 'copy-email',
                title: 'Copy',
                icon: 'copy',
                onSelect: async () => {
                  await copyToClipboard(currentEmail)
                }
              },
              {
                id: 'write-message',
                title: 'Write message',
                icon: 'mail',
                onSelect: () => {
                  const payload: WriteMessageDialogProps = { candidateJobs: [candidateJob] }
                  openDialog({ id: DialogId.WRITE_MESSAGE, payload })
                },
                isDisabled: userHasViewerRole,
                isSelectable: !userHasViewerRole
              },
              {
                id: 'separator',
                title: 'ActionsSeparator',
                type: 'separator'
              },
              {
                id: 'make-primary',
                title: 'Make primary',
                icon: 'listOrdered',
                isDisabled: currentEmailIndex === 0 || userHasViewerRole,
                isSelectable: currentEmailIndex >= 1 && !userHasViewerRole,
                onSelect: () => {
                  if (listingType === 'emailAddresses') {
                    const items = [...emailAddresses ?? []]
                    const item = items.splice(currentEmailIndex, 1)[0]
                    items.unshift(item)
                    setEmailAddresses?.(items)
                    handleUpdate({ emailAddresses: items })
                  } else {
                    const items = [...candidateEmailAddresses ?? []]
                    const item = items.splice(currentEmailIndex, 1)[0]
                    items.unshift(item)
                    setCandidateEmailAddresses?.(items)
                    handleUpdate({ candidateEmailAddresses: items })
                  }
                }
              },
              {
                id: 'edit',
                title: 'Edit',
                icon: 'pen-square',
                onSelect: () => {
                  setIsEditable(currentEmail)
                },
                isDisabled: userHasViewerRole,
                isSelectable: !userHasViewerRole
              },
              {
                id: 'remove',
                title: 'Remove',
                icon: 'trash',
                variant: 'negative',
                onSelect: () => {
                  if (listingType === 'emailAddresses') {
                    const updated = [...emailAddresses ?? []]
                    updated.splice(currentEmailIndex, 1)
                    setEmailAddresses?.(updated)
                    handleUpdate({ emailAddresses: updated })
                  } else {
                    const updated = [...candidateEmailAddresses ?? []]
                    updated.splice(currentEmailIndex, 1)
                    setCandidateEmailAddresses?.(updated)
                    handleUpdate({ candidateEmailAddresses: updated })
                  }
                },
                isDisabled: userHasViewerRole,
                isSelectable: !userHasViewerRole
              }
            ]}
            menuPosition="end"
            size="small"
          />
        </Flex>
    </S.EmailAddress>
  )
}

export const CandidateEmailsList = ({ candidateJob, showInvalidEmails }: CandidateEmailsListProps): JSX.Element => {
  const [emailAddresses, setEmailAddresses] = useState<string[]>([])
  const [candidateEmailAddresses, setCandidateEmailAddresses] = useState<CandidateEmail[]>([])
  const { updateCandidateJob } = useUpdateCandidate(candidateJob.id)

  useEffect(() => {
    if (candidateJob.candidate.emails) {
      setEmailAddresses(candidateJob.candidate.emails)
      // const mock = [
      //   {
      //     emailAddress: 'test@texample.com',
      //     verified: true,
      //     verifiedAt: new Date(),
      //     type: CandidateEmailType.PERSONAL
      //   },
      //   {
      //     emailAddress: 'test2@texample.com',
      //     verified: false,
      //     verifiedAt: new Date(),
      //     type: CandidateEmailType.WORK
      //   }
      // ]
      // setCandidateEmailAddresses(mock)
      setCandidateEmailAddresses(candidateJob.candidate.emailAddresses ?? [])
    }
    // setEmailAddresses(['test@texample.com', 'test2@texample.com', 'test3@example.com'])
  }, [candidateJob.candidate.emails, candidateJob.candidate.emailAddresses])

  const handleUpdate = ({ emailAddresses, candidateEmailAddresses }: { emailAddresses?: string[], candidateEmailAddresses?: CandidateEmail[] }): void => {
    try {
      updateCandidateJob({
        updatedCandidate: {
          id: candidateJob.candidate.id,
          name: candidateJob.candidate.name,
          location: candidateJob.candidate.location,
          emails: emailAddresses,
          emailAddresses: candidateEmailAddresses
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      {!candidateEmailAddresses.length
        ? emailAddresses.map((email, index) => (
            <EmailAddressListing
              key={email}
              listingType="emailAddresses"
              currentEmailIndex={index}
              candidateJob={candidateJob}
              currentEmail={email}
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
              handleUpdate={handleUpdate}
            />
        ))
        : candidateEmailAddresses.map((email, index) => (
            <EmailAddressListing
              {...email}
              key={email.emailAddress}
              listingType="candidateEmailAddresses"
              currentEmailIndex={index}
              candidateJob={candidateJob}
              currentEmail={email.emailAddress}
              candidateEmailAddresses={candidateEmailAddresses}
              setCandidateEmailAddresses={setCandidateEmailAddresses}
              handleUpdate={handleUpdate}
              showInvalidEmails={showInvalidEmails}
            />
        ))}
    </>
  )
}
