import { useRef, forwardRef } from 'react'
import { useKeyboardEvent } from 'src/hooks/use-keyboard-event'
import * as S from './div-as-button.styled'

interface DivAsButtonProps {
  id?: string
  isDisabled?: boolean
  ariaLabel: string
  dataName?: string
  hiddenTitle?: boolean
  children: React.ReactNode
  onClick: () => void
  onFocus?: () => void
}

export const DivAsButton = forwardRef<HTMLDivElement, DivAsButtonProps>(({
  id,
  ariaLabel,
  children,
  onClick,
  onFocus,
  dataName,
  hiddenTitle = true,
  isDisabled = false
}, ref) => {
  const element = useRef<HTMLDivElement>(null)
  useKeyboardEvent(
    ['Enter', ' '],
    () => {
      if (onClick) {
        onClick()
      }
    },
    { isEnabled: true, target: element }
  )

  if (isDisabled) {
    return <S.DivAsButton $isDisabled={isDisabled}>{children}</S.DivAsButton>
  }

  return (
    <S.DivAsButton
      id={id}
      $isDisabled={isDisabled}
      title={hiddenTitle ? undefined : ariaLabel}
      aria-label={ariaLabel}
      data-name={dataName}
      ref={ref ?? element}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          onClick()
        }
      }}
    >
      {children}
    </S.DivAsButton>
  )
})
