import Api from '..'
import { z } from 'zod'
import { sequenceParser, sequenceStepParser } from './sequences'
import { candidateJobExpandedParser } from './candidate_jobs'

export const candidateSequenceStepMessageReviewParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  reviewedByUserId: z.string().uuid().nullable(),
  reviewed: z.boolean(),
  subject: z.string().nullable(),
  body: z.string().nullable(),
  compiledSubject: z.string().nullable(),
  compiledBody: z.string().nullable(),
  dirty: z.boolean(),
  personalizationInstructions: z.string().nullish()
})

export type CandidateSequenceStepMessageReview = z.infer<typeof candidateSequenceStepMessageReviewParser>

export const candidateSequenceStepMessageReviewExtendedParser = candidateSequenceStepMessageReviewParser.extend({
  sequence: sequenceParser,
  candidateJob: candidateJobExpandedParser,
  sequenceStep: sequenceStepParser
})

export type CandidateSequenceStepMessageReviewExtended = z.infer<typeof candidateSequenceStepMessageReviewExtendedParser>

export interface CandidateSequenceStepMessageReviewQueryResult {
  pages: CandidateSequenceStepMessageReviewExtended[][]
  pageParams: number[]
}

export async function sendCandidateSequenceStepMessageReview (
  jobId: string,
  candidateSequenceStepMessageReviewId: string,
  subject: string,
  body: string
): Promise<CandidateSequenceStepMessageReview> {
  const { data } = await Api.put(`/jobs/${jobId}/candidate_sequence_step_message_review/${candidateSequenceStepMessageReviewId}/send`, null, {
    subject,
    body
  })

  return candidateSequenceStepMessageReviewParser.parse(data)
}
