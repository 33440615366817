import styled, { css } from 'styled-components'
import type { CalendarEntryStyleProps, MentionedTimesStyleProps } from './elements'

export const Email = styled.div<{ $isSequenceMessage?: boolean }>`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02); */
  overflow: hidden;
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  `}
`

// export const EmailContainerExpandTitleButton = styled.span`
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;

//   margin-left: 0.3rem;
//   margin-bottom: 2px;
//   padding: 0 2px;

//   border-radius: 4px;

//   cursor: pointer;

//   background-color: ${({ theme }) => theme.colors.fgTranslucent5};

//   &:hover {
//     background-color: ${({ theme }) => theme.colors.fgTranslucent10};
//   }
// `

export const EmailContent = styled.div<{ $isSequenceMessage?: boolean }>`
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    /* opacity: 0.75; */
  `}
`

export const EmailContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const EmailContentHeaderRow = styled.div<{ $isSequenceMessage?: boolean }>`
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 0.5rem;
  padding: 0.75rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.bgTertiary};
  ${({ $isSequenceMessage }) => $isSequenceMessage && css`
    border-bottom: solid 1px ${({ theme }) => theme.colors.fgTranslucent5};
  `}
`

export const EmailBody = styled.div`
  padding: 0.75rem;
`

export const InboundEmailActions = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
`

export const EditButton = styled.div`
  display: none;
  position: absolute;
  top: 0.65rem;
  right: 0.5rem;
  z-index: 1;
`

export const Comment = styled.div<{ $isEditable: boolean }>`
  width: 100%;
  position: relative;
  border-radius: 4px;
  /* background-color: ${({ theme }) => theme.colors.bgTertiary}; */
  background-color: ${({ theme }) => theme.colors.warningTranslucent5};
  padding: 0.25rem 0.75rem 0.75rem 2.25rem;
  /* margin-top: 0.5rem; */
  @media (hover: hover) {
    &:hover {
      ${EditButton} {
        ${({ $isEditable }) => $isEditable && 'display: flex;'}
      }
    }
  }
  * {
    font-size: ${({ theme }) => theme.fontSizes['12']};
    color: ${({ theme }) => theme.colors.fgPrimary};
    line-height: ${({ theme }) => theme.lineHeights['1.33']};
    &:empty {
      min-height: 1rem;
    }
  }
`

export const ManualTask = styled.div`
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  padding: 0.25rem 0.75rem 0.75rem 2.25rem;
  * {
    font-size: ${({ theme }) => theme.fontSizes['12']};
    color: ${({ theme }) => theme.colors.fgPrimary};
    line-height: ${({ theme }) => theme.lineHeights['1.33']};
    &:empty {
      min-height: 1rem;
    }
  }
`

export const FromToHeaderNotice = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Description = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  /* text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  p, span {
    line-height: 1.1;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    span {
      white-space: nowrap;
      line-height: inherit;
    }
  }
  a {
    all: inherit;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 0.2rem;
    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`

interface CalendarEntryProps extends CalendarEntryStyleProps {
  $isFutureEvent: boolean
}

export const CalendarEntry = styled.div<CalendarEntryProps>`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${({ $variant, theme }) => $variant === 'card' && `
    padding: 0.75rem;
    // box-shadow: ${theme.boxShadows.sm};
  `}

  ${({ $isFutureEvent, theme }) => $isFutureEvent && `
    box-shadow: none;
    border: dashed 1px;
    border-color: ${theme.colors.fgTranslucent10};
  `}
`

export const CalendarEntryInner = styled.div`
  flex: 1 1 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.375rem;
`

export const CalendarEntryAttendees = styled.p`
  span {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    color: ${({ theme }) => theme.colors.fgSecondary};
    &:not(:last-child)::after {
      content: ', ';
    }
  }
`

export const CalendarEntryTime = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgSecondary};
  &:before {
    margin: 0 0.25rem;
  }
`

export const CalendarEntryMainInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

// export const CalendarEntryTitle = styled.p`
//   font-size: ${({ theme }) => theme.fontSizes[12]};
//   font-weight: 500;
//   color: ${({ theme }) => theme.colors.fgPrimary};
// `

export const CalenderEntryTimestamp = styled.div`
  display: inline-flex;
  padding-top: 0.2rem;
`

export const MentionedTimes = styled.button<MentionedTimesStyleProps>`
  all: unset;
  background-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'positive':
        return theme.colors.positiveTranslucent25
      case 'negative':
        return theme.colors.negativeTranslucent25
      default:
        return theme.colors.bgSecondary // neutral/grey option
    }
  }};
  border-bottom: dashed 1px;
  border-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'positive':
        return theme.colors.positiveBg
      case 'negative':
        return theme.colors.negativeBg
      default:
        return theme.colors.fgSecondary // neutral/grey option
    }
  }};
  padding: 0.2rem 0.25rem 0.05rem 0.25rem;
  border-radius: 2px;
`
