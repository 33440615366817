import { Caption } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives'
import { useState } from 'react'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { BarChart } from '../bar-chart'
import type { BarChartProps } from '../bar-chart'
import * as S from './grouped-bar-chart.styled'
import { EmptyChart } from '../empty-chart'
import { Icon } from 'src/components/primitives/icon'
import { useReportsShouldCompareToPreviousPeriod } from 'src/hooks/use-reports-should-compare-to-previous-period'
import { When } from 'src/components/blocks/when'

interface ChartGroup {
  label: string
  value: number
  pastValue?: number
  valueChangeDirection?: 'up' | 'down' | 'neutral'
  chartTitle: string
  chartData?: BarChartProps['data']
}

interface SelectableChartGroupProps {
  groups: ChartGroup[]
}

export const GroupedBarChart = ({ groups }: SelectableChartGroupProps): JSX.Element => {
  const [selectedGroup, setSelectedGroup] = useState<number>(0)
  const { shouldCompare } = useReportsShouldCompareToPreviousPeriod()

  const getPercentageChange = (currentValue: number, previousValue?: number): string => {
    if (previousValue === undefined) {
      return ''
    }

    if (previousValue === 0) {
      if (currentValue === 0) {
        return '0%'
      }
      return '100%'
    }

    const percentageChange = Math.round(((currentValue - previousValue) / previousValue) * 100)
    const sign = percentageChange > 0 ? '+' : ''
    return `${sign}${percentageChange}%`
  }

  const currentBarChartData = groups[selectedGroup]?.chartData

  const showBarChart = currentBarChartData && currentBarChartData.length >= 1

  return (
    <S.Wrapper>
      <S.GroupSelectors>
        <S.GroupSelectorsList>
          {groups.map((group, index) => (
            <DivAsButton
              key={`${group.label}-${group.value}`}
              ariaLabel="Select group"
              onClick={() => {
                setSelectedGroup(index)
              }}
            >
              <S.GroupSelector $isSelected={selectedGroup === index}>
                <Caption size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                  {group.label}
                </Caption>
                <Flex $gap={12} $align="flex-end">
                  <h4>{group.value.toLocaleString()}</h4>
                  <When condition={shouldCompare}>
                    <Flex $align="center" $gap={4} $width="fit-content">
                      <Caption
                        size="XS"
                        $color={
                          group.valueChangeDirection === 'up'
                            ? 'positiveFg'
                            : group.valueChangeDirection === 'down'
                              ? 'warningFg'
                              : 'fgSecondary'
                        }
                        $whiteSpace="nowrap"
                      >
                        {getPercentageChange(group.value, group.pastValue)}
                      </Caption>
                      {group.valueChangeDirection === 'up' && (
                        <Icon name="arrow-up" size={10} color="positiveFg" />
                      )}
                      {group.valueChangeDirection === 'down' && (
                        <Icon name="arrow-down" size={10} color="warningFg" />
                      )}
                    </Flex>
                  </When>
                </Flex>
              </S.GroupSelector>
            </DivAsButton>
          ))}
        </S.GroupSelectorsList>
      </S.GroupSelectors>
      <S.GroupWrapper>
        {showBarChart && (
          <BarChart
            chartTitle={groups[selectedGroup].chartTitle}
            data={currentBarChartData}
            showTimeFilter={false}
            showLegend={false}
          />
        )}
        {!showBarChart && <EmptyChart $minHeight="100px" />}
      </S.GroupWrapper>
    </S.Wrapper>
  )
}
