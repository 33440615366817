import type { CriteriaKey, CriteriaValue, NameOptionalCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import { titleCase } from 'src/libs/string'
import { languageTypeAheadOptions } from '../languages'

interface LanguageRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: NameOptionalCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const LanguageRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: LanguageRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])

  const suggestions = useMemo(() => {
    if (!query) {
      return []
    }
    return languageTypeAheadOptions(query)
  }, [query])

  const items = useMemo(() => {
    if (criteriaValue.length === 0) {
      return null
    }
    return criteriaValue.map((languageCriteria, index) => {
      return (
        <CriteriaPill
          key={index}
          value={{
            label: titleCase(languageCriteria.name),
            optional: languageCriteria.optional ?? true
          }}
          useNegative={false} // Language criteria does not have negative option
          onUpdate={(key: string, value: CriteriaPillValue) => {
            const updatedCriteriaValue = [...criteriaValue]
            updatedCriteriaValue[index] = {
              ...updatedCriteriaValue[index],
              [key]: value
            }
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
          onRemove={() => {
            const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
        />
      )
    })
  }, [criteriaKey, criteriaValue, onCriteriaUpdate])

  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={suggestions.length === 0}
        disabled={disabled}
        triggerSearch={setQueryDebounced}
        suggestions={suggestions}
        onSelect={(suggestion: { name: string }) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              name: suggestion.name,
              optional: false
            }]
          )
        }}
        items={items}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
