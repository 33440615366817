export enum TrackingEventType {
  VIEWED_LOGIN_PAGE = 'VIEWED_LOGIN_PAGE',
  VIEWED_LOGIN_LANDING_PAGE_AFTER_MEETING_BOOKED = 'VIEWED_LOGIN_LANDING_PAGE_AFTER_MEETING_BOOKED',
  SIGNED_UP = 'SIGNED_UP',
  LOGGED_IN = 'LOGGED_IN',
  COMPLETED_ONBOARDING = 'COMPLETED_ONBOARDING',
  SUBSCRIBED = 'SUBSCRIBED',
  ADD_CUSTOM_AI_QUESTION = 'ADD_CUSTOM_AI_QUESTION',
  TELL_PIN_WHAT_YOU_ARE_LOOKING_FOR = 'TELL_PIN_WHAT_YOU_ARE_LOOKING_FOR',
  STARTED_ONBOARDING_ON_MOBILE = 'STARTED_ONBOARDING_ON_MOBILE',
  PRODUCT_TOUR_SKIPPED = 'PRODUCT_TOUR_SKIPPED',
  PRODUCT_TOUR_STARTED = 'PRODUCT_TOUR_STARTED',
  PRODUCT_TOUR_COMPLETED = 'PRODUCT_TOUR_COMPLETED',
  SEQUENCE_TOUR_SKIPPED = 'SEQUENCE_TOUR_SKIPPED',
  SEQUENCE_TOUR_STARTED = 'SEQUENCE_TOUR_STARTED',
  SEQUENCE_TOUR_COMPLETED = 'SEQUENCE_TOUR_COMPLETED',
  BOOKING_SUCCESSFUL = 'bookingSuccessful' // casing to match naming of www
}
