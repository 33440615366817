import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { pauseSubscriptionApi } from 'src/libs/api/backend/billing'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  pauseSubscription: (args: Args) => void
}

export const usePauseSubscription = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async (_: Args) => {
      await pauseSubscriptionApi()
    },
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while pausing your subscription: ${err.message}`
      })
      variables?.onError?.()
    },
    onSuccess: (_, variables) => {
      notifySuccess({
        message: 'Subscription paused successfully'
      })
      variables?.onSuccess?.()
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.session] })
    }
  })

  const pauseSubscription = (args: Args): void => {
    mutation.mutate(args)
  }

  return { pauseSubscription }
}
