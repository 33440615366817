import { useNavigate, useParams } from 'react-router-dom'
import { SEO } from '../../../components/primitives/seo'
import { EmpySequenceState } from 'src/components/blocks/empty-state/empty-sequence-state'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import RouteBuilder from 'src/libs/route-builder'
import { useEffect, useState } from 'react'

const JobCandidatesCreateSequencePage = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: emailSequence, isLoading: isEmailSequenceLoading } = useJobSequenceQuery(jobId)
  const [hasRedirected, setHasRedirected] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isEmailSequenceLoading) {
      return
    }
    if (!isSequenceStepsEmpty(emailSequence) && !hasRedirected) {
      setHasRedirected(true)
      navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
    }
  }, [isEmailSequenceLoading, emailSequence, jobId, hasRedirected, navigate])

  return (
    <>
      <SEO title="Create Sequence" />
      <EmpySequenceState />
    </>
  )
}

export default JobCandidatesCreateSequencePage
