import { useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/blocks/empty-state'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { ExportToAtsButton, UndoRejectButton, WriteEmailButton } from '../candidate-table-actions'
import { COLUMN } from '../candidate-table-cells/table-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'

export const CandidatesArchivedTable = (): JSX.Element => {
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const { isPending, data: rejectedCandidates } = useCandidateJobsQuery({
    archived: true
  })

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.REJECTION_REASON, COLUMN.REJECTED_AT, COLUMN.SOURCE]}
      isLoading={isPending}
      candidateJobs={rejectedCandidates}
      pageHeaderHeight={rejectedCandidates && rejectedCandidates.length > 0 ? 64 : 32}
      emptyState={
        <EmptyState
          heading="No Candidates Archived"
          description="All archived candidates can be found here."
          svg="userCards"
          actions={[
            {
              href: `/jobs/${jobId}/candidates/sourcing`,
              leadingIcon: 'binoculars',
              children: 'Go to Sourcing'
            }
          ]}
          $padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
        />
      }
      selectedRowsActions={
        <>
          <UndoRejectButton />
          <WriteEmailButton candidateJobs={rejectedCandidates} />
          <ExportToAtsButton candidateJobs={rejectedCandidates} job={job} />
        </>
      }
    />
  )
}
