import { submitNpsFeedbackApi } from 'src/libs/api/backend/feedback'
import { useMutation } from '@tanstack/react-query'

interface SubmitNpsFeedbackArgs {
  rating: number
  comment?: string
  onSuccess?: () => void
}

interface UseSubmitNpsFeedbackRes {
  submitNpsFeedback: (args: SubmitNpsFeedbackArgs) => void
}

export const useSubmitNpsFeedback = (): UseSubmitNpsFeedbackRes => {
  const mutation = useMutation({
    mutationFn: async ({ rating, comment }: SubmitNpsFeedbackArgs) => {
      return await submitNpsFeedbackApi({ rating, comment })
    },
    onSuccess: (_, variables) => {
      variables?.onSuccess?.()
    }
  })

  const submitNpsFeedback = (args: SubmitNpsFeedbackArgs): void => {
    mutation.mutate(args)
  }

  return { submitNpsFeedback }
}
