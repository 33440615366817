import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import type { CandidateSequence } from 'src/libs/api/backend/candidate_sequence'
import type { ManualQueueQueryResult } from 'src/libs/api/backend/manual_queue'
import { skipManualTask as skipManualTaskHandler } from 'src/libs/api/backend/manual_task'
import type { ManualTask } from 'src/libs/api/backend/manual_task'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  candidateSequenceId: ManualTask['candidateSequenceId']
  position: number
  manualTaskId?: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  skipManualTask: (args: Args) => void
  isPending: boolean
}

export const useSkipManualTask = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)
  const { jobId } = useParams()

  const mutation = useMutation<CandidateSequence, Error, Args>({
    mutationFn: async ({ candidateSequenceId, position }: Args) => {
      return await skipManualTaskHandler(candidateSequenceId, position)
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred while skipping manual task: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, args: Args) => {
      // update cache directly to remove ManualQueueItem from ReadyToSendPage queue
      queryClient.setQueriesData<ManualQueueQueryResult>({ queryKey: [queryKeys.manualQueue, jobId] }, (oldManualQueueItems) => {
        if (isNil(oldManualQueueItems)) {
          return oldManualQueueItems
        }

        const { [args.manualTaskId ?? '']: _, ...newData } = oldManualQueueItems
        return newData
      })

      if (args.onSuccess) {
        args.onSuccess()
      }
    }
  })

  const skipManualTask = (args: Args): void => {
    mutation.mutate(args)
  }

  return {
    skipManualTask,
    isPending: mutation.isPending
  }
}
