import { Caption } from 'src/components/primitives/typography'
import { useEffect, useMemo, useState } from 'react'
import * as S from './grouped-bar-chart.styled'
import { Tabbar } from 'src/components/primitives/tabbar'
import { isNil } from 'lodash'
import { When } from 'src/components/blocks/when'
import { EmptyChart } from '../empty-chart'

interface DataGroup {
  category: string
  data: DataItem[]
}

interface DataItem {
  label: string
  value: number
  barColor?: string
}

interface Filter {
  title: string
  value: string
}

interface GroupedBarChartProps {
  title: string
  dataKey: string
  data: DataGroup[]
}

export const GroupedBarChart = ({
  title,
  dataKey,
  data
}: GroupedBarChartProps): JSX.Element => {
  const [filters, setFilters] = useState<Filter[] | null>(null)
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState<DataItem[]>(data[0].data)

  useEffect(() => {
    const generatedFilters = data.map(data => {
      return {
        title: data.category,
        value: data.category
      }
    })
    setFilters(generatedFilters)
    setSelectedFilter(generatedFilters?.[0])
  }, [data])

  useEffect(() => {
    if (!isNil(selectedFilter)) {
      const indexOfSelectedSet = data.findIndex(data => data.category === selectedFilter.value)
      const selectedSet = data[indexOfSelectedSet].data
      const sortedData = [...selectedSet].sort((a, b) => b.value - a.value)
      setChartData(sortedData)
      setIsLoading(false)
    }
  }, [data, selectedFilter])

  const maxValue = useMemo(() => {
    return chartData[0]?.value ?? 0
  }, [chartData])

  return (
    <S.GroupedBarChart>
      <S.Header>
        <Caption size="SM" $color="fgPrimary">
          {title}
        </Caption>
        {filters && filters.length >= 2 && selectedFilter && (
          <Tabbar
            ariaLabel="Filter chart by category"
            initiallySelected={selectedFilter.value}
            $variant="selectNormal"
            tabs={filters.map((filter) => ({
              ...filter,
              onSelect: () => {
                setSelectedFilter(filter)
              }
            }))}
          />
        )}
      </S.Header>
      <S.Segments style={{ visibility: !isLoading ? 'visible' : 'hidden' }}>
        <When condition={!chartData.length}>
          <EmptyChart $minHeight='80px' />
        </When>
        <When condition={chartData.length >= 1}>
          {chartData.map((item) => (
            <S.Segment
              key={item.label}
              $barColor={item.barColor ?? '#D2DFF9'}
              $barWidth={maxValue > 0 ? Math.round((item.value / maxValue) * 100) : 100}
            >
              <S.SegmentLabels>
                <Caption size="XS">{item.label}</Caption>
                <Caption size="XS" $color="fgSecondary">
                  {item.value} {dataKey}
                </Caption>
              </S.SegmentLabels>
            </S.Segment>
          ))}
        </When>
      </S.Segments>
    </S.GroupedBarChart>
  )
}
