import type { Spacing } from 'src/styles/theme/types'
import type { ButtonStyleProps } from '../button'
import { Icon, Icons } from '../icon'
import type { IconName } from '../icon'
import * as S from './double-button.styled'
import { Dropdown } from '../dropdown'
import type { MenuItemProps } from '../dropdown'
import { Button as ButtonPrimitive } from '../button'
import { getIconColor } from '../button/utils'
import { Tooltip } from '../tooltip/tooltip'
import type { TooltipProps } from '../tooltip/tooltip'

export interface DoubleButtonStyleProps extends Omit<ButtonStyleProps, '$height'> {
  $height?: Spacing
}

interface DoubleButtonProps extends DoubleButtonStyleProps {
  icon: IconName
  children: string
  onPrimaryClick: () => void
  secondaryItems?: MenuItemProps[]
  primaryDisabled?: boolean
  secondaryDisabled?: boolean
  tooltip?: Omit<TooltipProps, 'trigger' | 'children'> & {
    text?: string
  }
}

export const DoubleButton = ({
  $variant = 'raised',
  $colorTheme = 'muted',
  $height = 48,
  icon,
  children,
  onPrimaryClick,
  secondaryItems,
  primaryDisabled,
  secondaryDisabled,
  tooltip
}: DoubleButtonProps): JSX.Element => {
  if (tooltip?.text) {
    return (
      <Tooltip
        position={tooltip.position}
        trigger={
          <S.DoubleButton
            $height={$height}
            $variant={$variant}
            $colorTheme={$colorTheme}
            $hasNoSecondaryAction={!secondaryItems}
          >
            <S.Primary
              onClick={onPrimaryClick}
              $variant={$variant}
              $colorTheme={$colorTheme}
              $hasNoSecondaryAction={!secondaryItems}
              disabled={primaryDisabled}
              $disabled={primaryDisabled}
            >
              <Icon name={icon} size={14} color={getIconColor($variant, $colorTheme)} />
              {children}
            </S.Primary>
            {secondaryItems && (
              <S.SecondaryWrapper $disabled={secondaryDisabled}>
                <Dropdown
                  trigger={
                    <ButtonPrimitive
                      nested
                      $fontSize={12}
                      $width={16}
                      $height={16}
                      $variant="flat"
                      $colorTheme={$colorTheme}
                      leadingIcon={Icons.chevronDown}
                      disabled={secondaryDisabled}
                    />
                  }
                  items={secondaryItems}
                  size="small"
                  menuPosition="end"
                />
              </S.SecondaryWrapper>
            )}
          </S.DoubleButton>
        }
      >
        {tooltip.text}
      </Tooltip>
    )
  }

  return (
    <S.DoubleButton
      $height={$height}
      $variant={$variant}
      $colorTheme={$colorTheme}
      $hasNoSecondaryAction={!secondaryItems}
    >
      <S.Primary
        onClick={onPrimaryClick}
        $variant={$variant}
        $colorTheme={$colorTheme}
        $hasNoSecondaryAction={!secondaryItems}
        disabled={primaryDisabled}
        $disabled={primaryDisabled}
      >
        <Icon name={icon} size={14} color={getIconColor($variant, $colorTheme)} />
        {children}
      </S.Primary>
      {secondaryItems && (
        <S.SecondaryWrapper $disabled={secondaryDisabled}>
          <Dropdown
            trigger={
              <ButtonPrimitive
                nested
                $fontSize={12}
                $width={16}
                $height={16}
                $variant="flat"
                $colorTheme={$colorTheme}
                leadingIcon={Icons.chevronDown}
                disabled={secondaryDisabled}
              />
            }
            items={secondaryItems}
            size="small"
            menuPosition="end"
          />
        </S.SecondaryWrapper>
      )}
    </S.DoubleButton>
  )
}
