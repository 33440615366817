import { Avatar } from 'src/components/primitives/avatar'
import type { AvatarStyleProps } from 'src/components/primitives/avatar'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { useSession } from 'src/hooks/queries/use-session'

interface DepartmentLogoProps extends Pick<AvatarStyleProps, '$size' | '$innerPadding'> {
  departmentId: string
}

export const DepartmentLogo = ({
  departmentId,
  $size = 16,
  $innerPadding = 0
}: DepartmentLogoProps): JSX.Element => {
  const {
    departments
  } = useGetDepartmentQuery()
  const { data: session } = useSession()

  const department = departments?.find(d => d.id === departmentId)

  if (!department) {
    return (
      <Avatar
        $type="logo"
        $size={$size}
        $border={false}
        photoUrl={session?.logoUrl}
        initials={session?.org?.name}
      />
    )
  }

  return (
    <Avatar
      $type="logo"
      initials={department?.name}
      $size={$size}
      $border={false}
      $innerPadding={$innerPadding}
      company={{ name: department?.name, url: department?.domain }}
    />
  )
}
