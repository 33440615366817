import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import * as OL from 'src/components/forms/options-list/options-list.styled'
import { Avatar } from 'src/components/primitives/avatar'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import { PageHeader } from 'src/components/blocks/page-header'
import * as S from './team-members-list.styled'
import { useForm } from 'src/hooks/use-form'
import { updateOrg } from 'src/libs/api/backend/orgs'
import { isNil } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'
import { Form } from 'src/components/forms/form'
import { Switch } from 'src/components/forms/switch'
import { z } from 'zod'
import { Spacer } from 'src/components/primitives/spacer'
import { Input } from 'src/components/forms/input'
import { createOrgInvite, deleteInvite } from 'src/libs/api/backend/invites'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from 'src/libs/query-keys'
import { useOrgInvitesQuery } from 'src/hooks/queries/use-org-invites'
import { useDeleteUser } from 'src/hooks/mutations/use-delete-user'
import { RoleOptions, TeamMemberRow } from './team-member-row'
import { useActivateUser } from 'src/hooks/mutations/use-activate-user'
import { useSetAtom } from 'jotai'
import { notifySuccessAtom, notifyErrorAtom } from 'src/stores/notifications'
import { useSetUserRole } from 'src/hooks/mutations/use-set-user-role'
import { UserRole } from 'src/libs/api/backend/users'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { When } from '../blocks/when'
import { Banner } from '../blocks/banner'

function InviteUser (): JSX.Element {
  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)
  const { userHasViewerRole } = useSession()

  const queryClient = useQueryClient()

  const inviteUserSchema = z.object({
    inviteEmail: z.string()
  })

  type AddOptionForm = z.infer<typeof inviteUserSchema>

  const { submit, register, reset, formData } = useForm<AddOptionForm>({
    schema: inviteUserSchema
  })

  const handleAddItem = async (data: AddOptionForm): Promise<void> => {
    if (userHasViewerRole) {
      return
    }
    try {
      await createOrgInvite([{ email: data.inviteEmail, role: UserRole.MEMBER }])
      await queryClient.invalidateQueries({ queryKey: [queryKeys.orgInvites] })
      notifySuccess({
        message: 'Invite was sent to your team member'
      })
      reset()
    } catch (e) {
      notifyError({
        message: 'Failed to send invite'
      })
    }
  }

  return (
    <S.InviteUserOption>
      <OL.AddOption>
        <Form onSubmit={submit(handleAddItem)}>
          <OL.AddOptionInput className="AddOptionInput" $disabled={userHasViewerRole}>
            <Input
              variant="naked"
              name="inviteEmail"
              type={'email'}
              placeholder={'Invite teammate by email address'}
              label="Add option"
              hiddenLabel
              register={register}
              isDisabled={userHasViewerRole}
            />
          </OL.AddOptionInput>
          <Button
            type="submit"
            $variant="fill"
            $colorTheme="tint"
            $height={24}
            $fontSize={12}
            disabled={!formData?.inviteEmail || userHasViewerRole}
          >
            Send invite
          </Button>
        </Form>
      </OL.AddOption>
    </S.InviteUserOption>
  )
}

export function OrgUsers (): JSX.Element {
  const queryClient = useQueryClient()
  const { user, org, userHasViewerRole } = useSession()
  const { data: orgUsers } = useOrgUsersQuery()
  const { data: orgInvitesRaw } = useOrgInvitesQuery()
  const { deleteUser } = useDeleteUser()
  const { restoreUser } = useActivateUser()
  const { updateUserRole } = useSetUserRole()

  const orgInvites = useMemo(() => orgInvitesRaw
    ?.filter((invite) => !invite.accepted)
    .sort((a, b) => (a.createdAt.valueOf() - b.createdAt.valueOf()) * -1)
  , [orgInvitesRaw])

  const domainAllowListForm = z.object({
    allowDomainJoin: z.boolean()
  })

  type DomainAllowListForm = z.infer<typeof domainAllowListForm>

  const notifySuccess = useSetAtom(notifySuccessAtom)
  const notifyError = useSetAtom(notifyErrorAtom)
  const { register, formData } = useForm<DomainAllowListForm>({
    schema: domainAllowListForm
  })

  useEffect(() => {
    if (!isNil(formData?.allowDomainJoin)) {
      updateOrg({ allowDomainJoin: formData.allowDomainJoin as boolean })
        .then(() => {
          notifySuccess({
            message: 'Updated successfully'
          })
        }).catch((error) => {
          console.log(error)
          notifyError({
            message: 'Failed to update'
          })
        })
    }
  }, [formData, notifyError, notifySuccess])

  return (
    <>
      <PageHeader heading="Members" />
      <When condition={!!userHasViewerRole}>
        <Banner $variant="warning" icon="alert-triangle">{viewerRoleNotice('make changes to these settings')}</Banner>
        <Spacer $size={24} />
      </When>
      <Form>
        <Switch
          $variant="raised"
          label={`Allow anyone from ${org?.name ?? 'your company'} to join this org`}
          description={`Must have a verified @${org?.domain} email address`}
          name="allowDomainJoin"
          register={register}
          defaultChecked={org?.allowDomainJoin}
          labelColor='fgPrimary'
          disabled={userHasViewerRole}
        />
      </Form>
      <Spacer $size={16} />
      <InviteUser />
      <OL.OptionsList>
        {orgUsers?.map((orgUser) => {
          const currentUser = orgUser.id === user?.id
          return (
            <TeamMemberRow
              key={orgUser.id}
              orgUser={orgUser}
              currentUser={currentUser}
              deleteUser={deleteUser}
              restoreUser={restoreUser}
              orgUsers={orgUsers}
              updateUserRole={updateUserRole}
            />
          )
        })}
        {orgInvites?.map(({ id, name, email, role }) => (
          <OL.Option key={id}>
            <OL.OptionAvatar>
              <Avatar $type="initials" initials={name} />
            </OL.OptionAvatar>
            <OL.OptionLabel>
              <p>{email}</p>
              <p>Invite sent</p>
            </OL.OptionLabel>
            <Flex $align="center" $justify="flex-end" $gap={12}>
              <>
                <Dropdown
                  trigger={
                    <Button
                      $variant="raised"
                      $colorTheme="muted"
                      $height={24}
                      $fontSize={12}
                      nested
                      trailingIcon="chevrons-up-down-small"
                      disabled={userHasViewerRole}
                    >
                      {RoleOptions.find(option => option.value === role)?.title}
                    </Button>
                  }
                  items={RoleOptions.map((option) => ({
                    id: option.value,
                    title: option.title,
                    value: option.value,
                    onSelect: () => {
                      void 0
                    }
                  }))}
                  disabled={true}
                  selectedValue={role}
                  size="small"
                />
                <Button
                  ariaLabel="Delete invite"
                  leadingIcon="trash"
                  $variant="ghost"
                  $colorTheme="muted"
                  $height={24}
                  $width={24}
                  $fontSize={12}
                  onClick={async () => {
                    try {
                      await deleteInvite(id)
                      await queryClient.invalidateQueries({ queryKey: [queryKeys.orgInvites] })
                      notifySuccess({
                        message: 'Invite was deleted and is no longer valid'
                      })
                    } catch (e) {
                      console.log(e)
                      notifyError({
                        message: 'Failed to delete invite'
                      })
                    }
                  }}
                  disabled={userHasViewerRole}
                />
              </>
            </Flex>
          </OL.Option>
        ))}
      </OL.OptionsList>
    </>
  )
}
