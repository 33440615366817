import { useMemo, useState } from 'react'
import { Button, Flex } from 'src/components/primitives'
import * as Dialog from 'src/components/primitives/dialog'
import { Caption } from 'src/components/primitives/typography'
import * as S from './refinement-feedback-dialog.styled'
import { useSubmitFeedback } from 'src/hooks/mutations/use-submit-feedback'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { useAtomValue, useSetAtom } from 'jotai'
import { isDialogOpenAtom, DialogId, controlDialogAtom } from 'src/stores/dialogs'
import { useParams } from 'react-router-dom'

export const RefinementFeedbackDialog = (): JSX.Element => {
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.REFINEMENT_FEEDBACK), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const [content, setContent] = useState<string>('')
  const { submitFeedback } = useSubmitFeedback()
  const { jobSearchRefinementId } = useParams()

  return (
    <Dialog.Root
      id={DialogId.REFINEMENT_FEEDBACK}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        controlDialog({ id: DialogId.REFINEMENT_FEEDBACK, newState: value })
      }}
      $width='640px'
    >
      <Dialog.Portal>
        <Dialog.Header
          title='Sourcing feedback'
          onClose={() => {
            controlDialog({ id: DialogId.REFINEMENT_FEEDBACK, newState: false })
          }}
        />
        <Dialog.Content>
          <Flex $direction='column' $gap={24}>
            <Flex $direction='column' $gap={8}>
              <Caption size='SM'>Please tell us what you didn’t like about the results.
              </Caption>
              <S.InputArea
                value={content}
                onChange={(event) => {
                  setContent(event.target.value)
                }}
                required
                placeholder='Write your feedback here (Required)'
              />
            </Flex>
            <Button
              $variant='fill'
              $colorTheme='tint'
              $height={40}
              disabled={!content}
              onClick={() => {
                submitFeedback({
                  feedback: {
                    body: content,
                    type: FeedbackType.BUG_REPORT,
                    jobSearchRefinementId,
                    url: window.location.href
                  }
                })
                controlDialog({ id: DialogId.REFINEMENT_FEEDBACK, newState: false })
              }}
            >
              Send Feedback
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
