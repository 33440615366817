import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from 'src/hooks/query-client'
import { GlobalContext } from 'src/contexts/global'
import { ThemeProvider } from 'styled-components'
import { GlobalReset, GlobalStyle } from 'src/styles/global'
import { lightTheme } from 'src/styles/theme'
import { AuthProvider } from './auth'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import PostHogPageviewTrackerProvider from './posthog-pageview-tracker'
import { QueryErrorHandler } from './query-error-handler'
import { AblyProvider } from 'ably/react'
import ablyClient from 'src/hooks/ably-client'
import { ChannelsProvider } from './ably-channels'
import { Provider } from 'jotai'
import { store } from 'src/stores'
import { Dialogs } from 'src/components/dialogs'
import { Notifications } from 'src/components/blocks/popup-notifications'
import { ApiStatusProvider } from './api_status'
import { CalComProvider } from 'src/components/blocks/book-a-demo-button/book-a-demo-button'
import { ProductTourProvider } from './product-tour'
import { SequenceTourProvider } from './sequence-tour'

type GlobalProviderProps = React.PropsWithChildren & {
  globalState: object
}

const GlobalProvider = ({ children, globalState }: GlobalProviderProps): JSX.Element => {
  return (
    <GlobalContext.Provider value={globalState}>
      <QueryClientProvider client={queryClient}>
        <QueryErrorHandler />
        <AuthProvider>
          <Provider store={store}>
            <ApiStatusProvider />
            <CalComProvider />
            <PostHogProvider client={posthog}>
              <PostHogPageviewTrackerProvider />
              <ProductTourProvider />
              <SequenceTourProvider />
                <AblyProvider client={ablyClient}>
                  <ThemeProvider theme={lightTheme}>
                    <GlobalReset />
                    <GlobalStyle />
                    <ChannelsProvider>
                      <Dialogs />
                      <Notifications />
                      {children}
                    </ChannelsProvider>
                  </ThemeProvider>
                </AblyProvider>
            </PostHogProvider>
          </Provider>
        </AuthProvider>
      </QueryClientProvider>
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
