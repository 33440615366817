import { useParams } from 'react-router-dom'
import { Item } from './item'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobInboxSort, CandidateJobInboxSortDisplay, CandidateJobInboxFilterDisplay, CandidateJobInboxFilter, CandidateJobRejectionReason, CandidateJobStatus } from 'src/libs/api/backend/candidate_jobs'
import { findIndex, isNil } from 'lodash'
import { formatDate } from 'src/utils/format-date'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import { useEffect, useRef, useState } from 'react'
import { When } from '../when'
import * as S from './inbox-sidebar.styled'
import { useVirtualizer } from '@tanstack/react-virtual'
import { SIDEBAR_PADDING, SIDEBAR_PADDING_COLLAPSED } from 'src/styles/constants'
// import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { Spacer } from 'src/components/primitives/spacer'
import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'
import { pluralize } from 'src/libs/pluralize'
import { Box } from 'src/components/primitives/box'

interface InboxSidebarProps {
  candidateJobs?: CandidateJobExpanded[]
  isPending?: boolean
  isExpanded: boolean
  toggleSidebarPinned: () => void
  isPinned: boolean
  setIsHovered: (value: boolean) => void
  isHovered: boolean
}

interface HandleItemSelectArgs {
  isSelected: boolean
  candidateJobId: string
  index: number
  isShiftKey: boolean
}

const FILTER_STATUS_MAP: Record<CandidateJobInboxFilter, CandidateJobStatus[]> = {
  [CandidateJobInboxFilter.NEEDS_ATTENTION]: [
    CandidateJobStatus.CANDIDATE_WAITING_FOR_RESPONSE,
    CandidateJobStatus.WAITING_ON_CANDIDATE_TO_RESPOND
  ],
  [CandidateJobInboxFilter.INTERESTED]: [
    CandidateJobStatus.INTERESTED,
    CandidateJobStatus.RESPONDED
  ],
  [CandidateJobInboxFilter.NOT_INTERESTED]: [
    CandidateJobStatus.PREVIOUSLY_UNSUBSCRIBED
  ],
  [CandidateJobInboxFilter.UNRESPONSIVE]: [
    CandidateJobStatus.CANDIDATE_UNRESPONSIVE
  ],
  [CandidateJobInboxFilter.ARCHIVED]: [
    CandidateJobStatus.REJECTED
  ]
}

export const InboxSidebar = ({
  candidateJobs: candidateJobsData,
  isPending,
  isExpanded = true,
  toggleSidebarPinned,
  isPinned,
  setIsHovered,
  isHovered
}: InboxSidebarProps): JSX.Element => {
  const { candidateJobId } = useParams()

  const [selectedCandidateJobIds, setSelectedCandidateJobIds] = useState<string[]>([])
  const [firstScroll, setFirstScroll] = useState(false)
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null)
  const [renderedCandidateJobs, setRenderedCandidateJobs] = useState<CandidateJobExpanded[]>([])

  // const { data: candidateJobData } = useCandidateJobQuery({
  //   candidateJobId
  // })

  const parentRef = useRef<HTMLDivElement>(null)

  const { setParam, getParam, resetParam } = useQueryParams()
  const [selectedSort, setSelectedSort] = useState<CandidateJobInboxSort>(CandidateJobInboxSort.MOST_RECENT)
  const [selectedFilters, setSelectedFilters] = useState<CandidateJobInboxFilter[]>([])
  const [isArchiving, setIsArchiving] = useState(false)
  const { rejectCandidate } = useRejectCandidate()

  const handleFilterSelect = (value: string): void => {
    if (Object.values(CandidateJobInboxSort).includes(value as CandidateJobInboxSort)) {
      setSelectedSort(value as CandidateJobInboxSort)
      setParam('sort', value)
    } else if (Object.values(CandidateJobInboxFilter).includes(value as CandidateJobInboxFilter)) {
      const filterValue = value as CandidateJobInboxFilter
      const updatedFilters = selectedFilters.includes(filterValue)
        ? selectedFilters.filter(f => f !== filterValue)
        : [...selectedFilters, filterValue]
      setSelectedFilters(updatedFilters)
      if (updatedFilters.length === 0) {
        resetParam('filter')
      } else {
        setParam('filter', updatedFilters.join(','))
      }
    }
  }

  useEffect(() => {
    const sortParam = getParam('sort')
    const filterParam = getParam('filter')

    if (sortParam && Object.values(CandidateJobInboxSort).includes(sortParam as CandidateJobInboxSort)) {
      setSelectedSort(sortParam as CandidateJobInboxSort)
    }

    if (filterParam) {
      try {
        const filters = filterParam.split(',')
        const validFilters = filters.filter(f => Object.values(CandidateJobInboxFilter).includes(f as CandidateJobInboxFilter)) as CandidateJobInboxFilter[]
        setSelectedFilters(validFilters)
      } catch (error) {
        setSelectedFilters([])
        resetParam('filter')
      }
    } else {
      setSelectedFilters([])
    }
  }, [])

  const handleArchiveAll = (): void => {
    setIsArchiving(true)

    const candidateJobIdsToArchive = selectedCandidateJobIds.length > 0 ? selectedCandidateJobIds : renderedCandidateJobs?.map(c => c.id) ?? []

    rejectCandidate({
      candidateJobIds: candidateJobIdsToArchive,
      rejectionReason: CandidateJobRejectionReason.OTHER,
      onSettled: () => {
        setIsArchiving(false)
      }
    })
  }

  useEffect(() => {
    const sidebarElm = parentRef.current
    if (!sidebarElm) {
      return
    }

    const onMouseMove = (evt: MouseEvent): void => {
      const sidebarBoundingBox = sidebarElm.getBoundingClientRect()

      const isCurrentlyHovered =
        evt.pageX >= sidebarBoundingBox.left &&
        evt.pageX <= sidebarBoundingBox.right &&
        evt.pageY >= sidebarBoundingBox.top &&
        evt.pageY <= sidebarBoundingBox.bottom

      if (isCurrentlyHovered !== isHovered) {
        setIsHovered(isCurrentlyHovered)
      }
    }

    document.addEventListener('mousemove', onMouseMove)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [isHovered, setIsHovered, isExpanded])

  useEffect(() => {
    let processedCandidates = [...(candidateJobsData ?? [])]

    if (selectedFilters.length > 0) {
      processedCandidates = processedCandidates.filter(job => {
        const jobStatus = job.statusDisplay?.status
        if (!jobStatus) return false

        return selectedFilters.some(filter => {
          const validStatuses = FILTER_STATUS_MAP[filter]
          return validStatuses.includes(jobStatus)
        })
      })
    }

    if (selectedSort === CandidateJobInboxSort.OLDEST) {
      processedCandidates.sort((a, b) => {
        const dateA = new Date(a.candidate.lastCommunicatedAt ?? 0).getTime()
        const dateB = new Date(b.candidate.lastCommunicatedAt ?? 0).getTime()
        return dateA - dateB
      })
    }

    setRenderedCandidateJobs(processedCandidates)
  }, [selectedFilters, candidateJobsData, selectedSort])

  const virtualizer = useVirtualizer({
    count: renderedCandidateJobs?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 66,
    overscan: 10,
    getItemKey: (index) => renderedCandidateJobs?.[index]?.id ?? `item-${index}`
    // getItemKey: (index) => renderedCandidateJobs?.[index]?.candidateId ?? `candidate-${index}`
  })

  // useEffect(() => {
  //   virtualizer.measure()
  //   requestAnimationFrame(() => {
  //     virtualizer.measure()
  //   })
  // }, [selectedFilters, renderedCandidateJobs, virtualizer])

  // scrolls automatically to element when a direct link has been used like "view in inbox"
  useEffect(() => {
    if (!isNil(renderedCandidateJobs) && !firstScroll) {
      const index = findIndex(renderedCandidateJobs, { id: candidateJobId })
      if (index >= 0) {
        setTimeout(() => {
          virtualizer.scrollToIndex(index, {
            align: 'start'
          })
        })
        setFirstScroll(true)
      }
    }
  }, [renderedCandidateJobs, candidateJobId, virtualizer, firstScroll])

  const handleItemSelect = ({ isSelected, candidateJobId, index, isShiftKey }: HandleItemSelectArgs): void => {
    if (isShiftKey && lastSelectedIndex !== null && renderedCandidateJobs) {
      const start = Math.min(lastSelectedIndex, index)
      const end = Math.max(lastSelectedIndex, index)

      const idsToSelect = renderedCandidateJobs.slice(start, end + 1).map(job => job.id)

      setSelectedCandidateJobIds(prev => {
        const newSelection = isSelected
          ? Array.from(new Set([...prev, ...idsToSelect]))
          : prev.filter(id => !idsToSelect.includes(id))

        return newSelection
      })
    } else {
      setSelectedCandidateJobIds(prev => {
        const newSelection = prev.includes(candidateJobId)
          ? prev.filter(id => id !== candidateJobId)
          : [...prev, candidateJobId]
        setLastSelectedIndex(index)
        return newSelection
      })
    }
  }

  const getCandidateJobsCountPerFilter = (filterValue: CandidateJobInboxFilter): number => {
    const validStatuses = FILTER_STATUS_MAP[filterValue]
    return candidateJobsData?.filter(job => {
      const jobStatus = job.statusDisplay?.status
      return jobStatus && validStatuses.includes(jobStatus)
    }).length ?? 0
  }

  // loading
  if (isPending) {
    // return <LoadingSkeleton $variant="InboxSidebar" />
    return <></>
  }

  // empty state
  if (!isPending && (!renderedCandidateJobs?.length)) {
    return (
      <div
        style={{
          padding: isExpanded ? `0 ${SIDEBAR_PADDING}` : `0 ${SIDEBAR_PADDING_COLLAPSED}`,
          height: '100%'
        }}
      >
        <S.Empty>
          <Icon
            name={isExpanded ? 'messages-square' : 'x-octagon'}
            size={isExpanded ? 40 : 20}
            color="fgTranslucent10"
          />
          <When condition={isExpanded}>
            <Caption size="SM" $color="fgSecondary" $align="center">
              No conversations to display
            </Caption>
          </When>
        </S.Empty>
      </div>
    )
  }

  return (
    <S.InboxSidebar $isHovered={isHovered} $isPinned={isPinned}>
      <S.Items $isExpanded={isExpanded}>
        <S.InboxSidebarHeader $justify={isExpanded ? 'space-between' : 'center'}>
          <When condition={isExpanded}>
            <Dropdown
              trigger={
                <Button
                  nested
                  $variant="raised"
                  $colorTheme="normal"
                  $height={24}
                  $fontSize={12}
                  trailingIcon={Icons.chevronsUpDown}
                >
                  {selectedFilters.length > 0
                    ? (selectedFilters.map(filter => CandidateJobInboxFilterDisplay[filter]).join(', ').length > 27
                        ? selectedFilters.map(filter => CandidateJobInboxFilterDisplay[filter]).join(', ').slice(0, 27) + '...'
                        : selectedFilters.map(filter => CandidateJobInboxFilterDisplay[filter]).join(', '))
                    : CandidateJobInboxSortDisplay[selectedSort]
                  }
                </Button>
              }
              items={[
                {
                  title: 'View all',
                  type: 'sublabel'
                },
                ...Object.values(CandidateJobInboxSort).map(sortValue => ({
                  title: CandidateJobInboxSortDisplay[sortValue],
                  value: sortValue,
                  onSelect: () => { handleFilterSelect(sortValue) }
                })),
                {
                  title: 'Separator',
                  type: 'separator'
                },
                {
                  title: 'Filter',
                  type: 'sublabel'
                },
                ...Object.values(CandidateJobInboxFilter).map(filterValue => ({
                  title: CandidateJobInboxFilterDisplay[filterValue],
                  trailingIcon: <Box $padding={{ top: 0, left: 0, right: 2, bottom: 0 }}><Caption size="2XS" $color="fgSecondary">{getCandidateJobsCountPerFilter(filterValue)}</Caption></Box>,
                  value: filterValue,
                  isDisabled: getCandidateJobsCountPerFilter(filterValue) === 0,
                  isSelectable: getCandidateJobsCountPerFilter(filterValue) > 0,
                  onSelect: () => { handleFilterSelect(filterValue) }
                })),
                ...(selectedFilters.length > 0
                  ? [
                      {
                        title: 'Separator',
                        type: 'separator'
                      },
                      {
                        title: 'Reset Filters',
                        onSelect: () => {
                          setSelectedFilters([])
                          resetParam('filter')
                        }
                      }
                    ]
                  : [])
              ]}
              size="small"
              selectedValue={[selectedSort, ...selectedFilters]}
              selectedValueHasTrailingIcon={true}
              selectType="multi"
            />
          </When>
          <Button
            ariaLabel="Collapse navigation bar"
            leadingIcon={isPinned ? 'chevrons-left-thin' : 'chevrons-right-thin'}
            $variant="ghost"
            $colorTheme="muted"
            $height={24}
            $width={24}
            onClick={toggleSidebarPinned}
            $borderRadius={9999}
          />
        </S.InboxSidebarHeader>
        <Spacer $size={10} />
        <S.ItemsInner ref={parentRef}>
          <div
            style={{
              height: virtualizer.getTotalSize(),
              position: 'relative',
              width: '100%',
              scrollbarWidth: isExpanded ? 'thin' : 'none'
            }}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const candidateJob = renderedCandidateJobs[virtualRow.index]
              if (isNil(candidateJob)) {
                return null
              }
              let timePassed = '∞'
              if (!isNil(candidateJob.candidate.lastCommunicatedAt)) {
                timePassed =
                  formatDate(candidateJob.candidate.lastCommunicatedAt, {
                    format: 'DaysPassedShort'
                  }) ?? timePassed
              }

              const latestExperience = candidateJob.candidate.experiences?.[0]

              return (
                <div
                  style={{
                    width: 'calc(100% - 2px)',
                    position: 'absolute',
                    top: `${virtualRow.start}px`,
                    height: '66px'
                  }}
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  <Item
                    candidateJob={candidateJob}
                    timePassed={timePassed}
                    company={latestExperience?.company || ''}
                    companyWebsite={latestExperience?.companyInformation?.website}
                    companyLogoUrl={latestExperience?.logoUrl}
                    isExpanded={isExpanded}
                    isSelected={selectedCandidateJobIds.includes(candidateJob.id)}
                    hasAtLeastOneSelected={selectedCandidateJobIds.length > 0}
                    onSelect={(value) => {
                      handleItemSelect({
                        ...value,
                        index: virtualRow.index
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
        </S.ItemsInner>
      </S.Items>
      <When condition={selectedCandidateJobIds.length > 0 || selectedFilters.length > 0}>
        <S.InboxSidebarActions>
          <S.InboxSidebarActionsInner>
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={24}
              $fontSize={12}
              onClick={() => { handleArchiveAll() }}
              loading={isArchiving}
            >
              Archive {selectedCandidateJobIds.length > 0 ? `${pluralize(selectedCandidateJobIds.length, 'Candidate')}` : 'All'}
            </Button>
          </S.InboxSidebarActionsInner>
        </S.InboxSidebarActions>
      </When>
    </S.InboxSidebar>
  )
}
