import styled, { css } from 'styled-components'
import type { RuleSet } from 'styled-components'
import {
  Combobox as AriaKitCombobox,
  ComboboxItem as AriaKitComboboxItem,
  ComboboxList as AriaKitComboboxList
} from '@ariakit/react'

export const RowWrapper = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing[4]} 0 ${({ theme }) => theme.spacing[8]};
`

export const CriteriaRowWrapper = styled.div<{ $updated?: boolean }>`
  display: grid;
  grid-template-columns: 240px 1fr;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.spacing[6]};
  width: 100%;
  padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[12]};
  ${({ $updated }) => $updated && css`
      background-color: ${({ theme }) => theme.colors.warningTranslucent10};
    `
  }
  &:hover, &:focus-within {
    background-color: ${({ theme }) => theme.colors.tintTranslucent10};
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 2rem;
`

export const CriteriaPill = styled.div<{ $minWidth?: number, $hasIcon?: boolean }>`
  position: relative;
  display: flex;
  min-width: ${({ $minWidth }) => $minWidth ? `${$minWidth}px` : 'fit-content'};
  height: 32px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme, $hasIcon }) => $hasIcon ? `${theme.spacing[8]} ${theme.spacing[6]}` : theme.spacing[8]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
`

export const CriteriaSwitchContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[12]};
  gap: ${({ theme }) => theme.spacing[4]};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
`

export const CriteriaPillActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 1px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: -4px 0px 4px 0px #FFF;
`

const CommonInputStyles = css`
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
`

export const AddNewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.positiveTranslucent25};
  border-radius: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.positiveBg};
  outline: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  height: 32px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  width: 0px;
  overflow: hidden;
  transition: all 0s;
  opacity: 0;

  &:hover {
    border-color: ${({ theme }) => theme.colors.positiveBg};
  }
  &[data-state="open"],
  ${CriteriaRowWrapper}:hover &,
  ${CriteriaRowWrapper}:focus-within & {
    opacity: 1;
    width: 85px;
    transition: all 0s;
    transition-delay: 0.15s;
    padding: 2px 12px 2px 6px;
  }
`

export const MoreButton = styled.button`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.fgTertiary};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[8]};
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.fgTranslucent25};
  }
`

const CommonFieldStyles = (disabled?: boolean): RuleSet<object> => css`
  flex: 1 1 0;
  min-height: 2rem;
  padding: ${({ theme }) => theme.spacing[8]};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  &:focus {
    outline: none;
  }
  ${disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`

export const CriteriaMultiValuesField = styled.div<{ $disabled?: boolean }>`
  flex: 1 1 0;
  min-height: 2rem;
  display: flex;
  align-items: center;
  background-color: transparent;
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
  gap: ${({ theme }) => theme.spacing[4]};
  flex-wrap: wrap;
`
export const CustomRequirementField = styled.div<{ $disabled?: boolean, $errored?: boolean }>`
  ${({ $disabled }) => CommonFieldStyles($disabled)}
  ${({ $errored }) => $errored && css`
    border: 1px solid ${({ theme }) => theme.colors.negativeBg};
  `}
  width: 100%;
  input {
    ${CommonInputStyles}
  }
`

export const CustomRequirementWarning = styled.div`
  padding: 0 ${({ theme }) => theme.spacing[8]};
  width: 100%;
`

export const RemoveCriteriaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.spacing[32]};
  width: ${({ theme }) => theme.spacing[32]};
`

const CommonFieldStylesV2 = (disabled?: boolean): RuleSet<object> => css`
  min-height: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  ${disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
  &:focus-within {
    outline: none;
  }
`

export const MinMaxNumberField = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => CommonFieldStylesV2($disabled)}
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[2]};
`

export const MinMaxInputField = styled.input`
  color: ${({ theme }) => theme.colors.fgPrimary};
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  padding: ${({ theme }) => theme.spacing[4]};
  height: 100%;
  width: 2rem;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const SearchInput = styled.input`
  height: 32px;
  width: 200px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  padding: 0 ${({ theme }) => theme.spacing[10]};
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  &:focus {
    outline: none;
  }
`

export const Combobox = styled(AriaKitCombobox)<{ $isLoading: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: ${({ theme }) => theme.spacing[4]};
  height: 32px;
  width: 200px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  &:placeholder {
    color: ${({ theme }) => theme.colors.fgSecondary};
  }
  &:focus {
    outline: none;
  }
  padding: 0 ${({ theme }) => theme.spacing[10]};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Results = styled(AriaKitComboboxList)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 200px;
  width: calc(100% + 10px);
  scrollbar-width: thin;
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.md};
  z-index: ${({ theme }) => theme.zindeces.dropdown};
  overflow-y: auto;
  gap: 1px;
`

export const ResultInner = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
`

export const SuggestionLabel = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[16]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
`

export const ResultItem = styled(AriaKitComboboxItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[6]};
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[10]}`};
  border-radius: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  overflow: hidden;
  &[data-active-item] {
    background-color: ${({ theme }) => theme.colors.bgTertiary};
  }
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const RowContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: flex-start;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`

export const MultiSelectWrapper = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
  flex: 1 1 0;
`

export const MultiSelectInner = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  background-color: transparent;
  min-height: 2rem;
`

export const RemoveCriteriaButton = styled.div`
  opacity: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 32px;
  width: 32px;

  ${CriteriaRowWrapper}:hover &, ${CriteriaRowWrapper}:focus-within & {
    opacity: 1;
    transition: all 0.15s ease-in;
  }
`
