import { Grid } from 'src/components/primitives/grid'
import type { CommunicationInsightsOverviewPeriod } from 'src/libs/api/backend/reports_communication_insights'
import { AnalyticsCard } from 'src/components/charts/primitives/analytics-card'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'

interface OverviewProps {
  data: Partial<CommunicationInsightsOverviewPeriod>
}

export const OverviewChart = ({ data }: OverviewProps): JSX.Element => {
  const friendlyKey = (key: string): string => {
    switch (key) {
      case 'sent':
        return 'Messages Sent'
      case 'total':
        return 'Candidates Messaged'
      case 'responded':
        return 'Candidates Replied'
      case 'interested':
        return 'Candidates Interested'
      case 'scheduled':
        return 'Meetings Scheduled'
      case 'openedRate':
        return 'Open Rate'
      default:
        return key
    }
  }

  const dataPoints = 'dataPoints' in data ? data.dataPoints : data

  return (
    <Grid $columns={5} $columnGap={16} $width="100%">
      {Object.entries(dataPoints as Record<string, unknown>)
        .filter(([key]) => !key.startsWith('past'))
        .map(([key, value]) => (
          <ChartWrapper key={key} $padding={{ top: 16, right: 16, bottom: 16, left: 16 }}>
            <AnalyticsCard
              title={friendlyKey(key)}
              value={
                typeof value === 'number'
                  ? key === 'openedRate'
                    ? `${Math.round(value * 100)}%`
                    : Math.round(value).toLocaleString()
                  : String(value)
              }
              change={{
                current: value as number,
                past: Number((dataPoints as Record<string, unknown>)[`past${key.charAt(0).toUpperCase() + key.slice(1)}`]) || 0
              }}
              variantConfig={
                key === 'openedRate'
                  ? {
                      value: value as number,
                      thresholds: {
                        good: 0.45,
                        average: 0.3,
                        poor: 0.2
                      }
                    }
                  : undefined
              }
            />
          </ChartWrapper>
        ))}
    </Grid>
  )
}
