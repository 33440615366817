import styled from 'styled-components'

export const Wrapper = styled.div<{ $hasFile: boolean, $isDragActive: boolean }>`
  border-radius: 0.25rem;
  border: dashed 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  padding: 1rem 1.5rem;
  cursor: pointer;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isDragActive, theme }) => $isDragActive && `
    border: solid 1px;
    border-color: ${theme.colors.tintBg};
    background-color: ${theme.colors.tintTranslucent5};
  `}
  ${({ $hasFile, theme }) => $hasFile && `
    border: solid 1px;
    background-color: ${theme.colors.bgSecondary};
    height: auto;
  `}
`

export const AcceptedFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  svg {
    transform: translateY(1px);
  }
`
