export enum ReportType {
  CANDIDATES_INSIGHTS = 'candidates_insights',
  COMMUNICATION_INSIGHTS = 'communication_insights',
  PIPELINE_INSIGHTS = 'pipeline_insights',
  TEAM_ACTIVITIES = 'team_activities'
}

export enum TimeFrame {
  ALL_TIME = 'ALL_TIME',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS'
}

export enum TimeFrameDisplay {
  ALL_TIME = 'All Time',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_3_MONTHS = 'Last 3 Months',
  LAST_6_MONTHS = 'Last 6 Months'
}

export interface FetchReportArgs {
  jobId: string
  timeFrame: TimeFrame
  timezone?: string
  compareToPreviousPeriod?: boolean
}
