import { Button, Card, Flex, Spacer, VisualDivider } from 'src/components/primitives'
import { MAX_ALLOWED_EMAILS } from '../automation-settings/settings'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import * as S from './email-settings.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { useCallback, useEffect, useMemo, Fragment } from 'react'
import { Form, Input } from 'src/components/forms'
import { Toggle } from 'src/components/primitives/toggle'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import type { UpdateJob } from 'src/libs/api/backend/jobs'
import { WeekAvailabilityPicker } from '../week-availability-picker'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useSession } from 'src/hooks/use-session'
import { When } from '../when/when'
import { EmailLookUpSettings } from './email-lookup-settings'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { useHolidays } from 'src/hooks/use-holidays'
import { format } from 'date-fns'
import { EmailFallbackSettings } from './email-fallback-settings'

interface PreferenceRowProps {
  title: string
  subTitle: string | React.ReactNode
  action: React.ReactNode
}

const PreferenceRow = ({ title, subTitle, action }: PreferenceRowProps): JSX.Element => {
  return (
    <S.PreferenceRowWrapper>
      <S.PreferenceRowContainer>
        <Flex $gap={0} $direction='column' $width='auto' $flex="1 1 auto">
          <Caption size='SM' $ellipsis>{title}</Caption>
          <S.PreferenceRowSubTitle>
            {
              typeof subTitle === 'string'
                ? (
                    <Paragraph size='XS' $color='fgSecondary' $ellipsis $fontWeight={400}>{subTitle}</Paragraph>
                  )
                : (
                    subTitle
                  )
            }
          </S.PreferenceRowSubTitle>
        </Flex>
        <S.Action>
          {action}
        </S.Action>
      </S.PreferenceRowContainer>
    </S.PreferenceRowWrapper>
  )
}

const emailsLimitForm = z.object({
  dailyEmailLimit: z.number().max(MAX_ALLOWED_EMAILS, 'Maximum number of daily emails is 100')
})

type EmailsLimitForm = z.infer<typeof emailsLimitForm>

export const EmailSettings = (): JSX.Element => {
  const { isPending, data: emailSequence } = useJobSequenceQuery()
  const { upsertJobSequence } = useUpsertJobSequence()
  const { jobId } = useParams<{ jobId: string }>()
  const { data: jobData } = useJobQuery()
  const { updateJobPosition } = useUpdateJobPosition()
  const { featureFlags, userHasViewerRole } = useSession()
  const { register, setValue, formData, submit } = useForm<EmailsLimitForm>({
    schema: emailsLimitForm
  })
  const openDialog = useSetAtom(openDialogAtom)
  const { nextHoliday } = useHolidays()

  const updateSequence = useCallback((updates: Partial<typeof emailSequence>): void => {
    if (isNil(emailSequence) || isNil(jobId)) {
      return
    }

    upsertJobSequence({
      ...emailSequence,
      ...updates,
      jobId,
      enableEmailOpenTracking: updates?.enableEmailOpenTracking ?? emailSequence.enableEmailOpenTracking ?? false,
      enableEmailClickTracking: updates?.enableEmailClickTracking ?? emailSequence.enableEmailClickTracking ?? false,
      toastMessage: 'Successfully updated outreach preferences'
    })
  }, [emailSequence, jobId, upsertJobSequence])

  const updateEmailLimit = useCallback((): void => {
    if (!isNil(emailSequence) && formData?.dailyEmailLimit !== emailSequence.dailyEmailLimit) {
      if (typeof formData?.dailyEmailLimit === 'number' && formData?.dailyEmailLimit <= MAX_ALLOWED_EMAILS) {
        updateSequence({ dailyEmailLimit: formData.dailyEmailLimit })
      }
    }
  }, [emailSequence, formData?.dailyEmailLimit, updateSequence])

  const handleSubmit = useCallback(async (): Promise<void> => {
    updateEmailLimit()
  }, [updateEmailLimit])

  useEffect(() => {
    setValue('dailyEmailLimit', emailSequence?.dailyEmailLimit)
    setValue('autoArchiveAfterDays', emailSequence?.autoArchiveAfterDays)
  }, [emailSequence?.dailyEmailLimit, emailSequence?.autoArchiveAfterDays, setValue])

  const handleUpdateJob = useCallback((updatedFields: Partial<UpdateJob>, showToast = true) => {
    if (isNil(jobData) || isNil(jobId)) return
    if (userHasViewerRole) {
      return
    }
    updateJobPosition({
      updatedJob: {
        id: jobId,
        ...updatedFields
      },
      showToast
    })
  }, [jobData, jobId, updateJobPosition, userHasViewerRole])

  const emailTrackingToggles = useMemo(() => [
    {
      name: 'email-open-tracking',
      title: 'Track email opens',
      description: 'Add tracking to emails sent through Pin to track when candidates open them.',
      checked: emailSequence?.enableEmailOpenTracking ?? false,
      onChange: () => { updateSequence({ enableEmailOpenTracking: !emailSequence?.enableEmailOpenTracking }) }
    },
    {
      name: 'email-click-tracking',
      title: 'Track email link clicks*',
      description: 'Pin rewrites links in your emails to track when candidates click them. *Enabling this feature may cause your emails to be marked as spam by Google and other providers.',
      checked: emailSequence?.enableEmailClickTracking ?? false,
      onChange: () => { updateSequence({ enableEmailClickTracking: !emailSequence?.enableEmailClickTracking }) }
    }
  ], [emailSequence, updateSequence])

  const nextCustomHoliday = useMemo(() => {
    if (!jobData?.customHolidays?.length) return null
    const futureHolidays = jobData.customHolidays
      .filter(date => new Date(date) >= new Date())
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    return futureHolidays[0] ?? null
  }, [jobData?.customHolidays])

  if (isPending) {
    return <></>
  }

  return (
    <Flex $direction='column' $gap={12}>
      <When condition={featureFlags.includes(FeatureFlags.EMAIL_SEARCH_PREFERENCE)}>
        <Card>
          <EmailLookUpSettings
            onChange={(emailSearch) => {
              updateSequence({ emailSearch })
            }}
          />
          <VisualDivider $spacingTop={16} $spacingBottom={16} />
          <EmailFallbackSettings
            onChange={({ autoSkipEmailStepsOnMissingEmail }) => {
              updateSequence({ autoSkipEmailStepsOnMissingEmail })
            }}
          />
        </Card>
      </When>
      <PreferenceRow
        title='Auto-archive stale candidates'
        subTitle='Archive if no communications after this amount of days.'
        action={
          <Form onSubmit={submit(handleSubmit)}>
            <Input
              label="Auto-archive candidates after days"
              hiddenLabel
              type="number"
              name="autoArchiveAfterDays"
              placeholder={!isNil(emailSequence?.autoArchiveAfterDays) ? emailSequence.autoArchiveAfterDays.toString() : '15'}
              register={register}
              onBlurEvent={() => {
                if (typeof formData?.autoArchiveAfterDays === 'number') {
                  updateSequence({ autoArchiveAfterDays: formData.autoArchiveAfterDays })
                }
              }}
              $marginBottom={0}
              size='small'
              isDisabled={userHasViewerRole}
              trailingNote='days'
            />
          </Form>
        }
      />
      <PreferenceRow
        title='Daily outreach emails limit'
        subTitle='How many outreach emails Pin can send per day.'
        action={
          <Form onSubmit={submit(handleSubmit)}>
            <Input
              label="Max emails per day"
              hiddenLabel
              type="number"
              name="dailyEmailLimit"
              placeholder={emailSequence?.dailyEmailLimit.toString()}
              max={MAX_ALLOWED_EMAILS}
              register={register}
              onBlurEvent={() => {
                updateEmailLimit()
              }}
              $marginBottom={0}
              size='small'
              isDisabled={userHasViewerRole}
            />
          </Form>
        }
      />
      <Card>
        {emailTrackingToggles.map((toggle, index) => (
          <Fragment key={toggle.name}>
            <Flex $align="center" $justify="space-between">
              <Flex $direction="column" $gap={2}>
                <Caption size="SM">{toggle.title}</Caption>
                <Paragraph size="XS" $color="fgSecondary">
                  {toggle.description}
                </Paragraph>
              </Flex>
              <Toggle
                name={toggle.name}
                checked={toggle.checked}
                onChange={toggle.onChange}
                disabled={userHasViewerRole}
              />
            </Flex>
            {index !== emailTrackingToggles.length - 1 && <VisualDivider $spacingTop={16} $spacingBottom={16} />}
          </Fragment>
        ))}
      </Card>

      <Card>
        <Flex $direction="column" $gap={2}>
          <Caption size="SM">When to send emails</Caption>
          <Paragraph size="XS" $color="fgSecondary">
            Choose weekdays and time of day when emails can be sent.
          </Paragraph>
        </Flex>
        <Spacer $size={16} />
        <WeekAvailabilityPicker
          defaultAvailability={jobData?.emailWhenToSend}
          onAvailabilityChange={(updated) => {
            handleUpdateJob({ emailWhenToSend: updated }, false)
          }}
          disabled={userHasViewerRole}
        />
      </Card>
      <PreferenceRow
        title='Pause emails on holidays'
        subTitle={
          <>
            <Paragraph size='XS' $color='fgSecondary' $ellipsis>
              Next holiday: {nextHoliday ? format(new Date(nextHoliday.date), 'MMMM d, yyyy') : 'None'} {nextHoliday && `(${nextHoliday.name})`} · <S.AllHolidaysLink onClick={() => { openDialog({ id: DialogId.VIEW_HOLIDAYS }) }}>View all holidays</S.AllHolidaysLink>
            </Paragraph>
          </>
        }
        action={
          <Toggle
            name='skip-holidays'
            checked={jobData?.emailSkipHolidays ?? false}
            onChange={() => {
              handleUpdateJob({ emailSkipHolidays: !jobData?.emailSkipHolidays })
            }}
            disabled={userHasViewerRole}
          />
        }
      />
      <PreferenceRow
        title='Pause emails on custom dates'
        subTitle={
          <Paragraph size='XS' $color='fgSecondary' $ellipsis>
            {nextCustomHoliday
              ? `Next custom holiday: ${format(new Date(nextCustomHoliday), 'MMMM d, yyyy')}`
              : 'No custom holidays added'
            }
          </Paragraph>
        }
        action={
          <Button
            $variant="raised"
            $colorTheme="tint"
            $height={24}
            $fontSize={12}
            onClick={() => {
              openDialog({ id: DialogId.MANAGE_SEQUENCE_PAUSE_DATES })
            }}
          >
            Manage Dates
          </Button>
        }
      />
    </Flex>
  )
}
