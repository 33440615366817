import styled from 'styled-components'
import type { StatusViewVariant } from './status-view'
import { Spacing } from 'src/styles/theme/types'

interface StatusViewProps {
  $variant: StatusViewVariant
}

export const StatusView = styled.div<StatusViewProps>`
  position: relative;
  width: 100%;
  height: calc(100% - 1.5rem);
  border-radius: 6px;
  padding: 4rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $variant }) => {
    switch ($variant) {
      case 'negative': {
        return theme.colors.negativeTranslucent5
      }
      default: {
        return theme.colors.bgTertiary
      }
    }
  }}};
`

export const Inner = styled.div<{ $contentMaxWidth?: number, $gap?: Spacing }>`
  width: 100%;
  max-width: ${({ $contentMaxWidth }) => $contentMaxWidth ?? 420}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme, $gap }) => theme.spacing[$gap ?? '24']};
`
