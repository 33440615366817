import styled, { css } from 'styled-components'
import type { CandidateExperiencesAndEducationListStyleProps } from './candidate-experiences-list'

export const Wrapper = styled.div<CandidateExperiencesAndEducationListStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  ${({ $padding }) => $padding && css`
    padding: ${$padding.top}px ${$padding.right}px ${$padding.bottom}px ${$padding.left}px;
  `}
`

export const HeadingSummary = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`

export const ExperienceCard = styled.div`
`

export const CompanyLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  opacity: 0;
`

export const CardHeader = styled.header<{ $isExpanded: boolean }>`
  position: relative;
  user-select: none;
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background-color: ${({ theme }) => theme.colors.bgTertiary};
    opacity: 0;
    border-radius: 6px;
    z-index: 1;
  }
  ${({ $isExpanded }) => !$isExpanded && css`
    &:before {
      content: '';
      position: absolute;
      top: 40px;
      left: 19px;
      width: 1px;
      height: 16px;
      // transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
      // background-color: green;
    }
  `}
  @media (hover: hover) {
    &:hover {
      &:after {
        opacity: 1;
      }
      ${CompanyLinks} {
        opacity: 1;
      }
    }
  }
`

export const CardHeaderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  z-index: 2;
`

export const CompanyInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const CompanyDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

export const SkillAndExperienceTags = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const CompanyQuickFacts = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    line-height: 1;
  }
`

export const CardHeaderExpandedContent = styled.div`
  position: relative;
  padding: 0.75rem 0.5rem 0.75rem 3.25rem;
  users-select: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 19px;
    width: 1px;
    height: calc(100% + 17px);
    // transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
    // background-color: green;
  }
`

export const ExperienceCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem 0.5rem 0.5rem 3.25rem;
`

export const ExperiencePosition = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;

  /* vertical line */
  &:not(:last-of-type) {
    &:before {
      content: '';
      position: absolute;
      top: 12px;
      left: -32px;
      width: 1px;
      height: calc(100% - 12px + 20px);
      // transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
      // background-color: green;
    }
  }

  /* bullet point */
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: -36px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
    // background-color: green;
  }
`

export const ExperiencePositionHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const ExperiencePositionDescription = styled.div`
padding: 0.5rem 0 0 0.25rem;
`

export const CompanyRatingActions = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-top: 0.75rem;
`

export const OverlappingEmployees = styled.div`
  position: relative;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderOpaque};
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  gap: 3px;
  font-size: 10px;
  // color: ${({ theme }) => theme.colors.fgPrimary};
  text-wrap: nowrap;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  span {
    font-size: inherit;
    color: inherit;
    transform: translateY(-1px);
  }
`

export const OverlappingEmployeesAvatars = styled.div`
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  transform: translate(-2px, -5px);
`

export const MoreButton = styled.div`
`
