import { Fragment, useMemo, useState } from 'react'
import { debounce, isNil } from 'lodash'
import type { CriteriaKey, CriteriaValue, DegreeMajorPairCriteria } from 'src/libs/api/backend/candidate_search'
import { useMajorSuggestion } from 'src/hooks/queries/use-major-suggestion'
import { DEGREE_OPTIONS } from '../constants'
import * as S from './index.styled'
import { SuggestionInput } from './suggestion-input'
import type { MajorSuggestion } from 'src/libs/api/backend/typeaheads'
import { RemoveCriteria } from './remove-criteria'
import { CriteriaPill } from './criteria-pill'
import type { CriteriaPillValue } from './criteria-pill'
import { titleCase } from 'src/libs/string'

interface DegreeMajorRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: DegreeMajorPairCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const DegreeMajorRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: DegreeMajorRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])
  const { isPending, data: majorSuggestions } = useMajorSuggestion({ query })

  const items = useMemo(() => {
    if (criteriaValue.length === 0) {
      return undefined
    }
    return criteriaValue.map((majorValue, index) => {
      if (isNil(majorValue.major)) {
        return <Fragment key={index} />
      }
      return (
        <CriteriaPill
          key={index}
          value={{
            label: titleCase(majorValue.major),
            subtitle: `${DEGREE_OPTIONS.get(majorValue.degree ?? 2)}`,
            optional: majorValue.optional ?? true,
            negative: majorValue.negative ?? false,
            degree: majorValue.degree ?? null
          }}
          onUpdate={(key: string, value: CriteriaPillValue) => {
            const updatedCriteriaValue = [...criteriaValue]
            updatedCriteriaValue[index] = {
              ...updatedCriteriaValue[index],
              [key]: value
            }
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
          onRemove={() => {
            const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
            onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
          }}
        />
      )
    })
  }, [criteriaKey, criteriaValue, onCriteriaUpdate])

  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isPending}
        disabled={disabled}
        triggerSearch={setQueryDebounced}
        suggestions={majorSuggestions ?? []}
        onSelect={(suggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              major: (suggestion as MajorSuggestion).major,
              degree: 2,
              optional: false,
              negative: false
            }]
          )
        }}
        items={items}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
