import { useSearchParams } from 'react-router-dom'
import { InboxPage } from 'src/components/pages/inbox-page'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'

const JobInboxPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const stage = searchParams.get('stage')
  const candidateJobStage = stage as CandidateJobStage
  const lastCommunicatedWithUserId = searchParams.get('lastCommunicatedWithUserId')

  const { isPending, data: candidateJobsData } = useCandidateJobsQuery({
    stage: stage === 'null'
      ? undefined
      : Object.values(CandidateJobStage).includes(candidateJobStage)
        ? candidateJobStage
        : CandidateJobStage.COMMUNICATING,
    lastCommunicatedWithUserId: lastCommunicatedWithUserId ?? undefined
  })

  return (
    <>
      <InboxPage isLoading={isPending} candidateJobs={candidateJobsData} />
    </>
  )
}

export default JobInboxPage
