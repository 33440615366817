import { Button, Flex } from 'src/components/primitives'
import * as S from './extend-trial.styled'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { extendTrialApi } from 'src/libs/api/backend/billing'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

interface ExtendTrialProps {
  items: Array<{ price: string, quantity: number }>
}

export const ExtendTrial = ({ items }: ExtendTrialProps): JSX.Element => {
  const [extendTrialLoading, setExtendTrialLoading] = useState(false)
  const endTrialDate = useMemo(() => {
    const now = new Date()
    return new Date(now.setDate(now.getDate() + 7))
  }, [])

  const extendTrial = useCallback(async () => {
    const { url } = await extendTrialApi(items)
    const checkoutWindow = window.open(url, '_blank')
    const timer = setInterval(() => {
      if (checkoutWindow?.closed) {
        clearInterval(timer)
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.customerSubscriptions]
        })
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.session]
        })
        setExtendTrialLoading(false)
      }
    }, 500)
  }, [items])

  return (
    <S.ExtendTrialBox>
      <Flex $direction='column' $gap={4} $flex='1 1 auto' $width='fit-content'>
        <Caption size='SM'>
          Extend trial by 7 days
        </Caption>
        <Paragraph size='XS'>
          CC Required, cancel before {format(endTrialDate, 'MMM d, yyyy')} for no charge
        </Paragraph>
      </Flex>
      <Button
        $variant='raised-light'
        $colorTheme='tint'
        $height={40}
        $width={120}
        $fontSize={14}
        $align='center'
        onClick={() => {
          setExtendTrialLoading(true)
          void extendTrial()
        }}
        loading={extendTrialLoading}
      >
        Extend Trial
      </Button>
    </S.ExtendTrialBox>
  )
}
