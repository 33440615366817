import styled from 'styled-components'

export const ConnectToLinkedInWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  padding: 4rem 0;
`

export const ConnectToLinkedInContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

export const Navigation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 2;
`

export const StepWrapper = styled.div`
`

export const Step = styled.div`
  min-width: 600px;
  max-width: 80%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 1.25rem 1.25rem 1.25rem 1rem;
  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderTranslucent};
  }
`

export const StepIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkedInAvatar = styled.div<{ $isConnected: boolean }>`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  /* overflow: hidden; */
  &:after {
    content: '';
    display: ${({ $isConnected }) => $isConnected ? 'block' : 'none'};
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 999px;
    border: solid 2px;
    border-color: ${({ theme }) => theme.colors.positiveFg};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 999px;
  }
`

export const WrongAccountBanner = styled.div`
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  a {
    color: inherit;
    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`

export const ChromeExtensionActions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`
