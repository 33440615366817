import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { TileChart } from 'src/components/charts/primitives/tile-chart'
import type { EventsByTimeReport } from 'src/libs/api/backend/reports_communication_insights'

interface EmailsOpenedByTimeSentChartProps {
  data: EventsByTimeReport
}

export const EmailsOpenedByTimeSentChart = ({ data }: EmailsOpenedByTimeSentChartProps): JSX.Element => {
  const chartData = data.dataPoints
  const streaks = data.longestStreaks

  return (
    <ChartWrapper>
      <TileChart
        chartTitle="Emails Opened by Time Sent"
        chartSubtitle="Pin automatically optimizes time outreach emails are sent"
        data={chartData}
        streaks={streaks}
      />
    </ChartWrapper>
  )
}
