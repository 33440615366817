import { z } from 'zod'
import Api from '..'

const schoolSuggestionParser = z.object({
  linkedin: z.string(),
  name: z.string(),
  domain: z.string().optional(),
  count: z.number().optional(),
  logoUrl: z.string().nullish()
})

export type SchoolSuggestion = z.infer<typeof schoolSuggestionParser>

export async function searchSchools ({ query }: { query: string | undefined }): Promise<SchoolSuggestion[]> {
  const { data } = await Api.get(`/typeaheads/schools?query=${query}`)
  return z.array(schoolSuggestionParser).parse(data)
}

const locationSuggestionParser = z.object({
  name: z.string(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  metro: z.string().nullish(),
  country: z.string().nullish(),
  continent: z.string().nullish(),
  count: z.number().nullish()
})

export type LocationSuggestion = z.infer<typeof locationSuggestionParser>

export async function searchLocations ({ query }: { query: string | undefined }): Promise<LocationSuggestion[]> {
  const { data } = await Api.get(`/typeaheads/locations?query=${query}`)
  return z.array(locationSuggestionParser).parse(data)
}

const majorSuggestionParser = z.object({
  name: z.string(),
  major: z.string(),
  count: z.number().nullish()
})

export type MajorSuggestion = z.infer<typeof majorSuggestionParser>

export async function searchMajors ({ query }: { query: string | undefined }): Promise<MajorSuggestion[]> {
  const { data } = await Api.get(`/typeaheads/majors?query=${query}`)
  return z.array(majorSuggestionParser).parse(data)
}

export enum EntityGroupType {
  JOB_TITLE = 'JOB_TITLE',
  COMPANY = 'COMPANY',
  SCHOOL = 'SCHOOL',
  INVESTOR = 'INVESTOR',
  CERTIFICATION = 'CERTIFICATION'
}

const entityGroupSuggestionParser = z.object({
  id: z.string(),
  name: z.string(),
  groupType: z.nativeEnum(EntityGroupType)
})

export type EntityGroupSuggestion = z.infer<typeof entityGroupSuggestionParser>

export async function searchEntityGroups ({ query, entityGroup }: { query: string | undefined, entityGroup: EntityGroupType }): Promise<EntityGroupSuggestion[]> {
  const { data } = await Api.get('/typeaheads/entity_group', { query, entityGroup })
  return z.array(entityGroupSuggestionParser).parse(data)
}
