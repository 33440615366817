import { useEffect, useState } from 'react'
import { Spacer } from 'src/components/primitives/spacer'
import { Form, Switch } from 'src/components/forms'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { OnboardingHeader } from 'src/components/blocks/onboarding-header'
import { useForm } from 'src/hooks/use-form'
import { OptionsList } from 'src/components/forms/options-list'
import { useSession } from 'src/hooks/use-session'
import { useOrgInviteDirectory } from 'src/hooks/queries/use-org-invite'
import { useOrgInviteUsers } from 'src/hooks/mutations/use-org-invite-users'
import { domainAllowListForm, updateOrg } from 'src/libs/api/backend/orgs'
import type { DomainAllowListForm } from 'src/libs/api/backend/orgs'
import { useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'
import { UserRole } from 'src/libs/api/backend/users'
import { useOnboardingSteps } from 'src/hooks/use-onboarding-steps'

interface Invitee {
  name?: string | null
  email: string
  role: UserRole
  photoUrl?: string | null
}

const OnboardingInviteTeam = (): JSX.Element => {
  const { org } = useSession()
  const [invitees, setInvitees] = useState<Invitee[] | null>(null)
  const { register, formData } = useForm<DomainAllowListForm>({
    schema: domainAllowListForm
  })
  const { data: orgInviteDirectory } = useOrgInviteDirectory()
  const { inviteUsers } = useOrgInviteUsers()
  const navigate = useNavigate()
  const { nextStep } = useOnboardingSteps()

  useEffect(() => {
    if (!isNil(orgInviteDirectory)) {
      const orgInvitees = orgInviteDirectory.map((invitee) => ({
        name: invitee.name,
        email: invitee.email,
        role: UserRole.ADMIN,
        photoUrl: invitee.photoUrl
      }))
      setInvitees(orgInvitees)
    }
  }, [orgInviteDirectory])

  useEffect(() => {
    if (!isNil(formData)) {
      updateOrg(formData)
    }
  }, [formData])

  const sendInvitesAndContinue = (): void => {
    if (!isNil(invitees)) {
      const prepared = invitees.map(({ name, ...rest }) => ({
        ...rest,
        ...(name ? { name } : {})
      }))
      inviteUsers({ invitees: prepared })
    }
    if (nextStep?.href) {
      navigate(nextStep?.href)
    }
  }

  return (
    <>
      <OnboardingHeader
        heading="Invite Your Team"
        subheading="Work together to review and interview candidates"
      />
      <Form>
        <Switch
          $variant="raised"
          label={`Allow anyone from ${org?.name ?? 'your company'} to join this team`}
          description={`Must have a verified @${org?.domain} email address`}
          name="allowDomainJoin"
          register={register}
          labelColor='fgPrimary'
          defaultChecked={true}
        />
      </Form>
      <Spacer $size={40} />
      <OptionsList
        key={invitees?.length}
        $variant="raised"
        type="dropdown"
        label="Send invites"
        name="invitations"
        options={[
          { title: 'Admin', value: UserRole.ADMIN },
          { title: 'Member', value: UserRole.MEMBER },
          { title: 'Viewer', value: UserRole.VIEWER }
        ]}
        items={
          invitees?.map((invitee) => ({
            title: invitee.email,
            description: invitee.name,
            value: invitee.role,
            photoUrl: invitee.photoUrl
          })) ?? []
        }
        onItemsChanged={(items) => {
          const updatedInvitees = items.map((item) => ({
            name: item.description,
            email: item.title,
            role: item.value as UserRole
          }))
          setInvitees(updatedInvitees)
        }}
        addItemTitle="Invite teammate by email address"
        addItemFieldType="email"
        addItemDisabledWhenEmpty={true}
        removeItemIcon="trash"
      />
      <Spacer $size={40} />
      <Flex $align="center" $justify="space-between" $gap={16}>
        <Button $variant="raised" $colorTheme="tint" $height={40} href={nextStep?.href}>
          Continue without inviting
        </Button>
        {invitees?.length
          ? (
            <Button $variant="fill" $colorTheme="tint" $height={40} onClick={sendInvitesAndContinue}>
              Invite {invitees?.length} {invitees?.length === 1 ? 'person' : 'people'}
            </Button>
            )
          : null}
      </Flex>
    </>
  )
}

export default OnboardingInviteTeam
