import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import type { Job } from 'src/libs/api/backend/jobs'
import type { ManualQueueQueryResult } from 'src/libs/api/backend/manual_queue'
import { markManualTaskComplete } from 'src/libs/api/backend/manual_task'
import type { ManualTask } from 'src/libs/api/backend/manual_task'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  jobId: Job['id']
  manualTaskId: ManualTask['id']
  noteBody?: string | undefined
  responded?: boolean | undefined
  onSuccess?: (manualTask: ManualTask) => void
  onError?: () => void
}

interface Res {
  markManualTaskCompleted: ({ jobId, manualTaskId, noteBody, responded, onSuccess, onError }: Args) => void,
  isPending: boolean
}

export const useMarkManualTaskCompleted = (): Res => {
  const queryClient = useQueryClient()
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, manualTaskId, noteBody, responded }: Args) => {
      return await markManualTaskComplete(jobId, manualTaskId, noteBody, responded)
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred while completing manual task: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (manualTask: ManualTask, args: Args) => {
      // update cache directly to remove ManualQueueItem from ReadyToSendPage queue
      queryClient.setQueriesData<ManualQueueQueryResult>({ queryKey: [queryKeys.manualQueue, args.jobId] }, (oldManualQueueItems) => {
        if (isNil(oldManualQueueItems)) {
          return oldManualQueueItems
        }

        const { [manualTask.id]: _, ...newData } = oldManualQueueItems
        return newData
      })

      if (args.onSuccess) {
        args.onSuccess(manualTask)
      }
    }
  })

  const markManualTaskCompleted = ({ jobId, manualTaskId, noteBody, responded, onSuccess, onError }: Args): void => {
    mutation.mutate({ jobId, manualTaskId, noteBody, responded, onSuccess, onError })
  }

  return {
    markManualTaskCompleted,
    isPending: mutation.isPending
  }
}
