import { forwardRef, useEffect, useRef } from 'react'
import { FormElement } from '../form-element'
import { FieldLabel } from '../field-label'
import type { FieldCommonProps } from '../common'
import { FieldError } from '../field-error'
import * as S from './textarea.styled'
import { mergeRefs } from 'src/utils/merge-refs'

type Variant = 'normal' | 'ghost'

export interface StyleProps {
  $variant?: Variant
  $width?: string
}

interface Props extends StyleProps, FieldCommonProps {
  rows?: number
  autoGrow?: boolean
  autoGrowMaxRows?: number
  predefinedText?: string
  autoFocus?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      name,
      label,
      description,
      ariaLabel,
      placeholder,
      // predefinedText,
      register,
      rows = 12,
      autoGrow = false,
      autoGrowMaxRows,
      hiddenLabel = false,
      $variant = 'normal',
      $marginBottom = 16,
      autoFocus = false,
      isDisabled = false,
      onBlur,
      onFocus,
      onChange: onFieldChange,
      onPaste,
      $width
    },
    forwardedRef
  ) => {
    const { value, onChange, onBlur: onBlurField, error } = register(name)
    const textareaInternalRef = useRef<HTMLTextAreaElement | null>(null)
    // const [inputValue, setInputValue] = useState(predefinedText)

    const mergedRef = mergeRefs(textareaInternalRef, forwardedRef)

    // const handleInputChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    //   const { value } = event.target
    //   if (predefinedText && !value.startsWith(predefinedText)) {
    //     setInputValue(predefinedText + value.slice(predefinedText.length))
    //   } else {
    //     setInputValue(value)
    //   }
    //   onChange(event)
    // }, [value, predefinedText])

    useEffect(() => {
      if (autoGrow) {
        if (textareaInternalRef?.current) {
          // font size * line height
          const baseRowHeight = 14 * 1.43
          // top and bottom padding
          const textAreaPadding = 10 + 10
          const heightBasedOnRows = rows * baseRowHeight + textAreaPadding
          // set initial height of textarea otherwise it would not be recognized
          textareaInternalRef.current.style.height = `${heightBasedOnRows}px`

          const scrollHeight = textareaInternalRef.current.scrollHeight
          const maxHeight = autoGrowMaxRows ? autoGrowMaxRows * baseRowHeight : scrollHeight

          // adjust height until we reach the defined max height
          textareaInternalRef.current.style.height = `${scrollHeight}px`

          if (scrollHeight < maxHeight) {
            textareaInternalRef.current.style.height = `${scrollHeight}px`
          } else {
            textareaInternalRef.current.style.height = `${maxHeight}px`
          }
        }
      }
    }, [autoGrow, autoGrowMaxRows, rows, value])

    return (
      <FormElement $marginBottom={$marginBottom} $width={$width}>
        <FieldLabel
          label={label}
          htmlFor={name}
          hiddenLabel={hiddenLabel}
          description={description}
        />
        <S.Textarea
          ref={mergedRef}
          as="textarea"
          rows={rows}
          id={name}
          aria-label={ariaLabel ?? label}
          value={value as string}
          placeholder={placeholder}
          onChange={(event) => {
            onChange(event)
            if (onFieldChange) {
              onFieldChange(event)
            }
          }}
          onPaste={(event) => { onPaste?.(event) }}
          onBlur={() => {
            onBlurField()
            if (onBlur) {
              onBlur()
            }
          }}
          aria-describedby={`${name}-error`}
          aria-invalid={error ? 'true' : 'false'}
          disabled={isDisabled}
          $variant={$variant}
          $autoGrow={autoGrow}
          autoFocus={autoFocus}
          onFocus={onFocus}
        />
        {error ? <FieldError id={`${name}-error`}>{error}</FieldError> : null}
      </FormElement>
    )
  }
)

Textarea.displayName = 'Textarea'
