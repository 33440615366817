import * as RadixTooltip from '@radix-ui/react-tooltip'
import * as S from './tooltip.styled'

export interface TooltipStyleProps {
  $variant?: 'light' | 'dark'
  position?: 'top' | 'right' | 'bottom' | 'left'
  align?: 'start' | 'center' | 'end'
  $maxWidth?: number
  $textAlign?: 'left' | 'center' | 'right'
}

export interface TooltipProps extends TooltipStyleProps {
  delay?: number
  trigger: React.ReactNode
  renderTriggerAsButton?: boolean
  children: React.ReactNode | string
  keys?: string[]
  disabled?: boolean
  triggerDisabled?: boolean
  $triggerType?: 'button' | 'span'
}

export const Tooltip = ({
  trigger,
  renderTriggerAsButton = true,
  $variant = 'dark',
  position = 'top',
  children,
  keys,
  delay = 200,
  align = 'center',
  disabled = false,
  triggerDisabled = false,
  $maxWidth,
  $textAlign = 'left',
  $triggerType = 'button'
}: TooltipProps): JSX.Element => {
  if (disabled) {
    return <>{trigger}</>
  }

  const isMac = (): boolean => {
    return navigator.userAgent.toLowerCase().includes('mac')
  }

  const macCmdKey = '⌘'
  const windowsCmdKey = '⊞'

  const renderKeys: string[] = keys
    ? keys.map((k) => (k.toLowerCase() === 'cmd' ? (isMac() ? macCmdKey : windowsCmdKey) : k))
    : []

  const sideOffset = position === 'right' || position === 'left' ? 10 : 4
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delay}>
        <S.Trigger
          $triggerDisabled={triggerDisabled}
          type="button"
          asChild={renderTriggerAsButton}
          $triggerType={$triggerType}
        >
          {trigger}
        </S.Trigger>
        <RadixTooltip.Portal>
          <S.Content $variant={$variant} sideOffset={sideOffset} align={align} side={position} $maxWidth={$maxWidth} $textAlign={$textAlign}>
            {children}
            {keys && (
              <S.Keys>
                {renderKeys.map((k) => (
                  <span key={k}>{k}</span>
                ))}
              </S.Keys>
            )}
          </S.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
