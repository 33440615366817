import { useEffect } from 'react'
import { EmptyState } from 'src/components/blocks/empty-state'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from '../candidate-table-actions'
import { useQueryParams } from 'src/hooks/use-query-params'
import { isNil } from 'lodash'
import { COLUMN } from '../candidate-table-cells/table-builder'
import type { Job } from 'src/libs/api/backend/jobs'
import { useSetAtom } from 'jotai'
import { DialogId, openDialogAtom } from 'src/stores/dialogs'
import type { WriteMessageDialogProps } from 'src/components/dialogs/write-message-dialog'
import type { ExportToAtsDialogProps } from 'src/components/dialogs/export-to-ats-dialog/export-to-ats-dialog'
import { useParams } from 'react-router-dom'

interface CandidateAllTableProps {
  job?: Job
}

export const CandidatesAllTable = ({
  job = undefined
}: CandidateAllTableProps): JSX.Element => {
  const { getParam } = useQueryParams()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    hideDataWarehouseSourcedCandidates: true
  })
  const { jobId } = useParams()

  // For extension to open dialog with write email
  const writeManualEmailParams = getParam('writeManualEmail')
  const exportCandidatesParams = getParam('exportCandidate')

  const openDialog = useSetAtom(openDialogAtom)

  useEffect(() => {
    if (writeManualEmailParams && !isNil(candidateJobs)) {
      const payload: WriteMessageDialogProps = {
        candidateJobs: candidateJobs.filter((candidateJob) => candidateJob.candidateId === writeManualEmailParams)
      }
      openDialog({
        id: DialogId.WRITE_MESSAGE,
        payload
      })
    } else if (exportCandidatesParams && !isNil(candidateJobs)) {
      const payload: ExportToAtsDialogProps = {
        candidateJobs: candidateJobs.filter((candidateJob) => candidateJob.candidateId === exportCandidatesParams)
      }
      openDialog({
        id: DialogId.EXPORT_CANDIDATES,
        payload
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeManualEmailParams, candidateJobs])

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.SOURCE, COLUMN.CREATED_AT]}
      isLoading={isPending}
      candidateJobs={candidateJobs}
      pageHeaderHeight={candidateJobs && candidateJobs.length > 0 ? 64 : 32}
      emptyState={
        <EmptyState
          heading="No Candidates"
          description={candidateJobs && candidateJobs.length > 0
            ? 'None of your candidates match the selected filter.'
            : candidateJobs?.length === 0
              ? "Looks like you don't have any prospects yet, start sourcing candidates and all of them will show up right here."
              : ''}
          svg="userCards"
          actions={[
            {
              href: `/jobs/${jobId}/candidates/sourcing`,
              leadingIcon: 'binoculars',
              children: 'Go to Sourcing'
            }
          ]}
          $padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
        />
      }
      selectedRowsActions={
        <>
          <RejectButton />
          <WriteEmailButton candidateJobs={candidateJobs} />
          <ExportToAtsButton candidateJobs={candidateJobs} job={job} />
        </>
      }
    />
  )
}
