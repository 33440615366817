import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { GroupedBarChart } from 'src/components/charts/primitives/grouped-bar-chart'
import type { TeamActivitiesOvertime, TeamActivitiesOverviewPeriod } from 'src/libs/api/backend/reports_team_activities'
import { useMemo } from 'react'

interface TeamActivitiesOverTimeChartProps {
  overviewData?: TeamActivitiesOverviewPeriod
  data?: TeamActivitiesOvertime[]
}

const getValueChangeDirection = (currentValue: number, pastValue: number): 'up' | 'down' | 'neutral' => {
  if (currentValue > pastValue) return 'up'
  if (currentValue < pastValue) return 'down'
  return 'neutral'
}

export const TeamActivitiesOverTimeChart = ({ overviewData, data = [] }: TeamActivitiesOverTimeChartProps): JSX.Element => {
  const groups = useMemo(() => {
    const totals = data.reduce<Record<string, number>>((acc, curr) => ({
      total: (acc.total || 0) + (curr.total ?? 0),
      pastTotal: overviewData?.pastTotal ?? 0,
      searches: (acc.searches || 0) + (curr.searches ?? 0),
      pastSearches: overviewData?.pastSearches ?? 0,
      approved: (acc.approved || 0) + (curr.approved ?? 0),
      pastApproved: overviewData?.pastApproved ?? 0,
      rejected: (acc.rejected || 0) + (curr.rejected ?? 0),
      pastRejected: overviewData?.pastRejected ?? 0,
      manualTasks: (acc.manualTasks || 0) + (curr.manualTasks ?? 0),
      pastManualTasks: overviewData?.pastManualTasks ?? 0,
      manualEmails: (acc.manualEmails || 0) + (curr.manualEmails ?? 0),
      pastManualEmails: overviewData?.pastManualEmails ?? 0,
      scheduled: (acc.scheduled || 0) + (curr.scheduled ?? 0),
      pastScheduled: overviewData?.pastScheduled ?? 0
    }), {
      total: 0,
      pastTotal: 0,
      searches: 0,
      pastSearches: 0,
      approved: 0,
      pastApproved: 0,
      rejected: 0,
      pastRejected: 0,
      manualTasks: 0,
      pastManualTasks: 0,
      manualEmails: 0,
      pastManualEmails: 0,
      scheduled: 0,
      pastScheduled: 0
    })

    return [
      {
        label: 'All Activities',
        value: totals.total || 0,
        pastValue: overviewData?.pastTotal ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.total || 0, overviewData?.pastTotal ?? 0),
        chartTitle: 'All Activities Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          Total: point.total ?? 0
        }))
      },
      {
        label: 'Searches',
        value: totals.searches || 0,
        pastValue: overviewData?.pastSearches ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.searches || 0, overviewData?.pastSearches ?? 0),
        chartTitle: 'Searches Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          Searches: point.searches ?? 0
        }))
      },
      {
        label: 'Approved',
        value: totals.approved || 0,
        pastValue: overviewData?.pastApproved ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.approved || 0, overviewData?.pastApproved ?? 0),
        chartTitle: 'Approved Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          Approved: point.approved ?? 0
        }))
      },
      {
        label: 'Rejected',
        value: totals.rejected || 0,
        pastValue: overviewData?.pastRejected ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.rejected || 0, overviewData?.pastRejected ?? 0),
        chartTitle: 'Rejected Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          Rejected: point.rejected ?? 0
        }))
      },
      {
        label: 'Manual Emails',
        value: totals.manualEmails || 0,
        pastValue: overviewData?.pastManualEmails ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.manualEmails || 0, overviewData?.pastManualEmails ?? 0),
        chartTitle: 'Manual Emails Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          'Manual Emails': point.manualEmails ?? 0
        }))
      },
      {
        label: 'Manual Tasks',
        value: totals.manualTasks || 0,
        pastValue: overviewData?.pastManualTasks ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.manualTasks || 0, overviewData?.pastManualTasks ?? 0),
        chartTitle: 'Manual Tasks Over Time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          'Manual Tasks': point.manualTasks ?? 0
        }))
      },
      {
        label: 'Scheduled',
        value: totals.scheduled || 0,
        pastValue: overviewData?.pastScheduled ?? 0,
        valueChangeDirection: getValueChangeDirection(totals.scheduled || 0, overviewData?.pastScheduled ?? 0),
        chartTitle: 'Scheduled over time',
        chartData: data.map(point => ({
          label: point.friendlyDate,
          Scheduled: point.scheduled ?? 0
        }))
      }
    ]
  }, [data, overviewData])

  return (
    <ChartWrapper $padding={{ top: 0, right: 0, bottom: 0, left: 0 }}>
      <GroupedBarChart groups={groups} />
    </ChartWrapper>
  )
}
