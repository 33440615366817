import { NavLink } from 'react-router-dom'
import { HEADER_HEIGHT, HEADER_PADDING } from 'src/styles/constants'
import styled, { css } from 'styled-components'

interface InboxSidebarProps {
  $isHovered: boolean
  $isPinned: boolean
}

export const InboxSidebar = styled.nav<InboxSidebarProps>`
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme, $isHovered, $isPinned }) =>
    $isHovered && !$isPinned ? theme.boxShadows.lg : theme.boxShadows.sm};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 6px;

  h1 {
    line-height: ${HEADER_HEIGHT};
    margin-bottom: ${HEADER_PADDING};
  }
`

export const InboxSidebarHeader = styled.header<{ $justify: 'space-between' | 'center' }>`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0.25rem 0 1rem 0;
  justify-content: ${({ $justify }) => $justify};
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -0.75rem;
    left: 0;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
  }
`

export const InboxSidebarActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding: 0 0.5rem;
`

export const InboxSidebarActionsInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  border-top: solid 1px ${({ theme }) => theme.colors.borderTranslucent};
`

export const Items = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  contain: strict;
  padding: 0.5rem 0.75rem;
  flex: 1;
`

export const ItemsInner = styled.div`
  position: relative;
  height: auto;
  /* We need to account for padding and height of the header */
  min-height: calc(100% - (0.5rem + 0.5rem + 0.625rem));
  overflow: auto;
`

export const ItemCheckbox = styled.div<{ $isSelected: boolean, $hasAtLeastOneSelected: boolean }>`
  position: absolute;
  top: 0.35rem;
  left: 0.35rem; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  z-index: 3;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  opacity: ${({ $isSelected, $hasAtLeastOneSelected }) => $isSelected || $hasAtLeastOneSelected ? 1 : 0};
`

export const ItemInner = styled(NavLink)<{ $isSelected: boolean }>`
  position: relative;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: transparent;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  // @media (hover: hover) {
  //   &:hover {
  //     border-color: ${({ theme }) => theme.colors.tintTranslucent25};
  //     background-color: ${({ theme }) => theme.colors.bgSecondary};
  //   }
  // }
  &.active {
    border-color: ${({ theme }) => theme.colors.tintTranslucent25};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
  }
  ${({ $isSelected }) => $isSelected && css`
    background-color: ${({ theme }) => theme.colors.tintTranslucent10};
    &.active {
      background-color: ${({ theme }) => theme.colors.tintTranslucent10};
    }
  `}
`

export const Item = styled.div`
  position: relative;
  flex: 0 0 100%;
  @media (hover: hover) {
    &:hover {
      ${ItemCheckbox} {
        opacity: 1;
      }
      ${ItemInner} {
        // border-color: ${({ theme }) => theme.colors.borderTranslucent};
        background-color: ${({ theme }) => theme.colors.borderTranslucent};
      }
    }
  }
`

export const Empty = styled.div`
  height: 100%;
  border: dashed 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
`

export const ItemTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
  overflow: hidden;
`

export const TileTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`

export const Favorite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`
