import { Flex } from 'src/components/primitives/flex'
import { SEO } from 'src/components/primitives/seo'
import { useReports } from 'src/hooks/queries/use-reports'
import { useQueryParams } from 'src/hooks/use-query-params'
import { TimeFrame, ReportType } from 'src/libs/api/backend/reports_types'
import { TeamActivityChart } from 'src/components/charts/built/team-activity/team-activity-chart'
import { TeamActivityCountByHourChart } from 'src/components/charts/built/team-activity/team-activity-count-by-hour-chart'
import { TeamActivitiesOverTimeChart } from 'src/components/charts/built/team-activity/team-activities-over-time-chart'
import { When } from 'src/components/blocks/when'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { isNil } from 'lodash'
import { ReportsPageHeader } from 'src/components/blocks/reports-page-header/reports-page-header'

const ReportsTeamActivityPage = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const currentTimeFrame = getParam('timeFrame')

  const { isLoading, data } = useReports({
    type: ReportType.TEAM_ACTIVITIES,
    timeFrame: currentTimeFrame as TimeFrame ?? TimeFrame.LAST_30_DAYS
  })

  return (
    <>
      <SEO title="Team Activity Reports" />
      <ReportsPageHeader reportType={ReportType.TEAM_ACTIVITIES} />
      <When condition={isLoading}>
        <LoadingSkeleton $variant="Reports" />
      </When>
      <When condition={!isLoading && !isNil(data)}>
        <Flex $direction="column" $gap={20}>
          <TeamActivitiesOverTimeChart overviewData={data?.overview ?? undefined} data={data?.teamActivitiesOvertime ?? []} />
          <TeamActivityChart data={data?.teamMemberActivities ?? []} />
          <TeamActivityCountByHourChart data={data?.teamActivityCountByHour ?? []} />
          <div />
        </Flex>
      </When>
    </>
  )
}

export default ReportsTeamActivityPage
