import { useAtomValue } from 'jotai'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { COLUMN } from 'src/components/tables/candidate-table-cells'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import queryClient from 'src/hooks/query-client'
import { usePrintView } from 'src/hooks/use-print-view'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { isSourcing, JobSourcingState } from 'src/libs/api/backend/jobs'
import { queryKeys } from 'src/libs/query-keys'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { CONTENT_PADDING, CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { useLocalStorage } from 'usehooks-ts'
import { SourcingPageHeader, SourcingStatus } from 'src/components/blocks/sourcing'
import { Logo } from 'src/components/primitives/logo'
import RouteBuilder from 'src/libs/route-builder'
import { isNil } from 'lodash'
import { RefinementActionsBar } from '../refinement-actions-bar'
import { IfElse } from '../if-else'
import { CandidateDetailsCard } from '../candidate-details-card'
import { CandidatesTablePagesContentInner } from 'src/pages/job/candidates/candidates.styled'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { SEO } from 'src/components/primitives/seo'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { ToggleCandidateView } from '../toggle-candidate-view'
import { candidateDetailsChannelAtom, candidateSearchChannelAtom } from 'src/stores/websocket-channels'
import { useChannel } from 'ably/react'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { LoadingSkeleton } from '../loading-skeleton'
import { updateListJobSearchRefinements, useJobSearchRefinementQuery } from 'src/hooks/queries/use-job-search-refinement'
import { UpgradeCtaBanner } from '../subscribe-cta-card'
import { Spacer } from 'src/components/primitives/spacer'
import { useTrialLimits } from 'src/hooks/use-trial-limits'
import { ProductTourStep } from 'src/libs/product-tour'

interface SourcingProps {
  isManualSourceView: boolean
  jobSearchRefinementId?: string
  jobId: string
}

export const Sourcing = ({
  isManualSourceView,
  jobId,
  jobSearchRefinementId
}: SourcingProps): JSX.Element => {
  const { limitSourcing } = useTrialLimits()

  const { data: job, isPending: isJobPending } = useJobQuery()
  const { data: jobSearchRefinement, isPending: isJobSearchRefinementPending, refetch: refetchJobSearchRefinement } = useJobSearchRefinementQuery(jobId, jobSearchRefinementId)
  const { data: candidateJobs, isPending: isCandidateJobsPending } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    jobSearchRefinementId,
    source: isManualSourceView ? null : undefined,
    favorite: jobSearchRefinementId ? false : undefined
  })
  const { data: sequence, isPending: isSequencePending } = useJobSequenceQuery(jobId)
  const [_, setAppIsReadyForProductTour] = useLocalStorage(LocalStorageKey.APP_IS_READY_FOR_PRODUCT_TOUR, false)

  useEffect(() => {
    if (!isCandidateJobsPending && Array.isArray(candidateJobs) && candidateJobs.length > 0) {
      setAppIsReadyForProductTour(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateJobsPending, candidateJobs])

  const isLoading = useMemo(() => {
    if (jobSearchRefinementId && isJobSearchRefinementPending) {
      return true
    }

    return isJobPending || isCandidateJobsPending || isSequencePending
  }, [
    isJobPending,
    isJobSearchRefinementPending,
    isCandidateJobsPending,
    isSequencePending,
    jobSearchRefinementId
  ])

  const isPrintView = usePrintView()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const [currViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])
  const parentRef = useRef<HTMLDivElement>(null)

  const [lastRequestedAt, setLastRequestedAt] = useState<Date | null>(null)
  const [isRecommending, setIsRecommending] = useState(false)

  const virtualizer = useVirtualizer({
    count: renderedCandidates.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => currViewMode === ViewMode.COMPACT ? 300 : 700,
    overscan: 5,
    getItemKey: (index) => renderedCandidates[index].id
  })

  useEffect(() => {
    // Reset measurement of the virtualizer when the view mode changes
    virtualizer.measure()
  }, [currViewMode, virtualizer])

  useEffect(() => {
    setRenderedCandidates([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearchRefinementId])

  useEffect(() => {
    setIsRecommending(isSourcing(jobSearchRefinement?.sourcingState))
    setLastRequestedAt(jobSearchRefinement?.lastSourcingRequestedAt ?? null)
  }, [jobSearchRefinement])

  const autoApproveIsEnabled = useMemo(() => {
    return jobSearchRefinement?.automateAutoApproveCandidates ?? false
  }, [jobSearchRefinement?.automateAutoApproveCandidates])

  const invalidateCandidateJobs = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId],
      exact: false
    })
  }, [jobId])

  // This channel is used to let the FE know if the JobSearchRefinement has a visual update like
  // the title, subtitle, criteria, etc.
  const candidateSearchChannel = useAtomValue(candidateSearchChannelAtom)
  useChannel({ channelName: candidateSearchChannel, skip: !candidateSearchChannel }, (message) => {
    const { name: eventName } = message
    if (jobSearchRefinementId && eventName === jobSearchRefinementId) {
      const {
        sourcingState,
        lastSourcingRequestedAt
      } = message.data ?? {}

      if (sourcingState) {
        updateListJobSearchRefinements({
          jobId,
          jobSearchRefinementId,
          jobSearchRefinement: {
            sourcingState,
            lastSourcingRequestedAt
          }
        })

        const isSourcingState = isSourcing(sourcingState as JobSourcingState)
        if (isSourcingState) {
          if (renderedCandidates.length > 0) {
            setRenderedCandidates([])
          }
        }
      }

      void refetchJobSearchRefinement()
    }
  })

  // This channel will receive notifications whenever a candidate is added or updated to the list
  const candidateDetailsChannel = useAtomValue(candidateDetailsChannelAtom)
  useChannel({ channelName: candidateDetailsChannel, skip: !candidateDetailsChannel }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    renderedCandidates.forEach((c) => {
      if (idsSet.has(c.candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, c.candidateId]
        })
      }
    })
    invalidateCandidateJobs()
  })

  useEffect(() => {
    if (candidateJobs && !isRecommending) {
      if (limitSourcing) {
        setRenderedCandidates(candidateJobs.slice(0, 20))
      } else {
        setRenderedCandidates(candidateJobs)
      }
    }
  }, [candidateJobs, isRecommending, limitSourcing])

  // TODO: This is a temporary fix to prevent jitter when opening up an
  // experience item. Ideally, this should be implemented when the item is
  // opened then reset, rather than just displaying it entirely here.
  virtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false

  const pageTitle = useMemo(() => {
    if (isManualSourceView) {
      return 'Manually Added'
    }

    if (jobSearchRefinement?.title) {
      return `Sourcing: "${jobSearchRefinement.title}"`
    }

    return 'Sourcing'
  }, [isManualSourceView, jobSearchRefinement?.title])

  const isEmpty = useMemo((): boolean => renderedCandidates.length === 0, [renderedCandidates])

  const isSourcingView = useMemo(
    () => !!jobSearchRefinement,
    [jobSearchRefinement]
  )

  const handleUpdateSearchRefinementTitle = useCallback((updatedTitle: string): void => {
    if (jobSearchRefinementId) {
      updateJobSearchRefinement({
        jobId,
        jobSearchRefinementId,
        title: updatedTitle,
        automateAutoApproveCandidates: autoApproveIsEnabled
      })
    }
  }, [
    jobId,
    jobSearchRefinementId,
    autoApproveIsEnabled,
    updateJobSearchRefinement
  ])

  const isArchived = useMemo((): boolean => Boolean(job?.deleted), [job?.deleted])

  const title = useMemo(() => {
    if (isManualSourceView) {
      return 'Sourcing · Manually Added'
    }
    if (jobSearchRefinement) {
      return jobSearchRefinement.title ?? 'Sourcing · New Search'
    }
    return 'Sourcing · All sourced'
  }, [isManualSourceView, jobSearchRefinement])

  // const searchHasError = useMemo(() =>
  //   jobSearchRefinement?.sourcingState === JobSourcingState.NO_CANDIDATES_ERROR ||
  //   jobSearchRefinement?.sourcingState === JobSourcingState.INTERNAL_ERROR ||
  //   jobSearchRefinement?.sourcingState === JobSourcingState.SEARCH_EMPTY_ERROR ||
  //   jobSearchRefinement?.sourcingState === JobSourcingState.SEARCH_NOT_SUPPORTED_ERROR
  // , [jobSearchRefinement?.sourcingState])

  const updateJobRefinementOnClick = useCallback((searchCriteria: Criteria) => {
    if (jobSearchRefinementId) {
      updateListJobSearchRefinements({
        jobId,
        jobSearchRefinementId,
        jobSearchRefinement: {
          sourcingState: JobSourcingState.REQUESTED,
          lastSourcingRequestedAt: new Date()
        }
      })
      updateJobSearchRefinement({
        jobId,
        jobSearchRefinementId,
        automateAutoApproveCandidates: autoApproveIsEnabled,
        searchCriteria,
        onSuccess: () => {
          setRenderedCandidates([])
          // invalidateCandidateJobs()
        }
      })
    }
  }, [
    jobSearchRefinementId,
    jobId,
    updateJobSearchRefinement,
    autoApproveIsEnabled
  ])

  const visibleColumns: COLUMN[] = useMemo(() => {
    const columns: COLUMN[] = [COLUMN.FAVORITE, COLUMN.NAME, COLUMN.JOB_TITLE]
    if (!isManualSourceView) {
      columns.push(COLUMN.CRITERIA, COLUMN.CRITERIA_EXPANDED)
    }
    columns.push(COLUMN.CANDIDATE_STAGE_ACTIONS)
    return columns
  }, [isManualSourceView])

  const sourcingHeader = useMemo(() => {
    return (
      <SourcingPageHeader
        headingLoading={jobSearchRefinement && !jobSearchRefinement.title}
        showActions={!isArchived && !jobSearchRefinement}
        title={title}
        isEditable={(jobSearchRefinement?.title?.length ?? 0) >= 1}
        onEdit={(updatedTitle) => {
          handleUpdateSearchRefinementTitle(updatedTitle)
        }}
        jobId={jobId}
        jobSearchRefinement={jobSearchRefinement}
        isSequenceEmpty={isSequenceStepsEmpty(sequence)}
        customActions={isManualSourceView && !job?.deleted ? [<ToggleCandidateView />] : []}
        hasCandidateJobs={renderedCandidates.length > 0}
        isRecommending={isRecommending}
        maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
      />
    )
  }, [handleUpdateSearchRefinementTitle, isArchived, isManualSourceView, isRecommending, job?.deleted, jobId, jobSearchRefinement, renderedCandidates.length, sequence, title])

  const exportToPdfPrintUrl = useMemo(() => {
    if (isManualSourceView) {
      return RouteBuilder.build('JOBS_CANDIDATES_SOURCING_MANUAL', { jobId }, { print: true })
    }
    if (jobSearchRefinement) {
      return RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId, jobSearchRefinementId: jobSearchRefinement.id }, { print: true })
    }
    return undefined
  }, [isManualSourceView, jobId, jobSearchRefinement])

  const refinementActionBar = useMemo(() => {
    if (isNil(job) || isNil(jobSearchRefinement) || job.deleted) {
      return null
    }
    return (
      <RefinementActionsBar
        job={job}
        jobSearchRefinement={jobSearchRefinement}
        autoApproveIsEnabled={jobSearchRefinement.automateAutoApproveCandidates ?? false}
        isRecommending={isRecommending}
        setIsRecommending={setIsRecommending}
        maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
        exportToPdfPrintUrl={exportToPdfPrintUrl}
      />
    )
  }, [exportToPdfPrintUrl, isRecommending, job, jobSearchRefinement])

  if (isPrintView) {
    return (
      <>
        <SEO title={pageTitle} />
        <div
          ref={parentRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            overflow: 'auto'
          }}
        >
          <Logo variant="dark" size={64} />
          {sourcingHeader}
          {refinementActionBar}
          {renderedCandidates.map((candidateJob, index) => (
            <div
              key={candidateJob.id}
              style={{
                width: '100%',
                maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                breakBefore: index === 0 ? 'avoid-page' : 'page',
                breakInside: 'avoid',
                breakAfter: 'avoid-page'
              }}
            >
              <CandidateDetailsCard
                candidateJob={candidateJob}
              />
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <>
      <SEO title={pageTitle} />
      <CompaniesPreferencesProvider key={`${jobId}-${jobSearchRefinementId}`}>
        <div
          ref={parentRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            contain: 'strict',
            scrollBehavior: 'smooth'
          }}
        >
          <div
            id={ProductTourStep.SOURCING_RESULTS}
            style={{
              position: 'absolute',
              top: '20px',
              left: 0,
              width: '100%',
              maxWidth: `${parseInt(CANDIDATES_PAGES_MAX_WIDTH) + 16}px`,
              height: 'calc(100% - 40px)',
              pointerEvents: 'none',
              zIndex: 1000
            }}
          />
          {sourcingHeader}
          {refinementActionBar}
          {isLoading
            ? <div
                style={{
                  flex: 1,
                  marginLeft: CONTENT_PADDING,
                  maxWidth: CANDIDATES_PAGES_MAX_WIDTH
                }}
              >
                <LoadingSkeleton $variant="CandidateDetailsCard" delay={200} />
              </div>
            : <>
                <IfElse
                  condition={currViewMode === ViewMode.TABLE}
                  ifNode={
                    renderedCandidates.length > 0
                      ? <div style={{ marginLeft: CONTENT_PADDING }}>
                          <CandidatesTablePagesContentInner
                            key={`${jobId}-${jobSearchRefinementId}`}
                            data-component="CandidatesTablePagesContentInner"
                            $padding={0}
                            $maxWidth='auto'
                          >
                            <CandidatesSourcedTable
                              candidateJobs={renderedCandidates}
                              visibleColumns={visibleColumns}
                              pageHeaderHeight={jobSearchRefinement?.subtitle ? 132 : (jobSearchRefinement ? 116 : 64)}
                            />
                          </CandidatesTablePagesContentInner>
                        </div>
                      : null
                  }
                  elseNode={
                    <div
                      style={{
                        minHeight: virtualizer.getTotalSize(),
                        position: 'relative',
                        marginLeft: CONTENT_PADDING
                      }}
                    >
                      {virtualizer.getVirtualItems().map((virtualRow, index) => {
                        const candidateJob = renderedCandidates[virtualRow.index]
                        if (isNil(candidateJob)) {
                          return <Fragment key={virtualRow.key} />
                        }
                        return (
                          <div
                            style={{
                              width: '100%',
                              maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              transform: `translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`
                            }}
                            key={virtualRow.key}
                            data-index={virtualRow.index}
                            ref={virtualizer.measureElement}
                          >
                            <CandidateDetailsCard
                              candidateJob={candidateJob}
                              viewMode={currViewMode}
                              actionType='sourcing'
                              idOfFirstTimelineEntry={index === 0 ? ProductTourStep.SOURCING_COMPANY_DETAILS : undefined}
                            />
                          </div>
                        )
                      })}
                    </div>
                  }
                />
                {limitSourcing && (
                  <div style={{ marginLeft: CONTENT_PADDING }}>
                    {currViewMode === ViewMode.TABLE ? <Spacer $size={16} /> : null}
                    <UpgradeCtaBanner
                      heading="Unlock endless candidates"
                      benefits={[
                        'Unlock this premium feature for unlimited results',
                        'Auto-outreach to new candidates daily',
                        'Get more meetings with high-quality candidates'
                      ]}
                    />
                    <Spacer $size={16} />
                  </div>
                )}
                {!isNil(job) && (!!jobSearchRefinement || isManualSourceView) && (
                  <div style={{ marginLeft: CONTENT_PADDING, display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <SourcingStatus
                      jobSearchRefinement={jobSearchRefinement}
                      isRecommending={isRecommending}
                      isEmpty={isEmpty}
                      lastRequestedAt={lastRequestedAt ?? new Date()}
                      isSourcingView={isSourcingView}
                      isManualSourceView={isManualSourceView}
                      sourcingState={jobSearchRefinement?.sourcingState}
                      isArchived={isArchived}
                      viewMode={currViewMode}
                      onUpdateSearchRefinementCriteria={updateJobRefinementOnClick}
                      job={job}
                    />
                  </div>
                )
              }
            </>
          }
        </div>
      </CompaniesPreferencesProvider>
    </>
  )
}
