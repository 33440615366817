import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ $hasPreviousPeriodData: boolean }>`
  width: 100%;
  height: 100%;
  ${({ $hasPreviousPeriodData }) => $hasPreviousPeriodData && css`
    padding-right: 1rem;
  `}
`

export const Chart = styled.div<{ $total: number }>`
  height: 200px;
  display: flex;
  gap: 1px;
`

export const StageHeaders = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr) 3.95em;
  gap: 1px;
  margin-bottom: 16px;
`

export const StageHeader = styled.div<{ $isFirst: boolean }>`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.25rem;
`

export const Stage = styled.div<{ $columnIndex: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ $columnIndex }) => {
    const colors: Record<number, string> = {
      1: '77, 129, 238',
      2: '82, 208, 155',
      3: '82, 179, 208',
      4: '160, 77, 238',
      5: '238, 77, 195'
    }
    const prevColors: Record<number, string> = {
      2: '77, 129, 238',
      3: '82, 208, 155',
      4: '82, 179, 208',
      5: '160, 77, 238'
    }

    return css`
      --color: ${colors[$columnIndex]};
      ${$columnIndex > 1 ? `--prev-color: ${prevColors[$columnIndex]};` : ''}
      ${$columnIndex === 1 && 'flex: 0;'}
    `
  }}
`

export const StageBlock = styled.div<{ $value: string, $total: number, $blockIndex: number, $isEmpty: boolean }>`
  height: ${({ $value, $total }) => `calc(${$value} / ${$total} * 100%)`};
  position: relative;
  text-align: center;
  display: flex;
  --bg-opacity: ${({ $blockIndex }) => $blockIndex === 2 ? '0.5' : $blockIndex === 3 ? '0.25' : '1'};
  ${({ $isEmpty }) => $isEmpty && css`
    background: ${({ theme }) => theme.colors.bgTertiary};
  `}
`

export const StageBlockTooltip = styled.div<{ $show: boolean }>`
  position: absolute;
  top: ${({ $show }) => $show ? '0' : '6px'};
  left: 0;
  width: auto;
  min-width: 108px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  padding: 0.5rem;
  z-index: 2;
  opacity: ${({ $show }) => $show ? '1' : '0'};
  transition: opacity 0.1s ease-in-out, top 0.2s ease-in-out;
  pointer-events: none;
`

export const StageConnection = styled.div<{ $isEmpty: boolean }>`
  position: relative;
  flex: 1;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgPrimary};
  background: linear-gradient(to right, rgba(var(--prev-color), 0.15), rgba(var(--color), 0.15));
  ${({ $isEmpty }) => $isEmpty && css`
    background: ${({ theme }) => theme.colors.bgPrimary};
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.bgTertiary};
      opacity: 0.2;
    }
  `}
`

export const StageMain = styled.div<{ $isEmpty: boolean }>`
  position: relative;
  width: 4em;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.bgPrimary};
  background: rgba(var(--color), var(--bg-opacity, 1));
  ${({ $isEmpty }) => $isEmpty && css`
    background: ${({ theme }) => theme.colors.bgTertiary};
  `}
`

export const Badge = styled.div<{ $isEmpty: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  height: 18px;
  font-size: ${({ theme }) => theme.fontSizes['10']};
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 99px;
  padding: 2px 8px;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  transform: translate(-50%, 5px);
  ${({ $isEmpty }) => $isEmpty && css`
    opacity: 0;
  `}
`

export const Small = styled.small`
  display: inline-block;
  padding: 0 0.5em;
  opacity: 0.5;
`
