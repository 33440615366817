import type { ChannelsReplies } from 'src/libs/api/backend/reports_communication_insights'
import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { PieChart } from 'src/components/charts/primitives/pie-chart'
import { isNil } from 'lodash'
import { useMemo } from 'react'

interface ChannelRepliesChartProps {
  data: ChannelsReplies
}

const getPercentage = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    return 0
  }
  return Math.round((numerator / denominator) * 100)
}

export const ChannelRepliesChart = ({ data }: ChannelRepliesChartProps): JSX.Element => {
  const { emailReplies, linkedinReplies, pastEmailReplies, pastLinkedinReplies } = data
  const chartData = useMemo(() => {
    if (emailReplies === 0 && linkedinReplies === 0) {
      return []
    }
    const total = emailReplies + linkedinReplies
    const pastTotal = (pastEmailReplies ?? 0) + (pastLinkedinReplies ?? 0)
    const emailValue = getPercentage(emailReplies, total)
    const linkedinValue = getPercentage(linkedinReplies, total)
    const pastEmailValue = !isNil(pastEmailReplies) ? getPercentage(pastEmailReplies, pastTotal) : undefined
    const pastLinkedinValue = !isNil(pastLinkedinReplies) ? getPercentage(pastLinkedinReplies, pastTotal) : undefined
    return [{
      label: 'Email',
      name: 'Email',
      value: emailValue,
      pastValue: pastEmailValue,
      valueChangeDirection: !isNil(pastEmailValue)
        ? emailValue - pastEmailValue > 0
          ? ('up' as const)
          : ('down' as const)
        : undefined
    },
    {
      label: 'LinkedIn',
      name: 'LinkedIn',
      value: linkedinValue,
      pastValue: pastLinkedinValue,
      valueChangeDirection: !isNil(pastLinkedinValue)
        ? linkedinValue - pastLinkedinValue > 0
          ? ('up' as const)
          : ('down' as const)
        : undefined
    }]
  }, [emailReplies, linkedinReplies, pastEmailReplies, pastLinkedinReplies])

  return (
    <ChartWrapper>
      <ChartHeader title="Channel Replies" />
      <PieChart
        chartTitle="Channel Replies"
        data={chartData}
      />
    </ChartWrapper>
  )
}
