import * as S from './progress-steps-list.styled'
import { Flex } from '../flex'
import { Caption } from '../typography'
import { Spinner } from '../spinner'

interface ProgressStep {
  text: string
  percentage: number
}

const STEPS: ProgressStep[] = [
  {
    text: 'Waiting for Pin to start',
    percentage: 10
  },
  {
    text: 'Filtering through 100M+ profiles',
    percentage: 40
  },
  {
    text: 'Ranking expertise and fit',
    percentage: 80
  },
  {
    text: 'Preparing results',
    percentage: 101
  }
]

const getCurrentStep = (progress: number): ProgressStep => {
  return STEPS.find((step, index) => {
    const nextStep = STEPS[index + 1]
    return progress <= step.percentage || !nextStep
  }) ?? STEPS[0]
}

export interface ProgressStepsListProps {
  progress: number
}

export const ProgressStepsList = ({ progress }: ProgressStepsListProps): JSX.Element => {
  const currentStep = getCurrentStep(progress)

  return (
    <S.Wrapper>
      <Flex $align="center" $gap={8}>
        <Spinner color="fgSecondary" />
        <Caption size="MD" $color="fgPrimary">
          {currentStep.text}
        </Caption>
      </Flex>
    </S.Wrapper>
  )
}
