import { Paragraph } from 'src/components/primitives/typography'
import { SubscriptionSettings } from 'src/components/blocks/subscription-settings'
import { useSession } from 'src/hooks/use-session'
import { differenceInDays, format } from 'date-fns'
import { Flex } from 'src/components/primitives'
import { useMemo, useState } from 'react'
import { ClosePinAccountFeedback } from 'src/components/blocks/close-pin-account-feedback'

export const SubscriptionDialog = (): JSX.Element => {
  const { org, subscribed, trialActive, extendedTrialActive } = useSession()
  const [closeAccount, setCloseAccount] = useState(false)

  const banner = useMemo(() => {
    if (org?.stripeSubscriptionCancelled && org?.stripeSubscriptionActiveUntil) {
      return (
        <Flex $direction='column'>
          <Paragraph size='SM' $fontWeight={500}>Your subscription is cancelled.</Paragraph>
          <Paragraph size='SM'>You can continue using Pin until {format(org?.stripeSubscriptionActiveUntil, 'MMM d, yyyy')}.</Paragraph>
        </Flex>
      )
    }
    if (org?.stripeSubscriptionCancelled && org?.stripeSubscriptionTrialActiveUntil) {
      return (
        <Flex $direction='column'>
          <Paragraph size='SM' $fontWeight={500}>Your extended trial is cancelled.</Paragraph>
          <Paragraph size='SM'>You can continue using Pin until {format(org?.stripeSubscriptionTrialActiveUntil, 'MMM d, yyyy')}.</Paragraph>
        </Flex>
      )
    }
    if (org?.stripeSubscriptionPaused) {
      return (
        <Flex $direction='column'>
          <Paragraph size='SM' $fontWeight={500}>Your subscription is paused.</Paragraph>
          <Paragraph size='SM'>Unpause to start hiring again.</Paragraph>
        </Flex>
      )
    }
    if (!subscribed && !!trialActive && org?.trialActiveUntil) {
      return (
        <Flex $direction='column'>
          <Paragraph size='SM' $fontWeight={500}>You’re currently in the the free trial period with {differenceInDays(org.trialActiveUntil, new Date())} days left.</Paragraph>
          <Paragraph size='SM'>Subscribe today to avoid disruption.</Paragraph>
        </Flex>
      )
    }
    return null
  }, [org?.stripeSubscriptionCancelled, org?.stripeSubscriptionActiveUntil, org?.stripeSubscriptionTrialActiveUntil, org?.stripeSubscriptionPaused, org?.trialActiveUntil, subscribed, trialActive])

  if (closeAccount) {
    return (
      <ClosePinAccountFeedback
        subscribed={!!subscribed || !!extendedTrialActive}
        paused={!!org?.stripeSubscriptionPaused}
        expired={false}
        onClose={() => {
          setCloseAccount(false)
        }}
      />
    )
  }
  return (
    <SubscriptionSettings
      notificationBanner={banner}
      onCloseAccount={setCloseAccount}
    />
  )
}
