import { Button, Flex } from 'src/components/primitives'
import { DURATION_MONTHS_CRITERIA_KEYS } from 'src/libs/api/backend/candidate_search'
import type { CriteriaKey, MinMaxCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import { Caption, Paragraph } from 'src/components/primitives/typography'
import { isNil } from 'lodash'
import * as S from './index.styled'
import { useCallback, useMemo } from 'react'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { RemoveCriteria } from './remove-criteria'
import { CriteriaProperties } from '../constants'

interface MinMaxDropdownProps {
  value: number | undefined | null
  onUpdate: (value: number) => void
  minValue?: number | null
  optionsMap: Map<number, string>
  disabled: boolean
}

const MinMaxDropdown = ({ optionsMap, value, onUpdate, minValue, disabled }: MinMaxDropdownProps): JSX.Element => {
  const options = useMemo(() => {
    return Array.from(optionsMap.keys()).filter((option) => {
      return minValue ? Number(option) >= minValue : true
    }).map((option) => ({
      id: option.toString(),
      title: optionsMap.get(Number(option)),
      onSelect: () => {
        onUpdate(Number(option))
      }
    }))
  }, [minValue, onUpdate, optionsMap])
  return (
    <Dropdown
      disabled={disabled}
      size='small'
      fontWeight={400}
      $maxHeight='400px'
      $minWidth='100px'
      $menuWidth='auto'
      trigger={
        <Button
          nested
          $variant='outline'
          $height={32}
          $fontSize={12}
          $width='fit-content'
          $fontWeight={400}
          trailingIcon={Icons.chevronsUpDownSmall}
          $align='space-between'
          disabled={disabled}
        >
          {!isNil(value)
            ? optionsMap.get(value)
            : <Paragraph size='XS'>Any</Paragraph>
          }
        </Button>
      }
      items={options}
    />
  )
}

interface MinMaxRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: MinMaxCriteria
  onUpdate: (value: Partial<MinMaxCriteria>) => void
  onRemove: () => void
  disabled: boolean
}

export const MinMaxRow = ({ criteriaKey, criteriaValue, onUpdate, onRemove, disabled }: MinMaxRowProps): JSX.Element => {
  const isDurationMonthsCriteria = DURATION_MONTHS_CRITERIA_KEYS.includes(criteriaKey)
  const { min, max, optional = true } = criteriaValue
  const updateMin = useCallback((value: number | undefined) => {
    onUpdate({
      min: value,
      max: !isNil(value) && !isNil(max) && max < value ? undefined : max
    })
  }, [onUpdate, max])
  const updateMax = useCallback((value: number | undefined) => {
    onUpdate({ max: value })
  }, [onUpdate])

  const options = useMemo(() => {
    return CriteriaProperties.get(criteriaKey)?.options as Map<number, string> | undefined
  }, [criteriaKey])

  const minField = useMemo(() => {
    if (!isNil(options)) {
      return (
        <MinMaxDropdown
          value={min}
          onUpdate={updateMin}
          optionsMap={options}
          disabled={disabled}
        />
      )
    }
    return (
      <>
        <S.MinMaxNumberField $disabled={disabled}>
          <Button
            leadingIcon={Icons.minus}
            $variant='ghost'
            $height={30}
            $width={24}
            $fontSize={12}
            $fontWeight={400}
            disabled={isNil(min)}
            onClick={() => {
              if (isNil(min)) {
                return
              }
              updateMin(isDurationMonthsCriteria ? min - 12 : min - 1)
            }}
          />
          <S.MinMaxInputField
            type='number'
            placeholder='Any'
            value={isNil(min) ? '' : isDurationMonthsCriteria ? Math.round(min / 12) : min}
            onChange={(e) => {
              const value = Number(e.target.value)
              if (value <= 0) {
                updateMin(undefined)
              } else {
                updateMin(isDurationMonthsCriteria ? value * 12 : value)
              }
            }}
          />
          <Button
            leadingIcon={Icons.plus}
            $variant='ghost'
            $height={30}
            $width={24}
            $fontSize={12}
            $fontWeight={400}
            onClick={() => {
              if (isNil(min)) {
                updateMin(12) // Set to 1 year if no min value
              } else {
                updateMin(isDurationMonthsCriteria ? min + 12 : min + 1)
              }
            }}
          />
        </S.MinMaxNumberField>
      </>
    )
  }, [disabled, isDurationMonthsCriteria, min, options, updateMin])

  const maxField = useMemo(() => {
    if (!isNil(options)) {
      return (
        <MinMaxDropdown
          value={max}
          onUpdate={updateMax}
          optionsMap={options}
          disabled={disabled}
          minValue={min}
        />
      )
    }
    return (
      <>
        <S.MinMaxNumberField $disabled={disabled}>
          <Button
            leadingIcon={Icons.minus}
            $variant='ghost'
            $height={30}
            $width={24}
            $fontSize={12}
            $fontWeight={400}
            disabled={isNil(max)}
            onClick={() => {
              if (isNil(max)) {
                return
              }
              updateMax(isDurationMonthsCriteria ? max - 12 : max - 1)
            }}
          />
          <S.MinMaxInputField
            type='number'
            placeholder='Any'
            value={isNil(max) ? '' : isDurationMonthsCriteria ? Math.round(max / 12) : max}
            onChange={(e) => {
              const value = Number(e.target.value)
              if (value <= 0) {
                updateMax(undefined)
              } else {
                updateMax(isDurationMonthsCriteria ? value * 12 : value)
              }
            }}
          />
          <Button
            leadingIcon={Icons.plus}
            $variant='ghost'
            $height={30}
            $width={24}
            $fontSize={12}
            $fontWeight={400}
            disabled={isNil(max)}
            onClick={() => {
              if (isNil(max)) {
                return
              }
              updateMax(isDurationMonthsCriteria ? max + 12 : max + 1)
            }}
          />
        </S.MinMaxNumberField>
        {isDurationMonthsCriteria && <Caption size='XS' $fontWeight={400}>years</Caption>}
      </>
    )
  }, [disabled, isDurationMonthsCriteria, max, min, options, updateMax])

  return (
    <Flex $gap={8} $align='center' $justify='space-between'>
      <Flex $gap={8} $align='center' $flex='1 1 auto'>
        <CriteriaSelection
          disabled={disabled}
          value={optional ?? true}
          onUpdate={(key: string, value: boolean) => {
            onUpdate({ [key]: value })
          }}
        />
        {minField}
        <Caption size='XS' $fontWeight={400}>to</Caption>
        {maxField}
      </Flex>
      <RemoveCriteria onRemove={onRemove} disabled={disabled} />
    </Flex>
  )
}
