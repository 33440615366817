import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import * as Dialog from 'src/components/primitives/dialog'
import { SequenceEditor } from 'src/components/blocks/sequence-editor'
import { When } from 'src/components/blocks/when'
import type { Spacing } from 'src/styles/theme/types'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { SequenceOptions } from './sequence-options'
import type { EmailSequence } from 'src/models/sequence'
import { Intro } from './intro'
import { useJobsQuery } from 'src/hooks/queries/use-jobs'
import * as S from './create-sequence-dialog.styled'
import { SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useAtomValue, useSetAtom } from 'jotai'
import { controlDialogAtom, DialogId, isDialogOpenAtom, openAlertAtom } from 'src/stores/dialogs'
import { CreateSequenceDialogView, LocalStorageKey } from 'src/constants'
import { sequenceHasUnsavedChangesAtom } from 'src/stores/sequence'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import { getSendingEmailAccountForSequence, validateSequence } from 'src/hooks/use-email-sequence-editor'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useSession } from 'src/hooks/use-session'
import RouteBuilder from 'src/libs/route-builder'
import { useLocalStorage } from 'usehooks-ts'

export interface CreateSequenceDialogProps {
  defaultView?: CreateSequenceDialogView
  defaultJobIdToCopySequenceFrom?: string
  showBackToOptionsLink?: boolean
}

export const CreateSequenceDialog = ({
  defaultView = CreateSequenceDialogView.INTRO,
  defaultJobIdToCopySequenceFrom,
  showBackToOptionsLink = true
}: CreateSequenceDialogProps): JSX.Element => {
  const { jobId } = useParams()
  const openAlert = useSetAtom(openAlertAtom)
  const { data: jobs } = useJobsQuery()
  const [jobIdToCopySequenceFrom, setJobIdToCopySequenceFrom] = useState<string | null>(defaultJobIdToCopySequenceFrom ?? null)
  const isDialogOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.CREATE_SEQUENCE), []))
  const controlDialog = useSetAtom(controlDialogAtom)
  const { data: sequence } = useJobSequenceQuery()
  const { isPending, data: sequenceToCopy } = useJobSequenceQuery(jobIdToCopySequenceFrom ?? undefined)
  const isEmptySequence = isSequenceStepsEmpty(sequence)
  const hasUnsavedChanges = useAtomValue(sequenceHasUnsavedChangesAtom)
  const { upsertJobSequence } = useUpsertJobSequence()
  const { data: orgUsers } = useOrgUsersQuery()
  const { user } = useSession()
  const navigate = useNavigate()

  const [userEmailAccounts, setUserEmailAccounts] = useState<EmailAccount[]>([])
  const [_, setUnsavedSequence] = useLocalStorage<EmailSequence | null>(LocalStorageKey.SEQUENCE, null)

  useEffect(() => {
    setUserEmailAccounts(orgUsers?.flatMap((user) => user.emailAccounts) ?? [])
  }, [orgUsers])

  const inCompletedSequenceStepsIds = useMemo(() => {
    return (
      sequence?.sequenceSteps?.filter((step) =>
        step.generationState === SequenceStepGenerationState.IN_PROGRESS
      ).map((step) => step.id).join('')
    )
  }, [sequence?.sequenceSteps])

  const getInitialView = useMemo((): CreateSequenceDialogView => {
    if (!isNil(defaultView)) {
      return defaultView
    } else if (isEmptySequence) {
      return CreateSequenceDialogView.INTRO
    } else {
      return CreateSequenceDialogView.OPTIONS
    }
  }, [defaultView, isEmptySequence])

  const [view, setView] = useState<CreateSequenceDialogView>(getInitialView)

  const defaultPadding = {
    top: 0 as Spacing,
    right: 0 as Spacing,
    bottom: 0 as Spacing,
    left: 0 as Spacing
  }

  const DIALOG_PADDING = {
    INTRO: {
      top: 24 as Spacing,
      right: 24 as Spacing,
      bottom: 24 as Spacing,
      left: 24 as Spacing
    },
    OPTIONS: defaultPadding,
    CREATE_MANUALLY: defaultPadding,
    CREATE_WITH_AI: defaultPadding
  }

  const getSequence = useCallback(
    (jobId: string | null): EmailSequence => {
      if (isNil(jobId)) {
        return {
          id: sequence?.id ?? null,
          sequenceSteps: [
            {
              position: 0,
              type: SequenceStepType.AUTOMATED_EMAIL,
              waitDays: 0,
              subject: 'Email 1',
              body: '',
              sendingEmailAlias: null,
              sendingLinkedInAccountId: null
            }
          ]
        }
      } else {
        return {
          id: sequence?.id ?? null,
          autoArchiveAfterDays: sequenceToCopy?.autoArchiveAfterDays,
          dailyEmailLimit: sequenceToCopy?.dailyEmailLimit,
          sequenceSteps:
            sequenceToCopy?.sequenceSteps?.map((step) => {
              return {
                ...step,
                id: undefined,
                generationState: null
              }
            }) ?? []
        }
      }
    },
    [sequence?.id, sequenceToCopy?.autoArchiveAfterDays, sequenceToCopy?.dailyEmailLimit, sequenceToCopy?.sequenceSteps]
  )

  const getLatestSequence = useCallback((): EmailSequence | null => {
    // Not using `useLocalStorage` hook on purpose to get the latest unsaved
    // sequence state without re-rendering
    const stored = localStorage.getItem(LocalStorageKey.SEQUENCE)
    return stored ? JSON.parse(stored) : null
  }, [])

  const saveSequence = useCallback(async () => {
    if (isNil(jobId)) {
      return
    }

    const latestSequence = getLatestSequence()
    if (!latestSequence || sequence?.id !== latestSequence?.id) {
      return
    }

    const validated = await validateSequence({
      sequence: latestSequence,
      defaultSendingAccount: getSendingEmailAccountForSequence({
        sendingEmailAccountId: latestSequence?.sequenceSteps?.[0]?.sendingEmailAccountId,
        currentUserId: user?.id,
        userEmailAccounts
      })
    })

    if (isNil(validated)) {
      return
    }

    upsertJobSequence({
      jobId,
      sequenceSteps: validated.sequenceSteps,
      active: validated.active ?? true,
      autoArchiveAfterDays: validated.autoArchiveAfterDays,
      dailyEmailLimit: validated.dailyEmailLimit,
      onSuccess: () => {
        controlDialog({ id: DialogId.CREATE_SEQUENCE, newState: false })
        setTimeout(() => {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
          setUnsavedSequence(null)
        }, 100)
      }
    })
  }, [controlDialog, jobId, sequence?.id, user?.id, userEmailAccounts, upsertJobSequence, getLatestSequence, navigate, setUnsavedSequence])

  const handleNotify = useCallback(
    (onConfirmAction: () => void): void => {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your changes will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onConfirm: () => {
          onConfirmAction()
        },
        confirmAndExitText: 'Save and leave',
        onConfirmAndExit: () => {
          void saveSequence()
        }
      })
    },
    [openAlert]
  )

  return (
    <Dialog.Root
      id={DialogId.CREATE_SEQUENCE}
      isOpen={isDialogOpen}
      onOpenChange={(value) => {
        if (view === CreateSequenceDialogView.CREATE_MANUALLY || view === CreateSequenceDialogView.CREATE_WITH_AI) {
          if (hasUnsavedChanges) {
            handleNotify(() => { controlDialog({ id: DialogId.CREATE_SEQUENCE, newState: value }) })
          } else {
            controlDialog({ id: DialogId.CREATE_SEQUENCE, newState: value })
          }
        } else {
          controlDialog({ id: DialogId.CREATE_SEQUENCE, newState: value })
        }
      }}
      $width={view === CreateSequenceDialogView.INTRO ? '500px' : 'large'}
      $height={view === CreateSequenceDialogView.INTRO ? 'auto' : 'large'}
      $innerPadding={DIALOG_PADDING[view]}
    >
      <Dialog.Portal>
        <Dialog.Header title="Outreach Sequence" />
        <Dialog.Content>
          <When condition={view === CreateSequenceDialogView.INTRO}>
            <Intro
              onContinue={() => {
                setView(
                  isEmptySequence &&
                    Array.isArray(jobs) &&
                    jobs.filter((job) => job.id !== jobId)?.length >= 1
                    ? CreateSequenceDialogView.OPTIONS
                    : CreateSequenceDialogView.CREATE_WITH_AI
                )
              }}
            />
          </When>
          <When condition={view === CreateSequenceDialogView.OPTIONS}>
            <SequenceOptions
              onSelectGenerationType={(generationType, jobIdToCopy) => {
                setView(generationType === 'AI' ? CreateSequenceDialogView.CREATE_WITH_AI : CreateSequenceDialogView.CREATE_MANUALLY)
                if (jobIdToCopy) {
                  setJobIdToCopySequenceFrom(jobIdToCopy)
                } else {
                  setJobIdToCopySequenceFrom(null)
                }
              }}
              onSkip={() => {
                controlDialog({ id: DialogId.CREATE_SEQUENCE, newState: false })
              }}
            />
          </When>
          <When condition={view === CreateSequenceDialogView.CREATE_WITH_AI}>
            <S.SequenceEditorWrapper>
              <SequenceEditor
                key={inCompletedSequenceStepsIds} // Force re-render till all steps are completed
                initialState={sequence ?? undefined}
                shouldAutoGenerateEmails={true}
                usedInDialog={true}
                {...(showBackToOptionsLink && {
                  onBackClick: () => {
                    handleNotify(() => {
                      setView(CreateSequenceDialogView.OPTIONS)
                    })
                  }
                })}
              />
            </S.SequenceEditorWrapper>
          </When>
          <When condition={view === CreateSequenceDialogView.CREATE_MANUALLY}>
            <S.SequenceEditorWrapper>
              <When condition={!isPending}>
                <SequenceEditor
                  initialState={getSequence(jobIdToCopySequenceFrom)}
                  shouldAutoGenerateEmails={true}
                  usedInDialog={true}
                  {...(showBackToOptionsLink && {
                    onBackClick: () => {
                      handleNotify(() => {
                        setView(CreateSequenceDialogView.OPTIONS)
                      })
                    }
                  })}
                />
              </When>
            </S.SequenceEditorWrapper>
          </When>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
