import styled, { css } from 'styled-components'

export const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  overflow-y: scroll;
`

export const HolidaysList = styled.ul`
  list-style: none;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 6px;
  overflow: hidden;
`

export const HolidaysListItem = styled.li<{ $passedHoliday?: boolean }>`
  padding: 0.5rem;
  border-bottom: solid 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  border-color: ${({ theme }) => theme.colors.bgTertiary};
  ${({ $passedHoliday }) => $passedHoliday && css`
    opacity: 0.5;
    pointer-events: none;
  `}
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.bgTertiary};
    }
  }
`

export const NextHolidayBadge = styled.span`
  background-color: ${({ theme }) => theme.colors.tintBg};
  color: ${({ theme }) => theme.colors.bgPrimary};
  border-radius: 99px;
  padding: 0.15rem 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes[10]};
  font-weight: ${({ theme }) => theme.fontWeights['500']};
  line-height: 1;
`
