import { useState } from 'react'
import { Form, Input } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { FormActionButtons } from '../form-action-buttons'
import { useCreateProject } from 'src/hooks/mutations/use-create-project'
import { useSetAtom } from 'jotai'
import { closeDialogAtom, DialogId } from 'src/stores/dialogs'
import { Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives/flex'
import { Spacer } from 'src/components/primitives/spacer'
import { useSession } from 'src/hooks/use-session'

const createProjectFormSchema = z.object({
  name: z.string(),
  departmentId: z.string().nullish()
})

export type CreateProjectFormSchema = z.infer<typeof createProjectFormSchema>

interface CreateProjectFormProps {
  preSelectedDepartmentId?: string | null
}

export const CreateProjectForm = ({ preSelectedDepartmentId }: CreateProjectFormProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const closeDialog = useSetAtom(closeDialogAtom)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitDisabled] = useState(false)
  const { submit, register, isValid, formData } = useForm<CreateProjectFormSchema>({
    schema: createProjectFormSchema,
    initialValues: {
      departmentId: preSelectedDepartmentId
    }
  })
  const { createNewProject } = useCreateProject()

  const handleSubmit = async (formData: CreateProjectFormSchema): Promise<void> => {
    setIsSubmitting(true)
    createNewProject({
      name: formData.name,
      departmentId: preSelectedDepartmentId,
      onSuccess: () => {
        setIsSubmitting(false)
        closeDialog(DialogId.CREATE_PROJECT)
      }
    })
  }

  return (
    <Form onSubmit={submit(handleSubmit)}>
      <Flex $width="86%" $direction="column">
        <Paragraph size="SM" $color="fgSecondary">Create projects to organize your job positions internally by team or department.</Paragraph>
      </Flex>
      <Spacer $size={24} />
      <Input name="name" placeholder="" label="Project name" register={register} $marginBottom={0} />
      <FormActionButtons
        saveText="Create Project"
        isSaving={isSubmitting}
        disabled={submitDisabled || !isValid || !formData.name || userHasViewerRole}
        topSpacing={24}
      />
    </Form>
  )
}
