import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { closePinAccount } from 'src/libs/api/backend/orgs'
import type { ClosePinAccountFeedbackArgs } from 'src/libs/api/backend/orgs'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom } from 'src/stores/notifications'
import queryClient from '../query-client'

type Args = ClosePinAccountFeedbackArgs & {
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  deleteOrg: (args: Args) => void
}

export const useDeleteOrg = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ reason, reasonDetails, feedbackCall }: Args) => {
      await closePinAccount({ reason, reasonDetails, feedbackCall })
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when disabling the user: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess()
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.session] })
    }
  })

  const deleteOrg = (args: Args): void => {
    mutation.mutate(args)
  }

  return { deleteOrg }
}
