import { CriteriaKey, Sex } from 'src/libs/api/backend/candidate_search'
import type { Criteria, CriteriaValue, StandardCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { CriteriaProperties, RACE_OPTIONS, SEX_OPTIONS } from '../constants'
import { Caption } from 'src/components/primitives/typography'
import { Button, Flex } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icon, Icons } from 'src/components/primitives/icon'
import { RemoveCriteria } from './remove-criteria'
import { useMemo } from 'react'
import { isNil } from 'lodash'
import { pluralize } from 'src/libs/pluralize'
import { deleteItem, optionalSwitchItems } from './appendix-menu-items'

interface DemographicsRowProps {
  criteria: Criteria
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey, index?: number) => void
  updated: boolean
  disabled: boolean
  onRowClick?: (criteriaKey: CriteriaKey) => void
}

interface SubCriteriaProps {
  criteriaKey: CriteriaKey
  criteriaValue: CriteriaValue | undefined
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  disabled: boolean
}

const GenderCriteria = ({ criteriaKey, criteriaValue, onCriteriaUpdate, disabled }: SubCriteriaProps): JSX.Element => {
  const genderValue = useMemo((): StandardCriteria | undefined => {
    if (isNil(criteriaValue)) {
      return undefined
    }
    return (criteriaValue as StandardCriteria[])[0]
  }, [criteriaValue])
  const items = useMemo((): MenuItemProps[] => [
    optionalSwitchItems({
      value: genderValue?.optional ?? true,
      disabled: isNil(genderValue),
      onUpdate: (value) => {
        if (isNil(genderValue)) {
          onCriteriaUpdate(criteriaKey, [{
            name: Sex.FEMALE,
            optional: value
          }])
        } else {
          onCriteriaUpdate(criteriaKey, [{
            ...genderValue,
            optional: value
          }])
        }
      }
    }),
    {
      id: 'optional-separator',
      title: 'Optional Separator',
      type: 'separator'
    },
    ...Array.from(SEX_OPTIONS.entries()).map(([name, label]) => ({
      id: name,
      title: label,
      onSelect: () => {
        onCriteriaUpdate(criteriaKey, [{
          ...(genderValue ?? {}),
          name
        }])
      },
      variant: genderValue?.name === name ? 'tint' as const : 'neutral' as const,
      trailingIcon: genderValue?.name === name ? Icons.check : undefined
    })),
    ...deleteItem({
      onUpdate: () => {
        onCriteriaUpdate(criteriaKey, [])
      }
    })
  ], [criteriaKey, genderValue, onCriteriaUpdate])
  return (
    <Dropdown
      size='small'
      disabled={disabled}
      trigger={
        <Button
          nested
          $variant='outline'
          $minWidth='72px'
          $height={32}
          $fontSize={12}
          $fontWeight={400}
          trailingIcon={Icons.chevronDownThin}
          $align='space-between'
          $colorTheme={genderValue ? 'normal' as const : 'muted' as const}
          disabled={disabled}
          leadingIcon={!isNil(genderValue?.optional) && !genderValue.optional
            ? <Icon name={Icons.pin} size={12} color='tintBg' />
            : undefined
          }
        >
          {genderValue ? SEX_OPTIONS.get(genderValue.name) : 'Gender'}
        </Button>
      }
      items={items}
    />
  )
}

const RaceCriteria = ({ criteriaKey, criteriaValue, onCriteriaUpdate, disabled }: SubCriteriaProps): JSX.Element => {
  const raceValue = useMemo((): StandardCriteria[] => {
    if (isNil(criteriaValue)) {
      return []
    }
    return (criteriaValue as StandardCriteria[])
  }, [criteriaValue])

  const selectedValues = useMemo((): string[] => {
    return raceValue.map((race) => race.name)
  }, [raceValue])

  const items = useMemo((): MenuItemProps[] => [
    optionalSwitchItems({
      value: raceValue[0]?.optional ?? true,
      disabled: raceValue.length === 0,
      onUpdate: (value) => {
        onCriteriaUpdate(criteriaKey, raceValue.map((race) => ({
          ...race,
          optional: value
        })))
      }
    }),
    {
      id: 'optional-separator',
      title: 'Optional Separator',
      type: 'separator'
    },
    ...Array.from(RACE_OPTIONS.entries()).map(([name, label]) => ({
      id: name,
      title: label,
      onSelect: () => {
        if (selectedValues.includes(name)) {
          onCriteriaUpdate(criteriaKey, raceValue.filter((race) => race.name !== name))
        } else {
          onCriteriaUpdate(criteriaKey, [...raceValue, {
            name,
            optional: raceValue[0]?.optional ?? true
          }])
        }
      },
      variant: selectedValues.includes(name) ? 'tint' as const : 'neutral' as const,
      trailingIcon: selectedValues.includes(name) ? Icons.check : undefined
    })),
    ...deleteItem({
      onUpdate: () => {
        onCriteriaUpdate(criteriaKey, [])
      }
    })
  ], [criteriaKey, onCriteriaUpdate, raceValue, selectedValues])

  return (
    <Dropdown
      size='small'
      disabled={disabled}
      trigger={
        <Button
          nested
          $variant='outline'
          $minWidth='120px'
          $height={32}
          $fontSize={12}
          $fontWeight={400}
          trailingIcon={Icons.chevronDownThin}
          $align='space-between'
          $colorTheme={raceValue.length > 0 ? 'normal' as const : 'muted' as const}
          disabled={disabled}
          leadingIcon={!isNil(raceValue[0]?.optional) && !raceValue[0].optional
            ? <Icon name={Icons.pin} size={12} color='tintBg' />
            : undefined
          }
        >
          {raceValue.length > 0 ? pluralize(raceValue.length, 'Ethnicity', { plural: 'Ethnicities' }) : 'Ethnicity'}
        </Button>
      }
      items={items}
    />
  )
}

export const DemographicsRow = ({
  criteria,
  onCriteriaUpdate,
  onCriteriaRemove,
  updated,
  disabled,
  onRowClick
}: DemographicsRowProps): JSX.Element => {
  return (
    <S.RowWrapper>
      <S.CriteriaRowWrapper
        $updated={updated}
        onClick={() => {
          if (updated) {
            onRowClick?.(CriteriaKey.DEMOGRAPHICS)
          }
        }}
      >
        <S.LabelContainer>
          <Caption size='XS' $fontWeight={400}>{CriteriaProperties.get(CriteriaKey.DEMOGRAPHICS)?.label}</Caption>
        </S.LabelContainer>
        <Flex $gap={8} $align='flex-start'>
          <Flex $gap={4} $flex='1 1 0'>
            <GenderCriteria
              criteriaKey={CriteriaKey.SEX}
              criteriaValue={criteria[CriteriaKey.SEX]}
              onCriteriaUpdate={onCriteriaUpdate}
              disabled={disabled}
            />
            <RaceCriteria
              criteriaKey={CriteriaKey.RACE_CATEGORY}
              criteriaValue={criteria[CriteriaKey.RACE_CATEGORY]}
              onCriteriaUpdate={onCriteriaUpdate}
              disabled={disabled}
            />
          </Flex>
          <S.RemoveCriteriaBox>
            <RemoveCriteria
              onRemove={() => {
                onCriteriaRemove(CriteriaKey.DEMOGRAPHICS)
              }}
              disabled={disabled}
              icon={Icons.trash}
            />
          </S.RemoveCriteriaBox>
        </Flex>
      </S.CriteriaRowWrapper>
    </S.RowWrapper>
  )
}
