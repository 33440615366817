import styled, { css } from 'styled-components'

export const Disabled = styled.div<{ $disabled?: boolean, $opacity?: number }>`
  opacity: ${({ $disabled, $opacity }) => $disabled ? $opacity : 1};
  pointer-events: ${({ $disabled }) => $disabled ? 'none' : 'auto'};
  user-select: ${({ $disabled }) => $disabled ? 'none' : 'auto'};
  ${({ $disabled }) => $disabled && css`
    * {
      pointer-events: none;
      user-select: none;
    }
  `}
`
