import {
  AlertTriangle,
  Archive,
  ArchiveRestore,
  ArrowDown,
  ArrowDownWideNarrow,
  ArrowRight,
  ArrowRightCircle,
  ArrowRightLeft,
  ArrowUp,
  ArrowUpDown,
  ArrowUpNarrowWide,
  ArrowUpRight,
  Ban,
  BarChart2,
  Bell,
  BellRing,
  Bird,
  Bold,
  Book,
  Briefcase,
  Building,
  Building2,
  Calendar,
  CalendarClock,
  CalendarDays,
  Check,
  CheckCheck,
  CheckCircleIcon,
  CheckCircle2,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
  ChevronUp,
  CircleAlert,
  CircleArrowUp,
  CircleCheckBig,
  CircleDashed,
  CircleHelp,
  Clipboard,
  CornerDownRight,
  ConciergeBell,
  Contact,
  Copy,
  Crosshair,
  DollarSign,
  Download,
  ExternalLink,
  Eye,
  File,
  FileDown,
  FileSearch,
  FileSpreadsheet,
  FileText,
  FilePen,
  Filter,
  Folder,
  Forward,
  Glasses,
  Globe,
  GraduationCap,
  GripHorizontal,
  GripVertical,
  HeartHandshake,
  HelpCircle,
  History,
  House,
  Hourglass,
  Image,
  Import,
  Inbox,
  Info,
  Italic,
  LayoutList,
  Layers,
  Link,
  Linkedin as LinkedinOutline,
  List,
  ListOrdered,
  Loader2 as Loader,
  Lock,
  LogOut,
  Mail,
  MailCheck,
  MailOpen,
  MailMinus,
  MailPlus,
  MailQuestion,
  MailWarning,
  Mails,
  MailX,
  MapPin,
  Maximize2,
  Megaphone,
  MessageCircle,
  MessagesSquare,
  Minus,
  MoreVertical,
  MousePointerClick,
  Network,
  PanelRightClose,
  PanelRightOpen,
  Paperclip,
  PauseCircle,
  Pencil,
  PenLine,
  PenSquare,
  PieChart,
  Pin,
  PinOff,
  Phone,
  PlayCircle,
  Plug,
  Plus,
  PlusCircle,
  Printer,
  Puzzle,
  RefreshCw,
  Reply,
  Rocket,
  School,
  Scroll,
  Search,
  SearchX,
  Send,
  Settings,
  Settings2,
  Share,
  SlidersHorizontal,
  SkipForward,
  Sparkles,
  Star,
  StopCircle,
  SquareArrowOutUpRight,
  SquareGanttChart,
  SquareCheckBig,
  ThumbsDown,
  ThumbsUp,
  Trash,
  Trash2,
  Table2,
  Twitter,
  Undo,
  Undo2,
  Unlink,
  UploadCloud,
  UserCircle,
  Users,
  Video,
  View,
  Wallet,
  Wand2,
  X,
  XCircle,
  XOctagon,
  Zap,
  TrendingUp
} from 'lucide-react'

import {
  BinocularsIcon,
  CheckFillIcon,
  CustomRequirementIcon,
  EllipsisSvg,
  ExperienceClockIcon,
  ExtensionsIcon,
  FileSignatureIcon,
  LayoutDetailsIcon,
  MailArrowLeftIcon,
  MailArrowRightIcon,
  PinNormalIcon,
  RefinementInputIcon,
  ReplyThickIcon,
  SleepSnoozeIcon,
  SparklesSmIcon,
  SparklesSolid,
  StarFillIcon,
  ThumbsDownFillIcon,
  ThumbsUpFillIcon,
  UploadIcon,
  VariableAddIcon,
  EmptyApplicantIcon,
  SlidersHorizontal2Icon,
  FilledCircleCheckIcon,
  ShareBoxIcon,
  MailReplyIcon
} from '../svgs'
import type { Color } from 'src/styles/theme/types'
import { useTheme } from 'styled-components'

export const Icons = {
  alertTriangle: 'alert-triangle',
  archive: 'archive',
  archiveRestore: 'archive-restore',
  arrowDown: 'arrow-down',
  arrowDownWideNarrow: 'arrow-down-wide-narrow',
  arrowRight: 'arrow-right',
  arrowRightLeft: 'arrow-right-left',
  arrowRightCircle: 'arrow-right-circle',
  arrowUp: 'arrow-up',
  arrowUpDown: 'arrow-up-down',
  arrowUpNarrowWide: 'arrow-up-narrow-wide',
  arrowUpRight: 'arrow-up-right',
  ban: 'ban',
  barChart2: 'bar-chart-2',
  bell: 'bell',
  bellRing: 'bell-ring',
  binoculars: 'binoculars',
  bird: 'bird',
  bold: 'bold',
  book: 'book',
  briefcase: 'briefcase',
  building: 'building',
  building2: 'building-2',
  calendar: 'calendar',
  calendarClock: 'calendar-clock',
  calendarDays: 'calendar-days',
  check: 'check',
  checkCheck: 'check-check',
  checkCircle: 'check-circle',
  checkCircle2: 'check-circle-2',
  checkFill: 'check-fill',
  chevronDown: 'chevron-down',
  chevronDownSmall: 'chevron-down-small',
  chevronDownThin: 'chevron-down-thin',
  chevronLeft: 'chevron-left',
  chevronRight: 'chevron-right',
  chevronsLeft: 'chevrons-left',
  chevronsLeftThin: 'chevrons-left-thin',
  chevronsRight: 'chevrons-right',
  chevronsRightThin: 'chevrons-right-thin',
  chevronsUpDown: 'chevrons-up-down',
  chevronsUpDownSmall: 'chevrons-up-down-small',
  chevronUp: 'chevron-up',
  chevronUpThin: 'chevron-up-thin',
  circleAlert: 'circle-alert',
  circleArrowUp: 'circle-arrow-up',
  circleCheckBig: 'circle-check-big',
  circleDashed: 'circle-dashed',
  circleHelp: 'circle-help',
  clipboard: 'clipboard',
  cornerDownRight: 'corner-down-right',
  conciergeBell: 'concierge-bell',
  contact: 'contact',
  copy: 'copy',
  crosshair: 'crosshair',
  customRequirement: 'custom-requirement',
  dollarSign: 'dollar-sign',
  download: 'download',
  ellipsis: 'ellipsis',
  emptyApplicant: 'empty-applicant',
  experienceClock: 'experience-clock',
  extensions: 'extensions',
  externalLink: 'external-link',
  eye: 'eye',
  file: 'file',
  fileDown: 'file-down',
  filePen: 'file-pen',
  fileSearch: 'file-search',
  fileSignature: 'file-signature',
  fileSpreadsheet: 'file-spreadsheet',
  fileText: 'file-text',
  filter: 'filter',
  filledCircleCheck: 'filled-circle-check',
  folder: 'folder',
  forward: 'forward',
  glasses: 'glasses',
  globe: 'globe',
  graduationCap: 'graduation-cap',
  gripHorizontal: 'grip-horizontal',
  gripVertical: 'grip-vertical',
  heartHandshake: 'heart-handshake',
  helpCircle: 'help-circle',
  history: 'history',
  house: 'house',
  hourglass: 'hourglass',
  image: 'image',
  import: 'import',
  inbox: 'inbox',
  info: 'info',
  italic: 'italic',
  layoutDetails: 'layout-details',
  layoutList: 'layout-list',
  layers: 'layers',
  link: 'link',
  linkedinOutline: 'linkedin-outline',
  list: 'list',
  listOrdered: 'listOrdered',
  loader: 'loader',
  lock: 'lock',
  logOut: 'log-out',
  mail: 'mail',
  mailArrowLeft: 'mail-arrow-left',
  mailArrowRight: 'mail-arrow-right',
  mailCheck: 'mail-check',
  mailMinus: 'mail-minus',
  mailOpen: 'mail-open',
  mailReply: 'mail-reply',
  mailPlus: 'mail-plus',
  mailQuestion: 'mail-question',
  mailWarning: 'mail-warning',
  mails: 'mails',
  mailX: 'mail-x',
  mapPin: 'map-pin',
  maximize2: 'maximize-2',
  megaphone: 'megaphone',
  messageCircle: 'message-circle',
  messagesSquare: 'messages-square',
  minus: 'minus',
  moreVertical: 'more-vertical',
  mousePointerClick: 'mouse-pointer-click',
  network: 'network',
  panelRightClose: 'panel-right-close',
  panelRightOpen: 'panel-right-open',
  paperClip: 'paper-clip',
  pauseCircle: 'pause-circle',
  pencil: 'pencil',
  penLine: 'pen-line',
  penSquare: 'pen-square',
  phone: 'phone',
  pieChart: 'pie-chart',
  pin: 'pin',
  pinNormal: 'pin-normal',
  pinOff: 'pin-off',
  playCircle: 'play-circle',
  plug: 'plug',
  plus: 'plus',
  plusCircle: 'plus-circle',
  printer: 'printer',
  puzzle: 'puzzle',
  refinementInput: 'refinement-input',
  refreshCw: 'refresh-cw',
  reply: 'reply',
  replyThick: 'reply-thick',
  rocket: 'rocket',
  school: 'school',
  scroll: 'scroll',
  search: 'search',
  searchX: 'search-x',
  send: 'send',
  settings: 'settings',
  settings2: 'settings-2',
  share: 'share',
  shareBox: 'share-box',
  sleepSnooze: 'sleep-snooze',
  slidersHorizontal: 'sliders-horizontal',
  slidersHorizontal2: 'sliders-horizontal-2',
  skipForward: 'skip-forward',
  sparkles: 'sparkles',
  sparklesSm: 'sparkles-sm',
  sparklesSolid: 'sparkles-solid',
  star: 'star',
  starFill: 'star-fill',
  stopCircle: 'stop-circle',
  squareArrowOutUpRight: 'square-arrow-out-up-right',
  squareGanttChart: 'square-gantt-chart',
  squareCheckBig: 'square-check-big',
  thumbsDown: 'thumbs-down',
  thumbsDownFill: 'thumbs-down-fill',
  thumbsUp: 'thumbs-up',
  thumbsUpFill: 'thumbs-up-fill',
  trash: 'trash',
  trash2: 'trash-2',
  table2: 'table-2',
  twitter: 'twitter',
  undo: 'undo',
  undo2: 'undo-2',
  unlink: 'unlink',
  uploadCloud: 'upload-cloud',
  userCircle: 'user-circle',
  users: 'users',
  upload: 'upload',
  variableAdd: 'variable-add',
  video: 'video',
  view: 'view',
  wallet: 'wallet',
  wand2: 'wand-2',
  x: 'x',
  xCircle: 'x-circle',
  xOctagon: 'x-octagon',
  zap: 'zap',
  trendingUp: 'trending-up'
} as const

export type IconName = (typeof Icons)[keyof typeof Icons]

interface IconProps {
  name: IconName
  size?: number
  color?: Color
}

export const Icon = ({ name, size = 16, color }: IconProps): JSX.Element => {
  const { colors } = useTheme()
  const iconColor = colors[color ?? 'fgPrimary']
  return (
    <>
      {name === Icons.alertTriangle && <AlertTriangle size={size} stroke={iconColor} />}
      {name === Icons.archive && <Archive size={size} stroke={iconColor} />}
      {name === Icons.archiveRestore && <ArchiveRestore size={size} stroke={iconColor} />}
      {name === Icons.arrowDown && <ArrowDown size={size} stroke={iconColor} />}
      {name === Icons.arrowDownWideNarrow && <ArrowDownWideNarrow size={size} stroke={iconColor} />}
      {name === Icons.arrowRight && <ArrowRight size={size} stroke={iconColor} />}
      {name === Icons.arrowRightCircle && <ArrowRightCircle size={size} stroke={iconColor} />}
      {name === Icons.arrowRightLeft && <ArrowRightLeft size={size} stroke={iconColor} />}
      {name === Icons.arrowUp && <ArrowUp size={size} stroke={iconColor} />}
      {name === Icons.arrowUpDown && <ArrowUpDown size={size} stroke={iconColor} />}
      {name === Icons.arrowUpNarrowWide && <ArrowUpNarrowWide size={size} stroke={iconColor} />}
      {name === Icons.arrowUpRight && <ArrowUpRight size={size} stroke={iconColor} />}
      {name === Icons.ban && <Ban size={size} stroke={iconColor} />}
      {name === Icons.barChart2 && <BarChart2 size={size} stroke={iconColor} />}
      {name === Icons.bell && <Bell size={size} stroke={iconColor} />}
      {name === Icons.bellRing && <BellRing size={size} stroke={iconColor} />}
      {name === Icons.binoculars && <BinocularsIcon size={size} iconColor={iconColor} />}
      {name === Icons.bird && <Bird size={size} stroke={iconColor} />}
      {name === Icons.bold && <Bold size={size} stroke={iconColor} />}
      {name === Icons.book && <Book size={size} stroke={iconColor} />}
      {name === Icons.briefcase && <Briefcase size={size} stroke={iconColor} />}
      {name === Icons.building && <Building size={size} stroke={iconColor} />}
      {name === Icons.building2 && <Building2 size={size} stroke={iconColor} />}
      {name === Icons.calendar && <Calendar size={size} stroke={iconColor} />}
      {name === Icons.calendarClock && <CalendarClock size={size} stroke={iconColor} />}
      {name === Icons.calendarDays && <CalendarDays size={size} stroke={iconColor} />}
      {name === Icons.check && <Check size={size} stroke={iconColor} />}
      {name === Icons.checkCheck && <CheckCheck size={size} stroke={iconColor} />}
      {name === Icons.checkCircle && <CheckCircleIcon size={size} stroke={iconColor} />}
      {name === Icons.checkCircle2 && <CheckCircle2 size={size} stroke={iconColor} />}
      {name === Icons.checkFill && <CheckFillIcon size={size} iconColor={iconColor} />}
      {name === Icons.chevronDown && <ChevronDown size={size} stroke={iconColor} />}
      {name === Icons.chevronDownSmall && <ChevronDown size={size * 0.75} stroke={iconColor} />}
      {name === Icons.chevronDownThin && <ChevronDown size={size} stroke={iconColor} strokeWidth={1} />}
      {name === Icons.chevronLeft && <ChevronLeft size={size} stroke={iconColor} />}
      {name === Icons.chevronRight && <ChevronRight size={size} stroke={iconColor} />}
      {name === Icons.chevronsLeft && <ChevronsLeft size={size} stroke={iconColor} />}
      {name === Icons.chevronsLeftThin && <ChevronsLeft size={size} stroke={iconColor} strokeWidth={1} />}
      {name === Icons.chevronsRight && <ChevronsRight size={size} stroke={iconColor} />}
      {name === Icons.chevronsRightThin && <ChevronsRight size={size} stroke={iconColor} strokeWidth={1} />}
      {name === Icons.chevronsUpDown && <ChevronsUpDown size={size} stroke={iconColor} />}
      {name === Icons.chevronsUpDownSmall && <ChevronsUpDown size={size * 0.75} stroke={iconColor} />}
      {name === Icons.chevronUp && <ChevronUp size={size} stroke={iconColor} />}
      {name === Icons.chevronUpThin && <ChevronUp size={size} stroke={iconColor} strokeWidth={1} />}
      {name === Icons.circleAlert && <CircleAlert size={size} stroke={iconColor} />}
      {name === Icons.circleArrowUp && <CircleArrowUp size={size} stroke={iconColor} />}
      {name === Icons.circleCheckBig && <CircleCheckBig size={size} stroke={iconColor} />}
      {name === Icons.circleDashed && <CircleDashed size={size} stroke={iconColor} />}
      {name === Icons.circleHelp && <CircleHelp size={size} stroke={iconColor} />}
      {name === Icons.clipboard && <Clipboard size={size} stroke={iconColor} />}
      {name === Icons.conciergeBell && <ConciergeBell size={size} stroke={iconColor} />}
      {name === Icons.cornerDownRight && <CornerDownRight size={size} stroke={iconColor} />}
      {name === Icons.contact && <Contact size={size} stroke={iconColor} />}
      {name === Icons.copy && <Copy size={size} stroke={iconColor} />}
      {name === Icons.crosshair && <Crosshair size={size} stroke={iconColor} />}
      {name === Icons.customRequirement && <CustomRequirementIcon size={size} iconColor={iconColor} />}
      {name === Icons.dollarSign && <DollarSign size={size} stroke={iconColor} />}
      {name === Icons.download && <Download size={size} stroke={iconColor} />}
      {name === Icons.ellipsis && <EllipsisSvg size={size} iconColor={iconColor} />}
      {name === Icons.emptyApplicant && <EmptyApplicantIcon size={size} iconColor={iconColor} />}
      {name === Icons.experienceClock && <ExperienceClockIcon size={size} iconColor={iconColor} />}
      {name === Icons.extensions && <ExtensionsIcon size={size} iconColor={iconColor} />}
      {name === Icons.externalLink && <ExternalLink size={size} stroke={iconColor} />}
      {name === Icons.eye && <Eye size={size} stroke={iconColor} />}
      {name === Icons.file && <File size={size} stroke={iconColor} />}
      {name === Icons.fileDown && <FileDown size={size} stroke={iconColor} />}
      {name === Icons.fileSearch && <FileSearch size={size} stroke={iconColor} />}
      {name === Icons.fileSpreadsheet && <FileSpreadsheet size={size} stroke={iconColor} />}
      {name === Icons.fileText && <FileText size={size} stroke={iconColor} />}
      {name === Icons.filePen && <FilePen size={size} stroke={iconColor} />}
      {name === Icons.fileSignature && <FileSignatureIcon size={size} iconColor={iconColor} />}
      {name === Icons.filter && <Filter size={size} stroke={iconColor} />}
      {name === Icons.filledCircleCheck && <FilledCircleCheckIcon size={size} iconColor={iconColor} />}
      {name === Icons.folder && <Folder size={size} stroke={iconColor} />}
      {name === Icons.forward && <Forward size={size} stroke={iconColor} />}
      {name === Icons.glasses && <Glasses size={size} stroke={iconColor} />}
      {name === Icons.globe && <Globe size={size} stroke={iconColor} />}
      {name === Icons.graduationCap && <GraduationCap size={size} stroke={iconColor} />}
      {name === Icons.gripHorizontal && <GripHorizontal size={size} stroke={iconColor} />}
      {name === Icons.gripVertical && <GripVertical size={size} stroke={iconColor} />}
      {name === Icons.heartHandshake && <HeartHandshake size={size} stroke={iconColor} />}
      {name === Icons.helpCircle && <HelpCircle size={size} stroke={iconColor} />}
      {name === Icons.history && <History size={size} stroke={iconColor} />}
      {name === Icons.house && <House size={size} stroke={iconColor} />}
      {name === Icons.hourglass && <Hourglass size={size} stroke={iconColor} />}
      {name === Icons.image && <Image size={size} stroke={iconColor} />}
      {name === Icons.import && <Import size={size} stroke={iconColor} />}
      {name === Icons.inbox && <Inbox size={size} stroke={iconColor} />}
      {name === Icons.info && <Info size={size} stroke={iconColor} />}
      {name === Icons.italic && <Italic size={size} stroke={iconColor} />}
      {name === Icons.layoutDetails && <LayoutDetailsIcon size={size} iconColor={iconColor} />}
      {name === Icons.layoutList && <LayoutList size={size} stroke={iconColor} />}
      {name === Icons.layers && <Layers size={size} stroke={iconColor} />}
      {name === Icons.link && <Link size={size} stroke={iconColor} />}
      {name === Icons.linkedinOutline && <LinkedinOutline size={size} stroke={iconColor} />}
      {name === Icons.list && <List size={size} stroke={iconColor} />}
      {name === Icons.listOrdered && <ListOrdered size={size} stroke={iconColor} />}
      {name === Icons.loader && <Loader size={size} stroke={iconColor} />}
      {name === Icons.lock && <Lock size={size} stroke={iconColor} />}
      {name === Icons.logOut && <LogOut size={size} stroke={iconColor} />}
      {name === Icons.mail && <Mail size={size} stroke={iconColor} />}
      {name === Icons.mailArrowLeft && <MailArrowLeftIcon size={size} iconColor={iconColor} />}
      {name === Icons.mailArrowRight && <MailArrowRightIcon size={size} iconColor={iconColor} />}
      {name === Icons.mailCheck && <MailCheck size={size} stroke={iconColor} />}
      {name === Icons.mailOpen && <MailOpen size={size} stroke={iconColor} />}
      {name === Icons.mailReply && <MailReplyIcon size={size} iconColor={iconColor} />}
      {name === Icons.mailMinus && <MailMinus size={size} stroke={iconColor} />}
      {name === Icons.mailPlus && <MailPlus size={size} stroke={iconColor} />}
      {name === Icons.mailQuestion && <MailQuestion size={size} stroke={iconColor} />}
      {name === Icons.mailWarning && <MailWarning size={size} stroke={iconColor} />}
      {name === Icons.mails && <Mails size={size} stroke={iconColor} />}
      {name === Icons.mailX && <MailX size={size} stroke={iconColor} />}
      {name === Icons.mapPin && <MapPin size={size} stroke={iconColor} />}
      {name === Icons.maximize2 && <Maximize2 size={size} stroke={iconColor} />}
      {name === Icons.megaphone && <Megaphone size={size} stroke={iconColor} />}
      {name === Icons.messageCircle && <MessageCircle size={size} stroke={iconColor} />}
      {name === Icons.messagesSquare && <MessagesSquare size={size} stroke={iconColor} />}
      {name === Icons.minus && <Minus size={size} stroke={iconColor} />}
      {name === Icons.moreVertical && <MoreVertical size={size} stroke={iconColor} />}
      {name === Icons.mousePointerClick && <MousePointerClick size={size} stroke={iconColor} />}
      {name === Icons.network && <Network size={size} stroke={iconColor} />}
      {name === Icons.panelRightClose && <PanelRightClose size={size} stroke={iconColor} />}
      {name === Icons.panelRightOpen && <PanelRightOpen size={size} stroke={iconColor} />}
      {name === Icons.paperClip && <Paperclip size={size} stroke={iconColor} />}
      {name === Icons.pauseCircle && <PauseCircle size={size} stroke={iconColor} />}
      {name === Icons.pencil && <Pencil size={size} stroke={iconColor} />}
      {name === Icons.penLine && <PenLine size={size} stroke={iconColor} />}
      {name === Icons.penSquare && <PenSquare size={size} stroke={iconColor} />}
      {name === Icons.phone && <Phone size={size} stroke={iconColor} />}
      {name === Icons.pieChart && <PieChart size={size} stroke={iconColor} />}
      {name === Icons.pin && <Pin size={size} stroke={iconColor} />}
      {name === Icons.pinNormal && <PinNormalIcon size={size} iconColor={iconColor} />}
      {name === Icons.pinOff && <PinOff size={size} stroke={iconColor} />}
      {name === Icons.playCircle && <PlayCircle size={size} stroke={iconColor} />}
      {name === Icons.plug && <Plug size={size} stroke={iconColor} />}
      {name === Icons.plus && <Plus size={size} stroke={iconColor} />}
      {name === Icons.plusCircle && <PlusCircle size={size} stroke={iconColor} />}
      {name === Icons.printer && <Printer size={size} stroke={iconColor} />}
      {name === Icons.puzzle && <Puzzle size={size} stroke={iconColor} />}
      {name === Icons.refinementInput && <RefinementInputIcon size={size} iconColor={iconColor} />}
      {name === Icons.refreshCw && <RefreshCw size={size} stroke={iconColor} />}
      {name === Icons.reply && <Reply size={size} stroke={iconColor} />}
      {name === Icons.replyThick && <ReplyThickIcon size={size} iconColor={iconColor} />}
      {name === Icons.rocket && <Rocket size={size} stroke={iconColor} />}
      {name === Icons.school && <School size={size} stroke={iconColor} />}
      {name === Icons.scroll && <Scroll size={size} stroke={iconColor} />}
      {name === Icons.search && <Search size={size} stroke={iconColor} />}
      {name === Icons.searchX && <SearchX size={size} stroke={iconColor} />}
      {name === Icons.send && <Send size={size} stroke={iconColor} />}
      {name === Icons.settings && <Settings size={size} stroke={iconColor} />}
      {name === Icons.settings2 && <Settings2 size={size} stroke={iconColor} />}
      {name === Icons.share && <Share size={size} stroke={iconColor} />}
      {name === Icons.shareBox && <ShareBoxIcon size={size} iconColor={iconColor} />}
      {name === Icons.sleepSnooze && <SleepSnoozeIcon size={size} iconColor={iconColor} />}
      {name === Icons.slidersHorizontal && <SlidersHorizontal size={size} stroke={iconColor} />}
      {name === Icons.slidersHorizontal2 && <SlidersHorizontal2Icon size={size} iconColor={iconColor} />}
      {name === Icons.skipForward && <SkipForward size={size} stroke={iconColor} />}
      {name === Icons.sparkles && <Sparkles size={size} stroke={iconColor} />}
      {name === Icons.sparklesSm && <SparklesSmIcon size={size} iconColor={iconColor} />}
      {name === Icons.sparklesSolid && <SparklesSolid size={size} iconColor={iconColor} />}
      {name === Icons.star && <Star size={size} stroke={iconColor} />}
      {name === Icons.starFill && <StarFillIcon size={size} iconColor={iconColor} />}
      {name === Icons.stopCircle && <StopCircle size={size} stroke={iconColor} />}
      {name === Icons.squareArrowOutUpRight && <SquareArrowOutUpRight size={size} stroke={iconColor} />}
      {name === Icons.squareGanttChart && <SquareGanttChart size={size} stroke={iconColor} />}
      {name === Icons.squareCheckBig && <SquareCheckBig size={size} stroke={iconColor} />}
      {name === Icons.thumbsDown && <ThumbsDown size={size} stroke={iconColor} />}
      {name === Icons.thumbsDownFill && <ThumbsDownFillIcon size={size} iconColor={iconColor} />}
      {name === Icons.thumbsUp && <ThumbsUp size={size} stroke={iconColor} />}
      {name === Icons.thumbsUpFill && <ThumbsUpFillIcon size={size} iconColor={iconColor} />}
      {name === Icons.trash && <Trash size={size} stroke={iconColor} />}
      {name === Icons.trash2 && <Trash2 size={size} stroke={iconColor} />}
      {name === Icons.table2 && <Table2 size={size} stroke={iconColor} />}
      {name === Icons.twitter && <Twitter size={size} stroke={iconColor} />}
      {name === Icons.undo && <Undo size={size} stroke={iconColor} />}
      {name === Icons.undo2 && <Undo2 size={size} stroke={iconColor} />}
      {name === Icons.unlink && <Unlink size={size} stroke={iconColor} />}
      {name === Icons.uploadCloud && <UploadCloud size={size} stroke={iconColor} />}
      {name === Icons.userCircle && <UserCircle size={size} stroke={iconColor} />}
      {name === Icons.users && <Users size={size} stroke={iconColor} />}
      {name === Icons.upload && <UploadIcon size={size} iconColor={iconColor} />}
      {name === Icons.variableAdd && <VariableAddIcon size={size} iconColor={iconColor} />}
      {name === Icons.video && <Video size={size} stroke={iconColor} />}
      {name === Icons.view && <View size={size} stroke={iconColor} />}
      {name === Icons.wallet && <Wallet size={size} stroke={iconColor} />}
      {name === Icons.wand2 && <Wand2 size={size} stroke={iconColor} />}
      {name === Icons.x && <X size={size} stroke={iconColor} />}
      {name === Icons.xCircle && <XCircle size={size} stroke={iconColor} />}
      {name === Icons.xOctagon && <XOctagon size={size} stroke={iconColor} />}
      {name === Icons.zap && <Zap size={size} stroke={iconColor} />}
      {name === Icons.trendingUp && <TrendingUp size={size} stroke={iconColor} />}
    </>
  )
}
