import styled from 'styled-components'

export const OrgJobsDropdown = styled.div<{ $isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    ${({ $isCollapsed }) => !$isCollapsed && `
      width: 100%;
    `
  }
`

export const NoJobsFound = styled.div`
  width: 100%;
  padding: 4px 16px 8px 16px;
  display: flex;
  gap: 8px;
`

export const FilterJobsByCreator = styled.div<{ $activeBorderOffset: number }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.85rem 0.85rem 0.85rem 0.85rem;
  &:before {
    content: '';
    position: absolute;
    bottom: 6px;
    left: ${({ $activeBorderOffset }) => $activeBorderOffset === 0 ? `calc(0.85rem + ${$activeBorderOffset}%)` : `${$activeBorderOffset}%`};
    width: calc(50% - 0.85rem);
    height: 2px;
    transition: all 0.15s cubic-bezier(0.77,0,0.18,1);
    background-color: ${({ theme }) => theme.colors.tintFg};
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
  }
`

export const FilterJobsByCreatorButton = styled.button`
  all: unset;
  position: relative;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights['500']};
  color: ${({ theme }) => theme.colors.fgPrimary};
`
