import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryKeys } from 'src/libs/query-keys'
import { isNil } from 'lodash'
import { addCandidateJobsToSequence, CandidateJobStage, getActiveCandidateSequences } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Icons } from 'src/components/primitives/icon'
import { useSetAtom } from 'jotai'
import { notifyAtom } from 'src/stores/notifications'

interface Res {
  addCandidateToSequence: (candidateJobIds: string[]) => void
}

interface UseAddCandidateToSequenceArgs {
  jobId?: string
}

export const useAddCandidateToSequence = ({ jobId }: UseAddCandidateToSequenceArgs = {}): Res => {
  const queryClient = useQueryClient()
  const notify = useSetAtom(notifyAtom)
  const { jobId: paramsJobId, jobSearchRefinementId } = useParams()

  const jobIdToUse = jobId ?? paramsJobId

  if (isNil(jobIdToUse)) {
    throw new Error('Missing jobId')
  }

  const mutation = useMutation({
    mutationFn: async (candidateJobIds: string[]) => {
      return await addCandidateJobsToSequence(jobIdToUse, candidateJobIds)
    },
    onMutate: (candidateJobIds: string[]) => {
      if (!isNil(jobSearchRefinementId)) {
        queryClient.setQueriesData<CandidateJobExpanded[]>({
          queryKey: [queryKeys.candidateJobs, jobIdToUse, {
            stage: CandidateJobStage.SOURCED,
            jobSearchRefinementId,
            source: undefined,
            favorite: false
          }]
        }, (old) => {
          if (isNil(old)) {
            return old
          }
          const candidateJobIdsSet = new Set(candidateJobIds)
          const filteredCandidateJobs = old.filter((candidateJob) => !candidateJobIdsSet.has(candidateJob.id))
          return filteredCandidateJobs
        })
      }
      queryClient.setQueriesData<CandidateJobExpanded[]>({ queryKey: [queryKeys.candidateJobs, jobIdToUse] }, (old) => {
        if (isNil(old)) {
          return old
        }

        return old.map((candidateJob) => {
          if (candidateJobIds.includes(candidateJob.id)) {
            return { ...candidateJob, stage: CandidateJobStage.PROSPECTING }
          }
          return candidateJob
        })
      })
    },
    onError: async (error, candidateJobIds: string[]) => {
      const activeCandidateSequences = await getActiveCandidateSequences(jobIdToUse, candidateJobIds)
      notify({
        type: 'failed-adding-candidates',
        variant: 'warning',
        position: 'bottom-right',
        icon: Icons.alertTriangle,
        payload: activeCandidateSequences
      })
      console.error('Error adding candidate to sequence', error.message)
    },
    onSuccess: async (data) => {
      notify({
        type: 'candidate-added',
        variant: 'neutral',
        payload: data
      })
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobIdToUse]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobIdToUse]
      })
    }
  })

  const addCandidateToSequence = (candidateJobIds: string[]): void => {
    mutation.mutate(candidateJobIds)
  }

  return { addCandidateToSequence }
}
