import { Button, Flex } from 'src/components/primitives'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { BooleanCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaSelection } from './criteria-selection'
import { RemoveCriteria } from './remove-criteria'
import { optionalSwitchItems } from './appendix-menu-items'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icon, Icons } from 'src/components/primitives/icon'

interface BooleanRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: BooleanCriteria
  onUpdate: (criteriaKey: CriteriaKey, criteriaValue: BooleanCriteria) => void
  onRemove: () => void
  disabled: boolean
}

export const BooleanRow = ({ criteriaKey, criteriaValue, onUpdate, onRemove, disabled }: BooleanRowProps): JSX.Element => {
  if (criteriaKey === CriteriaKey.CURRENTLY_EMPLOYED) {
    return (
      <Flex $gap={8} $align='center'>
        <CriteriaSelection
          value={criteriaValue.optional ?? true}
          onUpdate={(key: string, value: boolean) => {
            onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
          }}
          disabled={disabled}
        />
        <Flex $align='center' $flex='1 1 0'>
          <CriteriaSelection
            value={criteriaValue.value}
            onUpdate={(key: string, value: boolean) => {
              onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
            }}
            disabled={disabled}
            type='value'
          />
        </Flex>
        <RemoveCriteria onRemove={onRemove} disabled={disabled} />
      </Flex>
    )
  } else if (criteriaKey === CriteriaKey.HAS_VISA_SPONSORSHIP) {
    const TRUE_LABEL = 'Requires a visa sponsorship'
    const FALSE_LABEL = 'Doesn’t require a visa sponsorship'
    const items = [
      optionalSwitchItems({
        value: criteriaValue.optional ?? true,
        onUpdate: (value) => {
          onUpdate(criteriaKey, { ...criteriaValue, optional: value })
        }
      }), {
        id: 'optional-separator',
        title: 'Optional Separator',
        type: 'separator'
      }, {
        id: 'no',
        title: FALSE_LABEL,
        variant: !criteriaValue.value ? 'tint' as const : 'neutral' as const,
        trailingIcon: !criteriaValue.value ? Icons.check : undefined,
        onSelect: () => {
          onUpdate(criteriaKey, { ...criteriaValue, value: false })
        }
      }, {
        id: 'yes',
        title: TRUE_LABEL,
        variant: criteriaValue.value ? 'tint' as const : 'neutral' as const,
        trailingIcon: criteriaValue.value ? Icons.check : undefined,
        onSelect: () => {
          onUpdate(criteriaKey, { ...criteriaValue, value: true })
        }
      }
    ]
    return (
      <Flex $gap={8} $align='center'>
        <Dropdown
          size='small'
          $minWidth='230px'
          disabled={disabled}
          trigger={
            <Button
              nested
              $variant='outline'
              $minWidth='72px'
              $height={32}
              $fontSize={12}
              $fontWeight={400}
              trailingIcon={Icons.chevronDownThin}
              $align='space-between'
              disabled={disabled}
              leadingIcon={!criteriaValue.optional
                ? <Icon name={Icons.pin} size={12} color='tintBg' />
                : undefined
              }
            >
              {criteriaValue.value ? TRUE_LABEL : FALSE_LABEL}
            </Button>
          }
          items={items}
        />
      </Flex>
    )
  }
  return (
    <Flex $gap={8} $align='center'>
      <Flex $align='center' $flex='1 1 0'>
        <CriteriaSelection
          value={criteriaValue.optional ?? true}
          onUpdate={(key: string, value: boolean) => {
            onUpdate(criteriaKey, { ...criteriaValue, [key]: value })
          }}
          disabled={disabled}
        />
      </Flex>
      <RemoveCriteria onRemove={onRemove} disabled={disabled} />
    </Flex>
  )
}
