import { Flex } from 'src/components/primitives/flex'
import { SEO } from 'src/components/primitives/seo'
import { Grid } from 'src/components/primitives/grid'
import { useReports } from 'src/hooks/queries/use-reports'
import { OverviewChart } from 'src/components/charts/built/communication-insights/overview/overview'
import { OutreachRepliesChart } from 'src/components/charts/built/communication-insights/outreach-replies-chart'
import { CommunicationFunnelChart } from 'src/components/charts/built/communication-insights/communication-funnel-chart'
import { OutreachConversionChart } from 'src/components/charts/built/communication-insights/outreach-conversion-chart'
import { ChannelRepliesChart } from 'src/components/charts/built/communication-insights/channel-replies-chart'
import { SequenceStepActivityChart } from 'src/components/charts/built/communication-insights/sequence-step-activity-chart'
import { MessagesSentChart } from 'src/components/charts/built/communication-insights/messages-sent-chart'
import { EmailActivitiesChart } from 'src/components/charts/built/communication-insights/email-activities-chart'
import { EmailsOpenedByTimeSentChart } from 'src/components/charts/built/communication-insights/emails-opened-by-time-sent-chart'
import { RepliesByTimeReceivedChart } from 'src/components/charts/built/communication-insights/replies-by-time-received-chart'
import { TopSendersChart } from 'src/components/charts/built/communication-insights/top-senders-chart'
import { useQueryParams } from 'src/hooks/use-query-params'
import { ReportType, TimeFrame } from 'src/libs/api/backend/reports_types'
import { When } from 'src/components/blocks/when'
import { isNil } from 'lodash'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton/loading-skeleton'
import { PositionActivityChart } from 'src/components/charts/built/communication-insights/position-activity-chart'
import { ReportsPageHeader } from 'src/components/blocks/reports-page-header/reports-page-header'

const ReportsCommunicationsInsightsPage = (): JSX.Element => {
  const { getParam } = useQueryParams()
  const currentTimeFrame = getParam('timeFrame')

  const { isLoading, data } = useReports({
    type: ReportType.COMMUNICATION_INSIGHTS,
    timeFrame: currentTimeFrame as TimeFrame ?? TimeFrame.LAST_30_DAYS
  })

  return (
    <>
      <SEO title="Communications Insights Reports" />
      <ReportsPageHeader reportType={ReportType.COMMUNICATION_INSIGHTS} />
      <When condition={isLoading}>
        <LoadingSkeleton $variant="Reports" />
      </When>
      <When condition={!isLoading && !isNil(data)}>
        <Flex $direction="column" $gap={20}>
          <OverviewChart
            data={{
              // sent: data?.overview?.sent ?? 0,
              total: data?.overview?.total ?? 0,
              openedRate: data?.overview?.openedRate ?? 0,
              responded: data?.overview?.responded ?? 0,
              interested: data?.overview?.interested ?? 0,
              scheduled: data?.overview?.scheduled ?? 0
            }}
          />
          <Grid $columns={2} $columnGap={20} $width="100%">
            <CommunicationFunnelChart data={data?.communicationFunnel ?? { sent: 0, opened: 0, replied: 0, interested: 0, scheduled: 0 }} />
            <OutreachConversionChart data={data?.outreachConversions ?? []} />
          </Grid>
          <Grid $columns={2} $columnGap={20} $width="100%">
            <OutreachRepliesChart data={data?.outreachReplies ?? []} />
            <ChannelRepliesChart
              data={data?.channelsReplies ?? { emailReplies: 0, linkedinReplies: 0 }}
            />
          </Grid>
          <SequenceStepActivityChart data={data?.sequenceStepActivities ?? []} />
          <PositionActivityChart data={data?.positionActivities ?? []} />
          <TopSendersChart data={data?.topSenders ?? []} />
          <MessagesSentChart data={data?.messagesSent ?? []} />
          <EmailActivitiesChart data={data?.emailActivities ?? []} />
          <EmailsOpenedByTimeSentChart
            data={
              data?.emailsOpenedByTimeSent ?? {
                dataPoints: [],
                longestStreaks: [],
                timezone: 'UTC'
              }
            }
          />
          <RepliesByTimeReceivedChart
            data={
              data?.repliesByTimeReceived ?? {
                dataPoints: [],
                longestStreaks: [],
                timezone: 'UTC'
              }
            }
          />
          <div />
        </Flex>
      </When>
    </>
  )
}

export default ReportsCommunicationsInsightsPage
