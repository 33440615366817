import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { isNil } from 'lodash'
import { sendCandidateSequenceStepMessageReview } from 'src/libs/api/backend/candidate_sequence_step_message_review'
import type { ManualQueueQueryResult } from 'src/libs/api/backend/manual_queue'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  jobId: string
  candidateSequenceStepMessageReviewId: string
  subject: string
  body: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  sendCandidateSequenceStepMessage: (args: Args) => void
}

export const useSendCandidateSequenceStepMessage = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, candidateSequenceStepMessageReviewId, subject, body }: Args) => {
      return await sendCandidateSequenceStepMessageReview(jobId, candidateSequenceStepMessageReviewId, subject, body)
    },
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while sending message to candidate: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (cssmr, variables: Args) => {
      // update cache directly to remove ManualQueueItem from ReadyToSendPage queue
      queryClient.setQueriesData<ManualQueueQueryResult>({ queryKey: [queryKeys.manualQueue, variables.jobId] }, (oldManualQueueItems) => {
        if (isNil(oldManualQueueItems)) {
          return oldManualQueueItems
        }

        const { [cssmr.id]: _, ...newData } = oldManualQueueItems
        return newData
      })

      notifySuccess({
        message: 'Successfully sent message to candidate'
      })
      if (variables.onSuccess) {
        variables.onSuccess()
      }
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, variables.jobId]
      })
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, variables.jobId]
      })
    }
  })

  const sendCandidateSequenceStepMessage = (args: Args): void => {
    mutation.mutate(args)
  }

  return { sendCandidateSequenceStepMessage }
}
