import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { refreshManualQueueItems } from 'src/libs/api/backend/manual_queue'
import { queryKeys } from 'src/libs/query-keys'
import { notifyErrorAtom, notifySuccessAtom } from 'src/stores/notifications'

interface Args {
  jobId: string
  onSuccess?: () => void
  onError?: () => void
}

interface Res {
  refreshManualEmailCopy: (args: Args) => void
}

export const useRefreshManualEmailCopy = (): Res => {
  const queryClient = useQueryClient()

  const notifyError = useSetAtom(notifyErrorAtom)
  const notifySuccess = useSetAtom(notifySuccessAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId }: Args): Promise<void> => {
      await refreshManualQueueItems({
        jobId
      })
    },
    onError: (err, variables) => {
      console.error(err)
      notifyError({
        message: `An error occurred while refreshing manual email content: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews]
      })

      notifySuccess({
        message: 'Successfully started refreshing manual emails'
      })

      if (variables.onSuccess) {
        variables.onSuccess()
      }
    }
  })

  const refreshManualEmailCopy = (args: Args): void => {
    mutation.mutate(args)
  }

  return { refreshManualEmailCopy }
}
