import { Caption, Paragraph } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import * as S from './candidate-experiences-list.styled'
import { Flex } from 'src/components/primitives/flex'
import { formatLatestFundingStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateExpanded, CandidateExperience, CandidateExperienceGroupedByCompany } from 'src/libs/api/backend/candidate_jobs'
import { groupBy } from 'lodash'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { Fragment, useCallback, useRef, useState, useEffect } from 'react'
import { When } from '../when'
import { Button } from 'src/components/primitives/button'
import { CompanyPreferenceType } from 'src/libs/api/backend/company_preferences'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { useSession } from 'src/hooks/use-session'
import { useParams } from 'react-router-dom'
import { useDeleteCompanyPreference } from 'src/hooks/mutations/use-delete-company-preference'
import { useRateCompanyPreference } from 'src/hooks/mutations/use-rate-company-preference'
import { useCompaniesPreferences } from 'src/hooks/use-companies-preferences'
import { Icon } from 'src/components/primitives/icon'
import { titleCase } from 'src/libs/string'
import { AvatarsList } from '../avatars-list'
import { Tooltip } from 'src/components/primitives/tooltip'
import { useCurrentDepartment } from 'src/hooks/queries/use-current-department'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { Badge } from 'src/components/primitives/badge'
import { CandidateDetailsSectionHeading } from '../candidate-details/candidate-details'
import { getTimespan } from 'src/libs/time'

interface ExperienceProps {
  idOfFirstTimelineEntry?: string
  experiences: CandidateExperience[]
}

const Experiences = ({ experiences, idOfFirstTimelineEntry }: ExperienceProps): JSX.Element => {
  const { userHasViewerRole, featureFlags } = useSession()
  const [isExpanded, setIsExpanded] = useState(false)
  const [cardWidth, setCardWidth] = useState(0)
  const companyInformation = experiences[0].companyInformation
  const logoUrl = experiences[0].logoUrl
  const { department } = useCurrentDepartment()
  const { jobId } = useParams()
  const { setRateCompanyPreference } = useRateCompanyPreference()
  const { setDeleteCompanyPreference } = useDeleteCompanyPreference()
  const { companyPreferencesByLinkedin } = useCompaniesPreferences()
  const companyPreferenceType = companyInformation?.linkedin ? companyPreferencesByLinkedin[companyInformation?.linkedin]?.type : undefined

  const experienceCardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateWidth = (): void => {
      if (experienceCardRef.current) {
        setCardWidth(experienceCardRef.current.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => { window.removeEventListener('resize', updateWidth) }
  }, [])

  const hasOverlappingEmployees = experiences[0].overlappingClientCompanyEmployees && experiences[0].overlappingClientCompanyEmployees.length > 0
  const overlappingEmployees = experiences[0].overlappingClientCompanyEmployees ?? []

  const fundingStage = formatLatestFundingStage(companyInformation?.latestFundingStage)

  const rateCompany = useCallback((type: CompanyPreferenceType) => {
    if (companyInformation?.linkedin && jobId) {
      const preferenceId = companyPreferencesByLinkedin[companyInformation?.linkedin]?.id
      if (companyPreferenceType === type && preferenceId) {
        setDeleteCompanyPreference({ id: preferenceId })
      } else {
        setRateCompanyPreference({
          id: preferenceId,
          jobId,
          type,
          name: companyInformation?.name ?? '',
          linkedin: companyInformation?.linkedin
        })
      }
    }
  }, [
    companyInformation?.name,
    companyInformation?.linkedin,
    companyPreferencesByLinkedin,
    jobId,
    setDeleteCompanyPreference,
    setRateCompanyPreference,
    companyPreferenceType
  ])

  const preferenceButtonIsDisabled = !companyInformation || !jobId || !companyInformation?.linkedin || companyInformation?.linkedin?.startsWith('/school')

  const groupedExperienceTags = Array.from(
    new Set(
      experiences.flatMap(experience => experience.experienceTags || [])
    )
  )

  if (!experiences || experiences.length === 0) {
    return <></>
  }

  return (
    <S.ExperienceCard id={idOfFirstTimelineEntry} ref={experienceCardRef}>
      <DivAsButton ariaLabel="Expand" onClick={() => { setIsExpanded(!isExpanded) }}>
        <S.CardHeader data-name="CardHeader" $isExpanded={isExpanded}>
          <S.CardHeaderContent data-name="CardHeaderContent">
          <Avatar
            $size={40}
            $shape="soft"
            $type="logo"
            company={{ name: companyInformation?.name ?? experiences[0].company, url: companyInformation?.website, logoUrl }}
            fallbackIcon={{ name: 'building-2' }}
          />
            <S.CompanyInformation>
              <S.CompanyDetails>
                <Caption size="SM" $color="fgPrimary" $whiteSpace="nowrap">{companyInformation?.name ?? experiences[0].company}</Caption>
                {
                  featureFlags?.includes(FeatureFlags.SKILL_TAGS) && groupedExperienceTags?.length > 0 && (
                    <S.SkillAndExperienceTags>
                      {groupedExperienceTags?.slice(0, 1).map((tag) => (
                        <Fragment key={tag}>
                          <Badge $height={16} $variant="aiSolidFg" $transform="none">
                            {tag}
                          </Badge>
                        </Fragment>
                      ))}
                    </S.SkillAndExperienceTags>
                  )
                }
                {hasOverlappingEmployees && (
                  <S.OverlappingEmployees>
                    <S.OverlappingEmployeesAvatars>
                      <AvatarsList
                        avatars={overlappingEmployees.map((employee) => ({
                          id: employee.linkedin,
                          name: employee.name ?? '',
                          profilePhotoUrl: employee.profilePhotoUrl,
                          href: employee.linkedin
                        }))}
                        avatarDisplay={{ showCount: true, count: 3 }}
                        $size={20}
                        $overlap={2}
                        $border
                      />
                    </S.OverlappingEmployeesAvatars>
                    <Tooltip
                      trigger={
                        <span>worked here</span>
                      }
                    >
                      {department?.name ? `${department.name} employees ` : 'Employees from this department '} worked here
                    </Tooltip>
                  </S.OverlappingEmployees>
                )}
                <S.CompanyLinks>
                  {companyInformation?.website && (
                    <Button
                      href={`https://${companyInformation?.website}`}
                      ariaLabel="Open website"
                      $variant="ghost"
                      $colorTheme="muted"
                      $fontSize={12}
                      $width={20}
                      $height={20}
                      leadingIcon="globe"
                    />
                  )}
                  {companyInformation?.linkedin && (
                    <Button
                      href={`https://linkedin.com${companyInformation?.linkedin}`}
                      ariaLabel="Open LinkedIn profile"
                      $variant="ghost"
                      $colorTheme="muted"
                      $fontSize={12}
                      $width={20}
                      $height={20}
                      leadingIcon="linkedin"
                    />
                  )}
                </S.CompanyLinks>
              </S.CompanyDetails>
              <S.CompanyQuickFacts>
                {companyInformation?.gptIndustry && (
                  <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                   {companyInformation?.gptIndustry}
                  </Paragraph>
                )}
                {companyInformation?.founded && cardWidth > 680 && (
                  <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                    <Icon name="star" size={10} color="fgSecondary" /> Founded {companyInformation.founded}
                  </Paragraph>
                )}
                {companyInformation?.headCount && (
                  <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                    <Icon name="users" size={12} color="fgSecondary" /> {companyInformation?.headCount.replace('-', ' - ')} Employees
                  </Paragraph>
                )}
                {experiences[0].location && (
                  <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                    <Icon name="building" size={12} color="fgSecondary" /> {titleCase(experiences[0].location)}
                  </Paragraph>
                )}
                {fundingStage && (
                  <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
                    <Icon name="trending-up" size={12} color="fgSecondary" /> {fundingStage}
                  </Paragraph>
                )}
              </S.CompanyQuickFacts>
            </S.CompanyInformation>
          </S.CardHeaderContent>
        </S.CardHeader>
      </DivAsButton>
      <When condition={isExpanded}>
        <S.CardHeaderExpandedContent>
          <Paragraph size="XS" $color="fgPrimary" $lineHeight={1.33}>
            {companyInformation?.gptSummary ?? companyInformation?.headline}
          </Paragraph>
          <S.CompanyRatingActions>
            <Button
              $variant="flat"
              $colorTheme={
                companyPreferenceType === CompanyPreferenceType.LIKED
                  ? 'positive'
                  : 'muted'
              }
              $height={24}
              leadingIcon="thumbs-up"
              $fontSize={12}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                rateCompany(CompanyPreferenceType.LIKED)
              }}
              disabled={preferenceButtonIsDisabled || userHasViewerRole}
              tooltip={{
                text: userHasViewerRole ? viewerRoleNotice('rate this company') : (preferenceButtonIsDisabled ? 'Not enough data' : undefined)
              }}
            >
              More from this company
            </Button>
            <Button
              $variant="flat"
              $colorTheme={
                companyPreferenceType === CompanyPreferenceType.DISLIKED
                  ? 'negative'
                  : 'muted'
              }
              $height={24}
              leadingIcon="thumbs-down"
              $fontSize={12}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                rateCompany(CompanyPreferenceType.DISLIKED)
              }}
              disabled={preferenceButtonIsDisabled || userHasViewerRole}
              tooltip={{
                text: preferenceButtonIsDisabled ? 'Not enough data' : undefined
              }}
            >
              Fewer from this company
            </Button>
          </S.CompanyRatingActions>
        </S.CardHeaderExpandedContent>
      </When>
      <S.ExperienceCardContent>
        {experiences.map(experience => (
          <S.ExperiencePosition data-name="ExperiencePosition">
            <S.ExperiencePositionHeader data-name="ExperiencePositionHeader">
              <Flex $align="center">
                <Caption size="XS" $color="fgPrimary">{experience.title}</Caption>
              </Flex>
              <Paragraph size="XS" $color="fgSecondary">
                {experience.startDate ? getTimespan(experience.startDate, experience.endDate) : ''}
                {(experience.startDate && experience.location) ? ' · ' : ''}
                {experience.location ? titleCase(experience.location) : ''}
              </Paragraph>
            </S.ExperiencePositionHeader>
            <S.ExperiencePositionDescription data-name="ExperiencePositionDescription">
              <Paragraph size="XS" $color="fgPrimary" $lineHeight={1.33}>
                {experience.candidateSummary}
              </Paragraph>
            </S.ExperiencePositionDescription>
          </S.ExperiencePosition>
        ))}
      </S.ExperienceCardContent>
    </S.ExperienceCard>
  )
}

export interface CandidateExperiencesAndEducationListStyleProps {
  $padding?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
}

interface CandidateExperiencesListProps extends CandidateExperiencesAndEducationListStyleProps {
  experiences: CandidateExpanded['experiences']
  idOfFirstTimelineEntry?: string
}

export const CandidateExperiencesList = ({ experiences, idOfFirstTimelineEntry, $padding = { top: 24, bottom: 0, left: 24, right: 24 } }: CandidateExperiencesListProps): JSX.Element => {
  const yearsToExpand = 8
  const minInitialEntries = 4
  const currentDate = new Date()
  const cutoffDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - yearsToExpand))

  let cappedEntries = experiences.filter(entry => {
    const startDate = entry.startDate
    const endDate = entry.endDate

    if (!startDate) {
      return false
    }
    return new Date(startDate) >= cutoffDate || (endDate && new Date(endDate) >= cutoffDate)
  })

  if (cappedEntries.length < minInitialEntries) {
    cappedEntries = experiences.slice(0, minInitialEntries)
  }

  const remainingEntries = experiences.length - cappedEntries.length
  const [displayedItems, setDisplayedItems] = useState(cappedEntries)
  const [showMore, setShowMore] = useState(remainingEntries > 1)

  const experiencesGroupedByCompany: CandidateExperienceGroupedByCompany = groupBy(
    displayedItems,
    (experience) => experience.grouping
  )

  return (
    <S.Wrapper $padding={$padding}>
      <CandidateDetailsSectionHeading
        heading="Experience"
        // context={
        //   <S.HeadingSummary>
        //     <Paragraph size="2XS" $color="fgSecondary">5yr 2mo</Paragraph>
        //     <Paragraph size="2XS" $color="fgSecondary">&middot;</Paragraph>
        //     <Paragraph size="2XS" $color="fgSecondary">Average Tenure 2yr 6mo</Paragraph>
        //   </S.HeadingSummary>
        // }
      />
      {Object.values(experiencesGroupedByCompany).map((experiences, index) => (
        <Experiences
          key={experiences[0].grouping}
          experiences={experiences}
          idOfFirstTimelineEntry={index === 0 ? idOfFirstTimelineEntry : undefined}
        />
      ))}
      <When condition={showMore && experiences.length - cappedEntries.length > 0}>
        <S.MoreButton>
          <Button
            $variant="outline"
            $colorTheme="muted"
            $height={24}
            $width="auto"
            $fontSize={12}
            onClick={() => {
              setDisplayedItems(experiences)
              setShowMore(false)
            }}
          >
            Show {experiences.length - cappedEntries.length} older positions
          </Button>
        </S.MoreButton>
      </When>
    </S.Wrapper>
  )
}
