import { memo } from 'react'
import { Flex } from 'src/components/primitives/flex'
import { When } from '../when'
// import { MergeAttachmentType } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import {
  // CandidateEducationTimeline,
  CandidateEnhancedQuickOverview,
  CandidateEnhancedSummary,
  // CandidateExperienceTimeline,
  CandidateProfile
  // CandidateQuickOverview,
  // CandidateSummary
} from '../candidate-details'
import * as S from './candidate-details-card.styled'
import { ViewMode } from 'src/constants'
// import { IfElse } from '../if-else'
// import { Tabs } from 'src/components/primitives/tabs'
// import { Icon, Icons } from 'src/components/primitives/icon'
// import { CandidateResume } from '../candidate-resume'
import { usePrintView } from 'src/hooks/use-print-view'
import { CandidateDetailsActions } from './candidate-details-actions'
import type { CandidateDetailsActionType } from './candidate-details-actions'
import { CandidateExperiencesList } from '../candidate-experiences-list/candidate-experiences-list'
import { CandidateEducationList } from '../candidate-education-list'

type SourcingType = 'applicants' | 'sourcing'

export interface CandidateDetailProps {
  candidateJob: CandidateJobExpanded
  viewMode?: ViewMode
  type?: SourcingType
  actionType?: CandidateDetailsActionType
  idOfFirstTimelineEntry?: string
}

export type CollapsedVariant = 'loading' | 'undo'

// enum ApplicantTabs {
//   EXPERIENCE = 'experience',
//   RESUME = 'resume'
// }

export const CandidateDetailsCard = memo(({
  candidateJob,
  actionType,
  viewMode = ViewMode.DEFAULT,
  // type = 'sourcing',
  idOfFirstTimelineEntry
}: CandidateDetailProps): JSX.Element => {
  const isPrintView = usePrintView()
  // const [selectedTab, setSelectedTab] = useState(ApplicantTabs.EXPERIENCE)
  const { candidate } = candidateJob

  // const candidateExperiences = useMemo(() => {
  //   return (
  //     <>
  //       <CandidateExperienceTimeline
  //         experiences={candidate?.experiences}
  //         $padding={isPrintView ? { left: 0, right: 2 } : undefined}
  //         isPrintView={isPrintView}
  //         idOfFirstTimelineEntry={idOfFirstTimelineEntry}
  //       />
  //       <CandidateEducationTimeline
  //         educations={candidate?.educations}
  //         $padding={isPrintView ? { left: 0, right: 2 } : undefined}
  //       />
  //     </>
  //   )
  // }, [candidate?.educations, candidate?.experiences, isPrintView, idOfFirstTimelineEntry])

  // const resumeUrl = useMemo(() => {
  //   return candidateJob?.candidate?.attachments?.find(attachment => attachment.mergeAttachmentType === MergeAttachmentType.RESUME)?.url
  // }, [candidateJob?.candidate?.attachments])

  return (
    <S.CandidateDetailsCard
      $isPrintView={isPrintView}
    >
      <When condition={viewMode === ViewMode.DEFAULT}>
        <>
          <S.Header $isPrintView={isPrintView}>
            <CandidateProfile
              candidate={candidate}
              subheading={candidate.location}
              avatarSize={80}
              includeSocialLinks={!isPrintView}
              socialLinksIconSize={14}
            />
            <When condition={Boolean(actionType) && !isPrintView}>
              <Flex $gap={12} $align="center" $justify="flex-end" $width="auto">
                <CandidateDetailsActions
                  actionType={actionType}
                  candidateJob={candidateJob}
                />
              </Flex>
            </When>
          </S.Header>
          <S.Content $isPrintView={isPrintView}>
            <CandidateEnhancedSummary
              candidateJob={candidateJob}
              $padding={isPrintView ? { top: 6, right: 0, bottom: 0, left: 0 } : { bottom: 0 }}
            />
            <CandidateExperiencesList
              experiences={candidateJob.candidate.experiences}
              idOfFirstTimelineEntry={idOfFirstTimelineEntry}
            />
            <CandidateEducationList educations={candidateJob.candidate.educations} />
          </S.Content>
        </>
      </When>

      <When condition={viewMode === ViewMode.COMPACT}>
        <>
          <S.Header>
            <CandidateProfile
              candidate={candidate}
              subheading={candidate.location}
              avatarSize={64}
              avatarShape="soft"
              includeSocialLinks
              socialLinksPosition='bottom'
            />
            <When condition={Boolean(actionType)}>
              <S.HeaderActions>
                <CandidateDetailsActions
                  actionType={actionType}
                  candidateJob={candidateJob}
                />
              </S.HeaderActions>
            </When>
          </S.Header>
          <S.Content>
            {/* <CandidateQuickOverview candidateJob={candidateJob} /> */}
            <CandidateEnhancedSummary
              candidateJob={candidateJob}
              $padding={isPrintView ? { top: 6, right: 0, bottom: 0, left: 0 } : { bottom: 0 }}
            />
            <CandidateEnhancedQuickOverview candidateJob={candidateJob} />
          </S.Content>
        </>
      </When>
    </S.CandidateDetailsCard>
  )
})
