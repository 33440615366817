import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import type { Criteria, CustomRequirementCriteria } from 'src/libs/api/backend/candidate_search'
import { restructureJobSearchRefinementCriteria } from 'src/libs/api/backend/jobs'
import type { Job, RestructureJobSearchRefinementCriteria } from 'src/libs/api/backend/jobs'
import { notifyErrorAtom } from 'src/stores/notifications'

interface Args {
  jobId: Job['id']
  customRequirements: CustomRequirementCriteria[]
  criteria: Criteria
  onSuccess?: (structuredCriteria: RestructureJobSearchRefinementCriteria) => void
  onError?: () => void
}

interface Res {
  createStructuredCriteria: (args: Args) => void
}

export const useCreateStructuredCriteria = (): Res => {
  const notifyError = useSetAtom(notifyErrorAtom)

  const mutation = useMutation({
    mutationFn: async ({ jobId, customRequirements, criteria }: Args) => {
      return await restructureJobSearchRefinementCriteria({ jobId, customRequirements, criteria })
    },
    onError: (err, variables) => {
      notifyError({
        message: `An error occurred when attemping to restructure your search criteria: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: (data, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess(data)
      }
    }
  })

  const createStructuredCriteria = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createStructuredCriteria }
}
