import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import * as S from './table.styled'
import { useVirtualizer } from '@tanstack/react-virtual'
import { isNil } from 'lodash'
import { DEFAULT_COLLAPSED_WIDTH } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { TableHeader } from './table-header'
import type { TableProps } from './type'
import { addSelectedRowsAtom, selectedRowsAtom, tableStateAtom, toggleSelectedRowAtom } from 'src/stores/table'
import { useAtomValue, useSetAtom } from 'jotai'
import { TableBody } from './table-body'

export const Table = <T extends { id: string }>({
  tableData,
  columns,
  setColumns,
  onRowClick,
  selectionActions,
  emptyState,
  stickyHeader = false,
  visibleRowsCount
}: TableProps<T>): JSX.Element => {
  const [rangeSelectionStart, setRangeSelectionStart] = useState<number | null>(null)
  const [tableRows, setTableRows] = useState<T[]>([])
  const setTableState = useSetAtom(tableStateAtom)
  const selectedRows = useAtomValue(selectedRowsAtom)
  const addSelectedRows = useSetAtom(addSelectedRowsAtom)
  const toggleSelectedRow = useSetAtom(toggleSelectedRowAtom)

  useEffect(() => {
    setTableRows(tableData)
  }, [tableData])

  useEffect(() => {
    return () => {
      // Reset state when unmount
      setTableState({
        sort: undefined,
        selectedRows: new Set<string>()
      })
    }
  }, [setTableState])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  // To avoid from accidentally selecting text on the site,
  // we want to disable user-select while there is a rowSelection active.
  useEffect(() => {
    const hasSelectedRows = selectedRows.size >= 1
    if (hasSelectedRows) {
      document.body.style.userSelect = 'none'
    } else {
      document.body.style.userSelect = ''
    }

    return () => {
      document.body.style.userSelect = ''
    }
  }, [selectedRows])

  const gridColumnSizes = useMemo((): string => {
    return columns.reduce((acc: string[], column: TableColumnDef<T>) => {
      if (column.collapsed) {
        return [...acc, column.collapsedSize ?? DEFAULT_COLLAPSED_WIDTH]
      }
      return [...acc, column.size ?? 'minmax(min-content, 1fr)']
    }, []).join(' ')
  }, [columns])

  const rowVirtualizer = useVirtualizer({
    count: !isNil(visibleRowsCount) ? Math.min(visibleRowsCount, tableRows.length) : tableRows.length,
    estimateSize: () => 54,
    getScrollElement: () => tableContainerRef.current,
    // measureElement:
    //   typeof window !== 'undefined' &&
    //   navigator.userAgent.includes('Firefox')
    //     ? element => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: 5,
    getItemKey: (index) => tableRows[index].id
  })

  const items = rowVirtualizer.getVirtualItems()

  const [beforeBuffer, afterBuffer] = useMemo(() => {
    if (items.length === 0) {
      return [0, 0]
    }
    return [
      items[0].start - rowVirtualizer.options.scrollMargin,
      rowVirtualizer.getTotalSize() - items[items.length - 1].end
    ]
  }, [items, rowVirtualizer])

  const onRowSelectionClicked = useCallback((event: React.MouseEvent<HTMLInputElement>, rowIndex: number) => {
    const currentRowId = tableRows[rowIndex].id
    if (event.shiftKey) {
      if (!isNil(rangeSelectionStart)) {
        const start = Math.min(rangeSelectionStart, rowIndex)
        const end = Math.max(rangeSelectionStart, rowIndex)
        const newSelection: string[] = []
        for (let i = start; i <= end; i++) {
          newSelection.push(tableRows[i].id)
        }
        addSelectedRows(newSelection)
      }
    } else {
      toggleSelectedRow(currentRowId)
      setRangeSelectionStart(rowIndex)
    }
  }, [addSelectedRows, rangeSelectionStart, tableRows, toggleSelectedRow])

  if (tableRows.length === 0) {
    return <>{emptyState}</>
  }

  return (
    <div
      ref={tableContainerRef}
      style={{
        // position: 'relative',
        width: '100%'
      }}
    >
      <S.Table
        style={{
          gridTemplateColumns: gridColumnSizes,
          paddingTop: beforeBuffer,
          paddingBottom: afterBuffer
        }}
      >
        <TableHeader
          columns={columns}
          setTableRows={setTableRows}
          tableRows={tableRows}
          originalTableData={tableData}
          setColumns={setColumns}
          selectionActions={selectionActions}
          stickyHeader={stickyHeader}
        />
        <TableBody
          rowVirtualizer={rowVirtualizer}
          items={items}
          tableRows={tableRows}
          onRowClick={onRowClick}
          selectedRows={selectedRows}
          columns={columns}
          onRowSelectionClicked={onRowSelectionClicked}
        />
      </S.Table>
    </div>
  )
}
