import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Icon, Icons } from 'src/components/primitives/icon'
import * as S from './index.styled'
import { Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { StandaloneSwitch } from '../../standalone-switch'
import { Tooltip } from 'src/components/primitives/tooltip'

export const optionalSwitchItems = ({
  value,
  onUpdate,
  disabled = false
}: {
  value: boolean
  onUpdate: (value: boolean) => void
  disabled?: boolean
}): MenuItemProps => {
  return ({
    id: 'optional-switch',
    title: 'Optional',
    type: 'node',
    isDisabled: disabled,
    content: (
      <S.CriteriaSwitchContainer>
        <Flex $flex='1 1 0' $justify='flex-start' $gap={4} $align='center'>
          <Icon name={Icons.pin} size={12} color={value ? 'fgTertiary' : 'tintBg'} />
          <Caption size='XS' $fontWeight={400}>Required</Caption>
        </Flex>
        <Flex $flex='1 1 0' $justify='flex-end' $gap={6} $align='center'>
          <Tooltip
            trigger={
              <span>
                <Icon name={Icons.info} size={12} color='fgTertiary' />
              </span>
            }
          >
            If Required, this criteria will be used to filter out candidates.
          </Tooltip>
          <StandaloneSwitch
            name='optional-required'
            $variant='ghost'
            disabled={disabled}
            defaultChecked={!value}
            onChange={(checked) => {
              // Logic is reversed because false --> required, true --> optional
              // But we want to show On --> required, Off --> optional
              onUpdate(!checked)
            }}
          />
        </Flex>
      </S.CriteriaSwitchContainer>
    )
  })
}

export const deleteItem = ({
  onUpdate
}: {
  onUpdate: () => void
}): MenuItemProps[] => {
  return ([
    {
      id: 'separator',
      title: 'Separator',
      type: 'separator'
    }, {
      id: 'delete',
      title: 'Remove',
      variant: 'negative',
      onSelect: () => {
        onUpdate()
      }
    }
  ])
}
