import { Banner } from 'src/components/blocks/banner'
import { CalendarSettings } from 'src/components/blocks/calendar-settings'
import { PageHeader } from 'src/components/blocks/page-header'
import { When } from 'src/components/blocks/when'
import { SEO } from 'src/components/primitives/seo'
import { Spacer } from 'src/components/primitives/spacer'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'

const CalendarPreferencesPage = (): JSX.Element => {
  const { userHasViewerRole } = useSession()

  return (
    <>
      <PageHeader heading="Calendar Preferences" />
      <SEO title="Calendar Preferences" />
      <When condition={!!userHasViewerRole}>
        <Banner $variant="warning" icon="alert-triangle">{viewerRoleNotice('make changes to these settings')}</Banner>
        <Spacer $size={24} />
      </When>
      <CalendarSettings />
    </>
  )
}

export default CalendarPreferencesPage
