import { Caption } from 'src/components/primitives/typography'
import * as S from './add-candidate-form.styled'
import { CandidateInfoErrorDisplay } from 'src/libs/api/backend/candidate_jobs'
import type { ValidateCandidatesFromCsv } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { pluralize } from 'src/libs/pluralize'
import { Spacer } from 'src/components/primitives'
import { useMemo } from 'react'

interface CsvSummaryProps {
  validationResult: ValidateCandidatesFromCsv | null
  idInputErrors: string[]
}

export const CsvSummary = ({ validationResult, idInputErrors }: CsvSummaryProps): JSX.Element | null => {
  const validRowsWithRecommendations = useMemo(() => {
    if (!validationResult || validationResult.validRows.length === 0) {
      return []
    }
    return validationResult.validRows.filter((row) => row.recommendations.length > 0)
  }, [validationResult])

  if (isNil(validationResult) && isNil(idInputErrors)) {
    return null
  }
  if (idInputErrors.length > 0) {
    return (
      <>
        <S.ErrorDetails>
          <Caption size="SM" $color="negativeFg">
            {pluralize(idInputErrors.length, 'invalid LinkedIn profile link')}
          </Caption>
          <Caption size="XS" $color="fgPrimary">
            The following are not valid LinkedIn profile links: {idInputErrors.join(', ')}
          </Caption>
        </S.ErrorDetails>
        <Spacer $size={24} />
      </>
    )
  }
  if (validationResult) {
    const { invalidRows, validationError } = validationResult
    if (validationError) {
      return (
        <>
          <S.ErrorDetails>
            <Caption size="XS" $color="negativeFg">
              {validationError}
            </Caption>
          </S.ErrorDetails>
          <Spacer $size={24} />
        </>
      )
    }
    if (invalidRows.length > 0) {
      return (
        <>
          <S.ErrorDetails>
            <Caption size="SM" $color="negativeFg">
              {pluralize(invalidRows.length, 'error')} found on file
            </Caption>
            <S.CsvErrorList>
              {invalidRows.map((row, index) => (
                <li key={index}>
                  {row.errors.map((error) => CandidateInfoErrorDisplay[error]).join(' or ')} for candidate {row.name ? row.name : row.linkedin || row.emails[0]}
                </li>
              ))}
            </S.CsvErrorList>
          </S.ErrorDetails>
          <Spacer $size={24} />
        </>
      )
    }
    if (validRowsWithRecommendations.length > 0) {
      return (
        <>
          <S.RecommendationDetails>
            <Caption size="SM" $color="tintFg">
              {pluralize(validRowsWithRecommendations.length, 'candidate')} found with partial information
            </Caption>
            <Caption size="XS" $fontWeight={400}>
              Consider adding LinkedIn profile links to your CSV before importing candidates to get the full picture of their current and past positions as well as other enhanced data Pin can offer.
            </Caption>
            <Caption size="XS" $fontWeight={400}>
              Candidate profiles might look empty without this information.
            </Caption>
          </S.RecommendationDetails>
          <Spacer $size={24} />
        </>
      )
    }
  }

  return null
}
