import { DIALOG_HEADER_HEIGHT_LG } from 'src/styles/constants'
import styled, { css } from 'styled-components'

export const SequenceEditor = styled.div<{ $usedInDialog?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ $usedInDialog }) => !$usedInDialog && css`
  /* 18px = page top padding */
  /* 28px = page header height */
  /* 1.125rem = gap between header and content */
  /* 8px = page bottom padding */
  height: calc(100vh - 18px - 28px - 1.125rem - 8px);
`}
`

export const Sidebar = styled.div<{ $usedInDialog?: boolean }>`
  position: absolute;
  top: 0;
  width: 30%;
  height: 100%;
  /* background-color: ${({ theme }) => theme.colors.bgPrimary}; */
  padding: 1.5rem 1.5rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  z-index: 2;
  // ${({ $usedInDialog }) => !$usedInDialog && css`
  //   position: fixed;
  //   top: ${DIALOG_HEADER_HEIGHT_LG};
  //   height: calc(100% - ${DIALOG_HEADER_HEIGHT_LG});
  // `}
`

export const SequenceTourArea = styled.div`
  position: absolute;
  top: 0.75rem;
  left: -0.75rem;
  width: 100%;
  height: calc(100% - 7rem);
  pointer-events: none;
  z-index: 2;
`

export const StepSelectors = styled.div<{ $isEditable: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  ${({ $isEditable }) => !$isEditable && css`
    pointer-events: none;
    opacity: 0.75;
  `}
`

export const StepSelectorDragHandle = styled.div<{ $disabled?: boolean }>`
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  position: absolute;
  top: 50%;
  left: -0.85rem;
  z-index: 2;
  opacity: 0;
  transform: translateY(-50%);
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`

export const StepSelector = styled.button<{
  $isGenerating: boolean
  $isCurrentStep: boolean
  $disabled: boolean
}>`
  position: relative;
  width: 100%;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  border-radius: 6px;
  border: ${({ $isGenerating }) => ($isGenerating ? 'dashed 1px' : 'solid 1px')};
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: left 0.2s ease-in-out;
  &:focus {
    outline: none;
    &:before {
      outline: none;
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      border: solid 2px;
      border-radius: 7px;
      border-color: ${({ theme }) => theme.colors.tintBg};
    }
  }
  &:before {
    content: '';
    display: ${({ $isCurrentStep }) => ($isCurrentStep ? 'block' : 'none')};
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: solid 1px;
    border-radius: 7px;
    border-color: ${({ theme }) => theme.colors.tintBg};
  }
  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      bottom: calc(-0.625rem - 1px);
      left: 18px;
      width: 1px;
      height: 0.625rem;
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
  }
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
  @media (hover: hover) {
    &:hover {
      ${StepSelectorDragHandle} {
        opacity: 1;
      }
    }
  }
`

export const StepSelectorIcon = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(1px);
`

export const StepSelectorLine = styled.div`
  width: 100%;
  height: 16px;
`

export const StepSelectorSpinner = styled.div`
  width: 1rem;
  height: 1rem;
`

export const StepSelectorError = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepSelectorErrorIcon = styled.button`
  all: unset;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepSelectorSubject = styled.div`
  width: 100%;
  overflow: hidden;
`

export const StepsSection = styled.div<{ $isEditable: boolean }>`
  position: relative;
  left: 30%;
  width: 70%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  padding: 0 2.5rem 1.5rem 2.5rem;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  ${({ $isEditable }) => !$isEditable && css`
    pointer-events: none;
    opacity: 0.75;
    * {
      pointer-events: none;
    }
  `}
`

export const SequenceStep = styled.div`
  width: 100%;
  /* subtract bottom margin of header */
  height: 100%;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const StepHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  min-height: 2.5rem;
  padding: 0 0.75rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const StepContent = styled.div`
  position: relative;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderOpaque};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  flex-grow: 1;
  overflow: hidden;
`

export const ActionsGroup = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.625rem;
  padding: 0.75rem 0 0 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    width: 1px;
    height: 0.75rem;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
    z-index: 1;
  }
`

export const AddStepButton = styled.div<{ $isEditable: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $isEditable }) => !$isEditable && css`
    pointer-events: none;
    opacity: 0.75;
    * {
      pointer-events: none;
    }
  `}
  button {
    z-index: 2;
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
  }
`

export const OpenPreferencesButton = styled.div<{ $isActive: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ $isActive }) => $isActive && css`
    &:before {
      content: '';
      outline: none;
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      border: solid 1px;
      border-radius: 7px;
      border-color: ${({ theme }) => theme.colors.tintBg};
      pointer-events: none;
      z-index: 2;
    }
  `}
`

export const AutoArchiveStep = styled(StepHeader)`
  width: 100%;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderOpaque};
`

export const Actions = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SequenceInstructions = styled.div``

export const SettingsAndOptionsBlock = styled.div<{ $isEditable: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.75rem 0 0 0;
  justify-content: center;
  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 0.75rem;
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
      z-index: 1;
    }
  }
  ${({ $isEditable }) => !$isEditable && css`
    pointer-events: none;
    opacity: 0.75;
    * {
      pointer-events: none;
    }
  `}
`

export const SettingsAndOptionsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  gap: 0.375rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  justify-content: center;
`

export const SendPreviewEmailInputPopup = styled.div`
  position: absolute;
  bottom: calc(48px + 12px);
  left: 12px;
  width: calc(100% - (12px * 2));
  border: 1px dashed ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  background: ${({ theme }) => theme.backgrounds.aiGradientTranslucent10};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    input {
      width: 100%;
      flex: 1 1 100%;
      height: 32px;
    }
  }
`

export const ManualTaskSettingsCard = styled.div`
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const SequencePreferences = styled.div<{ $usedInDialog: boolean }>`
  position: relative;
  left: 30%;
  width: 70%;
  height: 100%;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  border-bottom-right-radius: 8px;
  ${({ $usedInDialog }) => $usedInDialog && css`
  `}
`

export const SequencePreferencesInner = styled.div<{ $usedInDialog: boolean }>`
  width: 100%;
  padding: 2px 2px 2px 3px;
  height: 100%;
  max-height: 4000px;
  overflow-y: auto;
  scrollbar-width: thin;
  ${({ $usedInDialog }) => $usedInDialog && css`
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  `}
`
