import { useCallback, useEffect, useState } from 'react'
import { StatusView } from '../status-view'
import { ProgressStepsList } from 'src/components/primitives/progress-steps-list'
import { ProgressBar } from 'src/components/primitives/progress-bar'
import { Spacer } from 'src/components/primitives/spacer'
import { OnScreenSurveys } from '../on-screen-surveys'

const TOTAL_TIME = 50 * 1000 // search takes 60 seconds on avg, show 50s to avoid flashing progress

interface RecommendingViewProps {
  lastRequestedAt: Date
}

export const RecommendingView = ({ lastRequestedAt }: RecommendingViewProps): JSX.Element => {
  const [progress, setProgress] = useState(0)
  const updateProgress = useCallback((): void => {
    const elapsedTime = Date.now() - lastRequestedAt.getTime()
    const progressPercentage = Math.min(Math.max((elapsedTime / TOTAL_TIME) * 100, 0), 100)
    setProgress(progressPercentage)
  }, [lastRequestedAt])

  useEffect(() => {
    updateProgress()
    const interval = setInterval(updateProgress, 2000)
    return () => {
      clearInterval(interval)
    }
  }, [lastRequestedAt, updateProgress])

  return (
    <StatusView
      pin="searching"
      animatePin
      $gap={0}
      $contentMaxWidth={2560}
    >
      <ProgressStepsList progress={progress} />
      <Spacer $size={36} />
      <ProgressBar progress={progress} />
      <OnScreenSurveys />
    </StatusView>
  )
}
