import { isEqual, isNil } from 'lodash'
import { CriteriaGroupOrder, CriteriaProperties, CriteriaToGroupMap, DEFAULT_CRITERIA, DemographicsGroup, GroupToCriteriaMap } from 'src/components/blocks/refinement-filter-criteria/constants'
import type { CriteriaGroup } from 'src/components/blocks/refinement-filter-criteria/constants'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria, LocationCriteria, MinMaxCriteria, SchoolCompanyCriteria, CustomRequirementCriteria, JobTitleCriteria, NameOptionalCriteria, StandardCriteria, DegreeMajorPairCriteria, SearchEntityCriteria } from 'src/libs/api/backend/candidate_search'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'
import type { LocationSuggestion, SchoolSuggestion } from 'src/libs/api/backend/typeaheads'

const sanitizeSchoolCompanyCriteria = (schoolsCompanies: SchoolCompanyCriteria[]): SchoolCompanyCriteria[] => {
  return schoolsCompanies.filter((schoolCompany) => !!schoolCompany.name)
}

const sanitizeLocationCriteria = (locations: LocationCriteria[]): LocationCriteria[] => {
  return locations.filter((location) => !!location.city || !!location.state || !!location.metro || !!location.country || !!location.continent).map((location) => {
    return {
      ...location,
      radiusInMiles: location.city ? location.radiusInMiles : null
    }
  })
}

export const deduplicateLocationCriteria = (locations: LocationCriteria[]): LocationCriteria[] => {
  return locations.filter((location, index, self) => (
    index === self.findIndex((t) =>
      t.city === location.city &&
      t.state === location.state &&
      t.metro === location.metro &&
      t.country === location.country &&
      t.continent === location.continent
    )
  ))
}

const sanitizeMinMaxCriteria = (minMax: MinMaxCriteria): MinMaxCriteria | undefined => {
  if (!isNil(minMax.min) && !isNil(minMax.max) && minMax.min > minMax.max) {
    return undefined
  }
  if (isNil(minMax.min) && isNil(minMax.max)) {
    return undefined
  }
  return minMax
}

export const isDuplicatedCriteria = <T>(item: T, itemsList: T[], key: keyof T): boolean => {
  return itemsList.some((listItem) => (listItem[key] as string).toLowerCase().trim() === (item[key] as string).toLowerCase().trim())
}

export const deduplicateJobTitles = (jobTitles: JobTitleCriteria[]): JobTitleCriteria[] => {
  return jobTitles.filter((jobTitle, index, self) => (
    index === self.findIndex((t) => t.name.toLowerCase().trim() === jobTitle.name.toLowerCase().trim())
  ))
}

const sanitizeJobTitles = (jobTitles: JobTitleCriteria[]): JobTitleCriteria[] => {
  return deduplicateJobTitles(jobTitles.filter((jobTitle) => jobTitle.name.length > 0)).map((jobTitle) => {
    return {
      ...jobTitle,
      optional: false // EVERY job title is required
    }
  })
}

const deduplicateNameCriteria = <T extends { name: string }>(items: T[]): T[] => {
  return items.filter((item, index, self) => (
    index === self.findIndex((t) => t.name.toLowerCase().trim() === item.name.toLowerCase().trim())
  ))
}

const sanitizeLanguageCriteria = (languageCriteria: NameOptionalCriteria[]): NameOptionalCriteria[] => {
  return deduplicateNameCriteria<NameOptionalCriteria>(languageCriteria.filter((language) => language.name.length > 0))
}

const sanitizeStandardArrayCriteria = <T extends { name: string }>(items: T[]): T[] => {
  return deduplicateNameCriteria<T>(items.filter((item) => item.name.length > 0))
}

const sanitizeDegreeMajorCriteria = (items: DegreeMajorPairCriteria[]): DegreeMajorPairCriteria[] => {
  const dedupedMajors = items
    .filter((item) => !!item.major)
    .filter((item, index, self) => (
      index === self.findIndex((t) => t.major?.toLowerCase().trim() === item.major?.toLowerCase().trim())
    ))
  return dedupedMajors.map((major) => {
    return {
      ...major,
      degree: major.degree ?? 2,
      optional: major.optional ?? true,
      negative: major.negative ?? false
    }
  })
}

const sanitizeEntityGroupCriteria = (items: SearchEntityCriteria[]): SearchEntityCriteria[] => {
  return deduplicateNameCriteria<SearchEntityCriteria>(items.filter((item) => item.name.length > 0))
}

export const sanitizeRefinementCriteria = (criteria: Criteria | undefined | null): Criteria => {
  if (isNil(criteria)) {
    return DEFAULT_CRITERIA
  }
  let sanitizedCriteria: Criteria = {}
  for (const key in criteria) {
    const criteriaKey = key as CriteriaKey
    if (criteriaKey === CriteriaKey.DEMOGRAPHICS) {
      // Demographics is treated as a group, not a criteria
      continue
    }
    const value = criteria[criteriaKey]
    if (isNil(value)) {
      continue
    }
    const { type } = CriteriaProperties.get(criteriaKey) ?? {}
    if (type === 'job-title') {
      const sanitizedJobTitles = sanitizeJobTitles(value as JobTitleCriteria[])
      if (sanitizedJobTitles.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedJobTitles
        }
      }
    } else if (type === 'language') {
      const sanitizedLanguageCriteria = sanitizeLanguageCriteria(value as NameOptionalCriteria[])
      if (sanitizedLanguageCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedLanguageCriteria
        }
      }
    } else if (type === 'location') {
      const sanitizedLocationCriteria = sanitizeLocationCriteria(value as LocationCriteria[])
      if (sanitizedLocationCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedLocationCriteria
        }
      }
    } else if (type === 'company' || type === 'school') {
      const sanitizedSchoolCompanyCriteria = sanitizeSchoolCompanyCriteria(value as SchoolCompanyCriteria[])
      if (sanitizedSchoolCompanyCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedSchoolCompanyCriteria
        }
      }
    } else if (type === 'custom-requirement') {
      const sanitizedCustomRequirements = (value as CustomRequirementCriteria[]).filter((customRequirement) => customRequirement.requirement)
      if (sanitizedCustomRequirements.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedCustomRequirements
        }
      }
    } else if (type === 'degree-major') {
      const sanitizedDegreeMajorCriteria = sanitizeDegreeMajorCriteria(value as DegreeMajorPairCriteria[])
      if (sanitizedDegreeMajorCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedDegreeMajorCriteria
        }
      }
    } else if (!Array.isArray(value)) {
      if (type === 'min-max') {
        const sanitizedMinMaxCriteria = sanitizeMinMaxCriteria(value as MinMaxCriteria)
        if (sanitizedMinMaxCriteria) {
          sanitizedCriteria = {
            ...sanitizedCriteria,
            [criteriaKey]: sanitizedMinMaxCriteria
          }
        }
      } else if (type === 'boolean') {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: value
        }
      }
    } else if (type === 'entity-group') {
      const sanitizedEntityGroupCriteria = sanitizeEntityGroupCriteria(value as SearchEntityCriteria[])
      if (sanitizedEntityGroupCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedEntityGroupCriteria
        }
      }
    } else {
      // Array criteria
      const sanitizedArrayCriteria = sanitizeStandardArrayCriteria(value as StandardCriteria[])
      if (sanitizedArrayCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedArrayCriteria
        }
      }
    }
  }

  return sanitizedCriteria
}

export const isDifferentCollection = <T>(newCollection: T[], oldCollection: T[] | undefined, keys: Array<keyof T>): boolean => {
  if (!oldCollection) return true
  if (newCollection.length !== oldCollection.length) return true

  return newCollection.some((newItem) => {
    return !oldCollection.some((oldItem) => {
      return keys.some((key) => {
        return isEqual(newItem[key], oldItem[key])
      })
    })
  })
}

export const diffUpdatedCriterias = (newCriteria: Criteria, oldCriteria: Criteria | undefined): CriteriaKey[] => {
  if (isNil(oldCriteria)) {
    return Object.keys(newCriteria) as CriteriaKey[]
  }
  const diffCriteriaKeys: CriteriaKey[] = []
  Object.keys(newCriteria).forEach((key) => {
    const newCriteriaKey = key as CriteriaKey
    if (!(newCriteriaKey in oldCriteria)) {
      // New field added by AI is highlighted regardless of its value
      if (DemographicsGroup.has(newCriteriaKey)) {
        // This is handled specifically because Demographics is a grouped criteria
        diffCriteriaKeys.push(CriteriaKey.DEMOGRAPHICS)
      } else {
        diffCriteriaKeys.push(newCriteriaKey)
      }
    } else if (newCriteriaKey !== CriteriaKey.DEMOGRAPHICS) {
      const oldCriteriaValue = oldCriteria[newCriteriaKey]
      const newCriteriaValue = newCriteria[newCriteriaKey]
      const { type } = CriteriaProperties.get(newCriteriaKey) ?? {}
      if (type === 'job-title') {
        if (isDifferentCollection(newCriteriaValue as JobTitleCriteria[], oldCriteriaValue as JobTitleCriteria[], ['name', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (type === 'location') {
        if (isDifferentCollection(newCriteriaValue as LocationCriteria[], oldCriteriaValue as LocationCriteria[], ['city', 'state', 'metro', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (type === 'company' || type === 'school') {
        if (isDifferentCollection(newCriteriaValue as SchoolCompanyCriteria[], oldCriteriaValue as SchoolCompanyCriteria[], ['name', 'linkedin', 'domain', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (type === 'custom-requirement') {
        if (isDifferentCollection(newCriteriaValue as CustomRequirementCriteria[], oldCriteriaValue as CustomRequirementCriteria[], ['requirement', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (type === 'degree-major') {
        if (isDifferentCollection(newCriteriaValue as DegreeMajorPairCriteria[], oldCriteriaValue as DegreeMajorPairCriteria[], ['major', 'degree', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (type === 'standard') {
        if (isDifferentCollection(newCriteriaValue as StandardCriteria[], oldCriteriaValue as StandardCriteria[], ['name', 'negative', 'optional'])) {
          if (DemographicsGroup.has(newCriteriaKey)) {
            // This is handled specifically because Demographics is a grouped criteria
            diffCriteriaKeys.push(CriteriaKey.DEMOGRAPHICS)
          } else {
            diffCriteriaKeys.push(newCriteriaKey)
          }
        }
      } else {
        const { min, max, optional } = newCriteriaValue as MinMaxCriteria
        const { min: oldMin, max: oldMax, optional: oldOptional } = oldCriteriaValue as MinMaxCriteria
        if (min !== oldMin || max !== oldMax || optional !== oldOptional) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      }
    }
  })

  return diffCriteriaKeys
}

export const getSubtitle = (suggestion: SchoolSuggestion | CompanySuggestion | LocationSuggestion): string | undefined => {
  if ('city' in suggestion) {
    if (suggestion.city) {
      return 'City'
    } else if (suggestion.state) {
      if (suggestion.country?.toLowerCase() === 'canada') {
        const stateName = suggestion.state.toLowerCase()
        if (['nunavut', 'northwest territories', 'yukon'].includes(stateName)) {
          return 'Territory'
        }
        return 'Province'
      }
      return 'State'
    } else if (suggestion.metro) {
      return 'Metro'
    } else if (suggestion.country) {
      return 'Country'
    } else if (suggestion.continent) {
      return 'Continent'
    }
  }
  return undefined
}

export interface CriteriaOrderedGroup {
  groupKey: CriteriaGroup
  orderedCriteria: CriteriaKey[]
}

export const getCriteriaGroupOrder = (criteria: Criteria | undefined | null): CriteriaOrderedGroup[] => {
  if (isNil(criteria)) {
    return CriteriaGroupOrder.map((groupKey) => ({
      groupKey,
      orderedCriteria: []
    }))
  }
  const currentCriteriaKeys = new Set(Object.keys(criteria))
  DemographicsGroup.forEach((criteriaKey) => {
    if (criteriaKey in criteria) {
      currentCriteriaKeys.delete(criteriaKey)
      currentCriteriaKeys.add(CriteriaKey.DEMOGRAPHICS)
    }
  })

  return CriteriaGroupOrder.map((groupKey) => {
    const groupedKeys: CriteriaKey[] = []
    GroupToCriteriaMap.get(groupKey)?.forEach((criteriaKey) => {
      if (currentCriteriaKeys.has(criteriaKey)) {
        groupedKeys.push(criteriaKey)
      }
    })
    return {
      groupKey,
      orderedCriteria: groupedKeys
    }
  })
}

export const addCriteriaToOrderedGroup = (currentOrderedGroups: CriteriaOrderedGroup[], newCriteriaKey: CriteriaKey): CriteriaOrderedGroup[] => {
  const groupKey = CriteriaToGroupMap.get(newCriteriaKey)
  if (!groupKey) {
    console.error(`Criteria key ${newCriteriaKey} not found in any group`)
    return currentOrderedGroups
  }
  const newOrderedGroups = currentOrderedGroups.map((orderedGroup) => {
    if (orderedGroup.groupKey === groupKey) {
      const currentOrderedCriteria = orderedGroup.orderedCriteria
      // Get the full ordered list for this group from the map
      const fullGroupOrder = GroupToCriteriaMap.get(groupKey) ?? []
      // If criteria is already in the list, return unchanged
      if (currentOrderedCriteria.includes(newCriteriaKey)) {
        return orderedGroup
      }

      // Find the correct position to insert the new criteria
      const newIndex = fullGroupOrder.findIndex((key) => key === newCriteriaKey)
      let insertIndex = 0
      // Find where to insert based on the full group order
      for (let i = 0; i < newIndex; i++) {
        const currentKey = fullGroupOrder[i]
        const existingIndex = currentOrderedCriteria.indexOf(currentKey)
        if (existingIndex === -1) continue
        insertIndex = existingIndex + 1
      }

      // Insert the new criteria at the correct position
      const updatedCriteria = [
        ...currentOrderedCriteria.slice(0, insertIndex),
        newCriteriaKey,
        ...currentOrderedCriteria.slice(insertIndex)
      ]

      return {
        ...orderedGroup,
        orderedCriteria: updatedCriteria
      }
    }
    return orderedGroup
  })
  return newOrderedGroups
}

export const removeCriteriaFromOrderedGroup = (currentOrderedGroups: CriteriaOrderedGroup[], criteriaKey: CriteriaKey): CriteriaOrderedGroup[] => {
  return currentOrderedGroups.map((orderedGroup) => {
    return {
      ...orderedGroup,
      orderedCriteria: orderedGroup.orderedCriteria.filter((key) => key !== criteriaKey)
    }
  })
}

export const isCriteriaValid = (criteria: Criteria | undefined | null): boolean => {
  if (isNil(criteria) || isEqual(criteria, DEFAULT_CRITERIA)) {
    return false
  }
  const sanitizedCriteria = sanitizeRefinementCriteria(criteria)
  // Simply check if there are at least 2 valid criteria
  return Object.keys(sanitizedCriteria).length > 1
}
