import { Fragment, useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Components
import { Button } from 'src/components/primitives/button'
import type { ButtonProps } from 'src/components/primitives/button'
import { SequenceStep } from './sequence-step'
import { Flex } from 'src/components/primitives/flex'
import { When } from 'src/components/blocks/when'

// Hooks, libs, styles
import type { EmailSequence, EmailSequenceStep } from 'src/models/sequence'
import type { SequenceInstructionsForm } from './instructions'
import { isNil } from 'lodash'
import { useUpsertJobSequence } from 'src/hooks/mutations/use-upsert-job-sequence'
import {
  generateInitialSequence,
  parseInitialState,
  stepTypeIsEmail,
  stepTypeIsManualTask,
  useEmailSequenceEditor
} from 'src/hooks/use-email-sequence-editor'
import * as S from './sequence-editor.styled'
import { useAlertOnRouteChange } from 'src/hooks/use-alert-on-route-change'
import { SequenceStepGenerationState } from 'src/libs/api/backend/sequences'
import type { SequenceStepType } from 'src/libs/api/backend/sequences'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useIsSafari } from 'src/hooks/use-is-browser'
import { EmailSequenceSuggestion } from '../email-sequence-suggestion/email-sequence-suggestion'
import { Editor } from '../editor'
import { LoadingSkeleton } from '../loading-skeleton'
import { Box } from 'src/components/primitives/box'
import { useSendTestEmail } from 'src/hooks/mutations/use-send-test-email'
import { closeDialogAtom, DialogId, openAlertAtom } from 'src/stores/dialogs'
import { useSetAtom } from 'jotai'
import { convertSubjectToText, parseVariableToComponent } from '../editor/extensions/variable-parser'
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { StepSelector } from './step-selector'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { useSession } from 'src/hooks/use-session'
import { viewerRoleNotice } from 'src/libs/user-role-notice'
import { sequenceStepTypeDisplay } from './sequence-step-type-display'
import { EmailSettings } from '../email-settings'
import { SequenceTourStep } from 'src/libs/product-tour'

type Action = ButtonProps

const MAX_NUMBER_OF_STEPS = 10

export interface SequenceStepsEditorProps {
  initialState?: EmailSequence
  sequenceInstructions?: SequenceInstructionsForm
  shouldAutoGenerateEmails?: boolean
  isEditable?: boolean
  onBackClick?: () => void
  actions?: {
    save?: Action
    skip?: Action
    toggleActive?: () => void
  }
  usedInDialog?: boolean
}

enum EditorView {
  SEQUENCE = 'SEQUENCE',
  PREFERENCES = 'PREFERENCES'
}

export const SequenceStepsEditor = ({
  initialState,
  shouldAutoGenerateEmails = true,
  // actions = {}
  isEditable = true,
  onBackClick,
  usedInDialog = false
}: SequenceStepsEditorProps): JSX.Element => {
  const { userHasViewerRole } = useSession()
  const closeDialog = useSetAtom(closeDialogAtom)
  const openAlert = useSetAtom(openAlertAtom)
  const [currentStep, setCurrentStep] = useState<number | null>(0)
  const [animationDone, setAnimationDone] = useState(true)
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { jobId } = useParams()
  const scrollableStepsSectionEl = useRef<HTMLDivElement>(null)
  const {
    isLoading,
    sequenceState,
    emailsBeingGenerated,
    generatedEmails,
    getStepSubjectAndPlaceholder,
    getNewPosition,
    handleAddStep,
    isReadyForNextStep,
    handleGenerateStepSuggestion,
    handleConfirmStepSuggestion,
    handleDataChange,
    handleRemoveStep,
    handleUpdateReplyType,
    handleReorderSteps,
    validateSequenceState,
    hasEmptySequenceStep,
    hasUnsavedChanges,
    setHasUnsavedChanges
  } = useEmailSequenceEditor({
    initialState: parseInitialState(initialState ?? generateInitialSequence(3)),
    autoGenerateEmails: shouldAutoGenerateEmails,
    sequenceSize: initialState?.sequenceSteps?.length ?? 3
  })
  const { sendTestEmail } = useSendTestEmail()
  const [isReordering, setIsReordering] = useState(false)
  const [reorderKey, setReorderKey] = useState(0)
  const [editorView, setEditorView] = useState<EditorView>(EditorView.SEQUENCE)

  const isSafari = useIsSafari()

  const atLeastOneStepIsBeingGenerated = useMemo(
    () => sequenceState.sequenceSteps?.some((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS),
    [sequenceState.sequenceSteps]
  )

  useAlertOnRouteChange(hasUnsavedChanges, {
    confirmAndExitText: 'Save and leave',
    onConfirmAndExit: async () => {
      await new Promise<void>((resolve, reject) => {
        saveSequence({
          closeAfterSaving: false,
          onSuccess: () => {
            setHasUnsavedChanges(false)
            resolve()
          }
        }).catch(reject)
      })
    }
  })

  const handleReorderEnd = (event: DragEndEvent): void => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = sequenceState.sequenceSteps?.findIndex((step) => step.id === active.id)
      const newIndex = sequenceState.sequenceSteps?.findIndex((step) => step.id === over?.id)

      if (oldIndex !== undefined && newIndex !== undefined) {
        const updated = arrayMove(sequenceState.sequenceSteps ?? [], oldIndex, newIndex)
        handleReorderSteps(updated)
        setCurrentStep(newIndex)
        setReorderKey(prev => prev + 1)
      }
    }
    document.body.style.removeProperty('user-select')
  }

  useEffect(() => {
    if (isReordering) {
      document.body.style.userSelect = 'none'
    } else {
      document.body.style.removeProperty('user-select')
    }
  }, [isReordering])

  const { upsertJobSequence, isPending } = useUpsertJobSequence()

  const initialStepsAreGenerating = useMemo(() => {
    return !isNil(sequenceState.sequenceSteps) && sequenceState.sequenceSteps?.every((step) => step.generationState === SequenceStepGenerationState.IN_PROGRESS)
  }, [sequenceState.sequenceSteps])

  const stepIsGenerating = useCallback(
    (stepPosition: number): boolean => {
      const isNewSequenceStepBeingGenerated = emailsBeingGenerated?.includes(stepPosition) ?? false
      const isInitialSequenceStepsBeingGenerated =
        sequenceState.sequenceSteps?.at(stepPosition)?.generationState === SequenceStepGenerationState.IN_PROGRESS
      return isNewSequenceStepBeingGenerated || isInitialSequenceStepsBeingGenerated
    },
    [emailsBeingGenerated, sequenceState.sequenceSteps]
  )

  const editorIsEditable = useMemo(() => {
    return !initialStepsAreGenerating && isEditable
  }, [initialStepsAreGenerating, isEditable])

  const isSequenceGenerating = useMemo((): boolean => {
    // NOTE: we return true in this case because if `sequenceState.sequenceSteps` is null, then the initial sequence is being generated
    if (isNil(sequenceState.sequenceSteps)) {
      return true
    }

    if (isNil(emailsBeingGenerated)) {
      return false
    }

    return emailsBeingGenerated.length > 0
  }, [emailsBeingGenerated, sequenceState.sequenceSteps])

  const reorderingDisabled = useMemo(() =>
    sequenceState.sequenceSteps?.some(step =>
      initialStepsAreGenerating ||
      step.generationState === SequenceStepGenerationState.IN_PROGRESS ||
      stepIsGenerating(step.position)
    ), [sequenceState.sequenceSteps, initialStepsAreGenerating, stepIsGenerating]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      },
      disabled: reorderingDisabled
    })
  )

  const reachedMaxStepCount = useMemo(() => {
    return Array.isArray(sequenceState.sequenceSteps) && sequenceState.sequenceSteps.length === MAX_NUMBER_OF_STEPS
  }, [sequenceState])

  const saveSequence = useCallback(async ({ closeAfterSaving = true, onSuccess }: { closeAfterSaving?: boolean, onSuccess?: () => void } = {}) => {
    if (isNil(jobId)) {
      return
    }
    const validated = await validateSequenceState(sequenceState)

    if (isNil(validated)) {
      return
    }

    upsertJobSequence({
      jobId,
      sequenceSteps: validated.sequenceSteps,
      active: validated.active ?? true,
      autoArchiveAfterDays: validated.autoArchiveAfterDays,
      dailyEmailLimit: validated.dailyEmailLimit,
      onSuccess: () => {
        if (closeAfterSaving) {
          closeDialog(DialogId.CREATE_SEQUENCE)
        }
        onSuccess?.()
      }
    })
  }, [closeDialog, jobId, sequenceState, upsertJobSequence, validateSequenceState])

  const scrollToCurrentStep = useCallback((stepPosition: number, animatedScroll = true) => {
    if (scrollableStepsSectionEl.current) {
      const stepElement = document.getElementById(`step-${stepPosition}`)
      if (stepElement) {
        scrollableStepsSectionEl.current.onscrollend = () => {
          setAnimationDone(true)
        }
        scrollableStepsSectionEl.current.scrollTo({
          top: stepElement.offsetTop,
          behavior: animatedScroll ? 'smooth' : 'instant'
        })
        if (isSafari) {
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current)
          }
          // THANKS SAFARI FOR NOT SUPPORTING scrollend event
          // This will break the smooth animation on Chrome so don't enable it for Chrome powered browser or FF
          // Remove this when Safari supports https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event#browser_compatibility likes an adult
          scrollTimeoutRef.current = setTimeout(() => {
            setAnimationDone(true)
          }, animatedScroll ? 500 : 0)
        }
      }
    }
  }, [isSafari])

  useEffect(() => {
    if (isNil(currentStep)) {
      return
    }
    if (isReordering) {
      scrollToCurrentStep(currentStep, false)
    } else {
      scrollToCurrentStep(currentStep, true)
    }
  }, [currentStep, scrollToCurrentStep, isReordering])

  const onStepClick = useCallback((stepPosition: number) => {
    setEditorView(EditorView.SEQUENCE)
    setIsReordering(false)
    setAnimationDone(false)
    setCurrentStep(stepPosition)
  }, [])

  const handleSendTestEmail = async (step: EmailSequenceStep, recipients: string[], candidateId?: string | undefined): Promise<void> => {
    setIsSendingTestEmail(true)
    if (hasUnsavedChanges) {
      openAlert({
        message: 'Unsaved changes',
        description: 'You have unsaved changes in your sequence. You need to save your changes before being able to send a preview email.',
        confirmText: 'Save and send preview',
        variant: 'tint',
        onCancel: () => {
          setIsSendingTestEmail(false)
        },
        onConfirm: () => {
          void saveSequence({
            closeAfterSaving: false,
            onSuccess: () => {
              sendTestEmail({
                emails: recipients ?? [],
                sequenceStep: step,
                candidateId,
                onSuccess: () => {
                  closeDialog(DialogId.ALERT)
                  setHasUnsavedChanges(false)
                  setIsSendingTestEmail(false)
                }
              })
            }
          })
        }
      })
    } else {
      sendTestEmail({
        emails: recipients ?? [],
        sequenceStep: step,
        candidateId,
        onSuccess: () => {
          setIsSendingTestEmail(false)
        }
      })
    }
  }

  const renderEditorFooter = (stepIndex: number, stepType: SequenceStepType | undefined): JSX.Element | null => {
    if (isNil(sequenceState) || isNil(sequenceState.sequenceSteps)) {
      return null
    }

    const suggestion = sequenceState.sequenceSteps[stepIndex].emailBodySuggestion
    const body = sequenceState.sequenceSteps[stepIndex].body
    if (isNil(body)) {
      return null
    }

    const hasShortBody = body.length < 50
    const hasSuggestion = !isNil(suggestion)

    const showFooter = hasSuggestion || hasShortBody
    if (!showFooter) {
      return null
    }

    if (stepTypeIsManualTask(stepType)) {
      return null
    }

    return (
      <EmailSequenceSuggestion
        isGeneratingSuggestion={isSequenceGenerating}
        startCollpased={!isSequenceGenerating}
        // startCollpased={true}
        onOpenSuggestion={async () => {
          await handleGenerateStepSuggestion(stepIndex)
        }}
        onCloseSuggestion={async () => { }}
        onApplySuggestion={() => {
          handleConfirmStepSuggestion(true, stepIndex)
        }}
      >
        <When condition={isSequenceGenerating}>
          <Box $padding={{ top: 12, right: 12, bottom: 12, left: 12 }} $width='100%'>
            <LoadingSkeleton $variant="Text" />
          </Box>
        </When>
        <When condition={!isSequenceGenerating}>
          <Editor
            isEditable={false}
            initialContent={parseVariableToComponent(suggestion) ?? undefined}
            onDataChanged={() => {}}
            $minHeight="10rem"
            $editorHeight='fit-content'
          />
        </When>
      </EmailSequenceSuggestion>
    )
  }

  if (isLoading) {
    return <></>
  }

  return (
    <S.SequenceEditor key={reorderKey} $usedInDialog={usedInDialog}>
      <DndContext
        // key={sequenceState.sequenceSteps?.map(step => `${step.id}-${step.position}`).join('-')}
        key={reorderKey}
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={() => { setIsReordering(true) }}
        onDragEnd={handleReorderEnd}
        modifiers={[restrictToParentElement]}
      >
        <S.Sidebar $usedInDialog={usedInDialog}>
          <Flex $direction="column">
            <SortableContext items={sequenceState.sequenceSteps?.map(step => ({ id: step.id ?? step.position.toString() })) ?? []} strategy={verticalListSortingStrategy}>
              <S.SequenceTourArea id={SequenceTourStep.STEPS_SIDEBAR} />
              <S.StepSelectors $isEditable={editorIsEditable}>
                  {sequenceState.sequenceSteps?.map((step: EmailSequenceStep) => {
                    const { subject, placeholder } = getStepSubjectAndPlaceholder(step.position)

                    const stepIcon = (type?: SequenceStepType): React.ReactNode => {
                      if (isNil(step.subject) && stepTypeIsEmail(type)) {
                        return <Icon name={Icons.mailReply} size={12} color="tintFg" />
                      }
                      return sequenceStepTypeDisplay(type).icon
                    }

                    const stepTitle = (type?: SequenceStepType): string => {
                      if (stepTypeIsEmail(type)) {
                        if (step.position === 0) {
                          return 'Intro Email'
                        }

                        return isNil(step.subject)
                          ? `Reply After ${step.waitDays} days`
                          : `After ${step.waitDays} days`
                      }

                      return `After ${step.waitDays} days`
                    }

                    const stepSubtitle = (type?: SequenceStepType): string => {
                      if (stepTypeIsEmail(type)) {
                        return isNil(subject)
                          ? `Re: ${convertSubjectToText(subject ?? placeholder)}`
                          : convertSubjectToText(subject ?? placeholder) ?? ''
                      }

                      return sequenceStepTypeDisplay(type).title ?? ''
                    }

                    const stepHasError = isNil(step.body) || step.body === '' || step.body === '<div></div>'

                    return (
                      <Fragment key={`${step.id ?? step.position}-${reorderKey}`}>
                        <StepSelector
                          currentStep={currentStep}
                          step={step}
                          stepIcon={stepIcon(step.type)}
                          stepTitle={stepTitle(step.type)}
                          stepSubtitle={stepSubtitle(step.type)}
                          onStepClick={onStepClick}
                          initialStepsAreGenerating={initialStepsAreGenerating}
                          reorderingDisabled={initialStepsAreGenerating || step.generationState === SequenceStepGenerationState.IN_PROGRESS || stepIsGenerating(step.position)}
                          hasError={!initialStepsAreGenerating && stepHasError}
                        />
                      </Fragment>
                    )
                  })}
                </S.StepSelectors>
              </SortableContext>
            <S.ActionsGroup>
              <S.AddStepButton $isEditable={editorIsEditable}>
                <Button
                  $variant="outline"
                  $colorTheme="tint"
                  leadingIcon={Icons.plus}
                  $height={40}
                  $width='full'
                  $align='center'
                  $fontSize={12}
                  $borderRadius={6}
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  disabled={isLoading || reachedMaxStepCount || !isReadyForNextStep() || atLeastOneStepIsBeingGenerated || userHasViewerRole}
                  onClick={async () => {
                    setEditorView(EditorView.SEQUENCE)
                    onStepClick(getNewPosition())

                    if (shouldAutoGenerateEmails) {
                      await handleGenerateStepSuggestion()
                    } else {
                      handleAddStep()
                    }
                  }}
                  tooltip={{
                    text: userHasViewerRole ? viewerRoleNotice('edit this outreach sequence') : undefined,
                    position: 'top'
                  }}
                >
                  Add step
                </Button>
              </S.AddStepButton>
              <S.OpenPreferencesButton $isActive={editorView === EditorView.PREFERENCES}>
                <Button
                  $variant="outline"
                  $colorTheme="muted"
                  leadingIcon={Icons.settings2}
                  $height={40}
                  $width="full"
                  $fontSize={12}
                  $borderRadius={6}
                  $align="center"
                  onClick={() => {
                    setEditorView(EditorView.PREFERENCES)
                    setCurrentStep(null)
                  }}
                >
                  Preferences
                </Button>
              </S.OpenPreferencesButton>
            </S.ActionsGroup>
          </Flex>
          <Flex $direction="column" $gap={16}>
            <Flex $align="center" $gap={16}>
              {onBackClick && (
                <Button
                  leadingIcon={Icons.chevronLeft}
                  $variant="outline"
                  $colorTheme="muted"
                  $height={40}
                  $width="84px"
                  $minWidth="84px"
                  onClick={onBackClick}
                >
                  Back
                </Button>
              )}
              <Button
                // disabled={!editorIsEditable || isSequenceGenerating || isPending || Boolean(emailsBeingGenerated?.length) || !isReadyForNextStep() || hasEmptySequenceStep()}
                disabled={!editorIsEditable || isPending || !isReadyForNextStep() || hasEmptySequenceStep() || userHasViewerRole}
                $variant="fill"
                $colorTheme="tint"
                $height={40}
                $width="full"
                $align="center"
                onClick={async () => {
                  await saveSequence({ closeAfterSaving: false })
                  setHasUnsavedChanges(false)
                }}
                tooltip={{
                  text: userHasViewerRole ? viewerRoleNotice('edit this outreach sequence') : undefined,
                  position: 'top'
                }}
              >
                Save outreach sequence
              </Button>
            </Flex>
          </Flex>
        </S.Sidebar>
        <When condition={editorView === EditorView.SEQUENCE}>
          <S.StepsSection
            data-name="StepsSection"
            key={reorderKey}
            ref={scrollableStepsSectionEl}
            $isEditable={editorIsEditable}
            onClick={() => { setIsReordering(false) }}
          >
            {sequenceState.sequenceSteps?.map((step: EmailSequenceStep, index: number) => {
              return (
                <SequenceStep
                  key={`${reorderKey}-${step.id}-${step.position}`}
                  reorderKey={reorderKey}
                  step={step}
                  steps={sequenceState.sequenceSteps}
                  getStepSubjectAndPlaceholder={getStepSubjectAndPlaceholder}
                  initialStepBody={generatedEmails?.[step.position]?.body ?? undefined}
                  totalSteps={
                    Array.isArray(sequenceState.sequenceSteps)
                      ? sequenceState.sequenceSteps.length - 1
                      : 3
                  }
                  isGenerating={
                    step.generationState === SequenceStepGenerationState.IN_PROGRESS || stepIsGenerating(step.position)
                  }
                  onDataChanged={(updatedStep) => {
                    if (isReordering) return
                    handleDataChange(updatedStep)
                  }}
                  onSendTestEmail={(step, recipients, candidateId) => {
                    void handleSendTestEmail(step, recipients, candidateId)
                  }}
                  isSendingTestEmail={isSendingTestEmail}
                  onRemoveStep={() => {
                    onStepClick(step.position - 1)
                    handleRemoveStep(step)
                  }}
                  onReplyTypeUpdate={(value) => {
                    handleUpdateReplyType(step, value)
                  }}
                  onReorder={(newPosition) => {
                    console.log('reorder: ', newPosition)
                  }}
                  forceEditorFocus={step.position === currentStep && animationDone}
                  emailSuggestionFooter={renderEditorFooter(index, step.type)}
                />
              )
            })}
          </S.StepsSection>
        </When>
        <When condition={editorView === EditorView.PREFERENCES}>
          <S.SequencePreferences $usedInDialog={usedInDialog}>
            <S.SequencePreferencesInner $usedInDialog={usedInDialog}>
              <EmailSettings />
            </S.SequencePreferencesInner>
          </S.SequencePreferences>
        </When>
      </DndContext>
    </S.SequenceEditor>
  )
}
