import { Button } from 'src/components/primitives/button'
import { Dropdown } from 'src/components/primitives/dropdown'
import { useQueryParams } from 'src/hooks/use-query-params'
import { TimeFrame, TimeFrameDisplay } from 'src/libs/api/backend/reports_types'
import * as S from './reports-timeframe-selector.styled'
import { Caption } from 'src/components/primitives/typography'
import { Toggle } from 'src/components/primitives/toggle'
import { useLocalStorage } from 'usehooks-ts'
import { useEffect } from 'react'
import { LocalStorageKey } from 'src/constants'

interface TimeFrameStorage {
  timeFrame: TimeFrame
  compareToPreviousPeriod: boolean
}

export const ReportsTimeframeSelector = (): JSX.Element => {
  const { setParam, getParam } = useQueryParams()
  const currentTimeFrame = getParam('timeFrame') || TimeFrame.LAST_30_DAYS
  const currentCompare = getParam('compareToPreviousPeriod') ?? 'false'
  const [reportsDisplaySettings, setReportsDisplaySettings] = useLocalStorage<TimeFrameStorage | null>(LocalStorageKey.REPORTS_DISPLAY_SETTINGS, null)

  useEffect(() => {
    if (reportsDisplaySettings && !getParam('timeFrame')) {
      setParam('timeFrame', reportsDisplaySettings.timeFrame)
      setParam('compareToPreviousPeriod', String(reportsDisplaySettings.compareToPreviousPeriod))
    }
  }, [getParam, reportsDisplaySettings, setParam])

  const timeframeOptions = Object.keys(TimeFrameDisplay).map((key) => ({
    id: key,
    title: TimeFrameDisplay[key as keyof typeof TimeFrameDisplay],
    onSelect: () => {
      setParam('timeFrame', key)
      setReportsDisplaySettings({
        timeFrame: key as TimeFrame,
        compareToPreviousPeriod: currentCompare === 'true'
      })
    }
  }))

  return (
    <Dropdown
      trigger={
        <Button
          nested
          $variant="raised"
          $height={24}
          leadingIcon="calendar"
          $fontSize={12}
          trailingIcon="chevron-down"
        >
          {TimeFrameDisplay[currentTimeFrame as keyof typeof TimeFrameDisplay]}
        </Button>
      }
      items={[
        ...timeframeOptions,
        {
          title: 'Separator',
          type: 'separator'
        },
        {
          title: 'Custom',
          type: 'node',
          content: (
            <S.CompareDropdownItem $disabled={!currentTimeFrame || currentTimeFrame === TimeFrame.ALL_TIME}>
              <Caption size="XS" $color="fgPrimary">
                Compare to Previous Period
              </Caption>
              <Toggle
                name="compareToPreviousPeriod"
                checked={currentCompare === 'true'}
                disabled={!currentTimeFrame || currentTimeFrame === TimeFrame.ALL_TIME}
                onChange={() => {
                  const newCompareValue = currentCompare === 'true' ? 'false' : 'true'
                  setParam('compareToPreviousPeriod', newCompareValue)
                  setReportsDisplaySettings({
                    timeFrame: currentTimeFrame as TimeFrame,
                    compareToPreviousPeriod: newCompareValue === 'true'
                  })
                }}
              />
            </S.CompareDropdownItem>
          )
        }
      ]}
      selectedValue={currentTimeFrame}
      size="small"
      menuPosition="end"
    />
  )
}
