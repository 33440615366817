import { useMemo, useState } from 'react'
import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { ChartWrapper } from 'src/components/charts/primitives/chart-wrapper'
import { ChartHeader } from 'src/components/charts/primitives/chart-header'
import { Table } from 'src/components/primitives/table'
import { EmptyChart } from 'src/components/charts/primitives/empty-chart'
import type { TeamMemberActivities } from 'src/libs/api/backend/reports_team_activities'
import { DefaultTableHeader } from 'src/components/tables/candidate-table-cells'
import type { TableColumnDef } from 'src/components/tables/candidate-table-cells'
import { Caption } from 'src/components/primitives/typography'
import { TableReportNumberCell } from 'src/pages/job/reports/report-components'
import { When } from 'src/components/blocks/when'
import { Spacer } from 'src/components/primitives/spacer/spacer'
import { Button } from 'src/components/primitives/button'
import { useLocation } from 'react-router-dom'
import { useReportsShouldCompareToPreviousPeriod } from 'src/hooks/use-reports-should-compare-to-previous-period'

interface TeamActivityChartProps {
  data: TeamMemberActivities[]
}

const calculatePercentage = (value: number, baseValue: number): number => {
  if (baseValue === 0 && value > 0) return 1
  return baseValue ? Number((value / baseValue).toFixed(2)) : 0
}

interface TeamActivityTableData {
  id: string
  name: string
  photoUrl: string | null | undefined
  searches: number
  pastSearches: number
  approved: number
  pastApproved: number
  rejected: number
  pastRejected: number
  manualTasks: number
  pastManualTasks: number
  manualEmails: number
  pastManualEmails: number
  scheduled: number
  pastScheduled: number
}

type SortableTeamActivityColumns = 'searches' | 'approved' | 'rejected' | 'manualTasks' | 'manualEmails' | 'scheduled'

const sortByTeamActivityColumn = (field: SortableTeamActivityColumns) => {
  return (rowA: TeamActivityTableData, rowB: TeamActivityTableData) => {
    const valueA = rowA[field] ?? 0
    const valueB = rowB[field] ?? 0
    return valueA - valueB
  }
}

export const TeamActivityChart = ({ data }: TeamActivityChartProps): JSX.Element => {
  const { pathname } = useLocation()
  const isAllPositionsReport = pathname.includes('all/reports')
  const [showAllActivities, setShowAllActivities] = useState(false)
  const { shouldCompare } = useReportsShouldCompareToPreviousPeriod()

  const tableData = useMemo((): TeamActivityTableData[] => {
    return data.map((member) => ({
      id: member.userId,
      name: member.userName,
      photoUrl: member.profilePhotoUrl,
      searches: member.searches,
      pastSearches: member.pastSearches,
      approved: member.approved,
      pastApproved: member.pastApproved,
      rejected: member.rejected,
      pastRejected: member.pastRejected,
      manualTasks: member.manualTasks,
      pastManualTasks: member.pastManualTasks,
      manualEmails: member.manualEmails,
      pastManualEmails: member.pastManualEmails,
      scheduled: member.scheduled,
      pastScheduled: member.pastScheduled
    }))
  }, [data])

  const getValueChangeDirection = (current: number, past: number): 'up' | 'down' | undefined => {
    if (current === 0 && past === 0) return undefined
    return current > past ? 'up' : 'down'
  }

  const columns: Array<TableColumnDef<TeamActivityTableData>> = useMemo(() => [
    {
      columnId: 'member',
      size: 'minmax(min-content, 3fr)',
      visible: true,
      header: <DefaultTableHeader title="Team Member" minHeight={40} />,
      sortingFn: (a: TeamActivityTableData, b: TeamActivityTableData) => {
        return a.name.localeCompare(b.name)
      },
      cell: (info: TeamActivityTableData) => (
        <Flex $gap={8} $align="center">
          <Avatar
            $type="photo"
            $size={24}
            initials={info.name.charAt(0)}
            photoUrl={info.photoUrl}
          />
          <Caption size="XS">{info.name}</Caption>
        </Flex>
      )
    },
    {
      columnId: 'searches',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Searches" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('searches'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.searches}
          percentage={shouldCompare ? calculatePercentage(info.searches, info.pastSearches) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.searches, info.pastSearches) : undefined}
        />
      )
    },
    {
      columnId: 'approved',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Approved" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('approved'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.approved}
          percentage={shouldCompare ? calculatePercentage(info.approved, info.pastApproved) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.approved, info.pastApproved) : undefined}
        />
      )
    },
    {
      columnId: 'rejected',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Rejected" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('rejected'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.rejected}
          percentage={shouldCompare ? calculatePercentage(info.rejected, info.pastRejected) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.rejected, info.pastRejected) : undefined}
        />
      )
    },
    {
      columnId: 'manualTasks',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Manual Tasks" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('manualTasks'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.manualTasks}
          percentage={shouldCompare ? calculatePercentage(info.manualTasks, info.pastManualTasks) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.manualTasks, info.pastManualTasks) : undefined}
        />
      )
    },
    {
      columnId: 'manualEmails',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Manual Emails" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('manualEmails'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.manualEmails}
          percentage={shouldCompare ? calculatePercentage(info.manualEmails, info.pastManualEmails) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.manualEmails, info.pastManualEmails) : undefined}
        />
      )
    },
    {
      columnId: 'scheduled',
      size: 'minmax(min-content, 1fr)',
      visible: true,
      header: <DefaultTableHeader title="Scheduled" minHeight={40} />,
      headerAlign: 'center',
      sortingFn: sortByTeamActivityColumn('scheduled'),
      cell: (info: TeamActivityTableData) => (
        <TableReportNumberCell
          value={info.scheduled}
          percentage={shouldCompare ? calculatePercentage(info.scheduled, info.pastScheduled) : undefined}
          valueChangeDirection={shouldCompare ? getValueChangeDirection(info.scheduled, info.pastScheduled) : undefined}
        />
      )
    }
  ], [shouldCompare])

  return (
    <ChartWrapper>
      <ChartHeader title="Team Activity" $marginBottom={0} />
      <Spacer $size={12} />
      <Table<TeamActivityTableData>
        tableData={tableData}
        columns={columns}
        emptyState={<EmptyChart $minHeight='80px' />}
        visibleRowsCount={showAllActivities ? undefined : 5}
      />
      <When condition={isAllPositionsReport && !showAllActivities && data.length > 5}>
        <Spacer $size={12} />
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width="100%"
          $fontSize={12}
          onClick={() => {
            setShowAllActivities(!showAllActivities)
          }}
        >
          See All
        </Button>
      </When>
    </ChartWrapper>
  )
}
