import type { Spacing } from 'src/styles/theme/types'
import styled, { css } from 'styled-components'
import type { TagInputStyleProps } from './tag-input'

interface ContainerProps extends TagInputStyleProps {
  $containerPadding?: Spacing
  $disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.colors.bgPrimary};
  border: 1px solid ${({ theme }) => theme.colors.borderTranslucent};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme, $containerPadding }) => theme.spacing[$containerPadding ?? 4]};
  width: 100%;
  height: ${({ $height }) => typeof $height === 'number' ? `${$height}px` : 'unset'};
  overflow: hidden;
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`

export const TagList = styled.ul<ContainerProps>`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  background: none;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: 0;
  height: ${({ $height }) => typeof $height === 'number' ? `${$height - 10}px` : 'unset'};
`

export const TagListItem = styled.li`
  align-items: center;
  background: ${({ theme }) => theme.colors.bgTertiary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.fgPrimary};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  list-style: none;
  padding-left: ${({ theme }) => theme.spacing[6]};
  padding-right: ${({ theme }) => theme.spacing[6]};
  height: 100%;
`

export const TagListItemRemoveButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.fgSecondary};
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  height: 100%;
  justify-content: center;
  line-height: 0;
  margin-left: ${({ theme }) => theme.spacing[4]};
  padding: 0;
  width: 12px;
`

export const TagListInput = styled(TagListItem)<TagInputStyleProps>`
  background: none;
  flex-grow: 1;
  padding-left: 0;
  input {
    font-size: ${({ theme }) => theme.fontSizes[12]};
    font-weight: ${({ theme }) => theme.fontWeights[400]};
    border: none;
    width: 100%;
    padding-left: 4px;

    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      opacity: 0.75;
    }
  }
`
